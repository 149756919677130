import assertNever from "assert-never"
import React from "react"

const EmptyValue = ({ variant = "dash" }: { variant?: "dash" | "message" }) => {
  switch (variant) {
    case "dash":
      return <span className="text-muted">–</span>
    case "message":
      return <span className="text-muted">None</span>
    default:
      assertNever(variant)
  }
}

const MaybeNumber = ({ value }: { value: number | null | undefined }) => {
  if (value == null) return <EmptyValue />
  return <span>{value}</span>
}

const MaybeText = ({ value }: { value: string | null | undefined }) => {
  if (value == null || value.trim().length === 0)
    return (
      <div>
        <EmptyValue variant="message" />
      </div>
    )
  return <div className="whitespace-pre-wrap">{value}</div>
}

export { EmptyValue, MaybeNumber, MaybeText }
