import React from "react"
import { useId } from "@reach/auto-id"
// @ts-expect-error
import Select, { Option } from "react-select"
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager"

interface SearchSelectProps {
  options: Option[]
  values: Option[]
  onChange: StateManagerProps<Option, true>["onChange"]
  label: string
  isMulti?: boolean
  isClearable?: boolean
}

const SearchSelect = ({
  options,
  values,
  onChange,
  label,
  isMulti = true,
  isClearable = true,
}: SearchSelectProps) => {
  const id = useId()
  return (
    <div className={"mb-2 mt-2"} style={{ minWidth: "150px" }}>
      <label htmlFor={id} className="f-header-4">
        {label}
      </label>
      <Select
        inputId={id}
        className="basic-single"
        classNamePrefix="collection-select"
        isSearchable={true}
        isMulti={isMulti}
        name="ShippingPortSelect"
        options={options}
        isClearable={isClearable}
        onChange={onChange}
        value={values}
      />
    </div>
  )
}

export default SearchSelect
