import React from "react"
import { Field, FieldArray } from "formik"
import {
  InputWithFormikErrors,
  CheckboxWithFormikErrors,
} from "./WithFormikErrors"
import { Button, Label } from "./FormElements"
import CreatableSelect from "react-select/creatable"

type ContactsFormSectionProps = {
  formik: any
  initialContacts: any
  contactOptions: any
}

const ContactsFormSection = ({
  formik,
  initialContacts,
  contactOptions,
}: ContactsFormSectionProps) => {
  return (
    <>
      <div className="form-group d-flex">
        <Label>Maker Contacts</Label>
        <div className="w-100">
          <CreatableSelect
            id={`maker-order-contacts`}
            isMulti
            name={`contacts`}
            onChange={(options) => {
              const newContacts = formik.values.contacts.filter(
                (contact: any) => !contact.id,
              )
              const existingContacts = options.map((opt: any) => {
                return contactOptions.find((cOpt: any) => cOpt.id === opt.value)
              })

              const allContacts = [...newContacts, ...existingContacts]
              formik.setFieldValue("contacts", allContacts)
            }}
            options={contactOptions.map((item: any) => ({
              label: item.fullName,
              value: item.id,
            }))}
            defaultValue={initialContacts.map((item: any) => ({
              label: item.fullName,
              value: item.id,
            }))}
            classNamePrefix="collection-select"
            className="w-300px"
            isValidNewOption={() => false}
          />
        </div>
      </div>

      <div className="mb-4">
        <FieldArray
          name="contacts"
          render={(arrayHelpers) => (
            <>
              {formik.values.contacts.length > 0 && (
                <div className="card mb-3">
                  <ul className="list-group list-group-flush">
                    {formik.values.contacts.map((contact: any, i: any) => {
                      if (!contact || contact.hasOwnProperty("remove"))
                        return null

                      return (
                        <li className="list-group-item" key={i}>
                          <div className="row">
                            <div className="col">
                              <Label>Name</Label>
                              <Field
                                name={`contacts.${i}.fullName`}
                                as={InputWithFormikErrors}
                              />
                            </div>
                            <div className="col">
                              <Label>Email</Label>
                              <Field
                                name={`contacts.${i}.email`}
                                as={InputWithFormikErrors}
                              />
                            </div>
                            <div className="col">
                              <Label>Phone</Label>
                              <Field
                                name={`contacts.${i}.phone`}
                                as={InputWithFormikErrors}
                              />
                            </div>
                            <div className="col">
                              <Label>Job title</Label>
                              <Field
                                name={`contacts.${i}.jobTitle`}
                                as={InputWithFormikErrors}
                              />
                            </div>
                            {!contact.id && (
                              <div className="col-2">
                                <Field
                                  name={`contacts.${i}.copyToMaker`}
                                  as={CheckboxWithFormikErrors}
                                  id={`copy_to_maker_${i}`}
                                />
                                <label htmlFor={`copy_to_maker_${i}`}>
                                  Add Contact to Maker Profile
                                </label>
                              </div>
                            )}

                            <div className="col-1">
                              <button
                                type="button"
                                className="btn btn-danger float-right"
                                onClick={() => {
                                  if (contact.id) {
                                    formik.setFieldValue(
                                      `contacts.${i}.remove`,
                                      true,
                                    )
                                  } else {
                                    arrayHelpers.remove(i)
                                  }
                                }}
                              >
                                x
                              </button>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}

              <Button
                onClick={() => arrayHelpers.push({})}
                variant="secondary"
                className="btn-sm"
              >
                Add New Maker Contact
              </Button>
            </>
          )}
        />
      </div>
    </>
  )
}

export default ContactsFormSection
