import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    const element = $(this.element)

    element.select2({
      tags: true,
      createTag: element.data("disable-create")
        ? () => {
            return null
          }
        : undefined,
    })
  }
}
