import React from "react"

/**
 * @param {object} props
 * @param {Error} props.error
 */
const ErrorAlert = ({ error }) => (
  <div className="alert alert-danger" role="alert">
    <strong>Uh oh! </strong>
    {error.message}
  </div>
)
export default ErrorAlert
