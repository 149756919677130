import React from "react"
import Imgix from "react-imgix"
import { CertificationWithIconFragment } from "../generated/graphql"
import { EmptyValue } from "./EmptyValue"

export const CertificationList = ({
  certifications,
}: {
  certifications: Array<CertificationWithIconFragment>
}) => {
  return (
    <>
      {certifications.length > 0 ? (
        <div className="d-flex flex-wrap flex-grow-1 justify-content-around">
          {certifications.map((cert) => (
            <div className="p-2 mr-2 mb-2 d-flex flex-column" key={cert.name}>
              <Imgix
                src={cert.icon.imgixUrl}
                height={90}
                className="mb-2 mx-auto"
                htmlAttributes={{
                  alt: cert.name,
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <EmptyValue variant="message" />
      )}
    </>
  )
}
