import { Maker, MakerStatus } from "../generated/graphql"

export const activeMakerStatusLabels: Readonly<Record<
  Exclude<MakerStatus, MakerStatus.Deleted>,
  string
>> = {
  DRAFT: "Draft",
  ACTIVE: "Active",
  ARCHIVED: "Archived",
}

export const allMakerStatusLabels: Readonly<Record<MakerStatus, string>> = {
  ...activeMakerStatusLabels,
  DELETED: "Deleted",
}

export const makerStatusLabels = (
  maker: Pick<Maker, "status">,
): Readonly<Partial<Record<MakerStatus, string>>> =>
  maker.status === MakerStatus.Deleted
    ? allMakerStatusLabels
    : activeMakerStatusLabels
