import React from "react"
import { formatCents } from "../util/currency"
import { EmptyValue } from "./EmptyValue"

const FormatCentsMaybe = ({ cents }: { cents: number | null | undefined }) => {
  if (cents == null) return <EmptyValue />
  return <>{formatCents(cents)}</>
}

export { FormatCentsMaybe }
