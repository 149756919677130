import { ReactComponent as CaretDownFillIcon } from "bootstrap-icons/icons/caret-down-fill.svg"
import { ReactComponent as CaretUpFillIcon } from "bootstrap-icons/icons/caret-up-fill.svg"
import { ReactComponent as CaretUpIcon } from "bootstrap-icons/icons/caret-up.svg"
import { ReactComponent as EyeIcon } from "bootstrap-icons/icons/eye.svg"
import find from "lodash/find"
import React, { useState } from "react"
// @ts-expect-error todo
import CurrencyFormat from "react-currency-format"
import Imgix from "react-imgix"
import { UseInfiniteQueryResult } from "react-query"
import invariant from "tiny-invariant"
import {
  ProductSearchQuery,
  ProductSort,
  ProductSortField,
  SortOrder,
  useActiveClientOrderQuery,
} from "../generated/graphql"
import { compact } from "../util/filters"
import numberWithCommas from "../util/number-with-commas"
import AddProductToClientOrderButton from "./AddProductToClientOrderButton"
import { BorderBox, BorderBoxItem } from "./BorderBox"
import { CountryFlag } from "./CountryFlag"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import MakerSearchColumnSettingsModal from "./SearchColumnSettingsModal"
import { BorderBoxItemCell } from "./SearchElements"
import { withBasics } from "./withBasics"

export type Column = {
  name: string
  key: string
}
const IMAGE_COLUMN: Column = { key: "image", name: "Image" }
const NAME_COLUMN: Column = { key: "name", name: "Product Name" }
const PRICE_COLUMN: Column = { key: "price", name: "Price" }
const MINIMUM_ORDER_QUANTITY_COLUMN: Column = {
  key: "minimumOrderSize",
  name: "Minimum Order Quantity - Total Units",
}
const MAKER_ATTRIBUTES_COLUMN: Column = {
  key: "makerAttributes",
  name: "Maker Attributes",
}
const PRODUCT_CATEGORIES_COLUMN: Column = {
  key: "productCategory",
  name: "Product Category",
}
const MATERIALS_COLUMN: Column = {
  key: "materials",
  name: "Materials",
}
const MATERIAL_SUBCATEGORIES_COLUMN: Column = {
  key: "materialSubcategories",
  name: "Material Subcategories",
}
const CUSTOMIZATIONS_COLUMN: Column = {
  key: "customizations",
  name: "Customizations",
}
const PRODUCT_SUBCATEGORIES_COLUMN: Column = {
  key: "subcategories",
  name: "Product Subcategories",
}
const PREVIOUS_BUYERS_COLUMN: Column = {
  key: "previousBuyers",
  name: "Previous Buyers",
}
const MAKER_TYPE_COLUMN: Column = { key: "makerType", name: "Maker Type" }
const MAKER_ENVIRONMENT_COLUMN: Column = {
  key: "makerEnvironment",
  name: "Maker Environment",
}
const MAKER_CAPABILITIES_COLUMN: Column = {
  key: "makerCapabilities",
  name: "Maker Capabilities",
}
const CERTIFICATIONS_COLUMN: Column = {
  key: "certifications",
  name: "Certifications",
}
const COUNTRY_OF_ORIGIN_COLUMN: Column = {
  key: "countryOfOrigin",
  name: "Country of Origin",
}
const SKUS_COLUMN: Column = {
  key: "skus",
  name: "Platform IDs",
}
const PRODUCT_GROUP_STYLE_NUMBER_COLUMN: Column = {
  key: "styleNumbers",
  name: "Style Numbers",
}
const PRODUCT_NAME_ALIASES_COLUMN: Column = {
  key: "productNameAliases",
  name: "Name Aliases",
}
const PRODUCT_ATTRIBUTES_COLUMN: Column = {
  key: "productAttributes",
  name: "Product Attributes",
}
const ACTIONS_COLUMN: Column = { key: "actions", name: "Actions" }
const DEFAULT_COLUMNS: Column[] = [
  IMAGE_COLUMN,
  NAME_COLUMN,
  PRICE_COLUMN,
  MINIMUM_ORDER_QUANTITY_COLUMN,
  MAKER_ATTRIBUTES_COLUMN,
  PRODUCT_CATEGORIES_COLUMN,
  ACTIONS_COLUMN,
]
const ALL_COLUMNS: Column[] = DEFAULT_COLUMNS.concat(
  MATERIALS_COLUMN,
  MATERIAL_SUBCATEGORIES_COLUMN,
  CUSTOMIZATIONS_COLUMN,
  PRODUCT_SUBCATEGORIES_COLUMN,
  PREVIOUS_BUYERS_COLUMN,
  MAKER_TYPE_COLUMN,
  MAKER_ENVIRONMENT_COLUMN,
  MAKER_CAPABILITIES_COLUMN,
  CERTIFICATIONS_COLUMN,
  COUNTRY_OF_ORIGIN_COLUMN,
  SKUS_COLUMN,
  PRODUCT_GROUP_STYLE_NUMBER_COLUMN,
  PRODUCT_NAME_ALIASES_COLUMN,
  PRODUCT_ATTRIBUTES_COLUMN,
)

export const NAME_SORT_OPTION: ProductSort = {
  attribute: ProductSortField.Name,
  order: SortOrder.Asc,
}
export const PRICE_SORT_OPTION: ProductSort = {
  attribute: ProductSortField.Price,
  order: SortOrder.Asc,
}
export const MINIMUM_ORDER_QUANTITY_SORT_OPTION: ProductSort = {
  attribute: ProductSortField.MinimumOrderQuantity,
  order: SortOrder.Asc,
}

type ProductSearchResultsTableProps = {
  result: UseInfiniteQueryResult<ProductSearchQuery, Error>
  sort: ProductSort
  setSort: (v: ProductSort) => void
  query: string
}

const ProductSearchResults = (props: ProductSearchResultsTableProps) => {
  const activeClientOrderResult = useActiveClientOrderQuery()
  const { result, sort, setSort } = props
  const [visibleColumns, setVisibleColumns] = useState(DEFAULT_COLUMNS)
  const [columnSettingsOpen, setColumnSettingsOpen] = useState(false)
  const maybeRenderTableHeader = (column: Column) => {
    return find(visibleColumns, { key: column.key }) ? (
      <th>{column.name}</th>
    ) : null
  }
  const toggleColumnSort = (newSort: ProductSort) => {
    if (newSort.attribute === sort.attribute) {
      const newOrder =
        sort.order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
      setSort({ order: newOrder, attribute: sort.attribute })
    } else {
      setSort(newSort)
    }
  }
  const maybeRenderSortableTableHeader = (
    column: Column,
    colSort: ProductSort,
  ) => {
    return find(visibleColumns, {
      key: column.key,
    }) ? (
      <th
        onClick={() => toggleColumnSort(colSort)}
        style={{ cursor: "pointer" }}
      >
        {column.name}

        {colSort.attribute === sort.attribute ? (
          <span className="ml-1">
            {sort.order === SortOrder.Asc && <CaretUpFillIcon />}
            {sort.order === SortOrder.Desc && <CaretDownFillIcon />}
          </span>
        ) : (
          <span className="ml-1">
            <CaretUpIcon />
          </span>
        )}
      </th>
    ) : null
  }

  if (activeClientOrderResult.status === "error")
    return <RequestError {...result} />
  if (activeClientOrderResult.status === "loading") return <LoadingIndicator />
  invariant(activeClientOrderResult.data, `expected data`)
  const { activeClientOrder } = activeClientOrderResult.data

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  if (result.status === "loading") {
    return null
  }

  const { data } = result
  invariant(data)

  return (
    <>
      <div className="row mb-2">
        <div style={{ width: "100%" }}>
          <button
            className="float-right btn btn-link"
            onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
          >
            Manage
          </button>
          <MakerSearchColumnSettingsModal
            visibleColumns={visibleColumns}
            defaultColumns={DEFAULT_COLUMNS}
            allColumns={ALL_COLUMNS}
            isOpen={columnSettingsOpen}
            onClose={() => setColumnSettingsOpen(false)}
            onApply={(selectedColumns: Column[]) => {
              setVisibleColumns(selectedColumns)
              setColumnSettingsOpen(false)
            }}
          />
        </div>
      </div>

      <BorderBox>
        <div className="table-responsive">
          <table
            className="table table-hover table-fixed-truncated nice-table"
            style={{ tableLayout: "auto", width: "100%" }}
          >
            <thead>
              <tr>
                {maybeRenderTableHeader(IMAGE_COLUMN)}
                {maybeRenderSortableTableHeader(NAME_COLUMN, NAME_SORT_OPTION)}
                {maybeRenderSortableTableHeader(
                  PRICE_COLUMN,
                  PRICE_SORT_OPTION,
                )}
                {maybeRenderSortableTableHeader(
                  MINIMUM_ORDER_QUANTITY_COLUMN,
                  MINIMUM_ORDER_QUANTITY_SORT_OPTION,
                )}
                {maybeRenderTableHeader(MAKER_ATTRIBUTES_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_CATEGORIES_COLUMN)}
                {maybeRenderTableHeader(MATERIALS_COLUMN)}
                {maybeRenderTableHeader(MATERIAL_SUBCATEGORIES_COLUMN)}
                {maybeRenderTableHeader(CUSTOMIZATIONS_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_SUBCATEGORIES_COLUMN)}
                {maybeRenderTableHeader(PREVIOUS_BUYERS_COLUMN)}
                {maybeRenderTableHeader(MAKER_TYPE_COLUMN)}
                {maybeRenderTableHeader(MAKER_ENVIRONMENT_COLUMN)}
                {maybeRenderTableHeader(MAKER_CAPABILITIES_COLUMN)}
                {maybeRenderTableHeader(CERTIFICATIONS_COLUMN)}
                {maybeRenderTableHeader(COUNTRY_OF_ORIGIN_COLUMN)}
                {maybeRenderTableHeader(SKUS_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_GROUP_STYLE_NUMBER_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_NAME_ALIASES_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_ATTRIBUTES_COLUMN)}
                {maybeRenderTableHeader(ACTIONS_COLUMN)}
              </tr>
            </thead>
            <tbody>
              {data.pages
                .flatMap((page) => compact(page.products.nodes))
                .map((product) => (
                  <tr key={product.id}>
                    {visibleColumns.includes(IMAGE_COLUMN) && (
                      <td>
                        {product.image && product.image.imgixUrl.length > 0 && (
                          <Imgix
                            src={product.image.imgixUrl}
                            className="img-thumbnail"
                            sizes="48px"
                            htmlAttributes={{
                              alt: ``,
                              style: { width: `48px` },
                            }}
                            imgixParams={{
                              "ar": `1:1`,
                              "fit": `fill`,
                              "fill-color": `white`,
                            }}
                          />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(NAME_COLUMN) && (
                      <td>
                        <p className="mb-1">
                          <a href={product.showUrl} data-testid="product-link">
                            {product.name ?? "Unnamed"}
                          </a>
                        </p>
                        <p className="f-small-text mb-1">
                          <a href={product.maker.showUrl}>
                            {product.maker.name}
                          </a>
                        </p>
                        <p className="f-small-text mb-1">
                          <a href={product.productGroup.showUrl}>
                            {product.productGroup.name}
                          </a>
                        </p>
                      </td>
                    )}
                    {visibleColumns.includes(PRICE_COLUMN) && (
                      <td>
                        {product.price ? (
                          <span>
                            <CurrencyFormat
                              value={(product.price.amount / 100.0).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />{" "}
                            <div>
                              <small className="text-muted">
                                {product.price.quantity} Units
                              </small>
                            </div>
                          </span>
                        ) : null}
                      </td>
                    )}
                    {visibleColumns.includes(MINIMUM_ORDER_QUANTITY_COLUMN) && (
                      <td>{numberWithCommas(product.minimumOrderQuantity)}</td>
                    )}
                    {visibleColumns.includes(MAKER_ATTRIBUTES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.makerAttributes).map((attribute) => (
                            <BorderBoxItem key={attribute.id}>
                              {attribute.text}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(PRODUCT_CATEGORIES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.productCategories).map(
                            (productCategory) => (
                              <BorderBoxItem key={productCategory.id}>
                                {productCategory.name}
                              </BorderBoxItem>
                            ),
                          )}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(MATERIALS_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.materials.nodes).map((material) => (
                            <BorderBoxItem key={material.id}>
                              {material.name}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(MATERIAL_SUBCATEGORIES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.materialSubcategories.nodes).map(
                            (materialSubcategory) => (
                              <BorderBoxItem key={materialSubcategory.id}>
                                {materialSubcategory.name}
                              </BorderBoxItem>
                            ),
                          )}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(CUSTOMIZATIONS_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.customizations).map(
                            (customization) => (
                              <BorderBoxItem key={customization}>
                                {customization}
                              </BorderBoxItem>
                            ),
                          )}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(PRODUCT_SUBCATEGORIES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.subcategories).map((subcategory) => (
                            <BorderBoxItem key={subcategory}>
                              {subcategory}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(PREVIOUS_BUYERS_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {product.previousBuyers.map((previousBuyer) => (
                            <BorderBoxItem key={previousBuyer}>
                              {previousBuyer}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(MAKER_TYPE_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {product.maker.makerTypes.map((makerType) => (
                            <BorderBoxItem key={makerType}>
                              {makerType}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(MAKER_ENVIRONMENT_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {product.maker.environments.map(
                            (makerEnvironment) => (
                              <BorderBoxItem key={makerEnvironment}>
                                {makerEnvironment}
                              </BorderBoxItem>
                            ),
                          )}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(MAKER_CAPABILITIES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {product.maker.capabilities.map((makerCapability) => (
                            <BorderBoxItem key={makerCapability}>
                              {makerCapability}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(CERTIFICATIONS_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.makerCertificationTypes).map(
                            (makerCertification) => (
                              <BorderBoxItem key={makerCertification}>
                                {makerCertification}
                              </BorderBoxItem>
                            ),
                          )}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(COUNTRY_OF_ORIGIN_COLUMN) && (
                      <td>
                        {product.countryOfOrigin && (
                          <div key={product.countryOfOrigin.id}>
                            <CountryFlag country={product.countryOfOrigin} />{" "}
                            {product.countryOfOrigin.name}
                          </div>
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(SKUS_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {compact(product.skus).map((skus) => (
                            <BorderBoxItem key={skus.id}>
                              {skus.humanId}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(
                      PRODUCT_GROUP_STYLE_NUMBER_COLUMN,
                    ) && (
                      <td>
                        {product.styleNumber ? product.styleNumber : "None"}
                      </td>
                    )}
                    {visibleColumns.includes(PRODUCT_NAME_ALIASES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {product.nameAliases.map((nameAlias) => (
                            <BorderBoxItem key={nameAlias.id}>
                              {nameAlias.name}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(PRODUCT_ATTRIBUTES_COLUMN) && (
                      <td>
                        <BorderBoxItemCell>
                          {product.productAttributes.map((attribute) => (
                            <BorderBoxItem key={attribute.id}>
                              {attribute.name}
                            </BorderBoxItem>
                          ))}
                        </BorderBoxItemCell>
                      </td>
                    )}
                    {visibleColumns.includes(ACTIONS_COLUMN) && (
                      <td className="d-flex">
                        <a
                          className="btn btn-link"
                          data-content="View Product"
                          href={product.showUrl}
                        >
                          <EyeIcon />
                        </a>

                        {activeClientOrder && (
                          <AddProductToClientOrderButton
                            clientOrder={activeClientOrder.clientOrder}
                            product={product}
                            className="btn btn-primary mb-2"
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </BorderBox>
    </>
  )
}

export default withBasics(ProductSearchResults)
