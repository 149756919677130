import find from "lodash/find"
import React, { useState, useEffect } from "react"
import { ReactComponent as CaretDownFillIcon } from "bootstrap-icons/icons/caret-down-fill.svg"
import { ReactComponent as CaretUpFillIcon } from "bootstrap-icons/icons/caret-up-fill.svg"
import { UseInfiniteQueryResult } from "react-query"
import invariant from "tiny-invariant"
import {
  ClientOrdersQuery,
  useActiveClientOrderQuery,
  useUpdateActiveClientOrderMutation,
  useUpdateClientOrderMutation,
  useCurrentUserQuery,
  ClientOrderSort,
  SortOrder,
} from "../generated/graphql"
import { compact } from "../util/filters"
import { BorderBox } from "./BorderBox"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import ClientOrderSearchColumnSettingsModal from "./SearchColumnSettingsModal"
import {
  CREATED_AT_SORT_OPTION,
  DELIVERY_WINDOW_SORT_OPTION,
} from "./ClientOrdersListScreen"
import { formatDateLong } from "../util/dates"
import VisuallyHidden from "@reach/visually-hidden"
import { ReactComponent as EyeIcon } from "bootstrap-icons/icons/eye.svg"

export type Column = {
  name: string
  key: string
}

interface ColumnArrays {
  all: Column[]
  production: Column[]
  shipping: Column[]
}

const NAME_COLUMN: Column = { key: "id", name: "Client Project" }
const CLIENT_COLUMN: Column = { key: "client", name: "Client" }
const PO_REFERENCE_COLUMN: Column = {
  key: "externalPoReference",
  name: "External PO Reference",
}
const MAKER_COLUMN: Column = { key: "maker", name: "Maker" }
const MAKER_ORDERS_COLUMN: Column = { key: "makerOrders", name: "Maker Orders" }
const PROD_STATUS_COLUMN: Column = {
  key: "productionStatus",
  name: "Production Status",
}
const SHIPMENT_STATUS_COLUMN: Column = {
  key: "shipmentStatus",
  name: "Shipment Status",
}
const ACTIVE_TASK_COLUMN: Column = { key: "activeTasks", name: "Active Tasks" }
const TNA_COLUMN: Column = { key: "timeAndAction", name: "TnA" }
const MO_CUT_DATE_COLUMN: Column = { key: "moCutDate", name: "MO Cut Date" }
const EX_FACTORY_DATE_COLUMN: Column = {
  key: "exFactoryDate",
  name: "Ex Factory Date",
}
const COUNTRIES_COLUMN: Column = {
  key: "countriesOfOrigin",
  name: "Countries Of Origin",
}
const DELIVERY_WINDOW_COLUMN: Column = {
  key: "deliveryWindow",
  name: "Delivery Window",
}
const SALES_LEAD_COLUMN: Column = { key: "salesLeads", name: "Sales Leads" }
const PROD_LEAD_COLUMN: Column = {
  key: "productionLeads",
  name: "Production Leads",
}
const OPS_LEAD_COLUMN: Column = {
  key: "operationsLeads",
  name: "Operation Leads",
}
const DESIGN_LEAD_COLUMN: Column = {
  key: "designLeadsa",
  name: "Design Leads",
}
const FIN_LEAD_COLUMN: Column = { key: "finLeads", name: "Financial Leads" }
const IMPACT_LEAD_COLUMN: Column = {
  key: "impactLeads",
  name: "Impact Leads",
}

// Shipping columns
const SHIPPING_MODE_COLUMN: Column = {
  key: "shippingMode",
  name: "Shipping Mode",
}
const DEPARTURE_PORT_COLUMN: Column = {
  key: "departureShippingPort",
  name: "Export Port",
}
const DEPARTURE_PORT_AT_COLUMN: Column = {
  key: "departingPortAt",
  name: "Export Port Departure",
}
const ARRIVAL_PORT_COLUMN: Column = {
  key: "arrivalShippingPort",
  name: "Import Port",
}
const ARRIVAL_PORT_AT_COLUMN: Column = {
  key: "arrivingPortAt",
  name: "Import Port Arrival",
}
const VESSEL_STATUS_COLUMN: Column = {
  key: "vesselStatus",
  name: "Vessel Status",
}
const CUSTOMS_STATUS_COLUMN: Column = {
  key: "customsStatus",
  name: "Customs Status",
}
const FINAL_MILE_COLUMN: Column = {
  key: "finalMileDelivery",
  name: "Final Mile Delivery",
}
const DELIVERED_ON_COLUMN: Column = {
  key: "deliveredOn",
  name: "Delivered On",
}
const SHIPPING_DOCS_COLUMN: Column = {
  key: "shipmentDocs",
  name: "Shipping Docs",
}
const POD_DOCS_COLUMN: Column = {
  key: "shipmentDocs",
  name: "BOL/POD",
}
const PARCEL_CARRIER_COLUMN: Column = {
  key: "parcelCarrier",
  name: "Parcel Carrier",
}
const TRACKING_NUM_COLUMN: Column = {
  key: "trackingNumbers",
  name: "Tracking Numbers",
}

const ACTIONS_COLUMN: Column = { key: "actions", name: "Actions" }

const DATE_CREATED_COLUMN: Column = { key: "createdAt", name: "Created On" }
const DEFAULT_PRODUCTION_COLUMNS: Column[] = [
  NAME_COLUMN,
  CLIENT_COLUMN,
  PO_REFERENCE_COLUMN,
  MAKER_COLUMN,
  MAKER_ORDERS_COLUMN,
  PROD_STATUS_COLUMN,
  ACTIVE_TASK_COLUMN,
  TNA_COLUMN,
  EX_FACTORY_DATE_COLUMN,
  DELIVERY_WINDOW_COLUMN,
  SALES_LEAD_COLUMN,
  PROD_LEAD_COLUMN,
  OPS_LEAD_COLUMN,
  DATE_CREATED_COLUMN,
]
const DEFAULT_ALL_COLUMNS: Column[] = [
  NAME_COLUMN,
  CLIENT_COLUMN,
  MAKER_COLUMN,
  DATE_CREATED_COLUMN,
  EX_FACTORY_DATE_COLUMN,
  ACTIONS_COLUMN,
]
const DEFAULT_SHIPPING_COLUMNS: Column[] = [
  NAME_COLUMN,
  CLIENT_COLUMN,
  MAKER_COLUMN,
  MAKER_ORDERS_COLUMN,
  SHIPMENT_STATUS_COLUMN,
  COUNTRIES_COLUMN,
  PROD_LEAD_COLUMN,
  EX_FACTORY_DATE_COLUMN,
  SHIPPING_MODE_COLUMN,
  DEPARTURE_PORT_COLUMN,
  DEPARTURE_PORT_AT_COLUMN,
  ARRIVAL_PORT_COLUMN,
  ARRIVAL_PORT_AT_COLUMN,
  VESSEL_STATUS_COLUMN,
  CUSTOMS_STATUS_COLUMN,
  FINAL_MILE_COLUMN,
  DELIVERED_ON_COLUMN,
  SHIPPING_DOCS_COLUMN,
  POD_DOCS_COLUMN,
  PARCEL_CARRIER_COLUMN,
  TRACKING_NUM_COLUMN,
]

const DEFAULT_COLUMNS: ColumnArrays = {
  all: DEFAULT_ALL_COLUMNS,
  production: DEFAULT_PRODUCTION_COLUMNS,
  shipping: DEFAULT_SHIPPING_COLUMNS,
}

const ALL_COLUMNS = [
  NAME_COLUMN,
  CLIENT_COLUMN,
  PO_REFERENCE_COLUMN,
  MAKER_COLUMN,
  MAKER_ORDERS_COLUMN,
  PROD_STATUS_COLUMN,
  SHIPMENT_STATUS_COLUMN,
  ACTIVE_TASK_COLUMN,
  TNA_COLUMN,
  MO_CUT_DATE_COLUMN,
  EX_FACTORY_DATE_COLUMN,
  COUNTRIES_COLUMN,
  DELIVERY_WINDOW_COLUMN,
  SALES_LEAD_COLUMN,
  PROD_LEAD_COLUMN,
  OPS_LEAD_COLUMN,
  DESIGN_LEAD_COLUMN,
  FIN_LEAD_COLUMN,
  IMPACT_LEAD_COLUMN,
  DATE_CREATED_COLUMN,
  SHIPPING_MODE_COLUMN,
  DEPARTURE_PORT_COLUMN,
  DEPARTURE_PORT_AT_COLUMN,
  ARRIVAL_PORT_COLUMN,
  ARRIVAL_PORT_AT_COLUMN,
  VESSEL_STATUS_COLUMN,
  CUSTOMS_STATUS_COLUMN,
  FINAL_MILE_COLUMN,
  DELIVERED_ON_COLUMN,
  SHIPPING_DOCS_COLUMN,
  POD_DOCS_COLUMN,
  PARCEL_CARRIER_COLUMN,
  TRACKING_NUM_COLUMN,
]

type ClientOrderListResultsTableProps = {
  tracker: string
  result: UseInfiniteQueryResult<ClientOrdersQuery, Error>
  sorts: ClientOrderSort[]
  onColumnSortClick: (sort: ClientOrderSort) => void
}

const ClientOrderListResults = ({
  tracker,
  result,
  sorts,
  onColumnSortClick,
}: ClientOrderListResultsTableProps) => {
  const activeClientOrderResult = useActiveClientOrderQuery()
  const mutation = useUpdateActiveClientOrderMutation()
  const archiveMutation = useUpdateClientOrderMutation()
  const [columnSettingsOpen, setColumnSettingsOpen] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState<Column[]>(
    DEFAULT_COLUMNS.all,
  )
  const currentUserQuery = useCurrentUserQuery()

  // Update columns when tracker changes
  useEffect(() => {
    setVisibleColumns(DEFAULT_COLUMNS[tracker as keyof ColumnArrays])
  }, [tracker])

  if (currentUserQuery.status === "loading") return <LoadingIndicator />
  invariant(currentUserQuery.data, `expected data`)
  const { currentUser } = currentUserQuery.data

  if (activeClientOrderResult.status === "error")
    return <RequestError {...activeClientOrderResult} />
  if (activeClientOrderResult.status === "loading") return <LoadingIndicator />
  invariant(activeClientOrderResult.data, `expected data`)
  const { activeClientOrder } = activeClientOrderResult.data

  const handleAddProducts = (clientOrderId: string) => {
    mutation.mutate({ input: { clientOrderId } })
  }

  const handleArchive = async (clientOrderId: string) => {
    await archiveMutation.mutateAsync({
      input: { id: clientOrderId, archived: true },
    })
    result.refetch()
  }

  if (mutation.isSuccess) window.location.pathname = "/products"

  const maybeRenderTableHeader = (column: Column) => {
    return find(visibleColumns, { key: column.key }) ? (
      <th>{column.name}</th>
    ) : null
  }

  const isMutedText = (status: string) => {
    return (
      [
        "ready_for_shipment",
        "in_transit",
        "out_for_delivery",
        "delivered",
      ].includes(status) && tracker === "production"
    )
  }

  const maybeRenderSortableTableHeader = (
    column: Column,
    sort: ClientOrderSort,
    tooltipText?: string,
  ) => {
    const colSorts: ClientOrderSort[] = compact([
      find(sorts, { attribute: sort.attribute }),
    ])

    return find(visibleColumns, {
      key: column.key,
    }) ? (
      <th onClick={() => onColumnSortClick(sort)}>
        <div className="d-flex" data-toggle="tooltip" title={tooltipText}>
          {column.name}
          {colSorts.length > 0 ? (
            colSorts.map((sort: ClientOrderSort) => (
              <span className="ml-1" key={`${sort.attribute} ${sort.order}`}>
                {sort.order === SortOrder.Asc && <CaretUpFillIcon />}
                {sort.order === SortOrder.Desc && <CaretDownFillIcon />}
              </span>
            ))
          ) : (
            <span className="ml-1">
              <CaretDownFillIcon />
            </span>
          )}
        </div>
      </th>
    ) : null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  if (result.status === "loading") {
    return null
  }

  const { data } = result

  invariant(data)

  return (
    <div className="d-flex flex-column">
      <div>
        <button
          className="float-right btn btn-link f-action-text pb-2"
          onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
        >
          Manage
        </button>
        <ClientOrderSearchColumnSettingsModal
          visibleColumns={visibleColumns}
          defaultColumns={DEFAULT_COLUMNS[tracker as keyof ColumnArrays]}
          allColumns={ALL_COLUMNS}
          isOpen={columnSettingsOpen}
          onClose={() => setColumnSettingsOpen(false)}
          onApply={(selectedColumns: Column[]) => {
            setVisibleColumns(selectedColumns)
            setColumnSettingsOpen(false)
          }}
        />
      </div>
      <BorderBox>
        <div className="table-responsive">
          <table
            className="table table-hover table-fixed-truncated nice-table"
            style={{ tableLayout: "auto", width: "100%" }}
          >
            <thead>
              <tr>
                {maybeRenderTableHeader(NAME_COLUMN)}
                {maybeRenderTableHeader(CLIENT_COLUMN)}
                {maybeRenderTableHeader(PO_REFERENCE_COLUMN)}
                {maybeRenderTableHeader(MAKER_COLUMN)}
                {maybeRenderTableHeader(MAKER_ORDERS_COLUMN)}
                {maybeRenderTableHeader(PROD_STATUS_COLUMN)}
                {maybeRenderTableHeader(SHIPMENT_STATUS_COLUMN)}
                {maybeRenderTableHeader(ACTIVE_TASK_COLUMN)}
                {maybeRenderTableHeader(TNA_COLUMN)}
                {maybeRenderTableHeader(MO_CUT_DATE_COLUMN)}
                {maybeRenderTableHeader(EX_FACTORY_DATE_COLUMN)}
                {maybeRenderTableHeader(COUNTRIES_COLUMN)}

                {maybeRenderTableHeader(SHIPPING_MODE_COLUMN)}
                {maybeRenderTableHeader(DEPARTURE_PORT_COLUMN)}
                {maybeRenderTableHeader(DEPARTURE_PORT_AT_COLUMN)}
                {maybeRenderTableHeader(ARRIVAL_PORT_COLUMN)}
                {maybeRenderTableHeader(ARRIVAL_PORT_AT_COLUMN)}
                {maybeRenderTableHeader(VESSEL_STATUS_COLUMN)}
                {maybeRenderTableHeader(CUSTOMS_STATUS_COLUMN)}
                {maybeRenderTableHeader(FINAL_MILE_COLUMN)}
                {maybeRenderTableHeader(DELIVERED_ON_COLUMN)}
                {maybeRenderTableHeader(SHIPPING_DOCS_COLUMN)}
                {maybeRenderTableHeader(POD_DOCS_COLUMN)}
                {maybeRenderTableHeader(PARCEL_CARRIER_COLUMN)}
                {maybeRenderTableHeader(TRACKING_NUM_COLUMN)}

                {maybeRenderSortableTableHeader(
                  DELIVERY_WINDOW_COLUMN,
                  DELIVERY_WINDOW_SORT_OPTION,
                  "Delivery Window will sort client projects based on the latest date on the range",
                )}
                {maybeRenderTableHeader(SALES_LEAD_COLUMN)}
                {maybeRenderTableHeader(PROD_LEAD_COLUMN)}
                {maybeRenderTableHeader(OPS_LEAD_COLUMN)}
                {maybeRenderTableHeader(DESIGN_LEAD_COLUMN)}
                {maybeRenderTableHeader(FIN_LEAD_COLUMN)}
                {maybeRenderTableHeader(IMPACT_LEAD_COLUMN)}
                {maybeRenderSortableTableHeader(
                  DATE_CREATED_COLUMN,
                  CREATED_AT_SORT_OPTION,
                )}
                {maybeRenderTableHeader(ACTIONS_COLUMN)}
              </tr>
            </thead>
            <tbody>
              {data.pages
                .flatMap((page) => compact(page.clientOrders.nodes))
                .map((clientOrder) => (
                  <tr key={clientOrder.id}>
                    {visibleColumns.includes(NAME_COLUMN) && (
                      <td>
                        <a href={clientOrder.showUrl}>
                          #{clientOrder.humanId} - {clientOrder.description}
                        </a>
                      </td>
                    )}

                    {visibleColumns.includes(CLIENT_COLUMN) && (
                      <td>
                        <a href={clientOrder.client.showUrl}>
                          {clientOrder.client.name}
                        </a>
                      </td>
                    )}

                    {visibleColumns.includes(PO_REFERENCE_COLUMN) && (
                      <td>
                        {clientOrder.externalPoReference
                          ?.split("\n")
                          .map((refPiece, i) => (
                            <div key={i}>{refPiece}</div>
                          ))}
                      </td>
                    )}

                    {visibleColumns.includes(MAKER_COLUMN) && (
                      <td>
                        {clientOrder.makers.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker) => (
                            <div key={maker.id}>
                              <a href={maker.showUrl}>{maker.name}</a>
                            </div>
                          ))
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(MAKER_ORDERS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  <a href={mo.showUrl}>{mo.humanId}</a>
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(PROD_STATUS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.timeAndAction && mo?.productionStatus) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {mo.productionStatus
                                    .map((status) => {
                                      return _.capitalize(
                                        status.replaceAll("_", " "),
                                      )
                                    })
                                    .join(", ")}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(SHIPMENT_STATUS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {_.capitalize(
                                    mo.shipmentStatus.replaceAll("_", " "),
                                  )}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(ACTIVE_TASK_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {mo.activeTaskCount}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(TNA_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.timeAndAction) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  <a href={mo.timeAndAction.showUrl}>
                                    {mo.timeAndAction.humanId}
                                  </a>
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(MO_CUT_DATE_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.moCutDate) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {formatDateLong(mo.moCutDate)}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(EX_FACTORY_DATE_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.exFactoryDate) {
                              return (
                                <div
                                  key={mo.id}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {formatDateLong(mo.exFactoryDate)}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(COUNTRIES_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.countriesOfOrigin) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {mo.countriesOfOrigin
                                    .map((country) => {
                                      return country.name
                                    })
                                    .join(", ")}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(SHIPPING_MODE_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.shippingMode) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {_.capitalize(
                                    mo.shipment.shippingMode.replaceAll(
                                      "_",
                                      " ",
                                    ),
                                  )}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(DEPARTURE_PORT_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.departureShippingPort) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {mo.shipment.departureShippingPort.port}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(DEPARTURE_PORT_AT_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.departingPortAt) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {formatDateLong(mo.shipment.departingPortAt)}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(ARRIVAL_PORT_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.arrivalShippingPort) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {mo.shipment.arrivalShippingPort.port}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(ARRIVAL_PORT_AT_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.arrivingPortAt) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {formatDateLong(mo.shipment.arrivingPortAt)}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(VESSEL_STATUS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.vesselStatus) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {_.capitalize(
                                    mo.shipment.vesselStatus.replaceAll(
                                      "_",
                                      " ",
                                    ),
                                  )}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(CUSTOMS_STATUS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.customsStatus) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {_.capitalize(
                                    mo.shipment.customsStatus.replaceAll(
                                      "_",
                                      " ",
                                    ),
                                  )}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(FINAL_MILE_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.finalMileDelivery) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {_.capitalize(
                                    mo.shipment.finalMileDelivery.replaceAll(
                                      "_",
                                      " ",
                                    ),
                                  )}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(DELIVERED_ON_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.deliveredOn) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {formatDateLong(mo.shipment.deliveredOn)}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}

                    {visibleColumns.includes(SHIPPING_DOCS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.shipmentDocuments) {
                              const matchingDocs = mo.shipment.shipmentDocuments.filter(
                                (obj) =>
                                  obj.documentType !== "BOL" &&
                                  obj.documentType !== "POD",
                              )
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  <a href={mo.showUrl}>
                                    {matchingDocs.length || 0}
                                  </a>
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(POD_DOCS_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.shipmentDocuments) {
                              const matchingDocs = mo.shipment.shipmentDocuments.filter(
                                (obj) =>
                                  obj.documentType === "BOL" ||
                                  obj.documentType === "POD",
                              )
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  <a href={mo.showUrl}>
                                    {matchingDocs.length || 0}
                                  </a>
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(PARCEL_CARRIER_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.parcelCarrier) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {mo.shipment.parcelCarrier.name}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(TRACKING_NUM_COLUMN) && (
                      <td>
                        {clientOrder.makerOrders.nodes.length > 0 ? (
                          clientOrder.makers.nodes.map((maker, i) => {
                            let mo = clientOrder.makerOrders.nodes.find(
                              (mo) =>
                                mo.makerId === maker.id &&
                                mo.status !== "canceled",
                            )
                            if (mo?.shipment?.trackingNumbers) {
                              return (
                                <div
                                  key={i}
                                  className={
                                    isMutedText(mo.shipmentStatus)
                                      ? "faded"
                                      : ""
                                  }
                                >
                                  {`${mo.shipment.trackingNumbers.substring(
                                    0,
                                    10,
                                  )}...`}
                                </div>
                              )
                            } else {
                              return (
                                <div key={i}>
                                  <EmptyValue variant="message" />
                                </div>
                              )
                            }
                          })
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(DELIVERY_WINDOW_COLUMN) && (
                      <td>
                        {clientOrder.deliveryWindow?.startDate ? (
                          `${new Date(
                            clientOrder.deliveryWindow.startDate,
                          ).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                            timeZone: "UTC",
                          })} - ${new Date(
                            clientOrder.deliveryWindow.endDate,
                          ).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                            timeZone: "UTC",
                          })}`
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(SALES_LEAD_COLUMN) && (
                      <td>
                        <div>
                          {clientOrder.salesLeads.length > 0 ? (
                            clientOrder.salesLeads
                              .map((lead) => lead.fullName)
                              .join(", ")
                          ) : (
                            <EmptyValue variant="message" />
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(PROD_LEAD_COLUMN) && (
                      <td>
                        <div>
                          {clientOrder.productionLeads ? (
                            clientOrder.productionLeads
                              ?.map((lead) => lead.fullName)
                              .join(", ")
                          ) : (
                            <EmptyValue variant="message" />
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(OPS_LEAD_COLUMN) && (
                      <td>
                        <div>
                          {clientOrder.operationsLeads.length > 0 ? (
                            clientOrder.operationsLeads
                              .map((lead) => lead.fullName)
                              .join(", ")
                          ) : (
                            <EmptyValue variant="message" />
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(DESIGN_LEAD_COLUMN) && (
                      <td>
                        <div>
                          {clientOrder.designLeads ? (
                            clientOrder.designLeads
                              .map((lead) => lead.fullName)
                              .join(", ")
                          ) : (
                            <EmptyValue variant="message" />
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(FIN_LEAD_COLUMN) && (
                      <td>
                        <div>
                          {clientOrder.financialLeads.length > 0 ? (
                            clientOrder.financialLeads
                              .map((lead) => lead.fullName)
                              .join(", ")
                          ) : (
                            <EmptyValue variant="message" />
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(IMPACT_LEAD_COLUMN) && (
                      <td>
                        <div>
                          {clientOrder.impactLeads.length > 0 ? (
                            clientOrder.impactLeads
                              .map((lead) => lead.fullName)
                              .join(", ")
                          ) : (
                            <EmptyValue variant="message" />
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(DATE_CREATED_COLUMN) && (
                      <td>
                        <div>
                          {new Date(clientOrder.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              timeZone: "UTC",
                            },
                          )}{" "}
                          {new Date(clientOrder.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              timeZoneName: "short",
                            },
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(ACTIONS_COLUMN) && (
                      <td className="d-flex">
                        <a
                          className="btn btn-link"
                          data-content="View Product"
                          href={clientOrder.showUrl}
                        >
                          <EyeIcon />
                        </a>

                        {activeClientOrder &&
                        activeClientOrder.clientOrder.id === clientOrder.id ? (
                          <button
                            type="button"
                            className="btn btn-sm"
                            disabled={true}
                          >
                            Active
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={() => handleAddProducts(clientOrder.id)}
                              className="btn btn-pink btn-sm"
                              disabled={
                                mutation.isLoading || mutation.isSuccess
                              }
                            >
                              Add Products
                              <VisuallyHidden>Add Products</VisuallyHidden>
                            </button>
                            <RequestError {...mutation} />
                          </>
                        )}
                        {currentUser?.role === "admin" && (
                          <button
                            type="button"
                            className="btn btn-pink btn-sm"
                            onClick={() => handleArchive(clientOrder.id)}
                          >
                            Archive
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </BorderBox>
    </div>
  )
}

export default withBasics(ClientOrderListResults)
