const endpoint = "/graphql"

const csrfMeta = document.querySelector("meta[name=csrf-token]")
const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null

export async function graphqlRequest<TData, TVariables>(
  query: string,
  variables?: TVariables,
): Promise<TData> {
  const res = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      ...(csrf && { "X-CSRF-Token": csrf }),
    },
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: "same-origin",
  })

  const json = await res.json()

  if (json.errors) {
    const message = json.errors[0] ? json.errors[0].message : "Unknown error"

    throw new Error(message)
  }

  return json.data
}

export const graphqlFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
): (() => Promise<TData>) => {
  return () => graphqlRequest<TData, TVariables>(query, variables)
}
