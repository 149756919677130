import React from "react"
import { withBoundary } from "./ErrorBoundary"
import "react-toggle/style.css"
import Modal from "./Modal"
import * as S from "./MakerActivateButton.styled"
import { withBasics } from "./withBasics"
import { TimeAndActionCalendarTask } from "../generated/graphql"

interface TimeAndActionTaskStatusChangeModalProps {
  isOpen: boolean
  onClose: () => void
  task: TimeAndActionCalendarTask
  status: string
  currentUser: any
  updateTask: (task: TimeAndActionCalendarTask, changes: any) => void
}

const TimeAndActionTaskStatusChangeModal = ({
  isOpen,
  onClose,
  task,
  status,
  currentUser,
  updateTask,
}: TimeAndActionTaskStatusChangeModalProps) => {
  let dateField: keyof typeof task = "actualStartDate"
  let dateFieldLabel = "Start Date"
  let submitText = "Confirm In-Progress"

  if (status === "completed") {
    dateField = "dateCompleted"
    dateFieldLabel = "Completion Date"
    submitText = "Confirm Completed"
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const values: Record<string, string> = {}
    formData.forEach((value, key) => {
      values[key] = value as string
    })

    updateTask(task, {
      status: values.status,
      dateCompleted: values.dateCompleted,
      actualStartDate: values.actualStartDate,
      calendarChangeAttributes: {
        jobPosition: values.jobPosition || "",
        name: values.name || "",
      },
    })
  }

  return (
    <S.Container className="mb-2 mt-2">
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Time And Action Status Change</h5>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="mb-4 px-3">
                <p>
                  Please confirm that task <b>{task.name}</b> has been{" "}
                  {status === "completed" ? "completed" : "started"} and the{" "}
                  {status === "completed" ? "completion" : "start"} date.
                </p>

                <form onSubmit={handleSubmit}>
                  <input type="hidden" name="status" value={status} />

                  {status === "completed" && !task.actualStartDate && (
                    <>
                      <label
                        htmlFor={"actualStartDate"}
                        className="form-control-label font-weight-bold"
                      >
                        {"Start Date"}
                      </label>
                      <input
                        type="date"
                        name="actualStartDate"
                        max={new Date().toISOString().split("T")[0]}
                        className="form-control mb-2"
                        required
                      />
                    </>
                  )}

                  <label
                    htmlFor={dateField}
                    className="form-control-label font-weight-bold"
                  >
                    {dateFieldLabel}
                  </label>
                  <input
                    type="date"
                    name={dateField}
                    max={new Date().toISOString().split("T")[0]}
                    className="form-control mb-2"
                    required
                  />

                  {!currentUser && (
                    <>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="form-control-label font-weight-bold"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          className="form-control mb-2"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="jobPosition"
                          className="form-control-label font-weight-bold"
                        >
                          Job Position
                        </label>
                        <input
                          type="text"
                          name="jobPosition"
                          placeholder="Enter your job position"
                          className="form-control mb-2"
                          required
                        />
                      </div>
                    </>
                  )}

                  <div className="d-inline-flex align-items-center">
                    <div className="col-auto-w5 mr-2">
                      <button
                        type="submit"
                        className="btn btn-md btn-primary mr-2"
                      >
                        {submitText}
                      </button>
                      <button
                        type="button"
                        className="btn btn-md btn-secondary"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </Modal>
    </S.Container>
  )
}

export default withBoundary(withBasics(TimeAndActionTaskStatusChangeModal))
