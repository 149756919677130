// @ts-nocheck
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "template"]

  connect() {}

  add(e) {
    e.preventDefault()
    var parser = new DOMParser()
    this.containerTarget.appendChild(
      parser.parseFromString(
        this.templateTarget.innerHTML.replace(
          /CHILD_INDEX/g,
          new Date().getTime(),
        ),
        "text/html",
      ).body.firstChild,
    )
  }

  removePersisted(e) {
    e.preventDefault()

    var parent = e.currentTarget.parentNode
    var destroyField = parent.querySelectorAll(".destroy-field")[0]
    destroyField.value = "1"
    parent.classList.remove("d-flex")
    parent.classList.add("d-none")
  }

  remove(e) {
    e.preventDefault()
    var parent = e.currentTarget.parentNode
    parent.parentNode.removeChild(parent)
  }
}
