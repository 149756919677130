import { Controller } from "stimulus"
import $ from "jquery"
import { request } from "../util/request"

export default class extends Controller {
  connect() {
    const populateSubcategoriesSelect = (productCategoryId, selectedValues) => {
      $("#product_product_category_subcategories").empty()

      if (productCategoryId) {
        request({
          resource: `/product_categories/${productCategoryId}`,
          params: {},
        }).then((data) => {
          const { subcategories } = data
          subcategories.map((subcategory) => {
            if (
              $("#product_product_category_subcategories").find(
                "option[value='" + subcategory + "']",
              ).length
            ) {
              $("#product_product_category_subcategories")
                .val(subcategory)
                .trigger("change")
            } else {
              const selected = selectedValues.includes(subcategory)
              const newOption = new Option(
                subcategory,
                subcategory,
                true,
                selected,
              )
              $("#product_product_category_subcategories")
                .append(newOption)
                .trigger("change")
            }
            return undefined
          })

          $(".product_product_category_subcategories").show()
          $("#product_product_category_subcategories").select2({
            tags: true,
          })
        })
      } else {
        $(".product_product_category_subcategories").hide()
      }
    }

    const initialId = $("#product_product_category_product_category_id").val()
    const { initialSelected: initialSelectedString } = $(
      "#product_product_category_subcategories",
    ).data()
    const initialSelected = initialSelectedString.split(";")
    populateSubcategoriesSelect(initialId, initialSelected)
    $("#product_product_category_product_category_id").on("change", (event) => {
      populateSubcategoriesSelect(event.target.value, [])
    })
  }
}
