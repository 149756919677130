import { Controller } from "stimulus"
import $ from "jquery"
import invariant from "tiny-invariant"

export default class extends Controller {
  connect() {
    invariant(this.element instanceof HTMLElement)
    const preselected = this.element.dataset.preselected
    const select = $(this.element).select2({
      tags: false,
      tokenSeparators: [","],
    })

    if (preselected !== undefined) {
      select.val(preselected.split(","))
      select.trigger("change")
    }
  }
}
