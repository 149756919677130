/**
 * @example
 * ```tsx
 * pluralize({ singular: "a single apple", plural: "{{count}} apples", count: 42 })
 * ```
 */
export function pluralize({
  zero,
  singular,
  plural,
  count,
}: {
  singular: string
  plural: string
  count: number
  zero?: string
}) {
  let value: string

  switch (count) {
    case 0:
      value = zero ?? plural
      break
    case 1:
      value = singular
      break
    default:
      value = plural
  }

  return value.replace("{{count}}", count.toString())
}
