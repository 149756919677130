import { useField, useFormikContext } from "formik"
import React from "react"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

const toOption = (item: any, labelAttr: string) => {
  return typeof item === "object"
    ? { label: item[labelAttr], value: item.id }
    : { label: item, value: item }
}

const CollectionSelect = ({ name, value, options, labelAttr }: any) => {
  const formik = useFormikContext()

  return (
    <CreatableSelect
      className="w-100"
      name={name}
      defaultValue={(value || []).map((item: any) => toOption(item, labelAttr))}
      options={options.map((item: any) => toOption(item, labelAttr))}
      menuPosition="fixed"
      isMulti
      onChange={(value) =>
        formik.setFieldValue(
          name,
          value.map((x) => x.value),
        )
      }
      classNamePrefix="collection-select"
    />
  )
}

CollectionSelect.defaultProps = {
  labelAttr: "name",
}

export default CollectionSelect

export const CollectionSelectBasic = ({
  name,
  value,
  options,
  labelAttr,
}: any) => {
  const formik = useFormikContext()

  return (
    <CreatableSelect
      className="w-100"
      name={name}
      defaultValue={(value || []).map((item: any) => toOption(item, labelAttr))}
      options={options.map((item: any) => toOption(item, labelAttr))}
      menuPosition="fixed"
      isMulti
      onChange={(value) =>
        formik.setFieldValue(
          name,
          value.map((x) => x.value),
        )
      }
      classNamePrefix="collection-select"
    />
  )
}

export const MultiSelectField = ({
  options,
  ...props
}: { options: Array<{ label: string; value: string }> } & Omit<
  React.ComponentProps<typeof Select>,
  "options"
>) => {
  const [field, , { setValue }] = useField(props as any)
  return (
    <Select
      {...props}
      isMulti={true}
      options={options}
      onChange={(option) => {
        setValue(option)
      }}
      name={field.name}
      onBlur={field.onBlur}
      value={field.value}
    />
  )
}
