import React from "react"

const LoadingIndicator = () => (
  <div className="spinner-border text-primary" role="status">
    <span className="sr-only">Loading...</span>
  </div>
)
export default LoadingIndicator

export const CenteredLoadingIndicator = () => (
  <div className="d-flex py-3 justify-content-center">
    <LoadingIndicator />
  </div>
)
