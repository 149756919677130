import React, { useEffect, useMemo, useRef, useState } from "react"
import Imgix from "react-imgix"
import { ImageBackdrop, ImageOnHover } from "./LegacyImage"
import { ReactComponent as EyeSVG } from "../images/eye.svg"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import { ReactComponent as Plus40SVG } from "../images/plus-40.svg"
import VisuallyHidden from "@reach/visually-hidden"
import { useFormikContext } from "formik"
import { Blobby, blobImageUrl, directUploadUrl } from "../util/active-storage"
import { DirectUpload } from "@rails/activestorage"

export const ProductImage = (props: {
  id: string
  imgixUrl: string
  deleteImage(id: string): void
}) => {
  return (
    <div className="d-flex flex-column text-decoration-none position-relative">
      <Imgix
        src={props.imgixUrl}
        className="w-100 shadow"
        htmlAttributes={{
          alt: "",
          style: {
            aspectRatio: `1 / 1`,
          },
        }}
        sizes="200px"
        imgixParams={{
          "ar": `1:1`,
          "fit": `fill`,
          "fill-color": `white`,
        }}
      />
      <ImageBackdrop>
        <ImageOnHover>
          <a
            href={props.imgixUrl}
            className="d-flex text-white p-2"
            target="_blank"
            rel="noreferrer"
          >
            <EyeSVG style={{ width: 14 }} />
            <VisuallyHidden>View image</VisuallyHidden>
          </a>
        </ImageOnHover>
        <ImageOnHover>
          <button
            type="button"
            onClick={() => props.deleteImage(props.id)}
            className="border-0 bg-transparent d-flex p-2"
          >
            <TrashSVG
              style={{ width: 12, height: 14 }}
              className="text-danger"
            />
          </button>
        </ImageOnHover>
      </ImageBackdrop>
    </div>
  )
}

export const ProductImageBlob = (props: {
  blob: Blobby
  deleteBlob(id: string): void
}) => {
  return (
    <div className="d-flex flex-column text-decoration-none position-relative">
      <img
        src={blobImageUrl(props.blob)}
        className="shadow"
        alt=""
        style={{
          objectFit: "contain",
          aspectRatio: `1 / 1`,
        }}
      />
      <ImageBackdrop>
        <ImageOnHover>
          <button
            type="button"
            onClick={() => props.deleteBlob(props.blob.id)}
            className="border-0 bg-transparent d-flex p-2"
          >
            <TrashSVG
              style={{ width: 12, height: 14 }}
              className="text-danger"
            />
          </button>
        </ImageOnHover>
      </ImageBackdrop>
    </div>
  )
}

export const ProductImages = (props: { images: Array<any> }) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const formik = useFormikContext()
  const [images, setImages] = useState<Array<any>>(props.images)
  const [blobs, setBlobs] = useState<Array<Blobby>>([])

  const deleteImage = (id: string) => {
    setImages(
      images.map((image) => {
        if (image.id === id) {
          return { ...image, remove: true }
        } else {
          return image
        }
      }),
    )
  }

  const deleteBlob = (id: string) => {
    setBlobs(blobs.filter((blob) => blob.id !== id))
  }

  const handleUpload = () => {
    if (!fileRef.current) throw new Error(`expected file ref`)
    const files = fileRef.current.files
    if (!files) return

    for (const file of Array.from(files)) {
      const upload = new DirectUpload(file, directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          alert(`Error uploading file: ${error}`)
          return
        }

        setBlobs((prev) => [...prev, (blob as unknown) as Blobby])
      })
    }
  }

  const imageParams = useMemo(
    () => [
      ...images.map(({ id, remove }) => ({ id, remove })),
      ...blobs.map(({ signed_id }) => ({ signedId: signed_id })),
    ],
    [images, blobs],
  )

  useEffect(() => {
    // @ts-expect-error TODO(AM): revisit this logic- comparison to avoid infinite loop
    if (JSON.stringify(imageParams) !== JSON.stringify(formik.values.images))
      formik.setFieldValue("images", imageParams)
  })

  return (
    <>
      <div className="d-grid grid-cols-6 gap-4 mb-2">
        {images
          .filter((image) => !image.remove)
          .map((image) => (
            <ProductImage key={image.id} {...image} deleteImage={deleteImage} />
          ))}
        {blobs.map((blob) => (
          <ProductImageBlob key={blob.id} blob={blob} deleteBlob={deleteBlob} />
        ))}
        <input
          type="file"
          ref={fileRef}
          onChange={handleUpload}
          accept="image/*"
          className="d-none"
          id="add-image-input"
        />
        <label
          htmlFor="add-image-input"
          className="border border-dashed rounded border-pink px-3 pt-3 pb-2 mb-0 d-flex flex-wrap flex-grow-1 align-items-center justify-content-center"
          style={{ cursor: "pointer" }}
        >
          <Plus40SVG />
          <VisuallyHidden>Add Image</VisuallyHidden>
        </label>
      </div>
    </>
  )
}

export const ProductTechPack = (props: { techPack: any }) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const formik = useFormikContext()
  const [techPack, setTechPack] = useState<any>(props.techPack)

  const deleteTechPack = () => {
    setTechPack(null)
  }

  const handleUpload = () => {
    if (!fileRef.current) throw new Error(`expected file ref`)
    const files = fileRef.current.files
    if (!files) return

    for (const file of Array.from(files)) {
      const upload = new DirectUpload(file, directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          alert(`Error uploading file: ${error}`)
          return
        }

        setTechPack(blob)
      })
    }
  }

  const techPackParams = useMemo(
    () =>
      techPack
        ? {
            signedId: techPack.signed_id,
            remove: techPack.remove,
          }
        : null,
    [techPack],
  )

  useEffect(() => {
    if (
      // @ts-expect-error TODO(AM): revisit this logic- comparison to avoid infinite loop
      JSON.stringify(techPackParams) !== JSON.stringify(formik.values.techPack)
    )
      formik.setFieldValue("techPack", techPackParams, false)
  })

  return (
    <>
      <div className="d-grid grid-cols-6 gap-4 mb-2">
        {techPack ? (
          <div
            className="d-flex flex-column text-decoration-none position-relative bg-light text-muted small p-2"
            style={{
              aspectRatio: "1 / 1",
              overflow: "hidden",
              maxWidth: "200px",
            }}
          >
            {techPack.filename}
            <ImageBackdrop>
              <ImageOnHover>
                <button
                  type="button"
                  onClick={() => deleteTechPack()}
                  className="border-0 bg-transparent d-flex p-2"
                >
                  <TrashSVG
                    style={{ width: 12, height: 14 }}
                    className="text-danger"
                  />
                </button>
              </ImageOnHover>
            </ImageBackdrop>
          </div>
        ) : (
          <>
            <input
              type="file"
              ref={fileRef}
              onChange={handleUpload}
              className="d-none"
              id="add-tech-pack-input"
            />
            <label
              htmlFor="add-tech-pack-input"
              className="border border-dashed rounded border-pink px-3 pt-3 pb-2 d-flex flex-wrap flex-grow-1 align-items-center justify-content-center"
              style={{ cursor: "pointer", aspectRatio: "1 / 1" }}
            >
              <Plus40SVG />
              <VisuallyHidden>Add Tech Pack</VisuallyHidden>
            </label>
          </>
        )}
      </div>
    </>
  )
}
