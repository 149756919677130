// @ts-nocheck
import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["successIndicator", "savingIndicator"]

  connect() {
    this.successIndicatorTarget.hidden = true
    this.savingIndicatorTarget.hidden = true
  }

  onSuccess(e) {
    this.successIndicatorTarget.hidden = false
    this.savingIndicatorTarget.hidden = true
  }

  reloadOnSuccess(e) {
    this.successIndicatorTarget.hidden = false
    this.savingIndicatorTarget.hidden = true
    window.location.reload()
  }

  submit(e) {
    this.successIndicatorTarget.hidden = true
    this.savingIndicatorTarget.hidden = false
    Rails.fire(this.element, "submit")
  }
}
