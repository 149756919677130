import React from "react"

export const ErrorFallback = ({ error, componentStack }: any) => (
  <React.Fragment>
    <div>
      <div className="text-danger mb-1">
        You have encountered an unhandled error
      </div>
      <div className="text-monospace">{error.toString()}</div>
      <pre>{componentStack}</pre>
    </div>
  </React.Fragment>
)
