import React from "react"
import { withBoundary } from "./ErrorBoundary"
import "react-toggle/style.css"
import Modal from "./Modal"
import * as S from "./MakerActivateButton.styled"
import { withBasics } from "./withBasics"
import { TimeAndActionCalendarChange } from "../generated/graphql"
import ReactMarkdown from "react-markdown"
import { formatDateTimeLong } from "../util/dates"

interface TimeAndActionHistoryModalProps {
  isOpen: boolean
  onClose: () => void
  calendarChanges: TimeAndActionCalendarChange[]
}

const TimeAndActionHistoryModal = ({
  isOpen,
  onClose,
  calendarChanges,
}: TimeAndActionHistoryModalProps) => {
  return (
    <S.Container className="mb-2 mt-2">
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Time And Action History</h5>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              {calendarChanges.length > 0 ? (
                <ul className="list-group list-group-flush">
                  {calendarChanges.map((change) => (
                    <li key={change.id} className="list-group-item">
                      <ReactMarkdown>{`${
                        change.user?.displayName || change.responsible
                      } ${change.description} ${
                        change.relatedDescription || "n/a"
                      }`}</ReactMarkdown>
                      {change.notes && (
                        <div>
                          {"with note:"} <em>{change.notes}</em>
                        </div>
                      )}
                      <div>
                        <small>{formatDateTimeLong(change.createdAt)}</small>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="pt-3 pb-1 text-center">
                  {"The status hasn't been changed yet."}
                </p>
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </Modal>
    </S.Container>
  )
}

export default withBoundary(withBasics(TimeAndActionHistoryModal))
