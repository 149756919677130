import { ErrorBoundary } from "@sentry/react"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ErrorFallback } from "./ErrorFallback"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

/**
 * react query context, and a sentry error boundary :4)
 */
export const withBasics = <P extends Record<string, any>>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const componentDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || "UNKNOWN"

  const Wrapped = (props: P) => (
    <ErrorBoundary fallback={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    </ErrorBoundary>
  )

  Wrapped.displayName = `withBasics(${componentDisplayName})`

  return Wrapped
}
