import clsx from "clsx"
import React from "react"

const BorderBoxEmpty: React.FC = (props) => (
  <BorderBox>
    <div className="text-muted" {...props} />
  </BorderBox>
)

const BorderBox: React.FC<{ solid?: boolean }> = (props) => (
  <div
    className={clsx(
      "border",
      "rounded",
      "border-pink",
      "px-3",
      "pt-3",
      "pb-2",
      "d-flex",
      "flex-wrap",
      "flex-grow-1",
      "overflow-visible",
      "break-inside-avoid",
      {
        "border-dashed": !props.solid,
      },
    )}
    {...props}
  />
)

const BorderBoxItem: React.FC = (props) => (
  <div
    className="bg-pink rounded p-2 mr-2 mb-2 text-truncate text-14"
    {...props}
  />
)

const BorderBoxHeading: React.FC<{ uppercase?: boolean }> = ({
  uppercase = true,
  children,
}) => (
  <h6
    className={clsx("pl-2", uppercase && "text-uppercase")}
    style={{
      lineHeight: "19px",
      letterSpacing: "0.05em",
    }}
  >
    {children}
  </h6>
)

const BorderBoxContainer: React.FC = (props) => (
  <div className="mt-5 d-flex flex-column" {...props} />
)

export {
  BorderBox,
  BorderBoxContainer,
  BorderBoxEmpty,
  BorderBoxHeading,
  BorderBoxItem,
}
