import React from "react"
import clsx from "clsx"

const Label = ({ className, ...props }: JSX.IntrinsicElements["label"]) => (
  <label
    className={clsx(className, `form-control-label font-weight-bold`)}
    {...props}
  />
)

const Select = ({ className, ...props }: JSX.IntrinsicElements["select"]) => (
  <select className={clsx(className, `form-control`)} {...props} />
)

const Input = ({ className, ...props }: JSX.IntrinsicElements["input"]) => (
  <input className={clsx(className, `form-control`)} {...props} />
)

const TextArea = ({
  className,
  ...props
}: JSX.IntrinsicElements["textarea"]) => (
  <textarea className={clsx(className, `form-control`)} {...props} />
)

type Variants =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "link"

/**
 * defaults to type="button" (and not submit, as is html spec)
 */
const Button = ({
  variant,
  className,
  ...props
}: JSX.IntrinsicElements["button"] & { variant: Variants }) => (
  <button
    type={props.type ?? "button"}
    className={clsx(className, `btn btn-${variant}`)}
    {...props}
  />
)

const InvalidFeedback = ({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) => (
  <div className={clsx(className, `text-danger small w-100`)} {...props} />
)

export { Label, Select, Input, TextArea, Button, InvalidFeedback }
