const _ = (x) => {
  if (x == null) throw new Error("invariant: got null")
  return x
}

const login = (email, password) => {
  _(document.getElementById("user_email")).value = email
  _(document.getElementById("user_password")).value = password
  _(document.getElementById("session-new-submit")).click()
}

const updateSize = () => {
  const el = document.querySelector("#resizeOutput")
  if (el) {
    el.textContent = `${window.innerWidth}px`
  }
}
window.addEventListener("resize", updateSize)

window.addEventListener("load", () => {
  // see app/views/devise/sessions/new.html.haml
  document.querySelectorAll("[data-login]").forEach((node) => {
    node.addEventListener("click", (e) => {
      login(_(_(e.currentTarget).dataset.login), "password")
    })
  })
})
