import $ from "jquery"

// bootstrap sanitizes html for popups, tooltips. this allows more elements
// https://getbootstrap.com/docs/4.3/getting-started/javascript/#sanitizer
// @ts-ignore

const initPopovers = () => {
  let myDefaultWhiteList = $.fn.tooltip.Constructor.Default.whiteList

  myDefaultWhiteList.table = ["class"]
  myDefaultWhiteList.thead = []
  myDefaultWhiteList.tbody = []
  myDefaultWhiteList.tr = []
  myDefaultWhiteList.th = []
  myDefaultWhiteList.td = []
  myDefaultWhiteList.td = []
  myDefaultWhiteList.img = [...myDefaultWhiteList.img, "srcset"]

  $(() => {
    $('[data-toggle="popover"]').popover({ whiteList: myDefaultWhiteList })
    $('[data-toggle="tooltip"]').tooltip({ whiteList: myDefaultWhiteList })
  })
}

initPopovers()

export { initPopovers }
