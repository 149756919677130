import find from "lodash/find"
import React, { useState } from "react"
import { UseInfiniteQueryResult } from "react-query"
import invariant from "tiny-invariant"
import { MakerOrderSearchQuery } from "../generated/graphql"
import { compact } from "../util/filters"
import { BorderBox } from "./BorderBox"
import { RequestError } from "./Errors"
import { withBasics } from "./withBasics"
import MakerOrderSearchColumnSettingsModal from "./SearchColumnSettingsModal"

export type Column = {
  name: string
  key: string
}
const ID_COLUMN: Column = { key: "id", name: "Order" }
const DESCRIPTION_COLUMN: Column = { key: "description", name: "Description" }
const MAKER_COLUMN: Column = { key: "maker", name: "Maker" }
const STATUS_COLUMN: Column = { key: "status", name: "Status" }
const CREATED_AT_COLUMN: Column = { key: "createdAt", name: "Date Created" }
const EX_FACTORY_DATE_COLUMN: Column = {
  key: "exFactoryDate",
  name: "Ex-Factory Date",
}

const DEFAULT_COLUMNS: Column[] = [
  ID_COLUMN,
  DESCRIPTION_COLUMN,
  MAKER_COLUMN,
  STATUS_COLUMN,
  CREATED_AT_COLUMN,
  EX_FACTORY_DATE_COLUMN,
]

const ALL_COLUMNS = DEFAULT_COLUMNS

type MakerOrderListResultsTableProps = {
  result: UseInfiniteQueryResult<MakerOrderSearchQuery, Error>
}

const MakerOrderListResults = ({ result }: MakerOrderListResultsTableProps) => {
  const [columnSettingsOpen, setColumnSettingsOpen] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState(DEFAULT_COLUMNS)

  const maybeRenderTableHeader = (column: Column) => {
    return find(visibleColumns, { key: column.key }) ? (
      <th>{column.name}</th>
    ) : null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  if (result.status === "loading") {
    return null
  }

  const { data } = result
  invariant(data)

  return (
    <div className="d-flex flex-column">
      <div>
        <button
          className="float-right btn btn-link f-action-text pb-2"
          onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
        >
          Manage
        </button>
        <MakerOrderSearchColumnSettingsModal
          visibleColumns={visibleColumns}
          defaultColumns={DEFAULT_COLUMNS}
          allColumns={ALL_COLUMNS}
          isOpen={columnSettingsOpen}
          onClose={() => setColumnSettingsOpen(false)}
          onApply={(selectedColumns: Column[]) => {
            setVisibleColumns(selectedColumns)
            setColumnSettingsOpen(false)
          }}
        />
      </div>
      <BorderBox>
        <div className="table-responsive">
          <table
            className="table table-hover table-fixed-truncated nice-table"
            style={{ tableLayout: "auto", width: "100%" }}
          >
            <thead>
              <tr>
                {maybeRenderTableHeader(ID_COLUMN)}
                {maybeRenderTableHeader(DESCRIPTION_COLUMN)}
                {maybeRenderTableHeader(MAKER_COLUMN)}
                {maybeRenderTableHeader(STATUS_COLUMN)}
                {maybeRenderTableHeader(CREATED_AT_COLUMN)}
                {maybeRenderTableHeader(EX_FACTORY_DATE_COLUMN)}
              </tr>
            </thead>
            <tbody>
              {data.pages
                .flatMap((page) => compact(page.makerOrders.nodes))
                .map((makerOrder) => (
                  <tr key={makerOrder.id}>
                    {visibleColumns.includes(ID_COLUMN) && (
                      <td>
                        <a href={makerOrder.showUrl}>#{makerOrder.humanId}</a>
                      </td>
                    )}
                    {visibleColumns.includes(DESCRIPTION_COLUMN) && (
                      <td>
                        <div key={makerOrder.maker.id}>
                          {makerOrder.clientOrder.description}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(MAKER_COLUMN) && (
                      <td>
                        <div key={makerOrder.maker.id}>
                          <a href={makerOrder.maker.showUrl}>
                            {makerOrder.maker.name}
                          </a>
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(STATUS_COLUMN) && (
                      <td>
                        <div key={makerOrder.id}>
                          {makerOrder.humanStatus &&
                            _.capitalize(
                              makerOrder.humanStatus.replaceAll("_", " "),
                            )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(CREATED_AT_COLUMN) && (
                      <td>
                        <div key={makerOrder.id}>
                          {new Date(makerOrder.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              timeZone: "UTC",
                            },
                          )}{" "}
                          {new Date(makerOrder.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              timeZoneName: "short",
                            },
                          )}
                        </div>
                      </td>
                    )}
                    {visibleColumns.includes(EX_FACTORY_DATE_COLUMN) && (
                      <td>
                        <div key={makerOrder.id}>
                          {makerOrder.exFactoryDate != null &&
                            new Date(
                              makerOrder.exFactoryDate,
                            ).toLocaleDateString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              timeZone: "UTC",
                            })}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </BorderBox>
    </div>
  )
}

export default withBasics(MakerOrderListResults)
