import capitalize from "lodash/capitalize"
import filter from "lodash/filter"
import React from "react"
import Select, { OnChangeValue } from "react-select"
import invariant from "tiny-invariant"
import {
  ModeOfTransit,
  ShippingPort,
  ShippingPortLocation,
  useShippingPortsQuery,
} from "../generated/graphql"
import { RequestError } from "./Errors"
import S from "./ShippingPortSelect.styled"

type ShippingPortId = ShippingPort["id"]

type ShippingPortOption = {
  value: ShippingPortId
  label: string
  modeOfTransit: ModeOfTransit
}

const ShippingPortSelect = ({
  makerId,
  locationType,
  modeOfTransit,
  onChange,
  value,
}: {
  makerId?: string
  locationType: ShippingPortLocation
  modeOfTransit: ModeOfTransit
  onChange: (newValue: OnChangeValue<ShippingPortOption, false>) => void
  value: ShippingPortId | null
}) => {
  const result = useShippingPortsQuery({ makerId, locationType })

  if (result.isLoading) {
    return null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  invariant(result.data, `expected data`)

  let { shippingPorts } = result.data

  if (modeOfTransit) {
    shippingPorts = filter(shippingPorts, { modeOfTransit })
  }

  const options: Array<ShippingPortOption> = shippingPorts.map(
    (shippingPort) => {
      const { id: value, port, country, modeOfTransit } = shippingPort
      const { name } = country
      const label = `${port} - ${name} (${capitalize(modeOfTransit)})`
      return { value, label, modeOfTransit }
    },
  )

  return (
    <S.Container className="mb-2 mt-2">
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable={true}
        name="ShippingPortSelect"
        options={options}
        isClearable={true}
        onChange={onChange}
        value={options.find((o) => o.value === value) ?? null}
      />

      {makerId != null && shippingPorts.length === 0 ? (
        <div className="alert alert-danger" role="alert">
          The maker does not have any shipping ports.
        </div>
      ) : null}
    </S.Container>
  )
}

export default ShippingPortSelect
