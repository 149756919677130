import React from "react"
import { useField, useFormikContext } from "formik"
import { Input, Select, TextArea } from "./FormElements"
import DatePicker from "react-datepicker"
import clsx from "clsx"
import Checkbox from "./Checkbox"
import UnitInput from "./UnitInput"
import { format } from "date-fns"
import { createBasicDate } from "../util/dates"

export const SelectWithFormikErrors = ({ ...props }) => {
  const [field, meta] = useField(props.name)
  const isInvalid = meta.touched && meta.error

  return (
    <>
      <Select
        {...field}
        {...props}
        className={clsx(
          "form-control",
          props.className,
          isInvalid && "is-invalid",
        )}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}

export const InputWithFormikErrors = ({ ...props }) => {
  const [field, meta] = useField(props.name)
  const isInvalid = meta.touched && meta.error

  return (
    <>
      <Input
        {...field}
        {...props}
        className={clsx(
          "form-control",
          props.className,
          isInvalid && "is-invalid",
        )}
        value={props.value || ""}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}

export const TextAreaWithFormikErrors = ({ ...props }) => {
  const [field, meta] = useField(props.name)
  const isInvalid = meta.touched && meta.error

  return (
    <>
      <TextArea
        {...field}
        {...props}
        className={clsx("form-control", isInvalid && "is-invalid")}
        value={props.value || ""}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}

export const CheckboxWithFormikErrors = ({ ...props }) => {
  const [field, meta] = useField(props.name)
  const isInvalid = meta.touched && meta.error

  return (
    <>
      <Checkbox
        id={props.name}
        {...field}
        {...props}
        className={clsx(isInvalid && "is-invalid")}
        checked={props.value || false}
        label={props.label || ""}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}

export const DateInputWithFormikErrors = ({ ...props }) => {
  const formik = useFormikContext()
  const [field, meta] = useField(props.name)
  const isInvalid = meta.error

  return (
    <>
      <DatePicker
        {...field}
        {...props}
        className={clsx("form-control", isInvalid && "is-invalid")}
        value={props.value || ""}
        maxDate={props.maxDate}
        selected={(field.value && createBasicDate(field.value)) || null}
        onChange={(date: Date) => {
          props.onChange(format(date, "yyyy-MM-dd"))
          if (date) formik.setFieldValue(props.name, format(date, "yyyy-MM-dd"))
          else formik.setFieldValue(props.name, date)
        }}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}

export const DateRangeInputWithFormikErrors = ({ ...props }) => {
  const formik = useFormikContext()
  const [field, meta] = useField(props.name)
  const isInvalid = meta.error

  return (
    <>
      <DatePicker
        {...field}
        {...props}
        selectsRange
        startDate={
          field.value?.startDate ? createBasicDate(field.value.startDate) : null
        }
        endDate={
          field.value?.endDate ? createBasicDate(field.value.endDate) : null
        }
        className={clsx("form-control", isInvalid && "is-invalid")}
        onChange={(datesAry: any) => {
          if (datesAry[0] && datesAry[1]) {
            formik.setFieldValue(props.name, {
              startDate: format(datesAry[0], "yyyy-MM-dd"),
              endDate: format(datesAry[1], "yyyy-MM-dd"),
            })
          } else if (datesAry[0]) {
            formik.setFieldValue(props.name, {
              startDate: format(datesAry[0], "yyyy-MM-dd"),
            })
          }
        }}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}

export const UnitInputWithFormikErrors = ({ ...props }) => {
  const [field, meta, helpers] = useField(props.name)
  const isInvalid = meta.touched && meta.error

  return (
    <>
      <UnitInput
        unitName={props.unitName}
        unit={props.unit}
        value={field.value}
        // @ts-expect-error
        onChange={(value) => helpers.setValue(value)}
      />

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </>
  )
}
