import React from "react"
import { ImpactReportMakersForm } from "./ImpactReportMakers"
import { withBasics } from "./withBasics"

const ImpactReportMakersPdf = (props: { data: any }) => {
  return (
    <ImpactReportMakersForm
      impactReport={props.data.impactReport}
      readOnly={true}
    />
  )
}
export default withBasics(ImpactReportMakersPdf)
