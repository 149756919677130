import React from "react"
import clsx from "clsx"
import { useBetween } from "use-between"
import { withBasics } from "./withBasics"
import { useUnitState } from "../util/units"

function UnitToggle() {
  const { unitSystem, setUnitSystem } = useBetween(useUnitState)

  return (
    <div className="btn-group">
      <label
        className={clsx(
          "btn",
          "btn-sm",
          unitSystem === "imperial" ? "btn-pink" : "btn-outline-pink",
        )}
        onClick={() => setUnitSystem("imperial")}
      >
        Imperial
      </label>

      <label
        className={clsx(
          "btn",
          "btn-sm",
          unitSystem === "metric" ? "btn-pink" : "btn-outline-pink",
        )}
        onClick={() => setUnitSystem("metric")}
      >
        Metric
      </label>
    </div>
  )
}

export default withBasics(UnitToggle)
