export type RequestParams = {
  resource: string
  params?: { [x: string]: string | number | Date | null | boolean } // boolean questionable here...
}

export async function request({ resource, params }: RequestParams) {
  const csrfMeta = document.querySelector("meta[name=csrf-token]")
  const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null

  const headers: HeadersInit = new Headers()
  headers.set("Content-Type", "application/json")
  headers.set("Accept", "application/json")
  if (csrf) headers.set("X-CSRF-Token", csrf)

  const [path, search] = resource.split("?")
  const searchParams = new URLSearchParams(search)
  if (params) {
    Object.entries(params).forEach(([k, v]) => searchParams.set(k, `${v}`))
  }
  const searchParamsString = searchParams.toString()
  const url = `${path}${searchParamsString ? `?${searchParamsString}` : ``}`

  const response = await fetch(url, {
    headers: headers,
    credentials: "same-origin",
  })

  if (!response.ok) {
    throw new Error(`Response not ok (${response.status})`)
  }

  return await response.json()
}

export async function queryRequest(
  _key: string,
  ...args: Parameters<typeof request>
) {
  return request(...args)
}

export async function putData(url: string, data = {}) {
  const csrfMeta = document.querySelector("meta[name=csrf-token]")
  const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null

  const headers: HeadersInit = new Headers()
  headers.set("Content-Type", "application/json")
  headers.set("Accept", "application/json")
  if (csrf) headers.set("X-CSRF-Token", csrf)

  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    credentials: "same-origin",
    headers: headers,
    body: JSON.stringify(data),
  })
  return response.json()
}

export async function deleteRequest(url: string) {
  const csrfMeta = document.querySelector("meta[name=csrf-token]")
  const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null

  const headers: HeadersInit = new Headers()
  headers.set("Content-Type", "application/json")
  headers.set("Accept", "application/json")
  if (csrf) headers.set("X-CSRF-Token", csrf)

  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    credentials: "same-origin",
    headers: headers,
  })
  return response.json()
}
