import clsx from "clsx"
import { addDays, format, isAfter, parseISO } from "date-fns"
import { Field, Form, Formik } from "formik"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import snakecaseKeys from "snakecase-keys"
import invariant from "tiny-invariant"
import { initPopovers } from "../dom/popover"
import {
  Scalars,
  useCogsValidUntilQuery,
  useUpdateCogsExpiresOnMutation,
} from "../generated/graphql"
import { listify } from "../util/listify"
import { request } from "../util/request"
import { AutoSave } from "./AutoSave"
import { MultiSelectField } from "./CollectionSelect"
import { withBoundary } from "./ErrorBoundary"
import { RequestError } from "./Errors"
import { Label } from "./FormElements"
import LoadingIndicator from "./LoadingIndicator"

const CogsTableShippingRate = ({
  exportPortId,
  importPortId,
  includeShippingCost,
  includeDutyRate,
  productId,
  productGroupId,
  mode,
}: {
  exportPortId: Scalars["ID"]
  importPortId: Scalars["ID"]
  includeShippingCost: boolean
  includeDutyRate: boolean
  productId?: Scalars["ID"]
  productGroupId: Scalars["ID"]
  mode: "edit" | "view"
}) => {
  const params = {
    exportPortId,
    importPortId,
    includeShippingCost,
    includeDutyRate,
    productId: productId || null,
  }
  const [shippingRate, setShippingRate] = useState<any>(null)
  const [exportPort, setExportPort] = useState<any>(null)
  const [importPort, setImportPort] = useState<any>(null)

  const result = useQuery({
    queryKey: [
      `cogs_table`,
      {
        params,
      },
    ] as const,
    queryFn: async ({ queryKey }) => {
      const params = queryKey[1].params
      const result = await request({
        resource: `/product-groups/${productGroupId}/cogs-table-settings`,
        params: snakecaseKeys(params),
      })
      return result
    },
  })

  useEffect(() => {
    const data = result.data
    if (data == null) return

    // @ts-expect-error need jquery types
    $('[data-toggle="popover"]').popover("dispose")
    // @ts-expect-error need jquery types
    $('[data-toggle="tooltip"]').tooltip("dispose")

    setShippingRate(data.shippingRate)
    setExportPort(data.exportPort)
    setImportPort(data.importPort)
    initPopovers()
  }, [result.data])

  return (
    <>
      {exportPort != null && importPort != null ? (
        <div className="container">
          <div className="row">
            <div className="col px-0">
              {shippingRate ? (
                <p className="alert alert-primary">
                  Shipping Transit Time: {shippingRate.min_transit_time} to{" "}
                  {shippingRate.max_transit_time} days.
                </p>
              ) : (
                <p className="alert alert-danger">
                  We could not find a shipping rate between {exportPort.port}{" "}
                  and {importPort.port}
                </p>
              )}
            </div>
          </div>

          {exportPort.mode_of_transit !== importPort.mode_of_transit ? (
            <div className="row">
              <div className="col">
                <p className="alert alert-danger">
                  {exportPort.port} mode of transite (
                  {exportPort.mode_of_transit}) does not match
                  {importPort.port} mode of transit (
                  {importPort.mode_of_transit}
                  ).
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {result.data && result.data.content != null ? (
        <>
          <div className="mt-3 d-flex flex-column">
            <h6
              className="pl-2 text-uppercase"
              style={{ lineHeight: `19px`, letterSpacing: "0.05em" }}
            >
              COGS
            </h6>
            {productId != null ? (
              <div className="d-flex mt-2 mb-2">
                <CogsValidUntilForm productId={productId} mode={mode} />
              </div>
            ) : null}

            <div className="border border-dashed rounded border-pink px-3 pt-3 pb-2 d-flex flex-wrap flex-grow-1 overflow-x-auto">
              <div
                dangerouslySetInnerHTML={{
                  __html: result.data.content,
                }}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default withBoundary(CogsTableShippingRate)

const CogsValidUntilForm = ({
  productId,
  mode,
}: {
  productId: string
  mode: "edit" | "view"
}) => {
  const queryResult = useCogsValidUntilQuery({
    productId: productId,
  })
  const mutation = useUpdateCogsExpiresOnMutation()

  if (queryResult.status === "loading") return <LoadingIndicator />
  if (queryResult.status === "error") return <RequestError {...queryResult} />
  const { data } = queryResult
  invariant(data, `expected data`)

  const initialValues: {
    cogsExpiresOn: string
    pricingOwners: Array<{ label: string; value: string }>
  } = {
    cogsExpiresOn: data.product.cogsExpiresOn ?? "",
    pricingOwners: data.product.pricingOwners.nodes,
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values) => {
        await mutation.mutateAsync({
          input: {
            id: productId,
            cogsExpiresOn:
              values.cogsExpiresOn === "" ? null : values.cogsExpiresOn,
            pricingOwnerIds: values.pricingOwners.map((x) => x.value),
          },
        })
        queryResult.refetch()
      }}
    >
      {(formik) => (
        <Form>
          <div className="d-flex">
            <div
              className={clsx(
                "mb-2 mr-3 d-flex",
                mode === "edit" ? "flex-column" : "flex-row",
              )}
            >
              <Label htmlFor="cogsExpiresOn" className="mr-2 text-nowrap">
                Valid until
              </Label>
              <div className="mr-2">
                {mode === "edit" ? (
                  <Field
                    type="date"
                    name="cogsExpiresOn"
                    id="cogsExpiresOn"
                    className="form-control"
                  />
                ) : mode === "view" ? (
                  <>
                    {queryResult.data.product.cogsExpiresOn == null ? (
                      "–"
                    ) : (
                      <ExpiresOnDate
                        expiresOn={queryResult.data.product.cogsExpiresOn}
                      />
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div
              className={clsx(
                "mb-2 mr-3 d-flex",
                mode === "edit" ? "flex-column" : "flex-row",
              )}
            >
              <Label htmlFor="pricingOwners" className="mr-2 text-nowrap">
                Pricing Lead
              </Label>

              <div className="mr-2">
                {mode === "edit" ? (
                  <Field
                    name="pricingOwners"
                    as={MultiSelectField}
                    options={data.product.pricingOwnerOptions.nodes}
                  />
                ) : mode === "view" ? (
                  <div className="">
                    {listify(
                      data.product.pricingOwners.nodes.map(
                        (user) => user.label,
                      ),
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="align-self-end pb-2">
              {(mutation.isLoading || queryResult.isLoading) && (
                <LoadingIndicator />
              )}
              <AutoSave debounceMs={300} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ExpiresOnDate = ({ expiresOn }: { expiresOn: string }) => {
  const date = parseISO(expiresOn)
  const warningDate = addDays(date, -14)
  const isWarning = isAfter(new Date(), warningDate)
  const display = format(date, `MMM d, y`)
  return (
    <div className={clsx(`text-nowrap`, isWarning && "text-danger")}>
      {display}
    </div>
  )
}
