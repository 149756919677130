// @ts-nocheck
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitBtn", "requiredField"]

  connect() {
    this.checkFields()
  }

  requiredFieldChanged() {
    this.checkFields()
  }

  checkFields() {
    const allFieldsFilled = this.requiredFieldTargets.every(
      (field) => field.value.trim() !== "",
    )
    this.submitBtnTarget.disabled = !allFieldsFilled
  }
}
