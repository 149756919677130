import { DirectUpload } from "@rails/activestorage"
import { TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs"
import VisuallyHidden from "@reach/visually-hidden"
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik"
import React, { useEffect, useRef, useState } from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import * as Yup from "yup"
import {
  ClientOrderShowMakerFragment,
  ClientOrderStatus,
  ShippingPort,
  UpdateClientOrderInput,
  UpdateClientOrderMutation,
  UpdateClientOrderStatusMutation,
  useClientOrderHistoryQuery,
  useClientOrderShowQuery,
  useUpdateActiveClientOrderMutation,
  useUpdateClientOrderMutation,
  useUpdateClientOrderStatusMutation,
  ClientOrderProductInput,
  ClientOrderProductSkuInput,
  ClientOrderProductLineItemInput,
  ClientOrderProductCustomizationInput,
  ClientOrderProductAddonInput,
  MaterialSubcategory,
} from "../generated/graphql"
import { ReactComponent as CheckCircleSVG } from "../images/check-circle.svg"
import { ReactComponent as HistorySVG } from "../images/history.svg"
import { ReactComponent as InfoCircleSVG } from "../images/info-circle.svg"
import { ReactComponent as PlusCircleSVG } from "../images/plus-circle.svg"
import { ReactComponent as TimesCircleSVG } from "../images/times-circle.svg"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import { ReactComponent as LockSVG } from "../images/lock.svg"
import { Blobby, blobImageUrl, directUploadUrl } from "../util/active-storage"
import { deepChangedValuesDiff } from "../util/changed-values"
import { formatDate } from "../util/dates"
import { BorderBox, BorderBoxContainer, BorderBoxHeading } from "./BorderBox"
import ClientOrderConfirmModal from "./ClientOrderConfirmModal"
import { FormatCentsMaybe } from "./Currency"
import EditLink from "./EditLink"
import DuplicateButton from "./DuplicateButton"
import { ReactComponent as CopySVG } from "../images/copy.svg"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import { Button, Select } from "./FormElements"
import InputGroup from "./InputGroup"
import LoadingIndicator from "./LoadingIndicator"
import MaybeTooltip from "./MaybeTooltip"
import Modal from "./Modal"
import { ModeOfTransitLabel } from "./ModeOfTransitLabel"
import { FormatPercentageMaybe } from "./Percentage"
import ProductDimensions from "./ProductDimensions"
import { StyledTab } from "./StyledTab"
import { withBasics } from "./withBasics"
import {
  InputWithFormikErrors,
  SelectWithFormikErrors,
} from "./WithFormikErrors"
import ReactMarkdown from "react-markdown"
import { initPopovers } from "../dom/popover"
import { formatCents } from "../util/currency"
import Checkbox from "./Checkbox"
import { formatPercentage } from "../util/percentage"
import numberWithCommas from "../util/number-with-commas"
import { ClientDetailsAddress } from "../util/addresses"
import ContactsSection from "./ContactsSection"

type ClientOrderProps = {
  clientOrder: ClientOrderShowMakerFragment
}

const ClientOrderProfile = (props: { id: string }) => {
  const result = useClientOrderShowQuery({ id: props.id })

  const mutation = useUpdateActiveClientOrderMutation()
  const handleAddProducts = () => {
    mutation.mutate({ input: { clientOrderId: props.id } })
  }
  if (mutation.isSuccess) window.location.pathname = "/products"

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const clientOrder = result.data.clientOrder

  return (
    <div className="d-flex flex-column">
      <Header clientOrder={clientOrder} />

      <BorderBoxContainer>
        <BorderBoxHeading>Status</BorderBoxHeading>
        <BorderBox solid={true}>
          <StatusSection clientOrder={clientOrder} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Order Details</BorderBoxHeading>
        <BorderBox solid={true}>
          <OrderDetailsSection clientOrder={clientOrder} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Contacts</BorderBoxHeading>
        <BorderBox solid={true}>
          <ContactsSection order={clientOrder} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Internal Notes</BorderBoxHeading>
        <BorderBox solid={true}>
          <InternalNotesSection clientOrder={clientOrder} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Summary</BorderBoxHeading>
        <BorderBox solid={true}>
          <SummarySection clientOrder={clientOrder} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex align-items-baseline w-100">
          <BorderBoxHeading>Products</BorderBoxHeading>
          <button
            type="button"
            onClick={handleAddProducts}
            className="btn btn-sm text-dark-pink ml-auto"
            disabled={mutation.isLoading || mutation.isSuccess}
          >
            Add
          </button>
          <RequestError {...mutation} />
        </div>
        <BorderBox>
          <ProductsSection clientOrder={clientOrder} />
        </BorderBox>
      </BorderBoxContainer>
    </div>
  )
}

const clientOrderStatusLabels: Readonly<Record<ClientOrderStatus, string>> = {
  OPPORTUNITY: "Opportunity",
  PENDING_COSTING: "Pending Costing",
  AWAITING_PRESENTATION: "Awaiting Presentation",
  AWAITING_CLIENT_APPROVAL: "Awaiting Client Approval",
  CONVERTED: "Converted",
  PENDING_MAKER_ORDERS: "Pending Maker Order(s)",
  AWAITING_MAKER_ORDER_APPROVALS: "Awaiting Maker Order Approval(s)",
  MAKER_ORDERS_APPROVED: "Maker Order(s) Approved",
  IN_PRODUCTION: "In Production",
  IN_TRANSIT: "In Transit",
  LCA_DATA_REQUEST_COMPLETED: "LCA Data Request Completed",
  DELIVERED: "Delivered",
  COMPLETE: "Complete",
  LOST: "Lost",
}

const ClientOrderStatusSelect = ({ clientOrder }: ClientOrderProps) => {
  const mutation = useUpdateClientOrderStatusMutation()

  const handleChange = async (status: string) => {
    const {
      updateClientOrderStatus: result,
    }: UpdateClientOrderStatusMutation = await mutation.mutateAsync({
      input: {
        id: clientOrder.id,
        status,
      },
    })

    if (result && result.errors.length) {
      alert("Error updating status")
    } else {
      window.location.href = clientOrder.showUrl + (window.location.hash || "")
    }
  }

  return (
    <div className="d-flex align-items-center">
      <label
        htmlFor="client-order-status"
        className="text-nowrap font-weight-bold mb-0 mr-3"
        style={{ fontSize: "20px" }}
      >
        Project Status:
      </label>

      <Select
        id="client-order-status"
        defaultValue={clientOrder.status || ""}
        onChange={(e) => handleChange(e.target.value.toLowerCase())}
        style={{ maxWidth: "250px" }}
      >
        <option></option>
        {clientOrder.statusOptions.map((option) => (
          <option key={option} value={option}>
            {clientOrderStatusLabels[option]}
          </option>
        ))}
      </Select>
    </div>
  )
}

const Header = ({ clientOrder }: ClientOrderProps) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false)
  const createdAtDate = new Date(clientOrder.createdAt)

  return (
    <div className="mt-4">
      <div className="d-flex flex-row align-items-end mb-4">
        <div className="d-flex flex-column">
          <h1 className="f-title mb-0">
            Client Project #{clientOrder.humanId} {clientOrder.description}
            <span className="d-inline-block d-flex">
              <EditLink
                {...clientOrder}
                variant="icon"
                className="mt-2 ml-3"
                width={16}
              />
              <DuplicateButton
                {...clientOrder}
                variant="icon"
                className="mt-2 ml-3"
                width={16}
              />

              <a href="d-flex align-self-center align-items-center text-dark-pink mt-2 ml-3">
                <CopySVG />
              </a>
            </span>
          </h1>

          {clientOrder.creatorUser && (
            <div
              className="font-weight-bold text-nowrap"
              style={{ fontSize: "14px" }}
            >
              {`Created by ${
                clientOrder.creatorUser
                  ? clientOrder.creatorUser.fullName
                  : "n/a"
              } on ${createdAtDate.toLocaleString()}`}
            </div>
          )}
        </div>

        <div className="ml-auto">
          {clientOrder.impactReport ? (
            <a href={clientOrder.impactReport.showUrl} className="btn btn-dark">
              Review Impact Report
            </a>
          ) : (
            <a href={clientOrder.newImpactReportUrl} className="btn btn-dark">
              Create Impact Report
            </a>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <a
          href={clientOrder.client.showUrl}
          className="d-block font-weight-bold mb-2"
          style={{ fontSize: "20px" }}
        >
          {clientOrder.client.name}
        </a>

        <div
          className="font-weight-bold text-nowrap"
          style={{ fontSize: "20px" }}
        >
          Delivery Window:{" "}
          {clientOrder.deliveryWindow ? (
            <>
              {`${formatDate(
                clientOrder.deliveryWindow.startDate,
              )} - ${formatDate(clientOrder.deliveryWindow.endDate)}`}
            </>
          ) : (
            <EmptyValue variant="dash" />
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <ClientOrderStatusSelect clientOrder={clientOrder} />

        <div className="text-right">
          <button
            className="btn btn-sm btn-link f-action-text align-middle pb-0"
            onClick={() => setHistoryModalOpen(!historyModalOpen)}
          >
            <HistorySVG height={13} />
            <span className="pl-1">View History</span>
          </button>
          <ClientOrderHistoryModal
            clientOrder={clientOrder}
            isOpen={historyModalOpen}
            onClose={() => setHistoryModalOpen(false)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex">
          <div className="col-4">
            <div className="d-flex">
              <strong className="mr-3">Sales Lead:</strong>
              <div>
                {clientOrder.salesLeads.map((lead) => lead.fullName).join(", ")}
              </div>
            </div>
            <div className="d-flex">
              <strong className="mr-3">Design Lead:</strong>
              <div>
                {clientOrder.designLeads &&
                  clientOrder.designLeads
                    .map((lead) => lead.fullName)
                    .join(", ")}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <strong className="mr-3">Production Lead:</strong>
              <div>
                {clientOrder.productionLeads &&
                  clientOrder.productionLeads
                    .map((lead) => lead.fullName)
                    .join(", ")}
              </div>
            </div>
            <div className="d-flex">
              <strong className="mr-3">Impact Lead:</strong>
              <div>
                {clientOrder.impactLeads
                  .map((lead) => lead.fullName)
                  .join(", ")}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <strong className="mr-3">Operations Lead:</strong>
              <div>
                {clientOrder.operationsLeads
                  .map((lead) => lead.fullName)
                  .join(", ")}
              </div>
            </div>
            <div className="d-flex">
              <strong className="mr-3">Financial Lead:</strong>
              <div>
                {clientOrder.financialLeads
                  .map((lead) => lead.fullName)
                  .join(", ")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type ClientOrderHistoryModalProps = {
  clientOrder: ClientOrderShowMakerFragment
  isOpen: boolean
  onClose: () => void
}

const ClientOrderHistoryModal = ({
  clientOrder,
  isOpen,
  onClose,
}: ClientOrderHistoryModalProps) => {
  // TODO(AM): only perform query when modal opened for the first time
  const result = useClientOrderHistoryQuery({ id: clientOrder.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const changes = result.data.clientOrder.orderChanges

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-dialog" role="document">
        <div className="modal-content p-4" style={{ minWidth: "500px" }}>
          <div className="modal-header">
            <h4 className="modal-title">Client Project History</h4>
          </div>

          <div className="modal-body">
            <ul className="list-group list-group-flush">
              {changes.length > 0 ? (
                changes.map((change, i) => (
                  <li key={i} className="list-group-item px-0">
                    <ReactMarkdown components={{ p: "div" }}>
                      {`${change.ownerName} ${change.description}`}
                    </ReactMarkdown>
                    <small className="text-muted">
                      {formatDate(change.createdAt)}
                    </small>
                  </li>
                ))
              ) : (
                <p className="pt-3 pb-1 text-center">
                  The status hasn't been changed yet.
                </p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const StatusSection = ({ clientOrder }: ClientOrderProps) => (
  <div className="row w-100 m-0 pb-1">
    <div className="col-md-12 col-lg-6 p-2">
      <BorderBox>
        <div className="d-flex align-items-baseline w-100">
          <a
            className="font-weight-bold"
            href={clientOrder.salesPresentationsUrl}
          >
            Sales Presentations
          </a>
          <a
            className="ml-auto f-small-text"
            href={clientOrder.newSalesPresentationUrl}
            data-testid="new-sales-presentation"
          >
            Add
          </a>
        </div>
        {clientOrder.salesPresentations.nodes.length > 0 ? (
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th className="pl-2">Order #</th>
                  <th className="pr-2 text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {clientOrder.salesPresentations.nodes
                  .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                  .map((salesPresentation) => (
                    <tr key={salesPresentation.id}>
                      <td className="pl-2">
                        <a href={salesPresentation.showUrl}>
                          {salesPresentation.humanId}
                        </a>
                      </td>
                      <td className="pr-2 text-right">
                        <div
                          data-toggle="tooltip"
                          title={salesPresentation.humanPdfStatus}
                        >
                          {(() => {
                            switch (salesPresentation.pdfStatus) {
                              case "finished":
                                return <CheckCircleSVG />
                              case "generation_failed":
                              case "upload_failed":
                              case "other_failed":
                                return <TimesCircleSVG />
                              default:
                                return <InfoCircleSVG />
                            }
                          })()}
                        </div>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td
                    colSpan={2}
                    className="text-center f-small-text border-bottom-0 pb-0"
                  >
                    <a href={clientOrder.salesPresentationsUrl}>View All</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-muted mt-2 mb-2">
            No Sales Presentations have been added yet.
          </div>
        )}
      </BorderBox>
    </div>

    <div className="col-md-12 col-lg-6 p-2">
      <BorderBox>
        <div className="d-flex w-100">
          <a
            className="font-weight-bold"
            href={clientOrder.clientApprovalOrdersUrl}
          >
            Client Approval Orders
          </a>
          <a
            className="ml-auto f-small-text"
            href={clientOrder.newClientApprovalOrderUrl}
          >
            Add
          </a>
        </div>
        {clientOrder.clientApprovalOrders.nodes.length > 0 ? (
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th className="pl-2">Order #</th>
                  <th className="pr-2 text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {clientOrder.clientApprovalOrders.nodes
                  .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                  .map((clientApprovalOrder) => (
                    <tr key={clientApprovalOrder.id}>
                      <td className="pl-2">
                        <a href={clientApprovalOrder.showUrl}>
                          {clientApprovalOrder.humanId}
                        </a>
                      </td>
                      <td className="pr-2 text-right">
                        <div
                          data-toggle="tooltip"
                          title={clientApprovalOrder.humanStatus}
                        >
                          {(() => {
                            switch (clientApprovalOrder.status) {
                              case "accepted":
                                return <CheckCircleSVG />
                              case "rejected":
                              case "invalidated":
                                return <TimesCircleSVG />
                              default:
                                return <InfoCircleSVG />
                            }
                          })()}
                        </div>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td
                    colSpan={2}
                    className="text-center f-small-text border-bottom-0 pb-0"
                  >
                    <a href={clientOrder.clientApprovalOrdersUrl}>View All</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-muted mt-2 mb-2">
            No Client Approval Orders have been added yet.
          </div>
        )}
      </BorderBox>
    </div>

    <div className="col-md-12 col-lg-6 p-2">
      <BorderBox>
        <div className="d-flex w-100">
          <a className="font-weight-bold" href={clientOrder.timeAndActionsUrl}>
            Time and Actions
          </a>
          <a
            className="ml-auto f-small-text"
            href={clientOrder.newTimeAndActionUrl}
            data-testid="new-maker-order"
          >
            Add
          </a>
        </div>
        {clientOrder.timeAndActions.nodes.length > 0 ? (
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th className="pl-2">Order #</th>
                  <th className="pr-2 text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {clientOrder.timeAndActions.nodes
                  .slice(0, 3)
                  .sort((a, b) =>
                    a.active ? -1 : 1 || b.createdAt.localeCompare(a.createdAt),
                  )
                  .map((timeAndAction) => (
                    <tr key={timeAndAction.id}>
                      <td className="pl-2">
                        <a href={timeAndAction.showUrl}>
                          {timeAndAction.humanId}
                        </a>
                      </td>
                      <td className="pr-2 text-right">
                        <div
                          data-toggle="tooltip"
                          title={timeAndAction.active ? "Active" : "Canceled"}
                        >
                          {(() => {
                            switch (timeAndAction.active) {
                              case true:
                                return <CheckCircleSVG />
                              case false:
                                return <TimesCircleSVG />
                              default:
                                return <InfoCircleSVG />
                            }
                          })()}
                        </div>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td
                    colSpan={2}
                    className="text-center f-small-text border-bottom-0 pb-0"
                  >
                    <a href={clientOrder.timeAndActionsUrl}>View All</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-muted mt-2 mb-2">
            No Time and Actions have been added yet.
          </div>
        )}
      </BorderBox>
    </div>

    <div className="col-md-12 col-lg-6 p-2">
      <BorderBox>
        <div className="d-flex w-100">
          <a className="font-weight-bold" href={clientOrder.makerOrdersUrl}>
            Maker Orders
          </a>
          <a
            className="ml-auto f-small-text"
            href={clientOrder.newMakerOrderUrl}
            data-testid="new-maker-order"
          >
            Add
          </a>
        </div>
        {clientOrder.makerOrders.nodes.length > 0 ? (
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th className="pl-2">Order #</th>
                  <th className="pr-2 text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {clientOrder.makerOrders.nodes
                  .slice(0, 3)
                  .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                  .map((makerOrder) => (
                    <tr key={makerOrder.id}>
                      <td className="pl-2">
                        <a href={makerOrder.showUrl}>{makerOrder.humanId}</a>
                      </td>
                      <td className="pr-2 text-right">
                        <div
                          data-toggle="tooltip"
                          title={makerOrder.humanStatus}
                        >
                          {(() => {
                            if (makerOrder.status === "pending")
                              return <LockSVG style={{ marginRight: "3px" }} />

                            switch (makerOrder.status) {
                              case "approved":
                                return <CheckCircleSVG />
                              case "rejected":
                              case "canceled":
                                return <TimesCircleSVG />
                              default:
                                return <InfoCircleSVG />
                            }
                          })()}
                        </div>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td
                    colSpan={2}
                    className="text-center f-small-text border-bottom-0 pb-0"
                  >
                    <a href={clientOrder.makerOrdersUrl}>View All</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-muted mt-2 mb-2">
            No Maker Orders have been added yet.
          </div>
        )}
      </BorderBox>
    </div>
  </div>
)

const OrderDetailsSection = ({ clientOrder }: ClientOrderProps) => (
  <>
    <div className="row w-100 m-0 pb-1">
      <div className="col-md-12 col-lg-4 p-2">
        <div className="d-flex">
          <p className="font-weight-bold mb-1 ml-2">Shipping Address</p>
        </div>
        <BorderBox>
          {clientOrder.shippingAddress ? (
            <ClientDetailsAddress address={clientOrder.shippingAddress} />
          ) : (
            <EmptyValue variant="message" />
          )}
        </BorderBox>
      </div>
      <div className="col-md-12 col-lg-4 p-2">
        <p className="font-weight-bold mb-1 ml-2">Billing Address</p>
        <BorderBox>
          {clientOrder.billingAddress ? (
            <ClientDetailsAddress address={clientOrder.billingAddress} />
          ) : (
            <EmptyValue variant="message" />
          )}
        </BorderBox>
      </div>
      <div className="col-md-12 col-lg-4 p-2">
        <p className="font-weight-bold mb-1 ml-2">References</p>
        <BorderBox>
          <div className="position-relative flex-grow-1 mb-4">
            <div>
              <strong className="mr-2">Invoice Status:</strong>
              {"Unpaid" /* TODO */}
            </div>
            <div>
              <strong className="mr-2">Client Payment Terms:</strong>
              {clientOrder.paymentTerms || <EmptyValue variant="message" />}
            </div>
            <div>
              <strong className="mr-2">External PO Reference:</strong>
              {clientOrder.externalPoReference || (
                <EmptyValue variant="message" />
              )}
            </div>
            <div>
              <strong className="mr-2">External PO Files:</strong>
              <div>
                {clientOrder.clientSoPo && clientOrder.clientSoPo.length > 0 ? (
                  clientOrder.clientSoPo.map((soPo, i) => (
                    <a href={soPo.url} key={i} className="d-block">
                      {soPo.filename}
                    </a>
                  ))
                ) : (
                  <EmptyValue variant="message" />
                )}
              </div>
            </div>
            <div className="d-flex">
              <strong className="mr-2">Reference Images:</strong>
              <div>
                {clientOrder.referenceImages.length > 0 ? (
                  clientOrder.referenceImages.map((referenceImage) => (
                    <a href={referenceImage.url} className="d-block">
                      {referenceImage.filename}
                    </a>
                  ))
                ) : (
                  <EmptyValue variant="message" />
                )}
              </div>
            </div>
          </div>
        </BorderBox>
      </div>
    </div>
  </>
)

const InternalNotesSection = ({ clientOrder }: ClientOrderProps) => (
  <>{clientOrder.internalNotes || <EmptyValue variant="message" />}</>
)

const SummarySection = ({ clientOrder }: ClientOrderProps) => (
  <div className="d-flex w-100 justify-content-between mx-2 mb-2">
    <table>
      <tbody>
        <tr>
          <td className="font-weight-bold pr-4 p-1">Unique Products</td>
          <td>{numberWithCommas(clientOrder.totalUniqueProducts)}</td>
        </tr>
        <tr>
          <td className="font-weight-bold pr-4 p-1">Total Makers</td>
          <td>{numberWithCommas(clientOrder.makers.nodes.length)}</td>
        </tr>
        <tr>
          <td className="font-weight-bold pr-4 p-1">Total Units</td>
          <td>{numberWithCommas(clientOrder.totalSkuUnits)}</td>
        </tr>
      </tbody>
    </table>

    <table>
      <tbody>
        <tr>
          <td className="font-weight-bold pr-4 p-1">Total Order Cost</td>
          <td>
            <FormatCentsMaybe cents={clientOrder.totalCostCents} />
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold pr-4 p-1">Total Order Margin</td>
          <td>
            <FormatPercentageMaybe percentage={clientOrder.totalMargin} />
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold pr-4 p-1">Total Order Retail</td>
          <td>
            <FormatCentsMaybe cents={clientOrder.totalPriceLooseCents} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

const marginValidation = Yup.string()
  .nullable()
  .test(
    "base",
    "Only margin or price may be set but not both",
    (value, testContext) =>
      value == null || testContext.parent.perItemPrice == null,
  )

const perItemPriceValidation = Yup.string()
  .nullable()
  .test(
    "base",
    "Only margin or price may be set but not both",
    (value, testContext) => value == null || testContext.parent.margin == null,
  )

const validationSchema = Yup.object().shape({
  clientOrderProducts: Yup.array().of(
    Yup.object().shape({
      clientOrderProductSkus: Yup.array().of(
        Yup.object().shape({
          margin: marginValidation,
          perItemPrice: perItemPriceValidation,
        }),
      ),
      clientOrderProductCustomizations: Yup.array().of(
        Yup.object().shape({
          margin: marginValidation,
          perItemPrice: perItemPriceValidation,
        }),
      ),
      clientOrderProductAddons: Yup.array().of(
        Yup.object().shape({
          margin: marginValidation,
          perItemPrice: perItemPriceValidation,
        }),
      ),
      clientOrderProductLineItems: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Required"),
          margin: marginValidation,
          perItemPrice: perItemPriceValidation,
        }),
      ),
    }),
  ),
})

const ProductsSection = ({ clientOrder }: ClientOrderProps) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [confirmModalMakerOrders, setConfirmModalMakerOrders] = useState<any[]>(
    [],
  )
  const mutation = useUpdateClientOrderMutation()

  type FormInput = {
    clientOrderProducts: (
      | ClientOrderProduct
      | typeof clientOrder["clientOrderProducts"]["nodes"][number]
    )[]
  }

  type ClientOrderProduct = {
    clientOrderProductSkus: ClientOrderProductSkuInput[]
    clientOrderProductCustomizations: ClientOrderProductCustomizationInput[]
    clientOrderProductLineItems: ClientOrderProductLineItemInput[]
    clientOrderProductAddons: ClientOrderProductAddonInput[]
    id: string
    makerId: string
    totalCostCents: number
    totalPriceCents: number
    remove: boolean
  }

  const initialValues: FormInput = {
    clientOrderProducts: clientOrder.clientOrderProducts.nodes,
  }

  const formik = useFormik<typeof initialValues>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values, { setFieldValue, setFieldError }) => {
      const diff: typeof values = deepChangedValuesDiff(initialValues, values)

      if (Object.keys(diff).length === 0) {
        window.location.reload()
        return
      }

      if (!confirmModalOpen) {
        const makerIdsWithChanges: string[] = Object.keys(
          diff.clientOrderProducts,
        ).map((prodIndex: string) => {
          return values.clientOrderProducts[parseInt(prodIndex)].makerId
        })

        const changedMakerOrders = clientOrder.makerOrders.nodes.filter(
          (makerOrder) => makerIdsWithChanges.includes(makerOrder.maker.id),
        )

        if (changedMakerOrders.length > 0) {
          setConfirmModalMakerOrders(changedMakerOrders)
          setConfirmModalOpen(true)
          return
        }
      }

      const input: UpdateClientOrderInput = {
        id: clientOrder.id,
        clientOrderProducts: values.clientOrderProducts.map(
          (prod): ClientOrderProductInput => {
            return {
              id: prod.id,
              // @ts-ignore: This is weird, idk.
              remove: prod.remove,
              clientOrderProductSkus: prod.clientOrderProductSkus.map(
                (productSku): ClientOrderProductSkuInput => {
                  if ("sku" in productSku) {
                    return {
                      id: productSku.id,
                      // @ts-ignore: This is weird, idk.
                      remove: productSku.remove,
                      margin: productSku.margin?.toString(),
                      msrp: productSku.msrp?.toString(),
                      quantity: productSku.quantity,
                      perItemPrice: productSku.perItemPrice?.toString(),
                    }
                  }

                  return {
                    id: productSku.id,
                    remove: productSku.remove,
                    performLookupSku: productSku.id ? false : true,
                    margin: productSku.margin?.toString(),
                    msrp: productSku.msrp?.toString(),
                    quantity: productSku.quantity,
                    npaVariantOptions: productSku.npaVariantOptions,
                    editableVariant: productSku.editableVariant,
                    perItemPrice: productSku.perItemPrice,
                    addAllNpaVariantIds: productSku.addAllNpaVariantIds,
                  }
                },
              ),
              clientOrderProductLineItems: prod.clientOrderProductLineItems.map(
                (prodLineItem): ClientOrderProductLineItemInput => {
                  return {
                    id: prodLineItem.id,
                    // @ts-ignore: This is weird, idk.
                    remove: prodLineItem.remove,
                    name: prodLineItem.name,
                    quantity: prodLineItem.quantity,
                    perItemCost: prodLineItem.perItemCost?.toString(),
                    margin: prodLineItem.margin?.toString(),
                    perItemPrice: prodLineItem.perItemPrice?.toString(),
                  }
                },
              ),
              clientOrderProductCustomizations: prod.clientOrderProductCustomizations.map(
                (prodCust): ClientOrderProductCustomizationInput => {
                  return {
                    id: prodCust.id,
                    // @ts-ignore: Broken types up the chain... TODO
                    customizationId: prodCust.customizationId,
                    // @ts-ignore: This is weird, idk.
                    remove: prodCust.remove,
                    quantity: prodCust.quantity
                      ? parseInt(prodCust.quantity.toString())
                      : 0,
                    margin: prodCust.margin?.toString(),
                    perItemPrice: prodCust.perItemPrice?.toString(),
                  }
                },
              ),
              clientOrderProductAddons: prod.clientOrderProductAddons.map(
                (prodAddon): ClientOrderProductAddonInput => {
                  return {
                    id: prodAddon.id,
                    // @ts-ignore: Broken types up the chain... TODO
                    addonId: prodAddon.addonId,
                    // @ts-ignore: This is weird, idk.
                    remove: prodAddon.remove,
                    quantity: prodAddon.quantity
                      ? parseInt(prodAddon.quantity.toString())
                      : 0,
                    margin: prodAddon.margin?.toString(),
                    perItemPrice: prodAddon.perItemPrice?.toString(),
                  }
                },
              ),
            }
          },
        ),
      }

      try {
        const {
          updateClientOrder: result,
        }: UpdateClientOrderMutation = await mutation.mutateAsync({
          input,
        })

        if (result && result.errors.length) {
          for (let error of result.errors)
            if (error.path) setFieldError(error.path[1], error.message)
        } else {
          window.location.reload()
        }
      } catch (err) {
        const message = (() => {
          const { message } = err as Error

          // TODO(AM): better handling of error messages
          if (
            message.includes("Duplicate SKUs detected") ||
            message.includes("Product has multiple skus (Invariant)")
          )
            return "Cannot add a Platform ID more than once to a unique product"

          return `An error occured: ${message}`
        })()
        window.alert(message)
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="w-100">
        {clientOrder.makers.nodes.length > 0 ? (
          clientOrder.makers.nodes.map((maker) => {
            const clientOrderProducts = formik.values.clientOrderProducts.filter(
              (clientOrderProduct) =>
                clientOrderProduct.makerId === maker.id &&
                !clientOrderProduct.hasOwnProperty("remove"),
            )

            if (clientOrderProducts.length === 0) return null

            const totalCostCents = clientOrderProducts.reduce(
              (sum, clientOrderProduct) =>
                sum + (clientOrderProduct.totalCostCents || 0),
              0,
            )
            const totalPriceCents = clientOrderProducts.reduce(
              (sum, clientOrderProduct) =>
                sum + (clientOrderProduct.totalPriceCents || 0),
              0,
            )
            const totalMargin =
              totalPriceCents > 0
                ? ((totalPriceCents - totalCostCents) / totalPriceCents) * 100
                : 0

            return (
              <div key={maker.id} className="w-100">
                <div className="d-flex justify-content-between w-100 pb-4">
                  <div className="d-flex flex-row">
                    <Imgix
                      src={maker.logoUrlWithFallback}
                      sizes="100px"
                      className="shadow"
                    />
                    <p className="font-weight-bold text-nowrap ml-4">
                      <a href={maker.showUrl}>{maker.name}</a>
                    </p>
                  </div>

                  <table>
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Cost</td>
                        <td>
                          <FormatCentsMaybe cents={totalCostCents} />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold pr-4">Minimum Order</td>
                        <td>
                          <FormatCentsMaybe cents={maker.orderMinimumCents} />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Margin</td>
                        <td>
                          <FormatPercentageMaybe percentage={totalMargin} />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Price</td>
                        <td>
                          <FormatCentsMaybe cents={totalPriceCents} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <FieldArray
                  name="clientOrderProducts"
                  render={(arrayHelpers) => (
                    <>
                      {formik.values.clientOrderProducts.map(
                        (clientOrderProduct, i) => {
                          if (clientOrderProduct.makerId !== maker.id)
                            return null

                          return (
                            <ClientOrderProductRow
                              key={i}
                              clientOrderProduct={clientOrderProduct}
                              namePrefix={`clientOrderProducts.${i}`}
                              onDestroy={() => arrayHelpers.remove(i)}
                            />
                          )
                        },
                      )}
                    </>
                  )}
                />
              </div>
            )
          })
        ) : (
          <div className="text-muted mb-4">
            No Products have been added yet.
          </div>
        )}
        <div className="d-flex justify-content-end align-items-baseline mb-2">
          <Button variant="dark" type="submit">
            Update Client Project
          </Button>
        </div>

        <ClientOrderConfirmModal
          makerOrders={confirmModalMakerOrders}
          isOpen={confirmModalOpen}
          addingProduct={false}
          onClose={() => setConfirmModalOpen(false)}
          onSubmit={formik.handleSubmit}
        />
      </form>
    </FormikProvider>
  )
}

const ClientOrderProductRow = ({
  clientOrderProduct,
  namePrefix,
  onDestroy,
}: {
  clientOrderProduct: any
  namePrefix: string
  onDestroy: any
}) => {
  const formik = useFormikContext()
  const fileRef = useRef<HTMLInputElement>(null)
  const [imageBlob, setImageBlob] = useState<Blobby | null>(null)

  const handleImageUpload = () => {
    if (!fileRef.current) throw new Error(`expected file ref`)
    const files = fileRef.current.files
    if (!files) return

    for (const file of Array.from(files)) {
      const upload = new DirectUpload(file, directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          alert(`Error uploading file: ${error}`)
          return
        }

        formik.setFieldValue(`${namePrefix}.image.signedId`, blob.signed_id)
        formik.setFieldValue(`${namePrefix}.image.remove`, false)
        setImageBlob((blob as unknown) as Blobby)
      })
    }
  }

  const handleImageDestroy = () => {
    setImageBlob(null)
    formik.setFieldValue(`${namePrefix}.image.signedId`, null)
    formik.setFieldValue(`${namePrefix}.image.remove`, true)
  }

  if (clientOrderProduct.hasOwnProperty("remove")) return null

  let image
  if (imageBlob) {
    image = (
      <img
        src={blobImageUrl(imageBlob)}
        alt=""
        style={{
          width: "160px",
          objectFit: "contain",
          aspectRatio: `1 / 1`,
        }}
      />
    )
  } else if (
    !clientOrderProduct.image?.hasOwnProperty("remove") &&
    clientOrderProduct.image?.imgixUrl
  ) {
    image = (
      <Imgix
        src={clientOrderProduct.image.imgixUrl}
        sizes="160px"
        className="shadow"
      />
    )
  } else if (clientOrderProduct.product.image?.imgixUrl) {
    image = (
      <Imgix
        src={clientOrderProduct.product.image.imgixUrl}
        sizes="160px"
        className="shadow"
      />
    )
  }

  return (
    <div key={clientOrderProduct.id}>
      <div className="pt-4" style={{ borderTop: "1px solid #353A3F" }}>
        <div className="d-flex flex-row mb-2">
          {image}
          <div className="mt-2 ml-4">
            <p className="font-weight-bold text-nowrap">
              <a href={clientOrderProduct.product.showUrl}>
                {clientOrderProduct.product.name}
              </a>
            </p>
            <p>{clientOrderProduct.product.description}</p>
            {clientOrderProduct.product.dimension && (
              <p className="mb-0">
                <ProductDimensions
                  dimension={clientOrderProduct.product.dimension}
                />
              </p>
            )}
            {clientOrderProduct.product.materialSubcategories.nodes.length >
              0 && (
              <p className="mb-0">
                Materials:{" "}
                {clientOrderProduct.product.materialSubcategories.nodes
                  .map(
                    (materialSubcategory: MaterialSubcategory) =>
                      `${materialSubcategory.material.name} - ${materialSubcategory.name}`,
                  )
                  .join(", ")}
              </p>
            )}
            {clientOrderProduct.product.countryOfOrigin && (
              <p className="mb-0">
                Made in {clientOrderProduct.product.countryOfOrigin.name}.
              </p>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              if (clientOrderProduct.id) {
                formik.setFieldValue(`${namePrefix}.remove`, true)
              } else {
                onDestroy()
              }
            }}
            className="border-0 bg-transparent d-flex p-2 ml-auto"
          >
            <TrashSVG
              style={{ width: 12, height: 14 }}
              className="text-danger"
            />
            <VisuallyHidden>Remove Product</VisuallyHidden>
          </button>
        </div>
        {(imageBlob ||
          (clientOrderProduct.image &&
            !clientOrderProduct.image.hasOwnProperty("remove"))) && (
          <button
            type="button"
            className="btn btn-sm btn-link text-danger"
            onClick={handleImageDestroy}
          >
            Reset
          </button>
        )}
        <div>
          <input
            type="file"
            ref={fileRef}
            onChange={handleImageUpload}
            accept="image/*"
            className="d-none"
            id={`${namePrefix}.image`}
          />
          <label
            htmlFor={`${namePrefix}.image`}
            className="text-dark-pink p-2"
            style={{ cursor: "pointer" }}
          >
            <span className="btn btn-sm btn-dark">Update with Mockup</span>
          </label>
        </div>
      </div>
      <Tabs>
        <div className="d-flex flex-grow-1 justify-content-center">
          <TabList>
            <StyledTab>Summary</StyledTab>
            <StyledTab>Platform IDs</StyledTab>
            <StyledTab>Customizations</StyledTab>
            <StyledTab>Add-Ons</StyledTab>
            <StyledTab>Line Items</StyledTab>
            <StyledTab>Impact</StyledTab>
          </TabList>
        </div>
        <TabPanels>
          <TabPanel>
            <SummaryPanel
              clientOrderProduct={clientOrderProduct}
              namePrefix={namePrefix}
            />
          </TabPanel>
          <TabPanel>
            <SkusPanel
              clientOrderProduct={clientOrderProduct}
              namePrefix={namePrefix}
            />
          </TabPanel>
          <TabPanel>
            <CustomizationsPanel
              clientOrderProduct={clientOrderProduct}
              namePrefix={namePrefix}
            />
          </TabPanel>
          <TabPanel>
            <AddonsPanel
              clientOrderProduct={clientOrderProduct}
              namePrefix={namePrefix}
            />
          </TabPanel>
          <TabPanel>
            <LineItemsPanel
              clientOrderProduct={clientOrderProduct}
              namePrefix={namePrefix}
            />
          </TabPanel>
          <TabPanel>
            <ImpactPanel clientOrderProduct={clientOrderProduct} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

type ClientOrderProductProps = {
  clientOrderProduct: ClientOrderShowMakerFragment["clientOrderProducts"]["nodes"][number]
  namePrefix: string
}

const SummaryPanel = (props: ClientOrderProductProps) => {
  const { product } = props.clientOrderProduct
  const nameColSpan =
    1 + (product.variants.length > 0 ? 1 : 0) + product.npaVariants.length

  return (
    <div className="mb-4">
      <BorderBoxContainer>
        <BorderBox>
          <div className="table-responsive">
            <table className="table nice-table">
              <FieldArray
                name={`${props.namePrefix}.clientOrderProductSkus`}
                render={(arrayHelpers) => (
                  <SummaryPanelSkus {...props} arrayHelpers={arrayHelpers} />
                )}
              />
              <FieldArray
                name={`${props.namePrefix}.clientOrderProductCustomizations`}
                render={(arrayHelpers) => (
                  <SummaryPanelCustomizations
                    {...props}
                    arrayHelpers={arrayHelpers}
                    nameColSpan={nameColSpan}
                  />
                )}
              />
              <FieldArray
                name={`${props.namePrefix}.clientOrderProductAddons`}
                render={(arrayHelpers) => (
                  <SummaryPanelAddons
                    {...props}
                    arrayHelpers={arrayHelpers}
                    nameColSpan={nameColSpan}
                  />
                )}
              />
              <FieldArray
                name={`${props.namePrefix}.clientOrderProductLineItems`}
                render={(arrayHelpers) => (
                  <SummaryPanelLineItems
                    {...props}
                    arrayHelpers={arrayHelpers}
                    nameColSpan={nameColSpan}
                  />
                )}
              />
            </table>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </div>
  )
}

const defaultSkuValues = (
  product: ClientOrderShowMakerFragment["clientOrderProducts"]["nodes"][number]["product"],
  editableVariant: any,
) => ({
  performLookupSku: true,
  quantity: product.productGroup.minimumCogsColumnQuantity,
  editableVariant,
})

const SummaryPanelSkus = (
  props: ClientOrderProductProps & { arrayHelpers: FieldArrayRenderProps },
) => {
  const { product } = props.clientOrderProduct

  return (
    <>
      <thead>
        <tr>
          <th>Platform ID</th>
          {product.variants.length > 0 && <th>{product.variantName}</th>}
          {product.npaVariants.map((npaVariant, i) => (
            <th key={i}>{npaVariant.name}</th>
          ))}
          <th>Quantity</th>
          <th>Cost</th>
          <th>Margin</th>
          <th>Price</th>
          <th>Amount</th>
          <th>MSRP</th>
          <th>
            <button
              type="button"
              className="btn btn-sm btn-primary ml-auto"
              style={{ minWidth: "150px" }}
              onClick={() =>
                props.arrayHelpers.push(
                  defaultSkuValues(
                    product,
                    props.clientOrderProduct.clientOrderProductSkus[0].sku
                      .variant.id,
                  ),
                )
              }
            >
              Add Variant
            </button>
          </th>
        </tr>
      </thead>
      <SkusTableBody {...props} />
      <SkusTableTotals {...props} />
    </>
  )
}

const SummaryPanelCustomizations = (
  props: ClientOrderProductProps & {
    arrayHelpers: FieldArrayRenderProps
    nameColSpan: number
  },
) => (
  <>
    <thead>
      <tr>
        <th colSpan={props.nameColSpan}>Customization</th>
        <th>Quantity</th>
        <th>Cost</th>
        <th>Margin</th>
        <th>Price</th>
        <th>Amount</th>
        <th></th>
        <th>
          <MaybeTooltip
            show={
              props.clientOrderProduct.product.productGroup.customizations
                .length === 0
            }
            text={`${props.clientOrderProduct.product.productGroup.name} has no Customizations`}
          >
            <button
              type="button"
              className="btn btn-sm btn-primary ml-auto"
              style={{ minWidth: "150px" }}
              onClick={() => props.arrayHelpers.push({})}
              disabled={
                props.clientOrderProduct.product.productGroup.customizations
                  .length === 0
              }
            >
              Add Customization
            </button>
          </MaybeTooltip>
        </th>
      </tr>
    </thead>
    <CustomizationsTableBody {...props} />
  </>
)

const SummaryPanelAddons = (
  props: ClientOrderProductProps & {
    arrayHelpers: FieldArrayRenderProps
    nameColSpan: number
  },
) => (
  <>
    <thead>
      <tr>
        <th colSpan={props.nameColSpan}>Add-On</th>
        <th>Quantity</th>
        <th>Cost</th>
        <th>Margin</th>
        <th>Price</th>
        <th>Amount</th>
        <th></th>
        <th>
          <MaybeTooltip
            show={
              props.clientOrderProduct.product.productGroup.addons.length === 0
            }
            text={`${props.clientOrderProduct.product.productGroup.name} has no Add-Ons`}
          >
            <button
              type="button"
              className="btn btn-sm btn-primary ml-auto"
              style={{ minWidth: "150px" }}
              onClick={() => props.arrayHelpers.push({})}
              disabled={
                props.clientOrderProduct.product.productGroup.addons.length ===
                0
              }
            >
              Add Add-On
            </button>
          </MaybeTooltip>
        </th>
      </tr>
    </thead>
    <AddonsTableBody {...props} />
  </>
)

const SummaryPanelLineItems = (
  props: ClientOrderProductProps & {
    arrayHelpers: FieldArrayRenderProps
    nameColSpan: number
  },
) => (
  <>
    <thead>
      <tr>
        <th colSpan={props.nameColSpan}>Line Item</th>
        <th>Quantity</th>
        <th>Cost</th>
        <th>Margin</th>
        <th>Price</th>
        <th>Amount</th>
        <th></th>
        <th>
          <button
            type="button"
            className="btn btn-sm btn-primary ml-auto"
            style={{ minWidth: "150px" }}
            onClick={() => props.arrayHelpers.push({})}
          >
            Add Line Item
          </button>
        </th>
      </tr>
    </thead>
    <LineItemsTableBody {...props} />
  </>
)

const SkusPanel = (props: ClientOrderProductProps) => {
  const { product } = props.clientOrderProduct

  return (
    <FieldArray
      name={`${props.namePrefix}.clientOrderProductSkus`}
      render={(arrayHelpers) => (
        <div className="mb-4">
          <BorderBoxContainer>
            <div className="d-flex w-100">
              <BorderBoxHeading>Platform IDs</BorderBoxHeading>
              <button
                type="button"
                className="ml-auto btn btn-sm btn-link f-action-text align-middle"
                onClick={() =>
                  arrayHelpers.push(
                    defaultSkuValues(
                      product,
                      props.clientOrderProduct.clientOrderProductSkus[0].sku
                        .variant.id,
                    ),
                  )
                }
              >
                <PlusCircleSVG height={13} />
                <span className="pl-1">Add</span>
              </button>
            </div>
            <BorderBox>
              {props.clientOrderProduct.clientOrderProductSkus.filter(
                (item) => !item.hasOwnProperty("remove"),
              ).length > 0 ? (
                <div className="table-responsive">
                  <table className="table nice-table">
                    <thead>
                      <tr>
                        <th>Platform ID</th>
                        {product.variants.length > 0 && (
                          <th>{product.variantName || "Variant"}</th>
                        )}
                        {product.npaVariants.map((npaVariant, i) => (
                          <th key={i}>{npaVariant.name}</th>
                        ))}
                        <th>Quantity</th>
                        <th>Cost</th>
                        <th>Margin</th>
                        <th>Price</th>
                        <th>Amount</th>
                        <th>MSRP</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <SkusTableBody {...props} arrayHelpers={arrayHelpers} />
                    <SkusTableTotals {...props} />
                  </table>
                </div>
              ) : (
                <div className="text-muted">
                  No Platform IDs have been added yet.
                </div>
              )}
            </BorderBox>
          </BorderBoxContainer>
        </div>
      )}
    />
  )
}

const SkusTableTotals = ({ clientOrderProduct }: ClientOrderProductProps) => {
  const { product, clientOrderProductSkus } = clientOrderProduct

  if (clientOrderProductSkus.length <= 1) return null

  const totalQuantity = clientOrderProductSkus.reduce(
    (sum, { quantity }) => sum + (quantity || 0),
    0,
  )

  const totalCost = clientOrderProductSkus.reduce(
    (sum, { quantity, perItemAmountCents }) =>
      sum + (quantity || 0) * (perItemAmountCents || 0),
    0,
  )

  const skusWithMarginCount = clientOrderProductSkus.filter(
    (sku) => sku.margin || sku.calculatedMargin,
  ).length
  const averageMargin =
    skusWithMarginCount > 0
      ? clientOrderProductSkus.reduce(
          (sum, { margin, calculatedMargin }) =>
            // @ts-expect-error
            sum + ((margin ? parseFloat(margin) : 0) || calculatedMargin || 0),
          0,
        ) / skusWithMarginCount
      : 0

  const totalPriceCents = clientOrderProductSkus.reduce(
    (sum, { totalPriceCents }) => sum + (totalPriceCents || 0),
    0,
  )

  const totalMsrp = clientOrderProductSkus.reduce(
    // @ts-expect-error
    (sum, { msrp }) => sum + (msrp ? parseFloat(msrp) : 0),
    0,
  )

  return (
    <tbody>
      <tr className="font-weight-bold">
        <td></td>
        {product.variants.length > 0 && <td></td>}
        {product.npaVariants.map((npaVariant, j) => (
          <td key={j}></td>
        ))}
        <td>{numberWithCommas(totalQuantity)}</td>
        <td>{formatCents(totalCost)}</td>
        <td>{formatPercentage(averageMargin)}</td>
        <td></td>
        <td>{formatCents(totalPriceCents)}</td>
        <td>{formatCents(totalMsrp * 100)}</td>
        <td></td>
      </tr>
    </tbody>
  )
}

const SkusTableBody = ({
  clientOrderProduct,
  namePrefix,
  arrayHelpers,
}: ClientOrderProductProps & { arrayHelpers: FieldArrayRenderProps }) => {
  const formik = useFormikContext()
  const { product } = clientOrderProduct

  const sortHash = (clientOrderProductSku: any) => {
    return product.npaVariants
      .map((npaVariant, j) => {
        const npaVariantOption = clientOrderProductSku.sku?.npaVariantOptions.find(
          (option: any) => option.npaVariantId === npaVariant.id,
        )

        return npaVariantOption?.position
      })
      .join("-")
  }

  return (
    <tbody>
      {clientOrderProduct.clientOrderProductSkus
        .sort((a, b) => {
          const aHash = sortHash(a)
          const bHash = sortHash(b)

          if (aHash < bHash) return -1
          if (aHash > bHash) return 1

          return 0
        })
        .map((clientOrderProductSku, i) => {
          if (clientOrderProductSku.hasOwnProperty("remove")) return null

          const addAllNpaVariantIds =
            // @ts-expect-error
            clientOrderProductSku?.addAllNpaVariantIds || []

          return (
            <tr key={i}>
              <td style={{ lineHeight: "38px" }}>
                {clientOrderProductSku.id ? (
                  <>{clientOrderProductSku.sku.humanId}</>
                ) : (
                  <EmptyValue variant="dash" />
                )}
              </td>
              {product.variants.length > 0 && (
                <td style={{ lineHeight: "38px" }}>
                  {clientOrderProductSku.id ? (
                    clientOrderProductSku.sku.variant.name
                  ) : (
                    <Field
                      name={`${namePrefix}.clientOrderProductSkus.${i}.editableVariant`}
                      disabled={true}
                      as={SelectWithFormikErrors}
                    >
                      <option></option>
                      {product.variants.map(({ id, name }) => (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      ))}
                    </Field>
                  )}
                </td>
              )}
              {product.npaVariants.map((npaVariant, j) => {
                const npaVariantOption = clientOrderProductSku.sku?.npaVariantOptions.find(
                  (option) => option.npaVariantId === npaVariant.id,
                )

                return (
                  <td key={j} style={{ lineHeight: "38px" }}>
                    {clientOrderProductSku.id ? (
                      npaVariantOption?.name
                    ) : (
                      <>
                        <Field
                          name={`${namePrefix}.clientOrderProductSkus.${i}.npaVariantOptions.${j}`}
                          as={SelectWithFormikErrors}
                          disabled={addAllNpaVariantIds.includes(npaVariant.id)}
                        >
                          <option></option>
                          {npaVariant.npaVariantOptions
                            .sort(
                              (a, b) => (a.position || 0) - (b.position || 0),
                            )
                            .map(({ id, name }) => (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            ))}
                        </Field>
                        <div className="text-nowrap">
                          <Checkbox
                            id={`addAllNpaVariant-${i}-${j}`}
                            // @ts-expect-error
                            checked={clientOrderProductSku?.addAllNpaVariantIds?.includes(
                              npaVariant.id,
                            )}
                            onChange={() => {
                              formik.setFieldValue(
                                `${namePrefix}.clientOrderProductSkus.${i}.npaVariantOptions.${j}`,
                                undefined,
                              )

                              if (addAllNpaVariantIds.includes(npaVariant.id)) {
                                formik.setFieldValue(
                                  `${namePrefix}.clientOrderProductSkus.${i}.addAllNpaVariantIds`,
                                  addAllNpaVariantIds.filter(
                                    (id: any) => id !== npaVariant.id,
                                  ),
                                )
                              } else {
                                formik.setFieldValue(
                                  `${namePrefix}.clientOrderProductSkus.${i}.addAllNpaVariantIds`,
                                  [...addAllNpaVariantIds, npaVariant.id],
                                )
                              }
                            }}
                            label="Add All"
                          />
                        </div>
                      </>
                    )}
                  </td>
                )
              })}
              <td style={{ lineHeight: "38px" }}>
                <Field
                  type="number"
                  name={`${namePrefix}.clientOrderProductSkus.${i}.quantity`}
                  as={InputWithFormikErrors}
                />
              </td>
              <td>
                <div className="pl-1 pb-2">
                  {clientOrderProductSku.perItemAmountCents &&
                    formatCents(clientOrderProductSku.perItemAmountCents)}
                  {clientOrderProductSku.id &&
                    !clientOrderProductSku.perItemAmountCents && (
                      <div>
                        <a
                          href={clientOrderProductSku.editCostUrl}
                          className="btn text-danger"
                          title={`Cost needed for ${clientOrderProductSku.quantity} units`}
                          data-toggle="tooltip"
                        >
                          ⚠️
                        </a>
                      </div>
                    )}
                </div>
                <div className="d-flex">
                  {clientOrderProductSku.cogsPerItemAmountCents && (
                    <div className="px-1">
                      <div className="small text-muted text-nowrap lh-80">
                        COGS
                      </div>
                      <div className="text-nowrap">
                        <FormatCentsMaybe
                          cents={clientOrderProductSku.cogsPerItemAmountCents}
                        />
                        <span className="small"> / unit</span>
                      </div>
                    </div>
                  )}
                  {clientOrderProduct.includeShippingCosts && (
                    <div className="px-1">
                      <div className="small text-muted text-nowrap lh-80">
                        Est Shipping
                      </div>
                      {clientOrderProductSku.dutyCostsCents ? (
                        <div>
                          <div className="text-nowrap">
                            <FormatCentsMaybe
                              cents={clientOrderProductSku.shippingCostsCents}
                            />
                            <span className="small"> / unit</span>
                          </div>
                          {clientOrderProduct.exportShippingPort ? (
                            <ImportExportLine
                              label="Export"
                              {...clientOrderProduct.exportShippingPort}
                            />
                          ) : null}
                          {clientOrderProduct.importShippingPort ? (
                            <ImportExportLine
                              label="Import"
                              {...clientOrderProduct.importShippingPort}
                            />
                          ) : null}
                        </div>
                      ) : (
                        <EmptyValue variant="message" />
                      )}
                    </div>
                  )}
                  {clientOrderProduct.includeDutyCosts && (
                    <div className="px-1">
                      <div className="small text-muted text-nowrap lh-80">
                        Duty
                      </div>

                      {clientOrderProductSku.dutyCostsCents ? (
                        <div>
                          <div className="text-nowrap">
                            <FormatCentsMaybe
                              cents={clientOrderProductSku.dutyCostsCents}
                            />
                            <span className="small"> / unit</span>
                          </div>
                          {clientOrderProductSku.countryOfOrigin ? (
                            <div className="small text-nowrap lh-80 mb-1">
                              {clientOrderProductSku.countryOfOrigin.shortName}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <EmptyValue variant="message" />
                      )}
                    </div>
                  )}
                </div>
              </td>
              <td>
                <InputGroup prepend="%">
                  <Field
                    name={`${namePrefix}.clientOrderProductSkus.${i}.margin`}
                    placeholder={clientOrderProductSku.calculatedMargin?.toFixed(
                      1,
                    )}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup prepend="$">
                  <Field
                    name={`${namePrefix}.clientOrderProductSkus.${i}.perItemPrice`}
                    placeholder={clientOrderProductSku.calculatedPerItemPrice}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td style={{ lineHeight: "38px" }}>
                <FormatCentsMaybe
                  cents={clientOrderProductSku.totalPriceCents}
                />
              </td>
              <td>
                <InputGroup prepend="$">
                  <Field
                    name={`${namePrefix}.clientOrderProductSkus.${i}.msrp`}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td>
                <button
                  type="button"
                  onClick={() => {
                    if (clientOrderProductSku.id) {
                      formik.setFieldValue(
                        `${namePrefix}.clientOrderProductSkus.${i}.remove`,
                        true,
                      )
                    } else {
                      arrayHelpers.remove(i)
                    }
                  }}
                  className="border-0 bg-transparent d-flex p-2"
                >
                  <TrashSVG
                    style={{ width: 12, height: 14 }}
                    className="text-danger"
                  />
                  <VisuallyHidden>Remove Platform ID</VisuallyHidden>
                </button>
              </td>
            </tr>
          )
        })}
    </tbody>
  )
}

const CustomizationsPanel = (props: ClientOrderProductProps) => (
  <FieldArray
    name={`${props.namePrefix}.clientOrderProductCustomizations`}
    render={(arrayHelpers) => (
      <div className="mb-4">
        <BorderBoxContainer>
          <div className="d-flex w-100">
            <BorderBoxHeading>Customizations</BorderBoxHeading>
            {props.clientOrderProduct.product.productGroup.customizations
              .length > 0 && (
              <button
                type="button"
                className="ml-auto btn btn-sm btn-link f-action-text align-middle"
                onClick={() => arrayHelpers.push({})}
              >
                <PlusCircleSVG height={13} />
                <span className="pl-1">Add</span>
              </button>
            )}
          </div>
          <BorderBox>
            {props.clientOrderProduct.clientOrderProductCustomizations.filter(
              (item) => !item.hasOwnProperty("remove"),
            ).length > 0 ? (
              <div className="table-responsive">
                <table className="table nice-table">
                  <thead>
                    <tr>
                      <th>Customization</th>
                      <th>Quantity</th>
                      <th>Cost</th>
                      <th>Margin</th>
                      <th>Price</th>
                      <th>Amount</th>
                      <th></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <CustomizationsTableBody
                    {...props}
                    arrayHelpers={arrayHelpers}
                  />
                </table>
              </div>
            ) : (
              <div className="text-muted">
                No Customizations have been added yet.
              </div>
            )}
          </BorderBox>
        </BorderBoxContainer>
      </div>
    )}
  />
)

const CustomizationsTableBody = ({
  clientOrderProduct,
  namePrefix,
  arrayHelpers,
  nameColSpan,
}: ClientOrderProductProps & {
  arrayHelpers: FieldArrayRenderProps
  nameColSpan?: number
}) => {
  const formik = useFormikContext()

  useEffect(() => {
    initPopovers()
  }, [clientOrderProduct])

  return (
    <tbody>
      {clientOrderProduct.clientOrderProductCustomizations.map(
        (clientOrderProductCustomization, i) => {
          if (clientOrderProductCustomization.hasOwnProperty("remove"))
            return null

          return (
            <tr key={i}>
              <td
                className="text-nowrap"
                style={{ lineHeight: "38px" }}
                colSpan={nameColSpan}
              >
                {clientOrderProductCustomization.id ? (
                  <>
                    <p className="mb-0">
                      {clientOrderProductCustomization.customization.name}
                    </p>
                    <div>
                      {clientOrderProductCustomization.customizationArtworks.map(
                        (customizationArtwork) => (
                          <div
                            key={customizationArtwork.id}
                            className="d-inline-block mr-2"
                            data-toggle="popover"
                            data-trigger="hover"
                            data-content={`
                              <table class="table table-borderless table-sm">
                                <tr>
                                  <th>Design Name</th>
                                  <td>${customizationArtwork.designName}</td>
                                </tr>
                                <tr>
                                  <th>Imprint Type</th>
                                  <td>${customizationArtwork.imprintType}</td>
                                </tr>
                                <tr>
                                  <th>Design Location</th>
                                  <td>${customizationArtwork.designLocation}</td>
                                </tr>
                                <tr>
                                  <th>Design Size</th>
                                  <td>${customizationArtwork.designSize}</td>
                                </tr>
                                <tr>
                                  <th>Design Color</th>
                                  <td>${customizationArtwork.designColor}</td>
                                </tr>
                              </table>
                              <img src="${customizationArtwork.artwork.url}" alt="" width="250" />
                            `}
                            data-html="true"
                          >
                            <img
                              src={customizationArtwork.artwork.url}
                              alt=""
                              style={{
                                width: "31px",
                                objectFit: "contain",
                                aspectRatio: `1 / 1`,
                              }}
                            />
                          </div>
                        ),
                      )}

                      {clientOrderProductCustomization.customization
                        .acceptsArtwork && (
                        <a
                          href={clientOrderProductCustomization.editUrl}
                          className="btn btn-sm btn-dark"
                        >
                          Add Artwork
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  <Field
                    name={`${namePrefix}.clientOrderProductCustomizations.${i}.customizationId`}
                    as={SelectWithFormikErrors}
                  >
                    <option></option>
                    {clientOrderProduct.product.productGroup.customizationCategories.map(
                      (categoryName) => {
                        const customizations = clientOrderProduct.product.productGroup.customizations.filter(
                          (customization) =>
                            customization.customizationCategoryName ===
                            categoryName,
                        )

                        return (
                          <optgroup label={categoryName} key={categoryName}>
                            {customizations.map(({ id, name }) => (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            ))}
                          </optgroup>
                        )
                      },
                    )}
                  </Field>
                )}
              </td>
              <td>
                <Field
                  type="number"
                  name={`${namePrefix}.clientOrderProductCustomizations.${i}.quantity`}
                  placeholder={clientOrderProduct.clientOrderProductSkus.reduce(
                    (sum, sku) => sum + (sku.quantity || 0),
                    0,
                  )}
                  as={InputWithFormikErrors}
                />
              </td>
              <td style={{ lineHeight: "38px" }}>
                {clientOrderProductCustomization.perItemAmountCents &&
                  formatCents(
                    clientOrderProductCustomization.perItemAmountCents,
                  )}
                {clientOrderProductCustomization.id &&
                  !clientOrderProductCustomization.perItemAmountCents &&
                  clientOrderProductCustomization.quantity && (
                    <div>
                      <a
                        href={clientOrderProductCustomization.editCostUrl}
                        className="btn text-danger"
                        title={`Cost needed for ${clientOrderProductCustomization.quantity} units`}
                        data-toggle="tooltip"
                      >
                        ⚠️
                      </a>
                    </div>
                  )}
              </td>
              <td>
                <InputGroup prepend="%">
                  <Field
                    name={`${namePrefix}.clientOrderProductCustomizations.${i}.margin`}
                    placeholder={clientOrderProductCustomization.calculatedMargin?.toFixed(
                      1,
                    )}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup prepend="$">
                  <Field
                    name={`${namePrefix}.clientOrderProductCustomizations.${i}.perItemPrice`}
                    placeholder={
                      clientOrderProductCustomization.calculatedPerItemPrice
                    }
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td style={{ lineHeight: "38px" }}>
                <FormatCentsMaybe
                  cents={clientOrderProductCustomization.totalPriceCents}
                />
              </td>
              <td></td>
              <td>
                <button
                  type="button"
                  onClick={() => {
                    if (clientOrderProductCustomization.id) {
                      formik.setFieldValue(
                        `${namePrefix}.clientOrderProductCustomizations.${i}.remove`,
                        true,
                      )
                    } else {
                      arrayHelpers.remove(i)
                    }
                  }}
                  className="border-0 bg-transparent d-flex p-2"
                >
                  <TrashSVG
                    style={{ width: 12, height: 14 }}
                    className="text-danger"
                  />
                  <VisuallyHidden>Remove Customization</VisuallyHidden>
                </button>
              </td>
            </tr>
          )
        },
      )}
    </tbody>
  )
}

const AddonsPanel = (props: ClientOrderProductProps) => (
  <FieldArray
    name={`${props.namePrefix}.clientOrderProductAddons`}
    render={(arrayHelpers) => (
      <div className="mb-4">
        <BorderBoxContainer>
          <div className="d-flex w-100">
            <BorderBoxHeading>Add-Ons</BorderBoxHeading>
            {props.clientOrderProduct.product.productGroup.addons.length >
              0 && (
              <button
                type="button"
                className="ml-auto btn btn-sm btn-link f-action-text align-middle"
                onClick={() => arrayHelpers.push({})}
              >
                <PlusCircleSVG height={13} />
                <span className="pl-1">Add</span>
              </button>
            )}
          </div>
          <BorderBox>
            {props.clientOrderProduct.clientOrderProductAddons.filter(
              (item) => !item.hasOwnProperty("remove"),
            ).length > 0 ? (
              <div className="table-responsive">
                <table className="table nice-table">
                  <thead>
                    <tr>
                      <th>Add-On</th>
                      <th>Quantity</th>
                      <th>Cost</th>
                      <th>Margin</th>
                      <th>Price</th>
                      <th>Amount</th>
                      <th></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <AddonsTableBody {...props} arrayHelpers={arrayHelpers} />
                </table>
              </div>
            ) : (
              <div className="text-muted">No Add-Ons have been added yet.</div>
            )}
          </BorderBox>
        </BorderBoxContainer>
      </div>
    )}
  />
)

const AddonsTableBody = ({
  clientOrderProduct,
  namePrefix,
  arrayHelpers,
  nameColSpan,
}: ClientOrderProductProps & {
  arrayHelpers: FieldArrayRenderProps
  nameColSpan?: number
}) => {
  const formik = useFormikContext()

  return (
    <tbody>
      {clientOrderProduct.clientOrderProductAddons.map(
        (clientOrderProductAddon, i) => {
          if (clientOrderProductAddon.hasOwnProperty("remove")) return null

          return (
            <tr key={i}>
              <td
                className="text-nowrap"
                style={{ lineHeight: "38px" }}
                colSpan={nameColSpan}
              >
                {clientOrderProductAddon.id ? (
                  <>{clientOrderProductAddon.addon.name}</>
                ) : (
                  <Field
                    name={`${namePrefix}.clientOrderProductAddons.${i}.addonId`}
                    as={SelectWithFormikErrors}
                  >
                    <option></option>
                    {clientOrderProduct.product.productGroup.addons.map(
                      ({ id, name }) => (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      ),
                    )}
                  </Field>
                )}
              </td>
              <td>
                <Field
                  type="number"
                  name={`${namePrefix}.clientOrderProductAddons.${i}.quantity`}
                  placeholder={clientOrderProduct.clientOrderProductSkus.reduce(
                    (sum, sku) => sum + (sku.quantity || 0),
                    0,
                  )}
                  as={InputWithFormikErrors}
                />
              </td>
              <td style={{ lineHeight: "38px" }}>
                {clientOrderProductAddon.perItemAmountCents &&
                  formatCents(clientOrderProductAddon.perItemAmountCents)}
                {clientOrderProductAddon.id &&
                  !clientOrderProductAddon.perItemAmountCents &&
                  clientOrderProductAddon.quantity && (
                    <div>
                      <a
                        href={clientOrderProductAddon.editCostUrl}
                        className="btn text-danger"
                        title={`Cost needed for ${clientOrderProductAddon.quantity} units`}
                        data-toggle="tooltip"
                      >
                        ⚠️
                      </a>
                    </div>
                  )}
              </td>
              <td>
                <InputGroup prepend="%">
                  <Field
                    name={`${namePrefix}.clientOrderProductAddons.${i}.margin`}
                    placeholder={clientOrderProductAddon.calculatedMargin?.toFixed(
                      1,
                    )}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup prepend="$">
                  <Field
                    name={`${namePrefix}.clientOrderProductAddons.${i}.perItemPrice`}
                    placeholder={clientOrderProductAddon.calculatedPerItemPrice}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td style={{ lineHeight: "38px" }}>
                <FormatCentsMaybe
                  cents={clientOrderProductAddon.totalPriceCents}
                />
              </td>
              <td></td>
              <td>
                <button
                  type="button"
                  onClick={() => {
                    if (clientOrderProductAddon.id) {
                      formik.setFieldValue(
                        `${namePrefix}.clientOrderProductAddons.${i}.remove`,
                        true,
                      )
                    } else {
                      arrayHelpers.remove(i)
                    }
                  }}
                  className="border-0 bg-transparent d-flex p-2"
                >
                  <TrashSVG
                    style={{ width: 12, height: 14 }}
                    className="text-danger"
                  />
                  <VisuallyHidden>Remove Add-on</VisuallyHidden>
                </button>
              </td>
            </tr>
          )
        },
      )}
    </tbody>
  )
}

const LineItemsPanel = (props: ClientOrderProductProps) => (
  <FieldArray
    name={`${props.namePrefix}.clientOrderProductLineItems`}
    render={(arrayHelpers) => (
      <div className="mb-4">
        <BorderBoxContainer>
          <div className="d-flex w-100">
            <BorderBoxHeading>Line Items</BorderBoxHeading>
            <button
              type="button"
              className="ml-auto btn btn-sm btn-link f-action-text align-middle"
              onClick={() => arrayHelpers.push({})}
            >
              <PlusCircleSVG height={13} />
              <span className="pl-1">Add</span>
            </button>
          </div>

          <BorderBox>
            {props.clientOrderProduct.clientOrderProductLineItems.filter(
              (item) => !item.hasOwnProperty("remove"),
            ).length > 0 ? (
              <div className="table-responsive">
                <table className="table nice-table">
                  <thead>
                    <tr>
                      <th>Line Item</th>
                      <th>Quantity</th>
                      <th>Cost</th>
                      <th>Margin</th>
                      <th>Price</th>
                      <th>Amount</th>
                      <th></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <LineItemsTableBody {...props} arrayHelpers={arrayHelpers} />
                </table>
              </div>
            ) : (
              <div className="text-muted">
                No Line items have been added yet.
              </div>
            )}
          </BorderBox>
        </BorderBoxContainer>
      </div>
    )}
  />
)

const LineItemsTableBody = ({
  clientOrderProduct,
  namePrefix,
  arrayHelpers,
  nameColSpan,
}: ClientOrderProductProps & {
  arrayHelpers: FieldArrayRenderProps
  nameColSpan?: number
}) => {
  const formik = useFormikContext()

  return (
    <tbody>
      {clientOrderProduct.clientOrderProductLineItems.map(
        (clientOrderProductLineItem, i) => {
          if (clientOrderProductLineItem.hasOwnProperty("remove")) return null

          return (
            <tr key={i}>
              <td style={{ minWidth: "100px" }} colSpan={nameColSpan}>
                <Field
                  name={`${namePrefix}.clientOrderProductLineItems.${i}.name`}
                  placeholder="Example line item"
                  as={InputWithFormikErrors}
                />
              </td>
              <td style={{ lineHeight: "38px" }}>
                <Field
                  type="number"
                  name={`${namePrefix}.clientOrderProductLineItems.${i}.quantity`}
                  placeholder="1"
                  as={InputWithFormikErrors}
                />
              </td>
              <td>
                <InputGroup prepend="$">
                  <Field
                    name={`${namePrefix}.clientOrderProductLineItems.${i}.perItemCost`}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup prepend="%">
                  <Field
                    name={`${namePrefix}.clientOrderProductLineItems.${i}.margin`}
                    placeholder={clientOrderProductLineItem.calculatedMargin?.toFixed(
                      1,
                    )}
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup prepend="$">
                  <Field
                    name={`${namePrefix}.clientOrderProductLineItems.${i}.perItemPrice`}
                    placeholder={
                      clientOrderProductLineItem.calculatedPerItemPrice
                    }
                    as={InputWithFormikErrors}
                  />
                </InputGroup>
              </td>
              <td style={{ lineHeight: "38px" }}>
                <FormatCentsMaybe
                  cents={clientOrderProductLineItem.totalPriceCents}
                />
              </td>
              <td></td>
              <td>
                <button
                  type="button"
                  onClick={() => {
                    if (clientOrderProductLineItem.id) {
                      formik.setFieldValue(
                        `${namePrefix}.clientOrderProductLineItems.${i}.remove`,
                        true,
                      )
                    } else {
                      arrayHelpers.remove(i)
                    }
                  }}
                  className="border-0 bg-transparent d-flex p-2"
                >
                  <TrashSVG
                    style={{ width: 12, height: 14 }}
                    className="text-danger"
                  />
                  <VisuallyHidden>Remove Line Item</VisuallyHidden>
                </button>
              </td>
            </tr>
          )
        },
      )}
    </tbody>
  )
}

const ImpactPanel = (props: {
  clientOrderProduct: ClientOrderShowMakerFragment["clientOrderProducts"]["nodes"][number]
}) => (
  <div className="mb-4">
    <BorderBoxContainer>
      <BorderBoxHeading>Impact</BorderBoxHeading>
      <BorderBox>
        {props.clientOrderProduct.clientOrderProductSkus.length > 0 ? (
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Platform ID</th>
                  <th>Quantity</th>
                  <th>Energy</th>
                  <th>Water</th>
                  <th>Emissions Avoided</th>
                  <th>Pesticides</th>
                  <th>Waste Diverted</th>
                  <th>Water Bottles Recycled</th>
                  <th>Light Bulb Energy Saved</th>
                  <th>Driving Emissions Avoided</th>
                  <th>Drinking Water Saved</th>
                  <th>Land Saved From Pesticides</th>
                  <th>Carbon Offsetting</th>
                  <th>Fair wage hours worked</th>
                </tr>
              </thead>
              <tbody>
                {props.clientOrderProduct.clientOrderProductSkus
                  .filter((clientOrderProductSku) => clientOrderProductSku.id)
                  .map((clientOrderProductSku, i) => {
                    const {
                      sku,
                      quantity,
                      totalEnergyConservedKwh,
                      totalWaterSavedLiters,
                      totalEmissionsAvoidedKg,
                      totalPesticidesAvoidedG,
                      totalWasteDivertedG,
                      totalCarbonOffsetKg,
                      totalRecycledWaterBottles,
                      totalBulbEnergySavedHours,
                      totalDrivingEmissionsAvoidedKm,
                      totalDrinkingWaterSavedDays,
                      totalLandSavedFromPesticidesM2,
                      totalFairWageHoursWorked,
                    } = clientOrderProductSku
                    return (
                      <tr key={i}>
                        <td>{sku.humanId}</td>
                        <td>{quantity || <EmptyValue variant="dash" />}</td>
                        <td>
                          {totalEnergyConservedKwh ? (
                            `${numberWithCommas(
                              Math.round(totalEnergyConservedKwh),
                            )} kWh`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalWaterSavedLiters ? (
                            `${numberWithCommas(
                              Math.round(totalWaterSavedLiters),
                            )} Liters`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalEmissionsAvoidedKg ? (
                            <>
                              {numberWithCommas(
                                Math.round(totalEmissionsAvoidedKg),
                              )}
                              kgCO<sub>2</sub>e of CO<sub>2</sub>
                            </>
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalPesticidesAvoidedG ? (
                            `${numberWithCommas(
                              Math.round(totalPesticidesAvoidedG),
                            )} g`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalWasteDivertedG ? (
                            `${numberWithCommas(
                              Math.round(totalWasteDivertedG),
                            )} g`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalRecycledWaterBottles ? (
                            `${numberWithCommas(
                              Math.round(totalRecycledWaterBottles),
                            )}`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalBulbEnergySavedHours ? (
                            `${numberWithCommas(
                              Math.round(totalBulbEnergySavedHours),
                            )} hours`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalDrivingEmissionsAvoidedKm ? (
                            `${numberWithCommas(
                              Math.round(totalDrivingEmissionsAvoidedKm),
                            )} km`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalDrinkingWaterSavedDays ? (
                            `${numberWithCommas(
                              Math.round(totalDrinkingWaterSavedDays),
                            )} days`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalLandSavedFromPesticidesM2 ? (
                            `${numberWithCommas(
                              Math.round(totalLandSavedFromPesticidesM2),
                            )} m²`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalCarbonOffsetKg ? (
                            `${numberWithCommas(
                              Math.round(totalCarbonOffsetKg),
                            )} kg`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                        <td>
                          {totalFairWageHoursWorked ? (
                            `${numberWithCommas(
                              Math.round(totalFairWageHoursWorked),
                            )}`
                          ) : (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-muted">No Platform IDs have been added yet.</div>
        )}
      </BorderBox>
    </BorderBoxContainer>
  </div>
)

export default withBasics(ClientOrderProfile)

const ImportExportLine = ({
  label,
  modeOfTransit,
  abbreviation,
}: {
  label: string
} & Pick<ShippingPort, "abbreviation" | "modeOfTransit">) => {
  return (
    <div className="small text-nowrap lh-80 mb-1">
      {label}: {abbreviation}
      {" – "}
      <ModeOfTransitLabel value={modeOfTransit} />
    </div>
  )
}
