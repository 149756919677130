import React from "react"
import { MakerOrder } from "../generated/graphql"
import { Button } from "./FormElements"
import Modal from "./Modal"

type ClientOrderConfirmModalProps = {
  makerOrders: any
  isOpen: boolean
  addingProduct: boolean
  onClose: () => void
  onSubmit: () => void
}

const ClientOrderConfirmModal = ({
  makerOrders,
  isOpen,
  addingProduct,
  onClose,
  onSubmit,
}: ClientOrderConfirmModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-dialog" role="document">
        <div className="modal-content p-4" style={{ minWidth: "500px" }}>
          <div className="modal-header">
            <h4 className="modal-title">Maker Order(s) Exists</h4>
          </div>

          <div className="modal-body">
            <h6 className={"mb-4"}>{`Maker: ${makerOrders[0]?.maker.name}`}</h6>

            <p>
              {`WARNING: You are ${
                addingProduct ? "adding" : "editing"
              } a product to a Client Project that
              contains an existing Maker Order for this supplier.`}
            </p>

            <p>
              PLEASE NOTE: You must update the existing Maker Order for this
              product to appear within the Maker Order.
            </p>

            <h6>Maker Order(s):</h6>
            <ul>
              {makerOrders.map((mo: MakerOrder) => (
                <li key={mo.humanId}>{mo.humanId}</li>
              ))}
            </ul>
          </div>

          <div className="modal-footer">
            <Button type="button" variant="danger" onClick={onClose}>
              Cancel
            </Button>
            <Button type="button" variant="primary" onClick={onSubmit}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ClientOrderConfirmModal
