// @ts-nocheck
import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    $("#maker_order_feedback_sampling_included_true").on("change", () =>
      $("#samplingSection").show(),
    )
    $("#maker_order_feedback_sampling_included_false").on("change", () =>
      $("#samplingSection").hide(),
    )
    $("#maker_order_feedback_bulk_production_included_true").on("change", () =>
      $("#bulkProductionSection").show(),
    )
    $("#maker_order_feedback_bulk_production_included_false").on("change", () =>
      $("#bulkProductionSection").hide(),
    )
  }
}
