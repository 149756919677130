import { Field } from "formik"
import React, { useState } from "react"
import { Button, TextArea } from "./FormElements"
import Modal from "./Modal"

type MakerOrdePendingModalProps = {
  makerOrder: any
  isOpen: boolean
  isFormik: boolean
  onClose: () => void
  onSubmit: any
}

const MakerOrderPendingModal = ({
  makerOrder,
  isFormik = true,
  isOpen,
  onClose,
  onSubmit,
}: MakerOrdePendingModalProps) => {
  const [note, setNote] = useState("")

  const handleSubmit = () => {
    if (isFormik) {
      onSubmit()
    } else {
      onSubmit({
        status: "pending",
        makerOrderNotes: [{ note: note }],
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-dialog" role="document">
        <div className="modal-content p-4" style={{ minWidth: "500px" }}>
          <div className="modal-header">
            <h4 className="modal-title">Update Maker Order</h4>
          </div>

          <div className="modal-body">
            <p className="text-danger">
              WARNING: You are updating a Maker Order that has been shared with
              the maker. The maker contact will be notified via email that the
              Maker Order has been updated and will be prompted to re-approve
              the Maker Order. Please provide a detailed explanation of all
              changes made to the Maker Order.
            </p>

            <div>
              <div className="mb-4">
                {isFormik ? (
                  <Field
                    name="makerOrderNotes.0.note"
                    as={TextArea}
                    placeholder="'Ex. Units for Kid’s T-Shirts have been increased from 100 to 150 units.  COGs have been updated from $4.00 to $3.50."
                  />
                ) : (
                  <TextArea
                    className={"form-control"}
                    value={note}
                    placeholder="'Ex. Units for Kid’s T-Shirts have been increased from 100 to 150 units.  COGs have been updated from $4.00 to $3.50."
                    onChange={(e) => setNote(e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <Button type="button" variant="danger" onClick={onClose}>
              Cancel
            </Button>
            <Button type="button" variant="primary" onClick={handleSubmit}>
              Update Order
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MakerOrderPendingModal
