/** ActiveStorage.Blob isn't quite accurate */
export type Blobby = ActiveStorage.Blob & {
  id: string
  key: string
  created_at: Date
  service_name: string
}

export const directUploadUrl = "/rails/active_storage/direct_uploads"

export const blobImageUrl = ({ signed_id, key }: Blobby) =>
  `/rails/active_storage/blobs/${signed_id}/${key}`
