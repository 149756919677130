import { ReactComponent as CaretDownFillIcon } from "bootstrap-icons/icons/caret-down-fill.svg"
import { ReactComponent as CaretUpFillIcon } from "bootstrap-icons/icons/caret-up-fill.svg"
import { ReactComponent as CaretUpIcon } from "bootstrap-icons/icons/caret-up.svg"
import { ReactComponent as EyeIcon } from "bootstrap-icons/icons/eye.svg"
import find from "lodash/find"
import React, { useState } from "react"
import { UseInfiniteQueryResult } from "react-query"
import { ClientSearchQuery, ClientSort, SortOrder } from "../generated/graphql"
import { compact } from "../util/filters"
import { BorderBox } from "./BorderBox"
import { RequestError } from "./Errors"
import { NAME_SORT_OPTION } from "./ClientSearchScreen"
import { withBasics } from "./withBasics"

// Result Columns
export interface Column {
  name: string
  key: string
}
const ID_COLUMN: Column = { key: "humanId", name: "ID" }
const NAME_COLUMN: Column = { key: "name", name: "Name" }
const ACTIONS_COLUMN: Column = { key: "actions", name: "Actions" }
const DEFAULT_COLUMNS: Column[] = [ID_COLUMN, NAME_COLUMN, ACTIONS_COLUMN]

interface ClientSearchResultsTableProps {
  result: UseInfiniteQueryResult<ClientSearchQuery, Error>
  sorts: ClientSort[]
  onColumnSortClick: (sort: ClientSort) => void
}
const ClientSearchResultsTable = (props: ClientSearchResultsTableProps) => {
  const { result, sorts, onColumnSortClick } = props
  const [visibleColumns] = useState(DEFAULT_COLUMNS)

  const maybeRenderTableHeader = (column: Column) => {
    return find(visibleColumns, { key: column.key }) ? (
      <th>{column.name}</th>
    ) : null
  }

  const maybeRenderSortableTableHeader = (column: Column, sort: ClientSort) => {
    const colSorts: ClientSort[] = compact([
      find(sorts, { attribute: sort.attribute }),
    ])

    return find(visibleColumns, {
      key: column.key,
    }) ? (
      <th onClick={() => onColumnSortClick(sort)}>
        {column.name}

        {colSorts.length > 0 ? (
          colSorts.map((sort: ClientSort) => (
            <span className="ml-1" key={`${sort.attribute} ${sort.order}`}>
              {sort.order === SortOrder.Asc && <CaretUpFillIcon />}
              {sort.order === SortOrder.Desc && <CaretDownFillIcon />}
            </span>
          ))
        ) : (
          <span className="ml-1">
            <CaretUpIcon />
          </span>
        )}
      </th>
    ) : null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result

  return (
    <div className="d-flex flex-column">
      <BorderBox>
        <div className="table-responsive">
          <table className="table table-hover table-fixed-truncated nice-table">
            <thead>
              <tr>
                {maybeRenderTableHeader(ID_COLUMN)}
                {maybeRenderSortableTableHeader(NAME_COLUMN, NAME_SORT_OPTION)}
                {maybeRenderTableHeader(ACTIONS_COLUMN)}
              </tr>
            </thead>

            {data ? (
              <tbody>
                {data.pages
                  .flatMap((page) => compact(page.clients.nodes))
                  .map((client) => {
                    return (
                      <tr key={client.id}>
                        {visibleColumns.includes(ID_COLUMN) && (
                          <td>{client.humanId}</td>
                        )}
                        {visibleColumns.includes(NAME_COLUMN) && (
                          <td>
                            <a href={client.showUrl} data-testid="client-link">
                              {client.name}
                            </a>
                          </td>
                        )}

                        {visibleColumns.includes(ACTIONS_COLUMN) && (
                          <td>
                            <a
                              className="btn btn-link"
                              data-content="View Product"
                              href={client.showUrl}
                            >
                              <EyeIcon />
                            </a>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            ) : null}
          </table>
        </div>
      </BorderBox>
    </div>
  )
}

export default withBasics(ClientSearchResultsTable)
