import clsx from "clsx"
import React, { ReactNode } from "react"

type InputGroupProps = {
  prepend?: string
  append?: string
  children: ReactNode
  className?: string
}

const InputGroup = ({
  prepend,
  append,
  children,
  className,
}: InputGroupProps) => (
  <div className={clsx("input-group", className)} style={{ minWidth: "100px" }}>
    {prepend && (
      <div className="input-group-prepend">
        <span className="input-group-text">{prepend}</span>
      </div>
    )}

    {children}

    {append && (
      <div className="input-group-append">
        <span className="input-group-text">{append}</span>
      </div>
    )}
  </div>
)

export default InputGroup
