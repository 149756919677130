import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    $(this.element).select2({
      tags: false,
    })
  }
}
