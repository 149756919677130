import { Disclosure } from "@reach/disclosure"
import find from "lodash/find"
import React, { useState } from "react"
import { useDebounce } from "use-debounce/lib"
import {
  ClientSort,
  ClientSortField,
  SortOrder,
  useClientSearchQuery,
} from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { pluralize } from "../util/pluralize"
import LoadingIndicator from "./LoadingIndicator"
import ClientSearchResultsTable from "./ClientSearchResultsTable"
import { SearchInput } from "./SearchElements"
import { withBasics } from "./withBasics"

export const NAME_SORT_OPTION: ClientSort = {
  attribute: ClientSortField.Name,
  order: SortOrder.Asc,
}
export const MOST_RECENT_CLIENT_OPTION: ClientSort = {
  attribute: ClientSortField.MostRecent,
  order: SortOrder.Asc,
}
const DEFAULT_SORT_OPTIONS: ClientSort[] = [NAME_SORT_OPTION]

const ClientSearchScreen = () => {
  const [searchInput, setSearchInput] = useState("")
  const [searchInputDebounced] = useDebounce(searchInput, 400)

  // Sorting
  const [sorts, setSorts] = useState(DEFAULT_SORT_OPTIONS)
  const toggleColumnSort = (sort: ClientSort) => {
    let existingSort: ClientSort | undefined = find(sorts, {
      attribute: sort.attribute,
    })
    if (existingSort) {
      if (existingSort.order === SortOrder.Asc) {
        existingSort.order = SortOrder.Desc
      } else if (existingSort.order === SortOrder.Desc) {
        existingSort.order = SortOrder.Asc
      }

      setSorts([existingSort])
    } else {
      setSorts([sort])
    }
  }

  const result = useInfiniteGraphQLQuery(
    useClientSearchQuery,
    ({ pageParam }) => ({
      query: searchInputDebounced,
      sorts,
      after: pageParam,
    }),
    {
      getNextPageParam: (page) => page.clients.pageInfo.endCursor ?? undefined,
    },
  )

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center mt-6">
        <div className="d-flex mr-auto">
          <h1 className="my-0 mr-3 f-title mb-5">Clients</h1>
          {result.isFetching && <LoadingIndicator />}
        </div>
        <div>
          <a className="btn btn-dark" href="/clients/new">
            New Client
          </a>
        </div>
      </div>

      <Disclosure>
        <div className="mb-4 d-flex">
          <SearchInput
            placeholder="Search by Client Name or Client ID..."
            aria-label="Search"
            autoFocus={true}
            onChange={(e) => setSearchInput(e.currentTarget.value)}
            value={searchInput}
          />
        </div>
      </Disclosure>
      <>
        Results:{" "}
        {result.status === "success"
          ? pluralize({
              zero: "No results",
              singular: "1 Client",
              plural: "{{count}} Clients",
              count: result.data.pages[0].clients.totalCount,
            })
          : "…"}
      </>

      <ClientSearchResultsTable
        result={result}
        sorts={sorts}
        onColumnSortClick={toggleColumnSort}
      />

      {result.hasNextPage && (
        <button
          onClick={() => result.fetchNextPage()}
          type="button"
          className="btn btn-primary mb-3"
          disabled={result.isFetchingNextPage}
        >
          {result.isLoading ? "Loading..." : "Load more"}
        </button>
      )}
    </div>
  )
}

export default withBasics(ClientSearchScreen)
