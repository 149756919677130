export function isNotNull<T>(value: T): value is NonNullable<T> {
  return value != null
}

/**
 * returns a copy of the optional array with null and undefined elements removed
 *
 * useful with graphql responses, since fields and nodes are optional
 */
export function compact<T>(
  list: Array<T> | null | undefined,
): Array<NonNullable<T>> {
  if (list == null) return []
  return list.filter(isNotNull)
}
