import { DirectUpload } from "@rails/activestorage"
import { FormikProvider, useFormik, useFormikContext } from "formik"
import React, { useRef, useState } from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import {
  CertificationWithIconFragment,
  ImpactReportInput,
  ImpactReportMutation,
  ImpactReportShowMakerFragment,
  useImpactReportMutation,
  useImpactReportShowQuery,
} from "../generated/graphql"
import { Blobby, blobImageUrl, directUploadUrl } from "../util/active-storage"
import { deepChangedValues } from "../util/changed-values"
import { RequestError } from "./Errors"
import { Button } from "./FormElements"
import LoadingIndicator from "./LoadingIndicator"
import { ProductAttributes } from "./ProductAttributes"
import { CertificationList } from "./CertificationList"
import { withBasics } from "./withBasics"
import clsx from "clsx"

const ImpactReportMakers = (props: { id: string }) => {
  const result = useImpactReportShowQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const impactReport = result.data.impactReport

  return <ImpactReportMakersForm impactReport={impactReport} readOnly={true} />
}

const ImageUploadWrapper = ({
  object,
  makerIndex,
  clientOrderProductIndex,
  children,
}: any) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const formik = useFormikContext()
  const [imageBlob, setImageBlob] = useState<Blobby | null>(null)
  const [removed, setRemoved] = useState(false)
  const base =
    clientOrderProductIndex !== undefined
      ? `makers.${makerIndex}.products.${clientOrderProductIndex}`
      : `makers.${makerIndex}`

  const handleUpload = () => {
    if (!fileRef.current) throw new Error(`expected file ref`)
    const files = fileRef.current.files
    if (!files) return

    for (const file of Array.from(files)) {
      const upload = new DirectUpload(file, directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          alert(`Error uploading file: ${error}`)
          return
        }

        setImageBlob((blob as unknown) as Blobby)

        formik.setFieldValue(`${base}.id`, object.id)
        formik.setFieldValue(
          `${base}.impactReportImage.imageSignedId`,
          blob.signed_id,
        )
        formik.setFieldValue(`${base}.impactReportImage.remove`, undefined)
      })
    }
  }

  const removable =
    imageBlob ||
    (object.impactReportImage?.className === "ImpactReportImage" &&
      !object.impactReportImage?.remove)

  const removeImage = () => {
    setRemoved(true)
    if (imageBlob) setImageBlob(null)

    formik.setFieldValue(`${base}.id`, object.id)
    formik.setFieldValue(
      `${base}.impactReportImage.id`,
      object.impactReportImage.id,
    )
    formik.setFieldValue(`${base}.impactReportImage.imageSignedId`, undefined)
    formik.setFieldValue(`${base}.impactReportImage.remove`, true)
  }

  return (
    <div style={{ position: "relative" }} className="mb-4">
      {imageBlob ? (
        <img
          src={blobImageUrl(imageBlob)}
          alt=""
          className="w-100"
          style={{}}
        />
      ) : removed ? (
        <div
          className="w-100"
          style={{
            minHeight: "300px",
            backgroundColor: "#c4c4c4",
          }}
        ></div>
      ) : (
        children
      )}
      <div
        className="d-flex"
        // style={{ position: "absolute", bottom: "10px", right: "10px" }}
      >
        {removable && (
          <Button
            variant="danger"
            className="btn-sm mr-2"
            onClick={removeImage}
          >
            Remove
          </Button>
        )}
        <input
          type="file"
          ref={fileRef}
          onChange={handleUpload}
          accept="image/*"
          className="d-none"
          id={`image-${makerIndex}-${clientOrderProductIndex}`}
        />
        <label
          htmlFor={`image-${makerIndex}-${clientOrderProductIndex}`}
          className="d-flex text-dark-pink m-0"
          style={{ cursor: "pointer" }}
        >
          <span className="btn btn-sm btn-primary">Upload Image</span>
        </label>
      </div>
    </div>
  )
}

export const ImpactReportMakersForm = ({
  impactReport,
  readOnly,
}: {
  impactReport: ImpactReportShowMakerFragment
  readOnly: boolean
}) => {
  const mutation = useImpactReportMutation()
  const initialValues = {
    id: impactReport.id,
    makers: impactReport.makers.nodes,
    makerCertificationIds: impactReport.makerCertifications.nodes.map(
      (n) => n.id,
    ),
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldError }) => {
      const params = deepChangedValues(initialValues, values)

      const input: ImpactReportInput = {
        id: impactReport.id,
        ...params,
      }

      const {
        impactReport: result,
      }: ImpactReportMutation = await mutation.mutateAsync({
        input,
      })

      if (result && result.errors.length) {
        for (let error of result.errors)
          if (error.path) setFieldError(error.path[1], error.message)
      } else {
        window.location.href = result.impactReport.showUrl
      }
    },
  })

  const certificationsByMaker = (makerId: string) => {
    const entries = impactReport.makerCertifications.nodes.filter(
      (node) => node.maker.id === makerId,
    )
    let certs: Array<CertificationWithIconFragment> = []
    for (let entry of entries) certs.push(entry.certification)
    return certs
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.makers.map((maker, makerIndex) => (
          <>
            <div
              key={maker.id}
              className={clsx(
                "px-3",
                "pt-3",
                "pb-0",
                "overflow-x-auto",
                "text-left",
                "mb-4",
              )}
            >
              <div className="break-inside-avoid">
                {readOnly ? (
                  <>
                    {maker.impactReportImage && (
                      <ImpactReportImage
                        src={maker.impactReportImage.imgixUrl}
                      />
                    )}
                  </>
                ) : (
                  <ImageUploadWrapper object={maker} makerIndex={makerIndex}>
                    {maker.impactReportImage && (
                      <ImpactReportImage
                        src={maker.impactReportImage.imgixUrl}
                      />
                    )}
                  </ImageUploadWrapper>
                )}
                {maker.profile && <p>{maker.profile}</p>}
                {maker.socialImpactDescription && (
                  <p>{maker.socialImpactDescription}</p>
                )}
                {maker.environmentalImpactDescription && (
                  <p>{maker.environmentalImpactDescription}</p>
                )}
                {certificationsByMaker(maker.id).length > 0 && (
                  <CertificationList
                    certifications={certificationsByMaker(maker.id)}
                  />
                )}
              </div>
              {!readOnly && maker.makerCertifications.nodes.length > 0 && (
                <div className="card break-inside-avoid">
                  <div className="card-body">
                    <h5 className="card-title">Display Certifications</h5>
                    <ul>
                      {maker.makerCertifications.nodes.map((makerCert) => (
                        <li key={makerCert.id}>
                          <input
                            id={`checkbox-${makerCert.id}`}
                            type="checkbox"
                            className="form-check-input"
                            checked={formik.values.makerCertificationIds.includes(
                              makerCert.id,
                            )}
                            onChange={(ev) => {
                              let prev = formik.values.makerCertificationIds
                              let next = ev.target.checked
                                ? [...prev, makerCert.id]
                                : prev.filter((x) => x !== makerCert.id)
                              formik.setFieldValue(
                                "makerCertificationIds",
                                next,
                              )
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox-${makerCert.id}`}
                          >
                            {makerCert.certification.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              {maker.impactReportClientOrderProducts.map(
                (clientOrderProduct, productIndex) => {
                  const { product } = clientOrderProduct

                  return (
                    <div
                      key={clientOrderProduct.id}
                      className="d-flex align-items-center border rounded border-dashed border-pink mb-1 mt-3 p-3 mb-0 break-inside-avoid"
                    >
                      <div className="mr-4" style={{ width: "270px" }}>
                        {readOnly ? (
                          <>
                            {product.impactReportImage && (
                              <ProductImage
                                src={product.impactReportImage.imgixUrl}
                              />
                            )}
                          </>
                        ) : (
                          <ImageUploadWrapper
                            object={product}
                            makerIndex={makerIndex}
                            clientOrderProductIndex={productIndex}
                          >
                            {product.impactReportImage && (
                              <ProductImage
                                src={product.impactReportImage.imgixUrl}
                              />
                            )}
                          </ImageUploadWrapper>
                        )}
                      </div>

                      <div>
                        <div
                          className="f-title text-uppercase"
                          style={{ fontSize: "20px" }}
                        >
                          {product.name}
                        </div>
                        {product.countryOfOrigin && (
                          <p className="mb-0">
                            Made in {product.countryOfOrigin.name}
                          </p>
                        )}
                        {product.materials.nodes.length > 0 && (
                          <p className="mb-2">
                            Made of{" "}
                            {product.materials.nodes
                              .map((material) => material.name)
                              .join(", ")}
                          </p>
                        )}

                        {!!clientOrderProduct.totalEnergyConservedKwh && (
                          <p className="mb-0 font-weight-bold">
                            Energy: {clientOrderProduct.totalEnergyConservedKwh}{" "}
                            kWh of Energy Conserved
                          </p>
                        )}
                        {!!clientOrderProduct.totalWaterSavedLiters && (
                          <p className="mb-0 font-weight-bold">
                            Water: {clientOrderProduct.totalWaterSavedLiters} L
                            of Water Saved
                          </p>
                        )}
                        {!!clientOrderProduct.totalEmissionsAvoidedKg && (
                          <p className="mb-0 font-weight-bold">
                            Emissions Avoided:{" "}
                            {clientOrderProduct.totalEmissionsAvoidedKg} kgCO
                            <sub>2</sub>e of CO<sub>2</sub> Avoided
                          </p>
                        )}
                        {!!clientOrderProduct.totalPesticidesAvoidedG && (
                          <p className="mb-0 font-weight-bold">
                            Pesticides:{" "}
                            {clientOrderProduct.totalPesticidesAvoidedG} g of
                            pesticides avoided
                          </p>
                        )}
                        {!!clientOrderProduct.totalFairWageHoursWorked && (
                          <p className="mb-0 font-weight-bold">
                            Fair Wage Work:{" "}
                            {clientOrderProduct.totalFairWageHoursWorked} Fair
                            Wages/Hour
                          </p>
                        )}
                        {!!clientOrderProduct.totalRecycledWaterBottles && (
                          <p className="mb-0 font-weight-bold">
                            Recycling:{" "}
                            {clientOrderProduct.totalRecycledWaterBottles}{" "}
                            Recycled Water Bottles
                          </p>
                        )}
                        {clientOrderProduct.product.productAttributes.length >
                          0 && (
                          <div className="pt-3">
                            <ProductAttributes
                              product={clientOrderProduct.product}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                },
              )}
            </div>
            <hr className="border-pink m-3" />
          </>
        ))}

        {!readOnly && (
          <div className="d-flex justify-content-end align-items-baseline mt-4">
            <Button variant="dark" type="submit">
              Save
            </Button>
          </div>
        )}
      </form>
    </FormikProvider>
  )
}

export default withBasics(ImpactReportMakers)

const ImpactReportImage = (props: React.ComponentProps<typeof Imgix>) => {
  return (
    <Imgix
      className="mb-3 w-100"
      imgixParams={{ ar: "2:1", fit: "crop" }}
      htmlAttributes={{
        alt: "",
        style: { maxHeight: "500px", objectFit: "cover" },
      }}
      {...props}
    />
  )
}

const ProductImage = (props: { src: string }) => {
  return (
    <Imgix
      className="w-100"
      imgixParams={{
        "ar": `1:1`,
        "fit": `fill`,
        "fill-color": `white`,
      }}
      htmlAttributes={{
        alt: "",
        style: { width: "270px", maxHeight: "250px", objectFit: "contain" },
      }}
      sizes="270px"
      {...props}
    />
  )
}
