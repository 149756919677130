import { FormikProvider, useFormik } from "formik"
import React, { useState } from "react"
import invariant from "tiny-invariant"
import {
  MakerOrderShowMakerFragment,
  useMakerOrderShowQuery,
  useUpdateMakerOrderCogsMutation,
} from "../generated/graphql"
import { RequestError } from "./Errors"
import { Button } from "./FormElements"
import LoadingIndicator from "./LoadingIndicator"
import MakerOrderPendingModal from "./MakerOrderPendingModal"
import { withBasics } from "./withBasics"

type UpdateMakerOrderCogsButtonProps = {
  makerOrderId: string
}

const UpdateMakerOrderCogsButtonWrapper = (
  props: UpdateMakerOrderCogsButtonProps,
) => {
  const result = useMakerOrderShowQuery({ id: props.makerOrderId })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { makerOrder } = result.data

  if (!makerOrder) return null

  return <UpdateMakerOrderCogsButton makerOrder={makerOrder} />
}

const UpdateMakerOrderCogsButton = ({
  makerOrder,
}: {
  makerOrder: MakerOrderShowMakerFragment
}) => {
  const [pendingModalOpen, setPendingModalOpen] = useState(false)

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setFieldValue }) => {
      if (!pendingModalOpen && makerOrder.status !== "not_shared") {
        setFieldValue("status", "pending")
        setPendingModalOpen(true)
        return
      }

      const result = await mutation.mutateAsync({
        input: {
          id: makerOrder.id,
          ...values,
        },
      })

      window.location.href = result.updateMakerOrderCogs.makerOrder.showUrl
    },
  })

  const mutation = useUpdateMakerOrderCogsMutation()

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <MakerOrderPendingModal
          makerOrder={makerOrder}
          isOpen={pendingModalOpen}
          isFormik={true}
          onClose={() => setPendingModalOpen(false)}
          onSubmit={formik.handleSubmit}
        />
        <Button
          variant="primary"
          type="submit"
          disabled={mutation.isLoading || mutation.isSuccess}
        >
          {mutation.isLoading || mutation.isSuccess
            ? "Updating..."
            : "Update Order"}
        </Button>
        <RequestError {...mutation} />
      </form>
    </FormikProvider>
  )
}

export default withBasics(UpdateMakerOrderCogsButtonWrapper)
