import React, { useState, useEffect } from "react"
import { withBoundary } from "./ErrorBoundary"
import { request } from "../util/request"
import snakecaseKeys from "snakecase-keys"
import { initPopovers } from "../dom/popover"
import $ from "jquery"

/**
 * this file is intendeded to be deleted as soon as the legacy
 * product view is no longer available
 */
const CogsTableShippingRateDepreciated = ({
  exportPortId,
  importPortId,
  includeShippingCost,
  includeDutyRate,
  productId,
  productGroupId,
}) => {
  const params = {
    exportPortId,
    importPortId,
    includeShippingCost,
    includeDutyRate,
    productId,
  }
  const [shippingRate, setShippingRate] = useState(null)
  const [exportPort, setExportPort] = useState(null)
  const [importPort, setImportPort] = useState(null)

  useEffect(() => {
    if (exportPortId && importPortId) {
      request({
        resource: `/product-groups/${productGroupId}/cogs-table-settings`,
        params: snakecaseKeys(params),
      }).then((data) => {
        $('[data-toggle="popover"]').popover("dispose")
        $('[data-toggle="tooltip"]').tooltip("dispose")
        document.getElementById("cogs-tables").innerHTML = data.content
        setShippingRate(data.shippingRate)
        setExportPort(data.exportPort)
        setImportPort(data.importPort)
        initPopovers()
      })
    }
    // todo fix and verify this, no bandwidth atm
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPortId, importPortId, includeShippingCost, includeDutyRate])

  if (!exportPort || !importPort) {
    return null
  }

  return (
    <div className="container">
      <div className="row">
        {shippingRate ? (
          <div className="col">
            <p className="alert alert-primary">
              Shipping Transit Time: {shippingRate.min_transit_time} to{" "}
              {shippingRate.max_transit_time} days.
            </p>
          </div>
        ) : (
          <div className="col">
            <p className="alert alert-danger">
              We could not find a shipping rate between {exportPort.port} and{" "}
              {importPort.port}
            </p>
          </div>
        )}
      </div>

      {exportPort.mode_of_transit !== importPort.mode_of_transit ? (
        <div className="row">
          <div className="col">
            <p className="alert alert-danger">
              {exportPort.port} mode of transite ({exportPort.mode_of_transit})
              does not match
              {importPort.port} mode of transit ({importPort.mode_of_transit}).
            </p>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default withBoundary(CogsTableShippingRateDepreciated)
