import React from "react"
import { withBasics } from "./withBasics"

type CheckboxProps = {
  label: string
  id: string
} & JSX.IntrinsicElements["input"]

const Checkbox = (props: CheckboxProps) => {
  const { label, ...rest } = props
  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input accent-primary"
        type="checkbox"
        {...rest}
      />
      <label className="form-check-label ml-2" htmlFor={rest.id}>
        {label}
      </label>
    </div>
  )
}

export default withBasics(Checkbox)
