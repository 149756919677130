import { Controller } from "stimulus"

export default class extends Controller {
  declare selectTarget: HTMLSelectElement
  declare newFieldSetTarget: HTMLFieldSetElement

  static targets = ["select", "newFieldSet"]

  connect() {
    this.setFieldSetDisabledState()
  }

  select() {
    this.setFieldSetDisabledState()
  }

  setFieldSetDisabledState() {
    if (this.selectTarget.value === "") {
      this.newFieldSetTarget.disabled = false
      this.newFieldSetTarget.hidden = false
    } else {
      this.newFieldSetTarget.disabled = true
      this.newFieldSetTarget.hidden = true
    }
  }
}
