import React, { useState } from "react"
import { withBasics } from "./withBasics"
import {
  TimeAndActionCalendarTaskInput,
  TimeAndActionCalendarTaskOwnerInput,
  UpdateTimeAndActionInput,
  UpdateTimeAndActionMutation,
  useTimeAndActionQuery,
  useUpdateTimeAndActionMutation,
} from "../generated/graphql"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import invariant from "tiny-invariant"
import { BorderBox, BorderBoxContainer, BorderBoxHeading } from "./BorderBox"
import TimeAndActionCalendarTasks from "./TimeAndActionCalendarTasks"
import TimeAndActionCalendarTasksEdit from "./TimeAndActionCalendarTasksEdit"
import TimeAndActionHistoryModal from "./TimeAndActionHistoryModal"
import { formatDateTimeLong } from "../util/dates"

type TimeAndActionProps = {
  id: string
  editing: boolean
  locked: boolean
}

const TimeAndAction = ({ id, editing, locked }: TimeAndActionProps) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false)
  const result = useTimeAndActionQuery({ id: id })
  const mutation = useUpdateTimeAndActionMutation()

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const timeAndAction = result.data.timeAndAction

  const submit = async (tasks: TimeAndActionCalendarTaskInput[]) => {
    const input: UpdateTimeAndActionInput = {
      id: timeAndAction.id,
      calendar: {
        id: timeAndAction.calendar.id,
        tasks: tasks.map(
          (task): TimeAndActionCalendarTaskInput => {
            return {
              id: task.id,
              remove: task.remove,
              name: task.name,
              stage: task.stage,
              status: task.status,
              position: task.position,
              dueDate: task.dueDate,
              startDate: task.startDate,
              dateCompleted: task.dateCompleted,
              actualStartDate: task.actualStartDate,
              remindersOn: task.remindersOn,
              durationDays: task.durationDays,
              displayToMaker: task.displayToMaker,
              owners: task.owners.map(
                (owner): TimeAndActionCalendarTaskOwnerInput => {
                  return {
                    id: owner.id,
                    remove: owner.remove,
                    maker: owner.maker,
                    userId: owner.user?.id,
                  }
                },
              ),
            }
          },
        ),
      },
    }

    try {
      const {
        updateTimeAndAction: result,
      }: UpdateTimeAndActionMutation = await mutation.mutateAsync({
        input,
      })

      if (result) {
        if (result.errors.length) {
          // @ts-ignore: TODO
          // for (let error of result.errors)
          //   if (error.path) setFieldError(error.path[1], error.message)
        } else {
          window.location.replace(window.location.pathname)
        }
      }
    } catch (err) {
      const message = (() => {
        const { message } = err as Error
        return `An error occured: ${message}`
      })()
      window.alert(message)
    }
  }

  return (
    <div className="d-flex flex-column">
      <TimeAndActionHistoryModal
        isOpen={historyModalOpen}
        onClose={() => setHistoryModalOpen(false)}
        calendarChanges={timeAndAction.calendar.calendarChanges}
      />
      <BorderBoxContainer>
        <div className="d-flex align-items-baseline justify-content-between w-100">
          <BorderBoxHeading>
            {editing ? "Edit Time And Action" : "Time And Action"}
          </BorderBoxHeading>

          {!editing && (
            <div className="d-flex align-items-center">
              <div className="d-block">
                {`${
                  timeAndAction.updatedAt !== timeAndAction.createdAt
                    ? "Revised"
                    : "Created"
                } On: `}
                {formatDateTimeLong(
                  timeAndAction.updatedAt || timeAndAction.createdAt,
                )}
              </div>

              <div className="d-block">
                <div
                  className="btn btn-sm btn-link"
                  onClick={() => setHistoryModalOpen(true)}
                >
                  View History
                </div>
              </div>

              <div className="d-block">
                <a
                  href={timeAndAction.editUrl}
                  className="btn btn-link btn-sm text-dark-pink"
                >
                  Edit
                </a>
              </div>
            </div>
          )}
          <RequestError {...mutation} />
        </div>
        <BorderBox solid={true}>
          {editing ? (
            <TimeAndActionCalendarTasksEdit
              tasks={timeAndAction.calendar.tasks.nodes}
              template={false}
              submit={submit}
              ownerOptions={timeAndAction.ownerOptions}
            />
          ) : (
            <TimeAndActionCalendarTasks
              tasks={timeAndAction.calendar.tasks.nodes}
              showUrl={timeAndAction.showUrl}
              statusOptions={timeAndAction.taskStatusOptions}
              submit={submit}
              locked={locked}
            />
          )}
        </BorderBox>
      </BorderBoxContainer>
    </div>
  )
}

export default withBasics(TimeAndAction)
