import styled from "styled-components"

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`

const Alert = styled.div`
  margin-bottom: 0;
  margin-left: 10px;
  padding: 0.5rem 1.25rem;
`

export default { Container, Alert }
