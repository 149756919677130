import find from "lodash/find"
import React, { useState } from "react"
import { UseInfiniteQueryResult } from "react-query"
import invariant from "tiny-invariant"
import { ImpactReportSearchQuery } from "../generated/graphql"
import { compact } from "../util/filters"
import { BorderBox } from "./BorderBox"
import { RequestError } from "./Errors"
import { withBasics } from "./withBasics"
import { EmptyValue } from "./EmptyValue"
import ImpactReportSearchColumnSettingsModal from "./SearchColumnSettingsModal"

export type Column = {
  name: string
  key: string
  classes?: string
}
const TYPE_COLUMN: Column = { key: "type", name: "Type" }
const CLIENT_COLUMN: Column = { key: "client", name: "Client" }
const ID_COLUMN: Column = { key: "id", name: "Report" }
const CREATED_AT_COLUMN: Column = {
  key: "createdAt",
  name: "Date",
  classes: "col-3",
}
const DESCRIPTION_COLUMN: Column = { key: "description", name: "Description" }
const CLIENT_PROJECTS_COLUMN: Column = {
  key: "client_projects",
  name: "Client Projects",
}
const CP_DATE_CREATED_COLUMN: Column = {
  key: "dateCreated",
  name: "Date Created",
  classes: "col-3",
}
const DELIVERY_WINDOW_COLUMN: Column = {
  key: "deliveryWindow",
  name: "Delivery Window",
  classes: "col-3",
}
const MAKERS_COLUMN: Column = { key: "makers", name: "Makers" }
const DEFAULT_COLUMNS: Column[] = [
  CLIENT_COLUMN,
  TYPE_COLUMN,
  ID_COLUMN,
  CREATED_AT_COLUMN,
  DESCRIPTION_COLUMN,
  CLIENT_PROJECTS_COLUMN,
  CP_DATE_CREATED_COLUMN,
  DELIVERY_WINDOW_COLUMN,
  MAKERS_COLUMN,
]

const ALL_COLUMNS = DEFAULT_COLUMNS.concat()

const ImpactReportListResults = ({
  result,
}: {
  result: UseInfiniteQueryResult<ImpactReportSearchQuery, Error>
}) => {
  const [columnSettingsOpen, setColumnSettingsOpen] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState(DEFAULT_COLUMNS)
  const maybeRenderTableHeader = (column: Column) => {
    return find(visibleColumns, { key: column.key }) ? (
      <th className={column.classes}>{column.name}</th>
    ) : null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  if (result.status === "loading") {
    return null
  }

  const { data } = result
  invariant(data)

  return (
    <div className="d-flex flex-column">
      <div>
        <button
          className="float-right btn btn-link f-action-text pb-2"
          onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
        >
          Manage
        </button>
        <ImpactReportSearchColumnSettingsModal
          visibleColumns={visibleColumns}
          defaultColumns={DEFAULT_COLUMNS}
          allColumns={ALL_COLUMNS}
          isOpen={columnSettingsOpen}
          onClose={() => setColumnSettingsOpen(false)}
          onApply={(selectedColumns: Column[]) => {
            setVisibleColumns(selectedColumns)
            setColumnSettingsOpen(false)
          }}
        />
      </div>
      <BorderBox>
        <div className="table-responsive">
          <table
            className="table table-hover nice-table"
            style={{ tableLayout: "auto", width: "100%" }}
          >
            <thead>
              <tr>
                {maybeRenderTableHeader(TYPE_COLUMN)}
                {maybeRenderTableHeader(CLIENT_COLUMN)}
                {maybeRenderTableHeader(ID_COLUMN)}
                {maybeRenderTableHeader(CREATED_AT_COLUMN)}
                {maybeRenderTableHeader(DESCRIPTION_COLUMN)}
                {maybeRenderTableHeader(CLIENT_PROJECTS_COLUMN)}
                {maybeRenderTableHeader(CP_DATE_CREATED_COLUMN)}
                {maybeRenderTableHeader(DELIVERY_WINDOW_COLUMN)}
                {maybeRenderTableHeader(MAKERS_COLUMN)}
              </tr>
            </thead>
            <tbody>
              {data.pages
                .flatMap((page) => compact(page.impactReports.nodes))
                .map((impactReport) => (
                  <tr key={impactReport.id}>
                    {visibleColumns.includes(TYPE_COLUMN) && (
                      <td>
                        {impactReport.type
                          ?.split("_")
                          .map((w) => _.capitalize(w))
                          .join(" ")}
                      </td>
                    )}
                    {visibleColumns.includes(CLIENT_COLUMN) && (
                      <td>
                        {impactReport.client && (
                          <a href={impactReport.client.showUrl}>
                            {impactReport.client.name}
                          </a>
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(ID_COLUMN) && (
                      <td>
                        <a
                          href={impactReport.showUrl}
                        >{`IR${impactReport.humanId}`}</a>
                      </td>
                    )}
                    {visibleColumns.includes(CREATED_AT_COLUMN) && (
                      <td>
                        {new Date(impactReport.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                            timeZone: "UTC",
                          },
                        )}{" "}
                        {new Date(impactReport.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZoneName: "short",
                          },
                        )}
                      </td>
                    )}
                    {visibleColumns.includes(DESCRIPTION_COLUMN) && (
                      <td>
                        <div>{impactReport.description}</div>
                      </td>
                    )}
                    {visibleColumns.includes(CLIENT_PROJECTS_COLUMN) && (
                      <td>
                        {impactReport.clientOrders.map((clientProject) => (
                          <div key={clientProject.id}>
                            <a className="mr-1" href={clientProject.showUrl}>
                              {`#${clientProject.humanId}`}
                            </a>
                          </div>
                        ))}
                      </td>
                    )}
                    {visibleColumns.includes(CP_DATE_CREATED_COLUMN) && (
                      <td>
                        {impactReport.clientOrders.map((clientProject) => (
                          <div key={clientProject.id}>
                            {clientProject.createdAt && (
                              <span>
                                {new Date(
                                  clientProject.createdAt,
                                ).toLocaleDateString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                  timeZone: "UTC",
                                })}
                              </span>
                            )}
                          </div>
                        ))}
                      </td>
                    )}
                    {visibleColumns.includes(DELIVERY_WINDOW_COLUMN) && (
                      <td>
                        {impactReport.clientOrders.map((clientProject) => (
                          <div key={clientProject.id}>
                            {clientProject.deliveryWindow?.startDate ? (
                              `${new Date(
                                clientProject.deliveryWindow.startDate,
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                                timeZone: "UTC",
                              })} - ${new Date(
                                clientProject.deliveryWindow.endDate,
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                                timeZone: "UTC",
                              })}`
                            ) : (
                              <EmptyValue variant="message" />
                            )}
                          </div>
                        ))}
                      </td>
                    )}
                    {visibleColumns.includes(MAKERS_COLUMN) && (
                      <td>
                        {impactReport.makers.nodes.map((maker) => (
                          <div key={maker.id} className="mr-1">
                            <a href={maker.showUrl}>{maker.name}</a>
                          </div>
                        ))}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </BorderBox>
    </div>
  )
}

export default withBasics(ImpactReportListResults)
