/**
 * @param {string} name
 */
export const getEnv = (name) => {
  const el = document.querySelector(`meta[name=${name}]`)
  if (el instanceof HTMLMetaElement) {
    return el.content
  }
  return undefined
}
