import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
} from "react-query"
import { graphqlRequest } from "../util/graphql-fetch-config"

interface UseQueryFn<
  TData extends Record<string, any>,
  TVariables extends Record<string, any>
> {
  (variables: TVariables, options?: UseQueryOptions<TData>): unknown
  document: string
  getKey: (variables: TVariables) => unknown[]
}

/**
 * @todo fix types on this for variables
 *
 * thank you:
 * https://github.com/dotansimha/graphql-code-generator/issues/5212#issuecomment-823536072
 */
export function useInfiniteGraphQLQuery<
  TData extends Record<string, any>,
  TVariables extends Record<string, any>
>(
  useQuery: UseQueryFn<TData, TVariables>,
  getVariables: ({ pageParam }: { pageParam?: any }) => TVariables,
  options?: UseInfiniteQueryOptions<TData, Error>,
): UseInfiniteQueryResult<TData, Error> {
  return useInfiniteQuery<TData, Error>(
    useQuery.getKey(getVariables({})),
    ({ pageParam }) =>
      graphqlRequest<TData, TVariables>(
        useQuery.document,
        getVariables({ pageParam }),
      ),
    options,
  )
}
