// @ts-nocheck
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "textField"]

  connect() {}

  selectValueChanged() {
    this.populateTextField()
  }

  populateTextField() {
    const selectedOption = this.selectTarget.options[
      this.selectTarget.selectedIndex
    ]
    this.textFieldTarget.value = selectedOption.label
  }
}
