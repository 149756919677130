import React from "react"
import { withBasics } from "./withBasics"

const Contact = ({
  contact,
}: {
  contact: {
    fullName?: string | null | undefined
    email?: string | null | undefined
    jobTitle?: string | null | undefined
  }
}) => {
  return <>{contact.fullName || contact.email || contact.jobTitle}</>
}

export default withBasics(Contact)
