import React from "react"
import invariant from "tiny-invariant"
import { useImpactReportSearchFilterOptionsQuery } from "../generated/graphql"
import { RequestError } from "./Errors"
import SearchSelect from "./SearchSelect"
import { withBasics } from "./withBasics"
import DatePicker from "react-datepicker"

export type ImpactReportFilters = {
  makers: string[]
  clients: string[]
  createdAfter: Date | undefined
  createdBefore: Date | undefined
  deliveryWindowAfter: Date | undefined
  deliveryWindowBefore: Date | undefined
  types: string[]
}

export const DEFAULT_IMPACT_REPORT_FILTERS = {
  makers: [],
  clients: [],
  createdAfter: undefined,
  createdBefore: undefined,
  deliveryWindowAfter: undefined,
  deliveryWindowBefore: undefined,
  types: [],
}

type ImpactReportSearchFiltersProps = {
  filters: ImpactReportFilters
  setFilters: (v: ImpactReportFilters) => void
}

const ImpactReportSearchFilters = (props: ImpactReportSearchFiltersProps) => {
  const { filters, setFilters } = props

  const result = useImpactReportSearchFilterOptionsQuery()
  if (result.status === "loading") {
    return null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result
  invariant(data, `expected data`)
  const { impactReportSearchFilterOptions } = data

  return (
    data && (
      <div>
        <div className="row mb-2">
          <div style={{ width: "100%" }}>
            <button
              className="float-right btn btn-link"
              style={{ color: "#dc3545" }}
              onClick={() => {
                setFilters(DEFAULT_IMPACT_REPORT_FILTERS)
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={impactReportSearchFilterOptions.clients.map((value) => ({
                value: value?.id,
                label: value?.name,
              }))}
              values={filters.clients.map((clientId: String) => ({
                value: clientId,
                label: (
                  impactReportSearchFilterOptions.clients.find(
                    (option) => option?.id === clientId,
                  ) || {}
                )?.name,
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  clients: options.map((o) => o.value),
                })
              }}
              label="Clients"
            />

            <div className="mb-2 mt-2">
              <label className="f-header-4">Delivery Window After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.deliveryWindowAfter &&
                    filters.deliveryWindowAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.deliveryWindowAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    deliveryWindowAfter: date,
                  })
                }}
              />
            </div>
            <div className="mb-2 mt-2">
              <label className="f-header-4">Delivery Window Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.deliveryWindowBefore &&
                    filters.deliveryWindowBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.deliveryWindowBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    deliveryWindowBefore: date,
                  })
                }}
              />
            </div>
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={impactReportSearchFilterOptions.makers.map((value) => ({
                value: value?.id,
                label: value?.name,
              }))}
              values={filters.makers.map((makerId: String) => ({
                value: makerId,
                label: (
                  impactReportSearchFilterOptions.makers.find(
                    (option) => option?.id === makerId,
                  ) || {}
                )?.name,
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  makers: options.map((o) => o.value),
                })
              }}
              label="Makers"
            />

            <div className="mb-2 mt-2">
              <label className="f-header-4">Created After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.createdAfter &&
                    filters.createdAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.createdAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    createdAfter: date,
                  })
                }}
              />
            </div>
            <div className="mb-2 mt-2">
              <label className="f-header-4">Created Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.createdBefore &&
                    filters.createdBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.createdBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    createdBefore: date,
                  })
                }}
              />
            </div>
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={impactReportSearchFilterOptions.types.map(
                (status: String) => ({
                  value: status,
                  label: _.capitalize(status.replaceAll("_", " ")),
                }),
              )}
              values={filters.types.map((status: String) => ({
                value: status,
                label: _.capitalize(status.replaceAll("_", " ")),
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  types: options.map((o) => o.value),
                })
              }}
              label="Types"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default withBasics(ImpactReportSearchFilters)
