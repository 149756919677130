import { Field, FormikProvider, useFormik } from "formik"
import React, { useState } from "react"
import invariant from "tiny-invariant"
import {
  ActiveClientOrderMakerFragment,
  ModeOfTransit,
  NpaVariant,
  ShippingPortLocation,
  Sku,
  useActiveClientOrderQuery,
  useAddProductsToClientOrderFormQuery,
  useAddSkusToClientOrderMutation,
} from "../generated/graphql"
import { BorderBox, BorderBoxContainer } from "./BorderBox"
import Checkbox from "./Checkbox"
import ClientOrderConfirmModal from "./ClientOrderConfirmModal"
import { FormatCentsMaybe } from "./Currency"
import { RequestError } from "./Errors"
import { Button, Input, InvalidFeedback, Label } from "./FormElements"
import InputGroup from "./InputGroup"
import LoadingIndicator, { CenteredLoadingIndicator } from "./LoadingIndicator"
import ModeOfTransitSelect from "./ModeOfTransitSelect"
import ShippingPortSelect from "./ShippingPortSelect"
import { Toggle } from "./Toggle"
import { withBasics } from "./withBasics"
import {
  InputWithFormikErrors,
  SelectWithFormikErrors,
} from "./WithFormikErrors"

// based off app/javascript/components/CogsTableSettings.js

type AddProductsToClientOrderFormProps = {
  clientOrderProductId?: string
  productId: string
  productGroupId: string
  makerId: string
  defaultAirShippingPortId: string | null
  defaultSeaShippingPortId: string | null
  otherAirShippingPortId: string | null
  otherSeaShippingPortId: string | null
  defaultQuantity: number | null
}

const AddProductsToClientOrderFormWrapper = (
  props: AddProductsToClientOrderFormProps,
) => {
  const result = useActiveClientOrderQuery()

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { activeClientOrder } = result.data

  if (!activeClientOrder) return null

  return (
    <AddProductsToClientOrderForm
      {...props}
      clientOrder={activeClientOrder.clientOrder}
    />
  )
}

const AddProductsToClientOrderForm = ({
  clientOrder,
  clientOrderProductId,
  productId,
  productGroupId,
  makerId,
  defaultAirShippingPortId,
  defaultSeaShippingPortId,
  otherAirShippingPortId,
  otherSeaShippingPortId,
  defaultQuantity,
}: AddProductsToClientOrderFormProps & {
  clientOrder: ActiveClientOrderMakerFragment
}) => {
  const defaultExportId =
    defaultAirShippingPortId ??
    defaultSeaShippingPortId ??
    otherAirShippingPortId ??
    otherSeaShippingPortId

  // @ts-expect-error TODO(AM)
  const initialValues: ShippingDutyFormikFields & {
    quantity: string | null
    price: string
    margin: string
    msrp: string
    editableVariant: string
    npaVariantOptions: string[]
    makerOrders: any[]
  } = {
    quantity: defaultQuantity ? defaultQuantity.toString() : "",
    exportId: defaultExportId,
    modeOfTransit:
      defaultExportId === defaultAirShippingPortId ||
      defaultExportId === otherAirShippingPortId
        ? ModeOfTransit.Air
        : ModeOfTransit.Sea,
    importPortId: null,
    includeShippingCost: false,
    includeDutyRate: false,
    npaVariantOptions: [],
  }

  const [selectedSkuIds, setSelectedSkuIds] = useState<Sku["id"][]>([])
  const [addAllNpaVariantIds, setAddAllNpaVariantIds] = useState<
    NpaVariant["id"][]
  >([])

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [confirmModalMakerOrders, setConfirmModalMakerOrders] = useState<any[]>(
    [],
  )

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      type ValueKey = keyof typeof values
      const errors: Partial<Record<ValueKey, string>> = {}
      if (values.quantity == null || !(parseInt(values.quantity, 10) > 0)) {
        errors.quantity = "must be 1 or more"
      }

      if (values.price && values.margin) {
        errors.price = "cannot be set when margin is also set"
        errors.margin = "cannot be set when price is also set"
      }

      return errors
    },
    onSubmit: async (values, { setFieldValue }) => {
      invariant(values.quantity)

      if (!confirmModalOpen && confirmModalMakerOrders.length > 0) {
        setConfirmModalOpen(true)
        return
      }

      const npaVariantIdsWithSelection = [
        ...addAllNpaVariantIds,
        ...formik.values.npaVariantOptions.map(
          (id) =>
            product.npaVariants.find((npaVariant) =>
              npaVariant.npaVariantOptions.find((option) => option.id === id),
            )?.id,
        ),
      ]

      for (let npaVariant of product.npaVariants) {
        if (!npaVariantIdsWithSelection.includes(npaVariant.id)) {
          alert("All variants must have a selected option")
          return
        }
      }

      const result = await mutation.mutateAsync({
        input: {
          skus: selectedSkuIds.map((skuId) => ({
            skuId,
            // @ts-expect-error TODO(AM)
            quantity: parseInt(values.quantity, 10),
            price: parseFloat(values.price),
            margin: parseFloat(values.margin),
            msrp: parseFloat(values.msrp),
          })),
          includeDutyCosts: values.includeDutyRate,
          includeShippingCosts: values.includeShippingCost,
          clientOrderId: clientOrder.id,
          clientOrderProductId: clientOrderProductId,
          importShippingPortId: values.importPortId,
          exportShippingPortId: values.exportId,
          makerOrders: values.makerOrders,
        },
      })

      window.location.href = result.addSkusToClientOrder.clientOrder.showUrl
    },
  })

  const result = useAddProductsToClientOrderFormQuery(
    {
      id: productId,
      clientOrderId: clientOrder.id,
      unitCount: formik.values.quantity
        ? parseInt(formik.values.quantity, 10)
        : null,
      importShippingPortId: formik.values.importPortId,
      exportShippingPortId: formik.values.exportId,
    },
    {
      keepPreviousData: true,
    },
  )

  const mutation = useAddSkusToClientOrderMutation()

  if (result.error) return <RequestError {...result} />
  if (!result.data) return <CenteredLoadingIndicator />

  const product = result.data.product

  const makerOrders = clientOrder.makerOrders.nodes.filter(
    (makerOrder) => product.maker.id === makerOrder.maker.id,
  )

  // TODO(AM): refactor
  if (JSON.stringify(makerOrders) !== JSON.stringify(confirmModalMakerOrders))
    setConfirmModalMakerOrders(makerOrders)

  const selectedSkus = product.skus.filter(
    (sku) =>
      // The sku variant matches
      (product.variants.length === 0 ||
        sku.variant.id === formik.values.editableVariant) &&
      // The sku npa variant option matches
      formik.values.npaVariantOptions
        .filter((id) => id && id.length > 0) // Remove empty values
        .every((id) =>
          sku.npaVariantOptions.find((option) => option.id === id),
        ),
  )

  const newSelectedSkuIds = selectedSkus.map((sku) => sku.id)

  if (JSON.stringify(newSelectedSkuIds) !== JSON.stringify(selectedSkuIds))
    setSelectedSkuIds(newSelectedSkuIds)

  // TODO(AM): potentially hide other form elements if multiple skus are being added
  const selectedSku = selectedSkus.length > 0 ? selectedSkus[0] : null

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <ClientOrderConfirmModal
          makerOrders={confirmModalMakerOrders}
          isOpen={confirmModalOpen}
          addingProduct={true}
          onClose={() => setConfirmModalOpen(false)}
          onSubmit={formik.handleSubmit}
        />

        <div>
          <div className="row mb-4">
            <div className="col-lg-4 col-md-6">
              <Label htmlFor="quantity" className="f-header-4">
                Quantity
              </Label>
              <Field name="quantity" id="quantity" type="number" as={Input} />
              {formik.errors.quantity ? (
                <InvalidFeedback>{formik.errors.quantity}</InvalidFeedback>
              ) : null}
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6">
              <Label htmlFor="price" className="f-header-4">
                Price
              </Label>
              <InputGroup prepend="$">
                <Field
                  name="price"
                  id="price"
                  type="number"
                  as={InputWithFormikErrors}
                />
              </InputGroup>
            </div>
            <div className="col-lg-1 col-md-1">
              <div className="mt-4 text-center">OR</div>
            </div>
            <div className="col-lg-4 col-md-5">
              <Label htmlFor="margin" className="f-header-4">
                Margin
              </Label>
              <InputGroup prepend="%">
                <Field name="margin" type="number" as={InputWithFormikErrors} />
              </InputGroup>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6">
              <Label htmlFor="msrp" className="f-header-4">
                MSRP
              </Label>
              <InputGroup prepend="$">
                <Field
                  name="msrp"
                  id="msrp"
                  type="number"
                  as={InputWithFormikErrors}
                />
              </InputGroup>
            </div>
          </div>

          {product.variants.length > 0 && (
            <div className="row mb-4">
              <div className="col-lg-4 col-md-6">
                <Label htmlFor="editableVariant" className="f-header-4">
                  {product.variantName}
                </Label>

                <Field
                  id="editableVariant"
                  name="editableVariant"
                  as={SelectWithFormikErrors}
                >
                  <option></option>
                  {product.variants.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          )}

          {product.npaVariants.map((npaVariant, i) => (
            <div key={i} className="row mb-4">
              <div className="col-lg-4 col-md-6">
                <Label htmlFor={`npaVariantOptions${i}`} className="f-header-4">
                  {npaVariant.name}
                </Label>

                <Field
                  id={`npaVariantOptions${i}`}
                  name={`npaVariantOptions.${i}`}
                  as={SelectWithFormikErrors}
                  disabled={addAllNpaVariantIds.includes(npaVariant.id)}
                >
                  <option></option>
                  {npaVariant.npaVariantOptions
                    .sort((a, b) => (a.position || 0) - (b.position || 0))
                    .map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                </Field>

                <Checkbox
                  id={`addAllNpaVariant${i}`}
                  checked={addAllNpaVariantIds.includes(npaVariant.id)}
                  onChange={() => {
                    formik.setFieldValue(`npaVariantOptions.${i}`, "")

                    if (addAllNpaVariantIds.includes(npaVariant.id)) {
                      setAddAllNpaVariantIds(
                        addAllNpaVariantIds.filter(
                          (id) => id !== npaVariant.id,
                        ),
                      )
                    } else {
                      setAddAllNpaVariantIds([
                        ...addAllNpaVariantIds,
                        npaVariant.id,
                      ])
                    }
                  }}
                  label="Add All to Project"
                />
              </div>
            </div>
          ))}

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6">
              <Label htmlFor="exportShippingPort" className="f-header-4">
                Mode of Transit
              </Label>
              <ModeOfTransitSelect
                value={formik.values.modeOfTransit}
                onChange={(newValue) =>
                  formik.setFieldValue("modeOfTransit", newValue?.value)
                }
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <Label htmlFor="exportShippingPort" className="f-header-4">
                Export Shipping Port
              </Label>
              <ShippingPortSelect
                makerId={makerId}
                locationType={ShippingPortLocation.Export}
                modeOfTransit={formik.values.modeOfTransit}
                value={formik.values.exportId}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "exportId",
                    newValue ? newValue.value : null,
                  )
                }}
              />
            </div>
            <div className="col">
              <Label htmlFor="importShippingPort" className="f-header-4">
                Import Shipping Port
              </Label>
              <ShippingPortSelect
                locationType={ShippingPortLocation.Import}
                modeOfTransit={formik.values.modeOfTransit}
                value={formik.values.importPortId}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "importPortId",
                    newValue ? newValue.value : null,
                  )
                }}
              />
            </div>
          </div>

          <BorderBoxContainer>
            <BorderBox>
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Label</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormatCentsMaybe
                        cents={selectedSku?.variant.costCents}
                      />
                      {selectedSku?.variant.costCents != null ? (
                        <span className="small"> / unit</span>
                      ) : null}
                    </td>
                    <td>COGS</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      <FormatCentsMaybe
                        cents={selectedSku?.variant.shippingCostCents}
                      />
                      {selectedSku?.variant.shippingCostCents != null ? (
                        <span className="small"> / unit</span>
                      ) : null}
                    </td>
                    <td className="align-middle">Estimated shipping cost</td>
                    <td>
                      <div className="mb-2 mt-2 d-flex align-items-center">
                        <Toggle
                          id="shipping-rate-toggle"
                          className="mr-2"
                          checked={formik.values.includeShippingCost}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "includeShippingCost",
                              e.currentTarget.checked,
                            )
                          }
                          disabled={
                            formik.values.importPortId == null ||
                            formik.values.exportId == null
                          }
                        />
                        <Label
                          htmlFor="shipping-rate-toggle"
                          className="mb-0 mr-2"
                        >
                          Include estimated shipping cost
                        </Label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      <FormatCentsMaybe
                        cents={selectedSku?.variant.dutyCostCents}
                      />
                      {selectedSku?.variant.dutyCostCents != null ? (
                        <span className="small"> / unit</span>
                      ) : null}
                    </td>
                    <td className="align-middle">Duty Rate</td>
                    <td>
                      <div className="mb-2 mt-2 d-flex align-items-center">
                        <Toggle
                          id="duty-rate-toggle"
                          className="mr-2"
                          checked={formik.values.includeDutyRate}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "includeDutyRate",
                              e.currentTarget.checked,
                            )
                          }
                          disabled={
                            formik.values.importPortId == null ||
                            formik.values.exportId == null
                          }
                        />
                        <Label htmlFor="duty-rate-toggle" className="mb-0">
                          Include Duties
                        </Label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </BorderBox>
          </BorderBoxContainer>

          <div className="d-flex justify-content-end align-items-baseline mt-4">
            <a href={clientOrder.showUrl} className="text-danger mr-4">
              Cancel
            </a>

            <Button
              variant="dark"
              type="submit"
              disabled={mutation.isLoading || mutation.isSuccess}
            >
              {mutation.isLoading || mutation.isSuccess ? "Saving..." : "Save"}
            </Button>
            <RequestError {...mutation} />

            {result.isFetching && <CenteredLoadingIndicator />}
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

type ShippingDutyFormikFields = {
  modeOfTransit: ModeOfTransit
  exportId: string | null
  importPortId: string | null
  includeShippingCost: boolean
  includeDutyRate: boolean
}

export default withBasics(AddProductsToClientOrderFormWrapper)
