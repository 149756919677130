import React from "react"
import { Address } from "../generated/graphql"

export const ClientDetailsAddress = ({
  address,
}: {
  address: Pick<Address, "label" | "plain" | "contactName" | "contactPhone">
}) => (
  <div>
    {address.label && <p className="font-weight-bold mb-0">{address.label}</p>}
    {address.plain.split("\n").map((line, i) => (
      <p key={i} className="mb-0">
        {line}
      </p>
    ))}
    {address.contactName && (
      <p className="mb-0">Contact {address.contactName}</p>
    )}
    {address.contactPhone && <p className="mb-0">{address.contactPhone}</p>}
  </div>
)
