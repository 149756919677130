import { ReactComponent as CaretDownFillIcon } from "bootstrap-icons/icons/caret-down-fill.svg"
import { ReactComponent as CaretUpFillIcon } from "bootstrap-icons/icons/caret-up-fill.svg"
import { ReactComponent as CaretUpIcon } from "bootstrap-icons/icons/caret-up.svg"
import { ReactComponent as EyeIcon } from "bootstrap-icons/icons/eye.svg"
import { format, parseISO } from "date-fns"
import capitalize from "lodash/capitalize"
import find from "lodash/find"
import React, { useState } from "react"
import Imgix from "react-imgix"
import { UseInfiniteQueryResult } from "react-query"
import styled from "styled-components"
import { MakerSearchQuery, MakerSort, SortOrder } from "../generated/graphql"
import { compact } from "../util/filters"
import { BorderBox, BorderBoxItem } from "./BorderBox"
import { RequestError } from "./Errors"
import { MOST_RECENT_ORDER_OPTION, NAME_SORT_OPTION } from "./MakerSearchScreen"
import MakerSearchColumnSettingsModal from "./SearchColumnSettingsModal"
import { withBasics } from "./withBasics"
import chunk from "lodash/chunk"
import { BorderBoxItemCell, CellContainer } from "./SearchElements"

// Result Columns
export interface Column {
  name: string
  key: string
  size?: "small" | "medium" | "large"
}
const LOGO_COLUMN: Column = { key: "logo", name: "Logo", size: "small" }
const NAME_COLUMN: Column = { key: "name", name: "Name" }
const COUNTRIES_COLUMN: Column = { key: "countries", name: "Countries" }
const PRODUCTION_CAPACITY_COLUMN: Column = {
  key: "productionCapacity",
  name: "Product Capacity",
  size: "large",
}
const CERTIFICATIONS_COLUMN: Column = {
  key: "certifications",
  name: "Active Certifications",
}
const MAKER_ATTRIBUTES_COLUMN: Column = {
  key: "makerAttributes",
  name: "Attributes",
  size: "large",
}
const PRODUCT_CATEGORIES_COLUMN: Column = {
  key: "productCategories",
  name: "Product Categories",
  size: "large",
}
const PRODUCT_SUBCATEGORIES_COLUMN: Column = {
  key: "productSubcategories",
  name: "Product Subcategories",
  size: "large",
}
const STATUS_COLUMN: Column = { key: "status", name: "Status" }
const COST_RANGE_ESTIMATE_COLUMN: Column = {
  key: "costRangeEstimate",
  name: "Cost Range",
}
const MATERIALS_COLUMN: Column = { key: "materials", name: "Materials" }
const ENVIRONMENTS_COLUMN: Column = {
  key: "environments",
  name: "Environments",
}
const CAPABILITIES_COLUMN: Column = {
  key: "capabilities",
  name: "Capabilities",
}
const MOST_RECENT_ORDER_DATE_COLUMN: Column = {
  key: "mostRecentOrderDate",
  name: "Most Recent Order Date",
}
const ACTIONS_COLUMN: Column = { key: "actions", name: "Actions" }
const DEFAULT_COLUMNS: Column[] = [
  LOGO_COLUMN,
  NAME_COLUMN,
  MAKER_ATTRIBUTES_COLUMN,
  CERTIFICATIONS_COLUMN,
  COUNTRIES_COLUMN,
  PRODUCTION_CAPACITY_COLUMN,
  PRODUCT_CATEGORIES_COLUMN,
  STATUS_COLUMN,
  ACTIONS_COLUMN,
]
const ALL_COLUMNS: Column[] = DEFAULT_COLUMNS.concat(
  COST_RANGE_ESTIMATE_COLUMN,
  PRODUCT_SUBCATEGORIES_COLUMN,
  MATERIALS_COLUMN,
  ENVIRONMENTS_COLUMN,
  CAPABILITIES_COLUMN,
  MOST_RECENT_ORDER_DATE_COLUMN,
)
interface MakerSearchResultsTableProps {
  result: UseInfiniteQueryResult<MakerSearchQuery, Error>
  sorts: MakerSort[]
  onColumnSortClick: (sort: MakerSort) => void
}
const MakerSearchResultsTable = (props: MakerSearchResultsTableProps) => {
  const { result, sorts, onColumnSortClick } = props
  const [visibleColumns, setVisibleColumns] = useState(DEFAULT_COLUMNS)
  const [columnSettingsOpen, setColumnSettingsOpen] = useState(false)

  const maybeRenderTableHeader = (column: Column) => {
    return find(visibleColumns, { key: column.key }) ? (
      <StyledTh size={column.size}>{column.name}</StyledTh>
    ) : null
  }

  const maybeRenderSortableTableHeader = (column: Column, sort: MakerSort) => {
    const colSorts: MakerSort[] = compact([
      find(sorts, { attribute: sort.attribute }),
    ])

    return find(visibleColumns, {
      key: column.key,
    }) ? (
      <StyledTh onClick={() => onColumnSortClick(sort)}>
        {column.name}

        {colSorts.length > 0 ? (
          colSorts.map((sort: MakerSort) => (
            <span className="ml-1" key={`${sort.attribute} ${sort.order}`}>
              {sort.order === SortOrder.Asc && <CaretUpFillIcon />}
              {sort.order === SortOrder.Desc && <CaretDownFillIcon />}
            </span>
          ))
        ) : (
          <span className="ml-1">
            <CaretUpIcon />
          </span>
        )}
      </StyledTh>
    ) : null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result

  return (
    <div className="d-flex flex-column">
      <div>
        <button
          className="float-right btn btn-link f-action-text pb-2"
          onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
        >
          Manage
        </button>
        <MakerSearchColumnSettingsModal
          visibleColumns={visibleColumns}
          defaultColumns={DEFAULT_COLUMNS}
          allColumns={ALL_COLUMNS}
          isOpen={columnSettingsOpen}
          onClose={() => setColumnSettingsOpen(false)}
          onApply={(selectedColumns: Column[]) => {
            setVisibleColumns(selectedColumns)
            setColumnSettingsOpen(false)
          }}
        />
      </div>

      <BorderBox>
        <div className="table-responsive">
          <table className="table table-hover table-fixed-truncated nice-table">
            <thead>
              <tr>
                {maybeRenderTableHeader(LOGO_COLUMN)}
                {maybeRenderSortableTableHeader(NAME_COLUMN, NAME_SORT_OPTION)}
                {maybeRenderTableHeader(MAKER_ATTRIBUTES_COLUMN)}
                {maybeRenderTableHeader(CERTIFICATIONS_COLUMN)}
                {maybeRenderTableHeader(COUNTRIES_COLUMN)}
                {maybeRenderTableHeader(PRODUCTION_CAPACITY_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_CATEGORIES_COLUMN)}
                {maybeRenderTableHeader(PRODUCT_SUBCATEGORIES_COLUMN)}
                {maybeRenderTableHeader(STATUS_COLUMN)}
                {maybeRenderTableHeader(COST_RANGE_ESTIMATE_COLUMN)}
                {maybeRenderTableHeader(MATERIALS_COLUMN)}
                {maybeRenderTableHeader(ENVIRONMENTS_COLUMN)}
                {maybeRenderTableHeader(CAPABILITIES_COLUMN)}
                {maybeRenderSortableTableHeader(
                  MOST_RECENT_ORDER_DATE_COLUMN,
                  MOST_RECENT_ORDER_OPTION,
                )}
                {maybeRenderTableHeader(ACTIONS_COLUMN)}
              </tr>
            </thead>

            {data ? (
              <tbody>
                {data.pages
                  .flatMap((page) => compact(page.makers.nodes))
                  .map((maker) => {
                    const makerAttributes = maker.makerAttributes.nodes.filter(
                      (makerAttribute) => {
                        const makerMakerAttribute = maker.makerMakerAttributes.nodes.find(
                          (ma) => ma.makerAttributeId === makerAttribute.id,
                        )

                        return makerMakerAttribute?.response
                      },
                    )
                    return (
                      <tr key={maker.id}>
                        {visibleColumns.includes(LOGO_COLUMN) && (
                          <td>
                            <Imgix
                              src={maker.logoUrlWithFallback}
                              className="img-thumbnail"
                              sizes="48px"
                              htmlAttributes={{
                                alt: `${maker.name} logo`,
                                style: { width: `48px` },
                              }}
                              imgixParams={{
                                "ar": `1:1`,
                                "fit": `fill`,
                                "fill-color": `white`,
                              }}
                            />
                          </td>
                        )}
                        {visibleColumns.includes(NAME_COLUMN) && (
                          <td>
                            <a href={maker.showUrl} data-testid="maker-link">
                              {maker.name}
                            </a>
                          </td>
                        )}
                        {visibleColumns.includes(MAKER_ATTRIBUTES_COLUMN) && (
                          <td>
                            <BorderBoxItemCell>
                              {makerAttributes.map((attribute) => (
                                <BorderBoxItem key={attribute.id}>
                                  {attribute.text}
                                </BorderBoxItem>
                              ))}
                            </BorderBoxItemCell>
                          </td>
                        )}
                        {visibleColumns.includes(CERTIFICATIONS_COLUMN) && (
                          <td>
                            <BorderBoxItemCell>
                              {compact(maker.makerCertificationTypes).map(
                                (certificationType) => (
                                  <BorderBoxItem key={certificationType}>
                                    {certificationType}
                                  </BorderBoxItem>
                                ),
                              )}
                            </BorderBoxItemCell>
                          </td>
                        )}
                        {visibleColumns.includes(COUNTRIES_COLUMN) && (
                          <td>
                            <CellContainer>
                              {maker.countries.nodes.length <= 5 ? (
                                <>
                                  {compact(maker.countries.nodes).map(
                                    (country) => (
                                      <div key={country.id}>{country.name}</div>
                                    ),
                                  )}
                                </>
                              ) : (
                                <div className="row">
                                  {chunk(
                                    maker.countries.nodes,
                                    Math.ceil(maker.countries.nodes.length / 2),
                                  ).map((list) => (
                                    <div className="col-6">
                                      {list.map((country) => (
                                        <div key={country.id}>
                                          {country.name}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </CellContainer>
                          </td>
                        )}
                        {visibleColumns.includes(
                          PRODUCTION_CAPACITY_COLUMN,
                        ) && (
                          <td className="text-break">
                            {maker.productionCapacity}
                          </td>
                        )}
                        {visibleColumns.includes(PRODUCT_CATEGORIES_COLUMN) && (
                          <td>
                            <div className="d-flex flex-wrap">
                              {compact(maker.makerProductCategories.nodes).map(
                                (makerProductCategory) => (
                                  <BorderBoxItem key={makerProductCategory.id}>
                                    {makerProductCategory.productCategory.name}
                                  </BorderBoxItem>
                                ),
                              )}
                            </div>
                          </td>
                        )}
                        {visibleColumns.includes(
                          PRODUCT_SUBCATEGORIES_COLUMN,
                        ) && (
                          <td>
                            <div className="d-flex flex-wrap">
                              {compact(maker.subcategories).map(
                                (subcategory) => (
                                  <BorderBoxItem key={subcategory}>
                                    {subcategory}
                                  </BorderBoxItem>
                                ),
                              )}
                            </div>
                          </td>
                        )}
                        {visibleColumns.includes(STATUS_COLUMN) && (
                          <td className="text-break">
                            {capitalize(maker.status)}
                          </td>
                        )}
                        {visibleColumns.includes(
                          COST_RANGE_ESTIMATE_COLUMN,
                        ) && (
                          <td className="text-break">
                            <div className="d-flex flex-wrap">
                              {maker.costRangeEstimate ? (
                                <BorderBoxItem>
                                  {maker.costRangeEstimate}
                                </BorderBoxItem>
                              ) : (
                                "None"
                              )}
                            </div>
                          </td>
                        )}
                        {visibleColumns.includes(MATERIALS_COLUMN) && (
                          <td>
                            <div className="d-flex flex-wrap">
                              {compact(maker.materials.nodes).map(
                                (material) => (
                                  <BorderBoxItem key={material.id}>
                                    {material.name}
                                  </BorderBoxItem>
                                ),
                              )}
                            </div>
                          </td>
                        )}
                        {visibleColumns.includes(ENVIRONMENTS_COLUMN) && (
                          <td>
                            <div className="d-flex flex-wrap">
                              {compact(maker.environments).map(
                                (environment) => (
                                  <BorderBoxItem key={environment}>
                                    {environment}
                                  </BorderBoxItem>
                                ),
                              )}
                            </div>
                          </td>
                        )}
                        {visibleColumns.includes(CAPABILITIES_COLUMN) && (
                          <td>
                            <div className="d-flex flex-wrap">
                              {compact(maker.capabilities).map((capability) => (
                                <BorderBoxItem key={capability}>
                                  {capability}
                                </BorderBoxItem>
                              ))}
                            </div>
                          </td>
                        )}
                        {visibleColumns.includes(
                          MOST_RECENT_ORDER_DATE_COLUMN,
                        ) && (
                          <td className="text-break">
                            {maker.mostRecentOrderDate
                              ? format(
                                  parseISO(maker.mostRecentOrderDate),
                                  "LLL d, y",
                                )
                              : "None"}
                          </td>
                        )}
                        {visibleColumns.includes(ACTIONS_COLUMN) && (
                          <td>
                            <a
                              className="btn btn-link"
                              data-content="View Product"
                              href={maker.showUrl}
                            >
                              <EyeIcon />
                            </a>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            ) : null}
          </table>
        </div>
      </BorderBox>
    </div>
  )
}

export default withBasics(MakerSearchResultsTable)

const sizeMap: Record<Required<Column>["size"], string> = {
  small: "80px",
  medium: "180px",
  large: "240px",
}

const StyledTh = styled.th<{ size?: Column["size"] }>((props) => ({
  width: sizeMap[props.size ?? "medium"],
}))
