import React, { useState } from "react"
import { withBoundary } from "./ErrorBoundary"
import "react-toggle/style.css"
import Modal from "./Modal"
import * as S from "./MakerActivateButton.styled"
import { withBasics } from "./withBasics"
import chunk from "lodash/chunk"
import without from "lodash/without"
import union from "lodash/union"
import { Column } from "./MakerSearchResultsTable"

interface SearchColumnSettingsModalProps {
  isOpen: boolean
  defaultColumns: Column[]
  visibleColumns: Column[]
  allColumns: Column[]
  onApply: (selectedColumns: Column[]) => void
  onClose: () => void
}

const SearchColumnSettingsModal = ({
  onApply,
  visibleColumns,
  defaultColumns,
  allColumns,
  isOpen,
  onClose,
}: SearchColumnSettingsModalProps) => {
  const [selectedColumns, setSelectedColumns] = useState(visibleColumns)

  const onReset = () => {
    setSelectedColumns(defaultColumns)
    onApply(defaultColumns)
    onClose()
  }

  const renderCheckBox = (column: Column, i: number) => {
    return (
      <div key={i} className="d-flex">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id={`${column.key}-column-checkbox`}
            checked={selectedColumns.includes(column)}
            onChange={(ev) => {
              if (ev.target.checked) {
                setSelectedColumns(union(selectedColumns, [column]))
              } else {
                setSelectedColumns(without(selectedColumns, column))
              }
            }}
          />
          <label
            className="form-check-label text-24"
            htmlFor={`${column.key}-column-checkbox`}
          >
            {column.name}
          </label>
        </div>
      </div>
    )
  }

  return (
    <S.Container className="mb-2 mt-2">
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ minWidth: "500px" }}>
            <div className="modal-header">
              <h5 className="modal-title">Manage Chart</h5>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-link text-uppercase"
                  onClick={() => {
                    setSelectedColumns(allColumns)
                  }}
                >
                  SELECT ALL
                </button>
                <button
                  type="button"
                  className="btn btn-link text-uppercase"
                  onClick={() => {
                    setSelectedColumns([])
                  }}
                >
                  CLEAR ALL
                </button>
              </div>
            </div>
            <div className="modal-body px-6">
              <div className="row">
                {chunk(allColumns, Math.floor(allColumns.length / 2)).map(
                  (entries, i) => (
                    <div key={i} className="col-md-6">
                      {entries.map((col, i) => renderCheckBox(col, i))}
                    </div>
                  ),
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link text-danger"
                onClick={onReset}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => onApply(selectedColumns)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </S.Container>
  )
}

export default withBoundary(withBasics(SearchColumnSettingsModal))
