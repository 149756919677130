import React from "react"
import { ReactComponent as CarSVG } from "../images/car.svg"
import { ReactComponent as AirplaneSVG } from "../images/airplane.svg"
import { ReactComponent as SmartphoneSVG } from "../images/smartphone.svg"
import { withBasics } from "./withBasics"
import { EmptyValue } from "./EmptyValue"
import numberWithCommas from "../util/number-with-commas"

const CarbonOffsets = (props: {
  carbonOffsetAsKmOfDriving?: number | null
  carbonOffsetAsTransatlanticFlights?: number | null
  carbonOffsetAsPhonesCharged?: number | null
  largeText?: boolean
  round: boolean
}) => (
  <div className="d-flex justify-content-around w-100 mb-4">
    {props.largeText ? (
      <>
        <div className="px-4 text-center font-weight-bold">
          <CarSVG />
          <p className="pt-3">
            <span className="d-block" style={{ fontSize: "25px" }}>
              {props.carbonOffsetAsKmOfDriving ? (
                numberWithCommas(
                  props.round
                    ? Math.round(props.carbonOffsetAsKmOfDriving)
                    : props.carbonOffsetAsKmOfDriving,
                )
              ) : (
                <EmptyValue variant="dash" />
              )}
            </span>
            Km of Car Driving
          </p>
        </div>
        <div className="px-4 text-center font-weight-bold">
          <AirplaneSVG />
          <p className="pt-3">
            <span className="d-block" style={{ fontSize: "25px" }}>
              {props.carbonOffsetAsTransatlanticFlights ? (
                numberWithCommas(
                  props.round
                    ? Math.round(props.carbonOffsetAsTransatlanticFlights)
                    : props.carbonOffsetAsTransatlanticFlights,
                )
              ) : (
                <EmptyValue variant="dash" />
              )}
            </span>
            Transatlantic Flights
          </p>
        </div>
        <div className="px-4 text-center font-weight-bold">
          <SmartphoneSVG />
          <p className="pt-3">
            <span className="d-block" style={{ fontSize: "25px" }}>
              {props.carbonOffsetAsPhonesCharged ? (
                numberWithCommas(
                  props.round
                    ? Math.round(props.carbonOffsetAsPhonesCharged)
                    : props.carbonOffsetAsPhonesCharged,
                )
              ) : (
                <EmptyValue variant="dash" />
              )}
            </span>
            Smart Phones Charged
          </p>
        </div>
      </>
    ) : (
      <>
        <div className="p-4 text-center font-weight-bold">
          <CarSVG />
          <p className="pt-2">
            {props.carbonOffsetAsKmOfDriving ? (
              numberWithCommas(
                props.round
                  ? Math.round(props.carbonOffsetAsKmOfDriving)
                  : props.carbonOffsetAsKmOfDriving,
              )
            ) : (
              <EmptyValue variant="dash" />
            )}{" "}
            Km of Car Driving
          </p>
        </div>
        <div className="p-4 text-center font-weight-bold">
          <AirplaneSVG />
          <p className="pt-2">
            {props.carbonOffsetAsTransatlanticFlights ? (
              numberWithCommas(
                props.round
                  ? Math.round(props.carbonOffsetAsTransatlanticFlights)
                  : props.carbonOffsetAsTransatlanticFlights,
              )
            ) : (
              <EmptyValue variant="dash" />
            )}{" "}
            Transatlantic Flights
          </p>
        </div>
        <div className="p-4 text-center font-weight-bold">
          <SmartphoneSVG />
          <p className="pt-2">
            {props.carbonOffsetAsPhonesCharged ? (
              numberWithCommas(
                props.round
                  ? Math.round(props.carbonOffsetAsPhonesCharged)
                  : props.carbonOffsetAsPhonesCharged,
              )
            ) : (
              <EmptyValue variant="dash" />
            )}{" "}
            Smart Phones Charged
          </p>
        </div>
      </>
    )}
  </div>
)

CarbonOffsets.defaultProps = {
  round: true,
}

export default withBasics(CarbonOffsets)
