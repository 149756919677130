import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import React, { useState } from "react"
import invariant from "tiny-invariant"
import {
  ClientOrderEditMakerFragment,
  UpdateMakerInput,
  UpdateClientOrderMutation,
  useClientOrderEditQuery,
  useUpdateClientOrderMutation,
} from "../generated/graphql"
import { changedValues } from "../util/changed-values"
import { RequestError } from "./Errors"
import { Button, Label } from "./FormElements"
import LoadingIndicator from "./LoadingIndicator"
import ClientOrderEditConfirmModal from "./ClientOrderEditConfirmModal"
import { withBasics } from "./withBasics"
import {
  CheckboxWithFormikErrors,
  DateRangeInputWithFormikErrors,
  InputWithFormikErrors,
  SelectWithFormikErrors,
  TextAreaWithFormikErrors,
} from "./WithFormikErrors"
import LeadSelect from "./LeadSelect"
import { BorderBox, BorderBoxContainer, BorderBoxHeading } from "./BorderBox"
import { ImageWithControls } from "./LegacyImage"
import { ReactComponent as Plus40SVG } from "../images/plus-40.svg"
import VisuallyHidden from "@reach/visually-hidden"
import { DirectUpload } from "@rails/activestorage"
import { directUploadUrl } from "../util/active-storage"

type ClientOrderProps = {
  clientOrder: ClientOrderEditMakerFragment
}

const ClientOrderEdit = (props: { id: string }) => {
  const result = useClientOrderEditQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const clientOrder = result.data.clientOrder

  return <ClientOrderEditForm clientOrder={clientOrder} />
}

const ClientOrderEditForm = ({ clientOrder }: ClientOrderProps) => {
  const mutation = useUpdateClientOrderMutation()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const confirmModalMakerOrders: any = clientOrder.makerOrders.nodes.filter(
    (makerOrder) =>
      makerOrder.status !== "canceled" && makerOrder.status !== "completed",
  )

  const initialValues = {
    ...clientOrder,
    makerOrders: undefined,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: any, { setFieldValue, setFieldError }) => {
      const params: any = changedValues(clientOrder, values)

      if (params.billingAddressId) {
        delete params.billingAddress
      }

      if (params.shippingAddressId) {
        delete params.shippingAddress
      }

      // TODO(AM): Show the confirm modal only if fields from the Order or
      // Production Details have been changed
      if (
        confirmModalMakerOrders.length > 0 &&
        Object.keys(params).length > 0 &&
        !confirmModalOpen
      ) {
        setConfirmModalOpen(true)
        return
      }

      const input: UpdateMakerInput = {
        id: clientOrder.id,
        ...params,
      }

      try {
        const {
          updateClientOrder: result,
        }: UpdateClientOrderMutation = await mutation.mutateAsync({
          input,
        })

        if (result && result.errors.length) {
          for (let error of result.errors)
            if (error.path) setFieldError(error.path[1], error.message)
        } else {
          window.location.href =
            clientOrder.showUrl + (window.location.hash || "")
        }
      } catch (er) {
        window.alert(`An error occured: ${(er as Error).message}`)
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <ClientOrderEditConfirmModal
          makerOrders={confirmModalMakerOrders}
          isOpen={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onSubmit={formik.handleSubmit}
        />

        <div className="card">
          <div className="card-header">Client Details</div>
          <div className="card-body">
            <div className="form-group">
              <Label className="font-weight-bold">Client</Label>
              <Field
                name="client.name"
                disabled={true}
                as={InputWithFormikErrors}
              />
            </div>

            <div className="form-group">
              <Label className="font-weight-bold">Description</Label>
              <Field name="description" as={InputWithFormikErrors} />
            </div>

            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6">
                <LeadSelect
                  clientOrder={clientOrder}
                  defaultValue={clientOrder.salesLeads}
                  label="Sales Lead"
                  attribute="salesLeadIds"
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <LeadSelect
                  clientOrder={clientOrder}
                  defaultValue={clientOrder.designLeads}
                  label="Design Lead"
                  attribute="designLeadIds"
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <LeadSelect
                  clientOrder={clientOrder}
                  defaultValue={clientOrder.productionLeads}
                  label="Production Lead"
                  attribute="productionLeadIds"
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <LeadSelect
                  clientOrder={clientOrder}
                  defaultValue={clientOrder.impactLeads}
                  label="Impact Lead"
                  attribute="impactLeadIds"
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <LeadSelect
                  clientOrder={clientOrder}
                  defaultValue={clientOrder.operationsLeads}
                  label="Operations Lead"
                  attribute="operationsLeadIds"
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <LeadSelect
                  clientOrder={clientOrder}
                  defaultValue={clientOrder.financialLeads}
                  label="Financial Lead"
                  attribute="financialLeadIds"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="font-weight-bold">Contacts</label>
              <FieldArray
                name="contacts"
                render={(arrayHelpers) => (
                  <>
                    <div className="card mb-3">
                      <ul className="list-group list-group-flush">
                        {formik.values.contacts.map((contact: any, i: any) => {
                          if (contact.hasOwnProperty("remove")) return null

                          return (
                            <li className="list-group-item" key={i}>
                              <div className="row">
                                <div className="col">
                                  <Label>Name</Label>
                                  <Field
                                    name={`contacts.${i}.fullName`}
                                    as={InputWithFormikErrors}
                                  />
                                </div>
                                <div className="col">
                                  <Label>Email</Label>
                                  <Field
                                    name={`contacts.${i}.email`}
                                    as={InputWithFormikErrors}
                                  />
                                </div>
                                <div className="col">
                                  <Label>Phone</Label>
                                  <Field
                                    name={`contacts.${i}.phone`}
                                    as={InputWithFormikErrors}
                                  />
                                </div>
                                <div className="col">
                                  <Label>Job title</Label>
                                  <Field
                                    name={`contacts.${i}.jobTitle`}
                                    as={InputWithFormikErrors}
                                  />
                                </div>
                                {!contact.id && (
                                  <div className="col-2">
                                    <Field
                                      name={`contacts.${i}.copyToClient`}
                                      as={CheckboxWithFormikErrors}
                                      id={`copy_to_client_${i}`}
                                    />
                                    <label htmlFor={`copy_to_client_${i}`}>
                                      Add Contact to Client Profile
                                    </label>
                                  </div>
                                )}

                                <div className="col-1">
                                  <button
                                    type="button"
                                    className="btn btn-danger float-right"
                                    onClick={() => {
                                      if (contact.id) {
                                        formik.setFieldValue(
                                          `contacts.${i}.remove`,
                                          true,
                                        )
                                      } else {
                                        arrayHelpers.remove(i)
                                      }
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    <Button
                      onClick={() => arrayHelpers.push({})}
                      variant="secondary"
                      className="btn-sm"
                    >
                      Add New Client Contact
                    </Button>
                  </>
                )}
              />
            </div>

            <div className="form-group">
              <Label className="font-weight-bold">
                Delivery Window (Select two dates)
              </Label>
              <Field
                name="deliveryWindow"
                as={DateRangeInputWithFormikErrors}
              />
            </div>

            <div className="form-group">
              <Label className="font-weight-bold">Client legal agreement</Label>
              <Field name="clientLegalAgreementId" as={SelectWithFormikErrors}>
                <option></option>
                {clientOrder.clientLegalAgreementOptions.map((cla, i) => (
                  <option key={i} value={cla.id}>
                    {cla.name}
                  </option>
                ))}
              </Field>
              <small className="form-text text-muted">
                Default <a href="/client_legal_agreements">legal agreement</a>{" "}
                used in client approval orders
              </small>
            </div>

            <div className="d-grid grid-cols-2 gap-3">
              <div className="form-group">
                <Label className="font-weight-bold">Internal notes</Label>
                <Field name="internalNotes" as={TextAreaWithFormikErrors} />
              </div>
              <div className="form-group">
                <Label className="font-weight-bold">Client Payment terms</Label>
                <Field name="paymentTerms" as={TextAreaWithFormikErrors} />
              </div>
            </div>

            <div className="d-grid grid-cols-2 gap-3">
              <div className="form-group">
                <Label className="font-weight-bold">
                  External PO Reference
                </Label>
                <Field
                  name="externalPoReference"
                  as={TextAreaWithFormikErrors}
                />
              </div>

              <div className="form-group">
                <Label className="font-weight-bold">External PO Files</Label>
                <div>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    multiple // Add this 'multiple' attribute to allow selecting multiple files
                    onChange={(event) => {
                      if (!event.currentTarget.files) return

                      const files = event.currentTarget.files
                      const blobArray: string[] = []

                      const uploadPromises: Promise<void>[] = Array.from(
                        files,
                      ).map((file) => {
                        return new Promise<void>((resolve, reject) => {
                          const upload = new DirectUpload(file, directUploadUrl)
                          upload.create((error, blob) => {
                            if (error) {
                              alert(`Error uploading file: ${error}`)
                              reject(error)
                              return
                            }

                            blobArray.push(blob.signed_id)
                            resolve()
                          })
                        })
                      })

                      Promise.all(uploadPromises)
                        .then(() => {
                          formik.setFieldValue("clientSoPo", blobArray)
                        })
                        .catch((error: any) => {
                          console.error("Error during file upload:", error)
                        })
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="d-grid grid-cols-2 gap-3">
                  <div>
                    <Label className="font-weight-bold">Billing address</Label>
                    <Field name="billingAddressId" as={SelectWithFormikErrors}>
                      <option value="">New billing address</option>
                      {clientOrder.addressOptions.map((address, i) => (
                        <option key={i} value={address.id}>
                          {address.selectLabel}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div>
                    {(!formik.values.billingAddressId ||
                      formik.values.billingAddressId === "") && (
                      <>
                        <Label className="font-weight-bold">
                          Billing address label
                        </Label>
                        <Field
                          name="billingAddress.label"
                          as={InputWithFormikErrors}
                        />

                        <Label className="font-weight-bold">
                          Billing address
                        </Label>
                        <Field
                          name="billingAddress.plain"
                          as={TextAreaWithFormikErrors}
                        />

                        <Label className="font-weight-bold">
                          Billing address contact name
                        </Label>
                        <Field
                          name="billingAddress.contactName"
                          as={InputWithFormikErrors}
                        />

                        <Label className="font-weight-bold">
                          Billing address contact phone
                        </Label>
                        <Field
                          name="billingAddress.contactPhone"
                          as={InputWithFormikErrors}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="d-grid grid-cols-2 gap-3">
                  <div>
                    <Label className="font-weight-bold">Shipping address</Label>
                    <Field name="shippingAddressId" as={SelectWithFormikErrors}>
                      <option value="">New shipping address</option>
                      {clientOrder.addressOptions.map((address, i) => (
                        <option key={i} value={address.id}>
                          {address.selectLabel}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div>
                    {(!formik.values.shippingAddressId ||
                      formik.values.shippingAddressId === "") && (
                      <>
                        <Label className="font-weight-bold">
                          Shipping address label
                        </Label>
                        <Field
                          name="shippingAddress.label"
                          as={InputWithFormikErrors}
                        />

                        <Label className="font-weight-bold">
                          Shipping address
                        </Label>
                        <Field
                          name="shippingAddress.plain"
                          as={TextAreaWithFormikErrors}
                        />

                        <Label className="font-weight-bold">
                          Shipping address contact name
                        </Label>
                        <Field
                          name="shippingAddress.contactName"
                          as={InputWithFormikErrors}
                        />

                        <Label className="font-weight-bold">
                          Shipping address contact phone
                        </Label>
                        <Field
                          name="shippingAddress.contactPhone"
                          as={InputWithFormikErrors}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <BorderBoxContainer>
              <BorderBoxHeading>Reference Images</BorderBoxHeading>
              <BorderBox>
                <div className="d-grid grid-cols-6 gap-4 mb-2">
                  {clientOrder.referenceImages.map((cori, i) => (
                    <ImageWithControls
                      key={i}
                      {...cori}
                      resourceUrl={cori.attachmentUrl}
                    />
                  ))}
                  <a
                    href={clientOrder.newReferenceImageUrl}
                    className="border border-dashed rounded border-pink px-3 pt-3 pb-2 d-flex flex-wrap flex-grow-1 align-items-center justify-content-center"
                  >
                    <Plus40SVG />
                    <VisuallyHidden>Add Reference Image</VisuallyHidden>
                  </a>
                </div>
              </BorderBox>
            </BorderBoxContainer>
          </div>

          <div className="card-footer">
            <a href={clientOrder.showUrl} className="btn btn-secondary mr-2">
              Cancel
            </a>
            <Button variant="primary" type="submit">
              Save client project
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

export default withBasics(ClientOrderEdit)
