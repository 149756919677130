// @ts-nocheck
import Rails from "@rails/ujs"
import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [
    "fileField",
    "downloadLink",
    "savingButton",
    "uploadButton",
    "deleteButton",
    "deletingButton",
  ]

  connect() {
    this.savingButtonTarget.hidden = true
    this.deletingButtonTarget.hidden = true

    if (this.downloadLinkTarget.getAttribute("href") === "#") {
      this.downloadLinkTarget.hidden = true
      this.deleteButtonTarget.hidden = true
    } else {
      this.uploadButtonTarget.hidden = true
    }

    this.updateButtonStateForPolicy()
  }

  updateButtonStateForPolicy() {
    if (this.data.get("permit-create") === "false") {
      this.uploadButtonTarget.disabled = true
      this.uploadButtonTarget.parentNode.removeChild(this.uploadButtonTarget)
    }

    if (this.data.get("permit-destroy") === "false") {
      this.deleteButtonTarget.disabled = true
      this.deleteButtonTarget.parentNode.removeChild(this.deleteButtonTarget)
    }
  }

  deleteFile(e) {
    e.preventDefault()
    this.deletingButtonTarget.hidden = false
    this.deleteButtonTarget.hidden = true

    fetch(this.data.get("destroyPath"), {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
      body: "",
    }).then((res) => {
      this.downloadLinkTarget.setAttribute("href", "#")
      this.downloadLinkTarget.hidden = true
      this.uploadButtonTarget.hidden = false
      this.deletingButtonTarget.hidden = true
    })
  }

  fileSelected(e) {
    const directUploadUrl = this.data.get("directUploadUrl")

    this.savingButtonTarget.hidden = false
    this.uploadButtonTarget.hidden = true

    Array.from(this.fileFieldTarget.files).forEach((file) => {
      const upload = new DirectUpload(file, directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          console.log(error)
        } else {
          fetch(this.data.get("createPath"), {
            method: "POST",
            headers: {
              "X-CSRF-Token": Rails.csrfToken(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ signed_blob_ids: [blob.signed_id] }),
          })
            .then((res) => res.json())
            .then((res) => {
              this.downloadLinkTarget.setAttribute("href", res.downloadPath)
              this.data.set("destroyPath", res.destroyPath)
              this.downloadLinkTarget.hidden = false
              this.savingButtonTarget.hidden = true
              this.deleteButtonTarget.hidden = false
            })
        }
      })
    })
    this.fileFieldTarget.value = null
  }

  selectFile(e) {
    e.preventDefault()

    this.fileFieldTarget.click()
  }
}
