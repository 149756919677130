import React from "react"
import invariant from "tiny-invariant"
import { useClientOrderSearchFilterOptionsQuery } from "../generated/graphql"
import { RequestError } from "./Errors"
import SearchSelect from "./SearchSelect"
import { withBasics } from "./withBasics"
import DatePicker from "react-datepicker"

export type ClientOrderFilters = {
  clients: string[]
  makers: string[]
  salesLead: string[]
  productionLead: string[]
  operationsLead: string[]
  financialLead: string[]
  designLead: string[]
  impactLead: string[]
  createdAfter: Date | undefined
  createdBefore: Date | undefined
  exFactoryDateAfter: Date | undefined
  exFactoryDateBefore: Date | undefined
  moCutDateAfter: Date | undefined
  moCutDateBefore: Date | undefined
  clientOrderStatus: string[]
  productionStatus: string[]
  shipmentStatus: string[]
  shippingMode: string[]
  vesselStatus: string[]
  customsStatus: string[]
  finalMileDelivery: string[]
  importPort: string[]
  exportPort: string[]
  parcelCarrier: string[]
  shippingDocsPresent: Boolean | undefined
  bolPodDocsPresent: Boolean | undefined
}

const DEFAULT_CLIENT_ORDER_FILTERS = {
  clients: [],
  makers: [],
  salesLead: [],
  productionLead: [],
  operationsLead: [],
  financialLead: [],
  designLead: [],
  impactLead: [],
  createdAfter: undefined,
  createdBefore: undefined,
  exFactoryDateAfter: undefined,
  exFactoryDateBefore: undefined,
  moCutDateAfter: undefined,
  moCutDateBefore: undefined,
  clientOrderStatus: [],
  productionStatus: [],
  shipmentStatus: [],
  shippingMode: [],
  vesselStatus: [],
  customsStatus: [],
  finalMileDelivery: [],
  importPort: [],
  exportPort: [],
  parcelCarrier: [],
  shippingDocsPresent: undefined,
  bolPodDocsPresent: undefined,
}

const READABLE_BOOL_FILTERS: any = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
]

const DEFAULT_PRODUCTION_TRACKER_FILTERS = {
  shipmentStatus: ["not_ready"],
}

const DEFAULT_SHIPMENT_TRACKER_FILTERS = {
  shipmentStatus: ["ready_for_shipment", "in_transit", "out_for_delivery"],
}

export const computeDefaultFilters = (tracker: string) => {
  if (tracker === "production") {
    return {
      ...DEFAULT_CLIENT_ORDER_FILTERS,
      ...DEFAULT_PRODUCTION_TRACKER_FILTERS,
    }
  } else if (tracker === "shipping") {
    return {
      ...DEFAULT_CLIENT_ORDER_FILTERS,
      ...DEFAULT_SHIPMENT_TRACKER_FILTERS,
    }
  } else {
    return DEFAULT_CLIENT_ORDER_FILTERS
  }
}

type ClientOrderSearchFiltersProps = {
  filters: ClientOrderFilters
  setFilters: (v: ClientOrderFilters) => void
  tracker: string
}

const ClientOrderSearchFilters = (props: ClientOrderSearchFiltersProps) => {
  const { filters, setFilters, tracker } = props

  const result = useClientOrderSearchFilterOptionsQuery()
  if (result.status === "loading") {
    return null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result
  invariant(data, `expected data`)
  const { clientOrderSearchFilterOptions } = data

  return (
    data && (
      <div>
        <div className="row mb-2">
          <div style={{ width: "100%" }}>
            <button
              className="float-right btn btn-link"
              style={{ color: "#dc3545" }}
              onClick={() => {
                setFilters(computeDefaultFilters(tracker))
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={clientOrderSearchFilterOptions.clients.map((value) => ({
                value: value.id,
                label: value.name,
              }))}
              values={filters.clients.map((clientId: String) => ({
                value: clientId,
                label: (
                  clientOrderSearchFilterOptions.clients.find(
                    (option: any) => option.id === clientId,
                  ) || {}
                ).name,
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  clients: options.map((o) => o.value),
                })
              }}
              label="Clients"
            />

            <div className="mb-2 mt-2">
              <label className="f-header-4">Created After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.createdAfter &&
                    filters.createdAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.createdAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    createdAfter: date,
                  })
                }}
              />
            </div>
            <div className="mb-2 mt-2">
              <label className="f-header-4">Created Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.createdBefore &&
                    filters.createdBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.createdBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    createdBefore: date,
                  })
                }}
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.owners.map(
                  (status: any) => ({
                    value: status.id,
                    label: status.fullName,
                  }),
                )}
                values={filters.operationsLead.map((id: String) => ({
                  value: id,
                  label:
                    clientOrderSearchFilterOptions.owners.find(
                      (o) => o.id === id,
                    )?.fullName || "n/a",
                }))}
                onChange={(options: any) => {
                  setFilters({
                    ...filters,
                    operationsLead: options.map((o: any) => o.value),
                  })
                }}
                label="Operations Lead"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.owners.map(
                  (status: any) => ({
                    value: status.id,
                    label: status.fullName,
                  }),
                )}
                values={filters.salesLead.map((id: String) => ({
                  value: id,
                  label:
                    clientOrderSearchFilterOptions.owners.find(
                      (o) => o.id === id,
                    )?.fullName || "n/a",
                }))}
                onChange={(options: any) => {
                  setFilters({
                    ...filters,
                    salesLead: options.map((o: any) => o.value),
                  })
                }}
                label="Sales Lead"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.owners.map(
                  (status: any) => ({
                    value: status.id,
                    label: status.fullName,
                  }),
                )}
                values={filters.productionLead.map((id: String) => ({
                  value: id,
                  label:
                    clientOrderSearchFilterOptions.owners.find(
                      (o) => o.id === id,
                    )?.fullName || "n/a",
                }))}
                onChange={(options: any) => {
                  setFilters({
                    ...filters,
                    productionLead: options.map((o: any) => o.value),
                  })
                }}
                label="Production Lead"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.owners.map(
                  (status: any) => ({
                    value: status.id,
                    label: status.fullName,
                  }),
                )}
                values={filters.designLead.map((id: String) => ({
                  value: id,
                  label:
                    clientOrderSearchFilterOptions.owners.find(
                      (o) => o.id === id,
                    )?.fullName || "n/a",
                }))}
                onChange={(options: any) => {
                  setFilters({
                    ...filters,
                    designLead: options.map((o: any) => o.value),
                  })
                }}
                label="Design Lead"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.owners.map(
                  (status: any) => ({
                    value: status.id,
                    label: status.fullName,
                  }),
                )}
                values={filters.impactLead.map((id: String) => ({
                  value: id,
                  label:
                    clientOrderSearchFilterOptions.owners.find(
                      (o) => o.id === id,
                    )?.fullName || "n/a",
                }))}
                onChange={(options: any) => {
                  setFilters({
                    ...filters,
                    impactLead: options.map((o: any) => o.value),
                  })
                }}
                label="Impact Lead"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.owners.map(
                  (status: any) => ({
                    value: status.id,
                    label: status.fullName,
                  }),
                )}
                values={filters.financialLead.map((id: String) => ({
                  value: id,
                  label:
                    clientOrderSearchFilterOptions.owners.find(
                      (o) => o.id === id,
                    )?.fullName || "n/a",
                }))}
                onChange={(options: any) => {
                  setFilters({
                    ...filters,
                    financialLead: options.map((o: any) => o.value),
                  })
                }}
                label="Financial Lead"
              />
            </div>
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={clientOrderSearchFilterOptions.makers.map((value) => ({
                value: value.id,
                label: value.name,
              }))}
              values={filters.makers.map((makerId: String) => ({
                value: makerId,
                label: (
                  clientOrderSearchFilterOptions.makers.find(
                    (option) => option.id === makerId,
                  ) || {}
                ).name,
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  makers: options.map((o) => o.value),
                })
              }}
              label="Makers"
            />

            <div className="mb-2 mt-2">
              <label className="f-header-4">Ex Factory Date After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.exFactoryDateAfter &&
                    filters.exFactoryDateAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.exFactoryDateAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    exFactoryDateAfter: date,
                  })
                }}
              />
            </div>
            <div className="mb-2 mt-2">
              <label className="f-header-4">Ex Factory Date Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.exFactoryDateBefore &&
                    filters.exFactoryDateBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.exFactoryDateBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    exFactoryDateBefore: date,
                  })
                }}
              />
            </div>

            <div className="mb-2 mt-2">
              <label className="f-header-4">MO Cut Date After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.moCutDateAfter &&
                    filters.moCutDateAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.moCutDateAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    moCutDateAfter: date,
                  })
                }}
              />
            </div>

            <div className="mb-2 mt-2">
              <label className="f-header-4">MO Cut Date Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.moCutDateBefore &&
                    filters.moCutDateBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.moCutDateBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    moCutDateBefore: date,
                  })
                }}
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.shippingModes}
                values={clientOrderSearchFilterOptions.shippingModes.filter(
                  (option) => filters.shippingMode.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    shippingMode: options.map((o) => o.value),
                  })
                }}
                label="Shipping Mode"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.importPorts.map(
                  (port) => ({
                    value: port.id,
                    label: port.port,
                  }),
                )}
                values={filters.importPort.map((portId) => {
                  let fullPort = clientOrderSearchFilterOptions.importPorts.find(
                    (port) => port.id === portId,
                  )
                  return {
                    value: portId,
                    label: fullPort?.port,
                  }
                })}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    importPort: options.map((o) => o.value),
                  })
                }}
                label="Import Port"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.exportPorts.map(
                  (port) => ({ value: port.id, label: port.port }),
                )}
                values={filters.exportPort.map((portId) => {
                  let fullPort = clientOrderSearchFilterOptions.exportPorts.find(
                    (port) => port.id === portId,
                  )
                  return {
                    value: portId,
                    label: fullPort?.port,
                  }
                })}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    exportPort: options.map((o) => o.value),
                  })
                }}
                label="Export Port"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.parcelCarriers.map(
                  (carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                  }),
                )}
                values={filters.parcelCarrier.map((carrierId) => {
                  let fullCarrier = clientOrderSearchFilterOptions.parcelCarriers.find(
                    (carrier) => carrier.id === carrierId,
                  )
                  return {
                    value: carrierId,
                    label: fullCarrier?.name,
                  }
                })}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    parcelCarrier: options.map((o) => o.value),
                  })
                }}
                label="Parcel Carrier"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.clientOrderStatuses}
                values={clientOrderSearchFilterOptions.clientOrderStatuses.filter(
                  (option) => filters.clientOrderStatus.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    clientOrderStatus: options.map((o) => o.value),
                  })
                }}
                label="Client Project Status"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.productionStatuses}
                values={clientOrderSearchFilterOptions.productionStatuses.filter(
                  (option) => filters.productionStatus.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    productionStatus: options.map((o) => o.value),
                  })
                }}
                label="Production Status"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.shipmentStatuses}
                values={clientOrderSearchFilterOptions.shipmentStatuses.filter(
                  (option) => filters.shipmentStatus.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    shipmentStatus: options.map((o) => o.value),
                  })
                }}
                label="Shipment Status"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.customsStatuses}
                values={clientOrderSearchFilterOptions.customsStatuses.filter(
                  (option) => filters.customsStatus.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    customsStatus: options.map((o) => o.value),
                  })
                }}
                label="Customs Status"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.vesselStatuses}
                values={clientOrderSearchFilterOptions.vesselStatuses.filter(
                  (option) => filters.vesselStatus.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    vesselStatus: options.map((o) => o.value),
                  })
                }}
                label="Vessel Status"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                isMulti={false}
                isClearable={false}
                options={READABLE_BOOL_FILTERS}
                values={READABLE_BOOL_FILTERS.find(
                  (option: any) => filters.shippingDocsPresent === option.value,
                )}
                onChange={(option: any) => {
                  setFilters({
                    ...filters,
                    shippingDocsPresent: option.value,
                  })
                }}
                label="Shipping Docs Present"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                isMulti={false}
                isClearable={false}
                options={READABLE_BOOL_FILTERS}
                values={READABLE_BOOL_FILTERS.find(
                  (option: any) => filters.bolPodDocsPresent === option.value,
                )}
                onChange={(option: any) => {
                  setFilters({
                    ...filters,
                    bolPodDocsPresent: option.value,
                  })
                }}
                label="BOL/POD Docs Present"
              />
            </div>

            <div className="mb-2 mt-2">
              <SearchSelect
                options={clientOrderSearchFilterOptions.finalMileDeliveries}
                values={clientOrderSearchFilterOptions.finalMileDeliveries.filter(
                  (option) => filters.finalMileDelivery.includes(option.value),
                )}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    finalMileDelivery: options.map((o) => o.value),
                  })
                }}
                label="Final Mile Delivery"
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default withBasics(ClientOrderSearchFilters)
