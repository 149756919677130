import VisuallyHidden from "@reach/visually-hidden"
import React from "react"
import { ReactComponent as PencilSVG } from "../images/pencil.svg"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import { deleteRequest } from "../util/request"

const TableRowActions = (props: { editUrl?: string; resourceUrl?: string }) => {
  const deleteRow = () => {
    if (
      props.resourceUrl &&
      window.confirm("Are you sure you want to delete this?")
    ) {
      deleteRequest(props.resourceUrl).then(({ success }) => {
        if (success) window.location.reload()
      })
    }
  }

  return (
    <div className="d-flex">
      {props.editUrl && (
        <a href={props.editUrl} className="mr-3 text-dark-pink">
          <PencilSVG style={{ height: 14 }} />
          <VisuallyHidden>Edit</VisuallyHidden>
        </a>
      )}
      {props.resourceUrl && (
        <button
          type="button"
          onClick={deleteRow}
          className="border-0 p-0 bg-transparent"
        >
          <TrashSVG style={{ height: 14 }} className="text-danger" />
        </button>
      )}
    </div>
  )
}

export default TableRowActions
