import React, { useState } from "react"
import { useBetween } from "use-between"
import { convert, getSystemUnits, useUnitState } from "../util/units"
import { withBasics } from "./withBasics"

function SplitUnitInput(props: {
  value?: number
  onChange: any
  macroUnit: "lb"
  microUnit: "oz"
}) {
  const macroValue: number = Math.floor(props.value || 0)
  const microValue: number = parseFloat(
    convert((props.value || 0) - macroValue)
      .from(props.macroUnit)
      .to(props.microUnit)
      .toFixed(3),
  )

  return (
    <div className="row">
      <div className="col">
        <input
          className="form-control numeric float optional"
          type="number"
          value={macroValue}
          onChange={(e) => {
            const value =
              parseFloat(e.target.value) +
              convert(microValue).from(props.microUnit).to(props.macroUnit)
            props.onChange(value)
          }}
        />
      </div>

      <div className="col">
        <input
          className="form-control numeric float optional"
          type="number"
          value={microValue}
          onChange={(e) => {
            const value =
              macroValue +
              convert(parseFloat(e.target.value))
                .from(props.microUnit)
                .to(props.macroUnit)
            props.onChange(value)
          }}
        />
      </div>
    </div>
  )
}

export interface Props {
  name?: string
  label?: string
  value?: number
  unitName: "mass" | "length"
  unit: "cm" | "kg" | "lb" | "in"
  className?: string
  onChange?: any
}

function UnitInput(props: Props) {
  const [value, setValue] = useState<"" | number | undefined>(props.value)
  const inputId = props.name?.replace(/[[\]]/, "_")

  const { unitSystem } = useBetween(useUnitState)
  const units = getSystemUnits(unitSystem)

  const currentUnitName = units[props.unitName]

  const usefulValue = value !== "" ? value : undefined

  const inputValue: number = convert(usefulValue)
    .from(props.unit)
    .to(currentUnitName)

  const handleChange = (value: number) => {
    const submitValue = value
      ? convert(value).from(currentUnitName).to(props.unit)
      : ""
    setValue(submitValue)

    if (props.onChange) props.onChange(submitValue.toString())
  }

  const unitLabel = (() => {
    switch (currentUnitName) {
      case "lb":
        return "lb/oz"
      default:
        return currentUnitName
    }
  })()

  const unitInput = (() => {
    switch (currentUnitName) {
      case "lb":
        return (
          <SplitUnitInput
            value={inputValue}
            onChange={handleChange}
            macroUnit="lb"
            microUnit="oz"
          />
        )
      default:
        return (
          <input
            className="form-control numeric float optional"
            type="number"
            value={parseFloat(inputValue.toFixed(3))}
            onChange={(e) => handleChange(parseFloat(e.target.value))}
            id={inputId}
          />
        )
    }
  })()

  return (
    <div className="form-group float optional flex-grow-1">
      {props.name && <input type="hidden" name={props.name} value={value} />}

      {props.label && (
        <label
          className="form-control-label font-weight-bold float optional"
          htmlFor={inputId}
        >
          {`${props.label} (${unitLabel})`}
        </label>
      )}

      {unitInput}
    </div>
  )
}

export default withBasics(UnitInput)
