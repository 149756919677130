import React from "react"
import { Button } from "./FormElements"
import Modal from "./Modal"

type ClientOrderEditConfirmModalProps = {
  makerOrders: any
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

const ClientOrderEditConfirmModal = ({
  makerOrders,
  isOpen,
  onClose,
  onSubmit,
}: ClientOrderEditConfirmModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <div className="modal-dialog" role="document">
      <div className="modal-content p-4" style={{ minWidth: "500px" }}>
        <div className="modal-header">
          <h4 className="modal-title">Confirm Changes</h4>
        </div>

        <div className="modal-body">
          <p>
            Please note that there is an active maker order(s) associated with
            this client project. Changes made on the Client Project do not
            automatically reflect on the maker order(s). If a change need to be
            made to a maker order(s), please update the maker order(s) directly.
          </p>
        </div>

        <div className="modal-footer">
          <Button type="button" variant="danger" onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={onSubmit}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  </Modal>
)

export default ClientOrderEditConfirmModal
