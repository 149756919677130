import { DisclosureButton } from "@reach/disclosure"
import clsx from "clsx"
import React from "react"
import { ReactComponent as SearchIcon } from "../images/search.svg"
import { ReactComponent as FiltersIcon } from "../images/filters.svg"
import styled from "styled-components"

/**
 * styled input with a magnifying glass icon
 */
export const SearchInput = (props: JSX.IntrinsicElements["input"]) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text pr-1" id="basic-addon1">
          <SearchIcon />
        </span>
      </div>
      <input
        {...props}
        className={clsx(
          props.className,
          "form-control mr-2 border-left-0 pl-2",
        )}
      />
    </div>
  )
}

export const FiltersDisclosureButton = () => (
  <DisclosureButton
    className="btn btn-outline-dark border-white"
    type="button"
    aria-label="Toggle advanced search options"
  >
    <div className="d-flex align-items-center">
      <FiltersIcon className="mr-1" />
      FILTERS
    </div>
  </DisclosureButton>
)

export const CellContainer = styled.div`
  overflow-x: auto;
`

export const BorderBoxItemCell = styled(CellContainer)`
  display: flex;
  flex-wrap: wrap;
`
