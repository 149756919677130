import React from "react"
import invariant from "tiny-invariant"
import { useMakerOrderSearchFilterOptionsQuery } from "../generated/graphql"
import { RequestError } from "./Errors"
import SearchSelect from "./SearchSelect"
import { withBasics } from "./withBasics"
import DatePicker from "react-datepicker"

export type MakerOrderFilters = {
  makers: string[]
  clients: string[]
  createdAfter: Date | undefined
  createdBefore: Date | undefined
  exFactoryDateAfter: Date | undefined
  exFactoryDateBefore: Date | undefined
  inHandsAfter: Date | undefined
  inHandsBefore: Date | undefined
  statuses: string[]
}

export const DEFAULT_MAKER_ORDER_FILTERS = {
  makers: [],
  clients: [],
  createdAfter: undefined,
  createdBefore: undefined,
  exFactoryDateAfter: undefined,
  exFactoryDateBefore: undefined,
  inHandsAfter: undefined,
  inHandsBefore: undefined,
  statuses: [],
}

type MakerOrderSearchFiltersProps = {
  filters: MakerOrderFilters
  setFilters: (v: MakerOrderFilters) => void
}

const MakerOrderSearchFilters = (props: MakerOrderSearchFiltersProps) => {
  const { filters, setFilters } = props

  const result = useMakerOrderSearchFilterOptionsQuery()
  if (result.status === "loading") {
    return null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result
  invariant(data, `expected data`)
  const { makerOrderSearchFilterOptions } = data

  return (
    data && (
      <div>
        <div className="row mb-2">
          <div style={{ width: "100%" }}>
            <button
              className="float-right btn btn-link"
              style={{ color: "#dc3545" }}
              onClick={() => {
                setFilters(DEFAULT_MAKER_ORDER_FILTERS)
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={makerOrderSearchFilterOptions.clients.map((value) => ({
                value: value.id,
                label: value.name,
              }))}
              values={filters.clients.map((clientId: String) => ({
                value: clientId,
                label: (
                  makerOrderSearchFilterOptions.clients.find(
                    (option) => option.id === clientId,
                  ) || {}
                ).name,
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  clients: options.map((o) => o.value),
                })
              }}
              label="Clients"
            />

            <SearchSelect
              options={makerOrderSearchFilterOptions.makers.map((value) => ({
                value: value.id,
                label: value.name,
              }))}
              values={filters.makers.map((makerId: String) => ({
                value: makerId,
                label: (
                  makerOrderSearchFilterOptions.makers.find(
                    (option) => option.id === makerId,
                  ) || {}
                ).name,
              }))}
              onChange={(options) => {
                setFilters({
                  ...filters,
                  makers: options.map((o) => o.value),
                })
              }}
              label="Makers"
            />

            <div className="mb-2 mt-2">
              <SearchSelect
                options={makerOrderSearchFilterOptions.statuses.map(
                  (status: String) => ({
                    value: status,
                    label: _.capitalize(status.replaceAll("_", " ")),
                  }),
                )}
                values={filters.statuses.map((status: String) => ({
                  value: status,
                  label: _.capitalize(status.replaceAll("_", " ")),
                }))}
                onChange={(options) => {
                  setFilters({
                    ...filters,
                    statuses: options.map((o) => o.value),
                  })
                }}
                label="Statuses"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <label className="f-header-4">Created After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.createdAfter &&
                    filters.createdAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.createdAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    createdAfter: date,
                  })
                }}
              />
            </div>

            <div className="mb-2 mt-2">
              <label className="f-header-4">Created Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.createdBefore &&
                    filters.createdBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.createdBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    createdBefore: date,
                  })
                }}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <label className="f-header-4">Ex-Factory After</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.exFactoryDateAfter &&
                    filters.exFactoryDateAfter.toLocaleDateString()) ||
                  ""
                }
                selected={filters.exFactoryDateAfter || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    exFactoryDateAfter: date,
                  })
                }}
              />
            </div>

            <div className="mb-2 mt-2">
              <label className="f-header-4">Ex-Factory Before</label>
              <DatePicker
                className="form-control silver-border"
                value={
                  (filters.exFactoryDateBefore &&
                    filters.exFactoryDateBefore.toLocaleDateString()) ||
                  ""
                }
                selected={filters.exFactoryDateBefore || null}
                onChange={(date: Date) => {
                  setFilters({
                    ...filters,
                    exFactoryDateBefore: date,
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default withBasics(MakerOrderSearchFilters)
