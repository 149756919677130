import React, { useState } from "react"
import { withBoundary } from "./ErrorBoundary"
import Toggle from "react-toggle"
import "react-toggle/style.css"
import capitalize from "lodash/capitalize"
import Modal from "./Modal"
import * as S from "./MakerActivateButton.styled"
import { useUpdateMakerStatusMutation } from "../generated/graphql"
import { withBasics } from "./withBasics"

const ACTIVE_STATUS = "active"
const DRAFT_STATUS = "draft"

const MakerActivateButton = ({
  id,
  status: initialStatus,
  readyToActivate,
}) => {
  const [status, setStatus] = useState(initialStatus)
  const [modalOpen, setModalOpen] = useState(false)
  const { isLoading, isError, mutate } = useUpdateMakerStatusMutation()

  const onChange = async (event) => {
    const checked = event.target.checked

    if (!readyToActivate && status === DRAFT_STATUS) {
      setModalOpen(true)
    } else {
      const newStatus = checked ? ACTIVE_STATUS : DRAFT_STATUS
      mutate({ input: { id, status: newStatus } })
      setStatus(newStatus)
    }
  }
  const cancelModal = () => setModalOpen(false)
  const confirmModal = () => {
    mutate({ input: { id, status: ACTIVE_STATUS } })
    setStatus(ACTIVE_STATUS)
    setModalOpen(false)
  }

  return (
    <S.Container className="mb-2 mt-2">
      <Modal isOpen={modalOpen} style={{ maxWidth: "400px" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Are you sure you want to activate this maker?
              </h5>
              <button
                type="button"
                className="close"
                onClick={cancelModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                It is best practice to add a valid name, vetting status,
                contact, country, and capabilities before activating. Please
                ensure these fields are completed prior to activating, where
                possible.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmModal}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Toggle
        id="maker-activate"
        className="mr-2"
        checked={status === ACTIVE_STATUS}
        onChange={onChange}
        disabled={isLoading}
      />
      <span id="maker-activate">{capitalize(status)}</span>
      {isError ? (
        <S.Alert className="alert alert-danger" role="alert">
          Something went wrong! Please try again.
        </S.Alert>
      ) : null}
    </S.Container>
  )
}

export default withBoundary(withBasics(MakerActivateButton))
