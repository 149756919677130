// @ts-nocheck
import Rails from "@rails/ujs"
import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import { template } from "lodash"
import $ from "jquery"

export default class extends Controller {
  static targets = [
    "fileField",
    "downloadLink",
    "addingFilesButton",
    "addFilesButton",
    "deleteButton",
    "filesContainer",
    "fileTemplate",
  ]

  connect() {
    this.fileTemplate = template(this.fileTemplateTarget.innerHTML)
    this.addingFilesButtonTarget.hidden = true

    this.updateButtonStateForPolicy()
  }

  updateButtonStateForPolicy() {
    if (this.data.get("permit-create") === "false") {
      this.addFilesButtonTarget.parentNode.removeChild(
        this.addFilesButtonTarget,
      )
    }

    if (this.data.get("permit-destroy") === "false") {
      this.deleteButtonTargets.disabled = true
    }
  }

  deleteFile(e) {
    const $deleteButton = $(e.currentTarget)
    const $parent = $deleteButton.closest(
      "[data-target='multi-file-upload.file']",
    )
    const $deletingButton = $parent.find(
      "[data-target='multi-file-upload.deletingButton']",
    )

    $deleteButton.hide()
    $deletingButton.show()

    fetch($parent.data("multi-file-upload-destroy-path"), {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        $parent.remove()
      })
  }

  addFiles(e) {
    e.preventDefault()
    this.fileFieldTarget.click()
  }

  filesSelected(e) {
    this.addingFilesButtonTarget.hidden = false
    this.addFilesButtonTarget.hidden = true

    Array.from(this.fileFieldTarget.files).forEach((file) => {
      const upload = new DirectUpload(file, this.data.get("directUploadUrl"))

      upload.create((error, blob) => {
        if (error) {
          console.log(error)
        } else {
          fetch(this.data.get("createPath"), {
            method: "POST",
            headers: {
              "X-CSRF-Token": Rails.csrfToken(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ signed_blob_ids: [blob.signed_id] }),
          })
            .then((response) => response.json())
            .then((jsonResponse) => {
              const fileEl = $(this.fileTemplate(jsonResponse)).get(0)

              this.filesContainerTarget.appendChild(fileEl)

              this.addingFilesButtonTarget.hidden = true
              this.addFilesButtonTarget.hidden = false
              this.updateButtonStateForPolicy()
            })
        }
      })
    })
    this.fileFieldTarget.value = null
  }
}
