import { diff } from "deep-object-diff"

/* eslint eqeqeq: "off" */
export const isDate = (d: any) => d instanceof Date
export const isEmpty = (o: any) => Object.keys(o).length === 0
export const isObject = (o: any) => o != null && typeof o === "object"
export const isEmptyObject = (o: any) => isObject(o) && isEmpty(o)

export const deepChangedValuesDiff = (lhs: any, rhs: any) => {
  const delta: typeof rhs = diff(lhs, rhs)
  return delta
}

export const deepChangedValues = (lhs: any, rhs: any) => {
  if (lhs === rhs) return {}

  if (!isObject(lhs) || !isObject(rhs)) return rhs

  const l = lhs
  const r = rhs

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {}
    return r
  }

  const result = Object.keys(r).reduce((acc, key) => {
    const difference = deepChangedValues(l[key], r[key])

    // If the difference is empty, and the lhs is an empty object or the rhs is not an empty object
    if (
      isEmptyObject(difference) &&
      !isDate(difference) &&
      (isEmptyObject(l[key]) || !isEmptyObject(r[key]))
    )
      return acc // return no diff

    // If the object has an id key, always return it with the difference
    if (r[key] && r[key].hasOwnProperty("id")) difference["id"] = r[key]["id"]

    // @ts-expect-error
    acc[key] = difference
    return acc
  }, {})

  // Convert the result back to an array if the original object was one
  if (Array.isArray(r)) {
    return Object.values(result)
  } else {
    return result
  }
}

export const changedValues = (original: object, values: object) => {
  return (
    Object.keys(values)
      // @ts-expect-error
      .filter((key: string) => values[key] !== original[key])
      .reduce((obj: object, key: string) => {
        // @ts-expect-error
        obj[key] = values[key]
        return obj
      }, {})
  )
}
