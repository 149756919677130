// @ts-nocheck
import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from "jquery"

export default class extends Controller {
  static targets = [
    "successIndicator",
    "savingIndicator",
    "errorIndicator",
    "statusField",
    "modal",
  ]

  connect() {
    this.defaultIndicatorState()
    this.saveCurrentStatus()
  }

  saveCurrentStatus() {
    this.status = this.statusFieldTarget.value
  }

  restorePreviousStatus() {
    this.statusFieldTarget.value = this.status
  }

  defaultIndicatorState() {
    this.successIndicatorTarget.hidden = true
    this.savingIndicatorTarget.hidden = true
    this.errorIndicatorTarget.hidden = true
  }

  onSuccess(e) {
    this.saveCurrentStatus()
    this.errorIndicatorTarget.hidden = true
    this.successIndicatorTarget.hidden = false
    this.savingIndicatorTarget.hidden = true
    $(this.modalTarget).modal("hide")
  }

  onError(e) {
    if (e.detail[1] === "Bad Request") {
      up.extract(".sample-order-dispatch-info", e.detail[0].body.innerHTML)
    } else {
      this.modalClose()
      this.errorIndicatorTarget.hidden = false
    }
  }

  modalClose(e) {
    this.restorePreviousStatus()
    this.defaultIndicatorState()
    $(this.modalTarget).modal("hide")
  }

  change(e) {
    this.errorIndicatorTarget.hidden = true
    this.successIndicatorTarget.hidden = true
    this.savingIndicatorTarget.hidden = false

    if (this.statusFieldTarget.value === "dispatched") {
      $(this.modalTarget).modal("show")
    } else {
      Rails.fire(this.element, "submit")
    }
  }
}
