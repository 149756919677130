import compact from "lodash/compact"
import find from "lodash/find"
import flatten from "lodash/flatten"
import isNumber from "lodash/isNumber"
import union from "lodash/union"
import without from "lodash/without"
import React, { SyntheticEvent } from "react"
import CurrencyInput from "react-currency-input-field"
import invariant from "tiny-invariant"
import { useProductSearchFilterOptionsQuery } from "../generated/graphql"
import Checkbox from "./Checkbox"
import { RequestError } from "./Errors"
import SearchSelect from "./SearchSelect"
import SelectTree, { SelectOption } from "./SelectTree"
import { withBasics } from "./withBasics"

export type ProductFilters = {
  statuses: string[]
  certifications: string[]
  makerAttributeIds: string[]
  productAttributeIds: string[]
  makers: any[]
  makerIds: string[]
  environments: string[]
  productCategoryIds: string[]
  productSubcategoryIds: string[]
  materialIds: string[]
  materialSubcategoryIds: string[]
  customizations: string[]
  previousBuyers: string[]
  makerTypes: string[]
  makerCapabilities: string[]
  countryIds: string[]
  skus: string[]
  styleNumbers: string[]
  minPrice: number | undefined
  maxPrice: number | undefined
  minUnits: number | undefined
  maxUnits: number | undefined
}

// Status
export const ACTIVE_STATUS: string = "active"
export const DRAFT_STATUS: string = "draft"
export const ARCHIVED_STATUS: string = "archived"
export const DEFAULT_PRODUCT_FILTERS = {
  statuses: [ACTIVE_STATUS],
  certifications: [],
  makers: [],
  maerIds: [],
  makerAttributeIds: [],
  productAttributeIds: [],
  environments: [],
  productCategoryIds: [],
  productSubcategoryIds: [],
  makerIds: [],
  materialIds: [],
  materialSubcategoryIds: [],
  customizations: [],
  previousBuyers: [],
  makerTypes: [],
  makerCapabilities: [],
  countryIds: [],
  skus: [],
  styleNumbers: [],
  minPrice: undefined,
  maxPrice: undefined,
  minUnits: undefined,
  maxUnits: undefined,
}

type ProductSearchFiltersProps = {
  filters: ProductFilters
  setFilters: (v: ProductFilters) => void
}

const ProductSearchFilters = (props: ProductSearchFiltersProps) => {
  const { filters, setFilters } = props

  const result = useProductSearchFilterOptionsQuery()
  if (result.status === "loading") {
    return null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result
  invariant(data, `expected data`)
  const { productSearchFilterOptions } = data

  return (
    data && (
      <div>
        <div className="row mb-2 d-flex align-items-center justify-content-end">
          <div>
            <Checkbox
              checked={filters.statuses.includes(ACTIVE_STATUS)}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilters({
                    ...filters,
                    statuses: union(filters.statuses, [ACTIVE_STATUS]),
                  })
                } else {
                  setFilters({
                    ...filters,
                    statuses: without(filters.statuses, ACTIVE_STATUS),
                  })
                }
              }}
              id={"ActiveMakerCheckbox"}
              label={"Active"}
            />

            <Checkbox
              checked={filters.statuses.includes(DRAFT_STATUS)}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilters({
                    ...filters,
                    statuses: union(filters.statuses, [DRAFT_STATUS]),
                  })
                } else {
                  setFilters({
                    ...filters,
                    statuses: without(filters.statuses, DRAFT_STATUS),
                  })
                }
              }}
              id={"DraftMakerCheckbox"}
              label={"Draft"}
            />

            <Checkbox
              checked={filters.statuses.includes(ARCHIVED_STATUS)}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilters({
                    ...filters,
                    statuses: union(filters.statuses, [ARCHIVED_STATUS]),
                  })
                } else {
                  setFilters({
                    ...filters,
                    statuses: without(filters.statuses, ARCHIVED_STATUS),
                  })
                }
              }}
              id={"ArchivedMakerCheckbox"}
              label={"Archived"}
            />
          </div>

          <div>
            <button
              className="float-right btn btn-link"
              style={{ color: "#dc3545" }}
              onClick={() => {
                setFilters(DEFAULT_PRODUCT_FILTERS)
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.certifications.nodes.map(
                (cert) => ({
                  value: cert.id,
                  label: cert.name,
                }),
              )}
              values={filters.certifications.map((id) => ({
                value: id,
                label:
                  productSearchFilterOptions.certifications.nodes.find(
                    (cert) => cert.id === id,
                  )?.name ?? "–",
              }))}
              onChange={(options) =>
                setFilters({
                  ...filters,
                  certifications: options.map((o) => o.value),
                })
              }
              label="Certifications"
            />
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.makers.map((maker) => ({
                value: maker.id,
                label: maker.name,
              }))}
              values={filters.makerIds.map((id: string) => {
                const maker = find(productSearchFilterOptions.makers, {
                  id,
                })
                invariant(maker, `expected attribute`)

                return {
                  value: maker.id,
                  label: maker.name,
                }
              })}
              onChange={(options) => {
                const makerIds = options.map((o) => o.value)

                setFilters({
                  ...filters,
                  makerIds,
                })
              }}
              label="Makers"
            />
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.environments.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={filters.environments.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setFilters({
                  ...filters,
                  environments: options.map((o) => o.value),
                })
              }
              label="Environments"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              values={filters.countryIds.map((id: string) => {
                const country = find(productSearchFilterOptions.countries, {
                  id,
                })
                invariant(country)

                return {
                  value: country.id,
                  label: country.name,
                }
              })}
              onChange={(options) => {
                const countryIds = options.map((o) => o.value)

                setFilters({
                  ...filters,
                  countryIds,
                })
              }}
              label="Country of Origin"
            />
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.makerAttributes.map(
                (attribute) => ({
                  value: attribute.id,
                  label: attribute.text,
                }),
              )}
              values={filters.makerAttributeIds.map((id: string) => {
                const attribute = find(
                  productSearchFilterOptions.makerAttributes,
                  {
                    id,
                  },
                )
                invariant(attribute, `expected attribute`)

                return {
                  value: attribute.id,
                  label: attribute.text,
                }
              })}
              onChange={(options) => {
                const makerAttributeIds = options.map((o) => o.value)

                setFilters({
                  ...filters,
                  makerAttributeIds,
                })
              }}
              label="Maker Attributes"
            />
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <SelectTree
                label="Materials"
                options={productSearchFilterOptions.materials.map(
                  (material) => {
                    const children = material.materialSubcategories.nodes.map(
                      (subcategory) => {
                        return {
                          label: subcategory.name,
                          value: subcategory.id,
                          type: "subcategory",
                          checked: filters.materialSubcategoryIds.includes(
                            subcategory.id,
                          ),
                          children: [],
                        }
                      },
                    )

                    return {
                      label: material.name,
                      value: material.id,
                      type: "material",
                      subcategories: material.materialSubcategories.nodes.map(
                        (materialSubcategory) => materialSubcategory.id,
                      ),
                      checked: filters.materialIds.includes(material.id),
                      children,
                    }
                  },
                )}
                onChange={(
                  _currentNode: SelectOption,
                  selectedNodes: SelectOption[],
                ) => {
                  const materialIds = compact(
                    selectedNodes.map((node) => {
                      if (node.type === "material") {
                        return node.value
                      }
                      return undefined
                    }),
                  )
                  const materialSubcategoryIds = compact(
                    flatten(
                      selectedNodes.map((node) => {
                        switch (node.type) {
                          case "material":
                            return undefined //node.subcategories
                          case "subcategory":
                            return node.value
                          default:
                            return undefined
                        }
                      }),
                    ),
                  )
                  setFilters({
                    ...filters,
                    materialIds,
                    materialSubcategoryIds,
                  })
                }}
                onReset={() => {
                  setFilters({
                    ...filters,
                    materialIds: [],
                    materialSubcategoryIds: [],
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.customizations.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={filters.customizations.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setFilters({
                  ...filters,
                  customizations: options.map((o) => o.value),
                })
              }
              label="Customizations"
            />
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.makerTypes.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={filters.makerTypes.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setFilters({
                  ...filters,
                  makerTypes: options.map((o) => o.value),
                })
              }
              label="Maker Types"
            />
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.previousBuyers.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={filters.previousBuyers.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setFilters({
                  ...filters,
                  previousBuyers: options.map((o) => o.value),
                })
              }
              label="Previous Buyers"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <label htmlFor="skus" className="f-header-4">
                Platform IDs (comma separated)
              </label>
              <input
                id="skus"
                name="skus"
                className="form-control form-control"
                min="0"
                value={filters.skus.join(", ") || ""}
                onChange={(e: SyntheticEvent) => {
                  // @ts-expect-error todo
                  const skus = e.target.value
                    .split(",")
                    .map((sku: string) => sku.trim())
                    .filter((sku: string) => sku)
                  setFilters({
                    ...filters,
                    skus,
                  })
                }}
              />
            </div>
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.makerCapabilities.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={filters.makerCapabilities.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setFilters({
                  ...filters,
                  makerCapabilities: options.map((o) => o.value),
                })
              }
              label="Maker Capabilities"
            />
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <label htmlFor="styleNumbers" className="f-header-4">
                TTM Style Numbers (comma separated)
              </label>
              <input
                id="styleNumbers"
                name="styleNumbers"
                className="form-control form-control"
                min="0"
                value={filters.styleNumbers.join(", ") || ""}
                onChange={(e: SyntheticEvent) => {
                  // @ts-expect-error todo
                  const styleNumbers = e.target.value
                    .split(",")
                    .map((styleNumber: string) => styleNumber.trim())
                    .filter((styleNumber: string) => styleNumber)
                  setFilters({
                    ...filters,
                    styleNumbers,
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <SelectTree
                label="Product Categories"
                options={productSearchFilterOptions.productCategories.map(
                  (category) => {
                    const children = category.productSubcategories.nodes.map(
                      (subcategory) => {
                        return {
                          label: subcategory.name,
                          value: subcategory.id,
                          type: "subcategory",
                          checked: filters.productSubcategoryIds.includes(
                            subcategory.id,
                          ),
                          children: [],
                        }
                      },
                    )

                    return {
                      label: category.name,
                      value: category.id,
                      type: "category",
                      subcategories: category.productSubcategories.nodes.map(
                        (x) => x.id,
                      ),
                      checked: filters.productCategoryIds.includes(category.id),
                      children,
                    }
                  },
                )}
                onChange={(
                  _currentNode: SelectOption,
                  selectedNodes: SelectOption[],
                ) => {
                  const productCategoryIds = compact(
                    selectedNodes.map((node) => {
                      if (node.type === "category") {
                        return node.value
                      }
                      return undefined
                    }),
                  )
                  const productSubcategoryIds = compact(
                    flatten(
                      selectedNodes.map((node) => {
                        switch (node.type) {
                          case "category":
                            return node.subcategories
                          case "subcategory":
                            return node.value
                          default:
                            return undefined
                        }
                      }),
                    ),
                  )
                  setFilters({
                    ...filters,
                    productCategoryIds,
                    productSubcategoryIds,
                  })
                }}
                onReset={() => {
                  setFilters({
                    ...filters,
                    productCategoryIds: [],
                    productSubcategoryIds: [],
                  })
                }}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <label className="f-header-4">Price</label>

              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="minPrice" className="sr-only">
                      Min Price
                    </label>

                    <div>
                      <CurrencyInput
                        id="minPrice"
                        name="minPrice"
                        placeholder="Min Price"
                        className="form-control form-control-sm m-2"
                        defaultValue={filters.minPrice}
                        decimalsLimit={2}
                        prefix={"$"}
                        min="0"
                        onValueChange={(value, name) => {
                          if (value) {
                            const newMin = Math.fround(parseFloat(value) * 100)
                            setFilters({
                              ...filters,
                              minPrice: newMin,
                            })
                          } else {
                            setFilters({
                              ...filters,
                              minPrice: undefined,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <label htmlFor="maxPrice" className="sr-only">
                      Max Price
                    </label>

                    <div>
                      <CurrencyInput
                        id="maxPrice"
                        name="maxPrice"
                        min="0"
                        placeholder="Max Price"
                        className="form-control form-control-sm m-2"
                        defaultValue={filters.maxPrice}
                        decimalsLimit={2}
                        prefix={"$"}
                        onValueChange={(value, name) => {
                          if (value) {
                            const newMax = Math.fround(parseFloat(value) * 100)
                            setFilters({
                              ...filters,
                              maxPrice: newMax,
                            })
                          } else {
                            setFilters({
                              ...filters,
                              maxPrice: undefined,
                            })
                          }
                        }}
                      />
                    </div>

                    {filters.maxPrice &&
                    filters.minPrice &&
                    filters.maxPrice < filters.minPrice ? (
                      <div className="alert alert-danger">
                        Max price must be less than min price.
                      </div>
                    ) : null}

                    {filters.maxPrice && filters.maxPrice < 0 ? (
                      <div className="alert alert-danger">
                        Max price must be a positive number.
                      </div>
                    ) : null}

                    {filters.minPrice && filters.minPrice < 0 ? (
                      <div className="alert alert-danger">
                        Min price must be a positive number.
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <label className="f-header-4">Units</label>

              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="minUnits" className="sr-only">
                      Min Units
                    </label>

                    <div>
                      <input
                        id="minUnits"
                        name="minUnits"
                        placeholder="Min Units"
                        className="form-control form-control-sm m-2"
                        type="number"
                        min="0"
                        value={filters.minUnits || ""}
                        onChange={(e: SyntheticEvent) => {
                          // @ts-expect-error todo
                          const newMin = parseInt(e.target.value)
                          if (isNumber(newMin)) {
                            setFilters({
                              ...filters,
                              minUnits: newMin,
                            })
                          } else {
                            setFilters({
                              ...filters,
                              minUnits: undefined,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <label htmlFor="maxUnits" className="sr-only">
                      Max Units
                    </label>

                    <div>
                      <input
                        id="maxUnits"
                        name="maxUnits"
                        placeholder="Max Units"
                        className="form-control form-control-sm m-2"
                        type="number"
                        min="0"
                        value={filters.maxUnits || ""}
                        onChange={(e: SyntheticEvent) => {
                          // @ts-expect-error todo
                          const newMax = parseInt(e.target.value)
                          if (isNumber(newMax)) {
                            setFilters({
                              ...filters,
                              maxUnits: newMax,
                            })
                          } else {
                            setFilters({
                              ...filters,
                              maxUnits: undefined,
                            })
                          }
                        }}
                      />
                    </div>

                    {filters.maxUnits &&
                    filters.minUnits &&
                    filters.maxUnits < filters.minUnits ? (
                      <div className="alert alert-danger">
                        Max units must be less than min units.
                      </div>
                    ) : null}

                    {filters.maxUnits && filters.maxUnits < 0 ? (
                      <div className="alert alert-danger">
                        Max units must be a positive number.
                      </div>
                    ) : null}

                    {filters.minUnits && filters.minUnits < 0 ? (
                      <div className="alert alert-danger">
                        Min units must be a positive number.
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={productSearchFilterOptions.productAttributes.map(
                (attribute) => ({
                  value: attribute.id,
                  label: attribute.name,
                }),
              )}
              values={filters.productAttributeIds.map((id: string) => {
                const attribute = find(
                  productSearchFilterOptions.productAttributes,
                  {
                    id,
                  },
                )
                invariant(attribute, `expected attribute`)

                return {
                  value: attribute.id,
                  label: attribute.name,
                }
              })}
              onChange={(options) => {
                const productAttributeIds = options.map((o) => o.value)

                setFilters({
                  ...filters,
                  productAttributeIds,
                })
              }}
              label="Product Attributes"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default withBasics(ProductSearchFilters)
