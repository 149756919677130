import React from "react"
import { EmptyValue } from "./EmptyValue"

const ContactsSection = ({ order }: any) => (
  <>
    {order.contacts.length > 0 ? (
      <div className="table-responsive">
        <table className="table nice-table">
          <thead>
            <tr>
              <th>Contact Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Job Title</th>
            </tr>
          </thead>

          <tbody>
            {order.contacts.map((contact: any) => (
              <tr key={contact.id}>
                <td>{contact.fullName}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
                <td>{contact.jobTitle}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <EmptyValue variant="message" />
    )}
  </>
)

export default ContactsSection
