import React, { useState } from "react"
import DropdownTreeSelect, { TreeNode } from "react-dropdown-tree-select"
import S from "./SelectTree.styled"
import "react-dropdown-tree-select/dist/styles.css"
import { withBasics } from "./withBasics"
import { useId } from "@reach/auto-id"

export type SelectOption = TreeNode & {
  label: string
  value: string
  type: string
  checked: boolean
  subcategories: string[]
  children: any[]
}

export type SelectTreeProps = {
  onChange: (currentNode: SelectOption, selectedNodes: SelectOption[]) => void
  onReset: () => void
  options: SelectOption[]
  label: string
}

const SelectTree = (props: SelectTreeProps) => {
  const { onChange, options, onReset } = props
  const [showReset, setShowReset] = useState(false)

  const internalOnChange = (
    currentNode: SelectOption,
    selectedNodes: SelectOption[],
  ) => {
    setShowReset(selectedNodes.length > 0)
    onChange(currentNode, selectedNodes)
  }

  const id = useId()

  return (
    <div className="mb-2 mt-2">
      <label htmlFor={id} className="f-header-4">
        {props.label}
      </label>
      <S.Container>
        {showReset && <S.ClearAllButton onClick={onReset}>x</S.ClearAllButton>}
        <DropdownTreeSelect
          id={id}
          data={options}
          // @ts-ignore
          onChange={internalOnChange}
          keepTreeOnSearch={true}
          showPartiallySelected={true}
        />
      </S.Container>
    </div>
  )
}

export default withBasics(SelectTree)
