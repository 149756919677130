import styled from "styled-components"

const Container = styled.div`
  width: 100%;

  .search {
    border-bottom: none;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }

  .tag {
    border-radius: 2 px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3 px;
    padding-left: 6 px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
  }

  .tag-remove {
    color: hsl(0, 0%, 20%);
  }

  .react-dropdown-tree-select .dropdown {
    width: 100%;
  }

  .dropdown-content {
    width: 100%;
    overflow: scroll;
    max-height: 50vh;
  }

  .dropdown-trigger {
    width: 100%;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: "";
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: "";
  }
`
const ClearAllButton = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  margin-top: 5px;
  z-index: 30;
  color: hsl(0, 0%, 80%);
  font-size: 25px;
  cursor: pointer;
`

export default { Container, ClearAllButton }
