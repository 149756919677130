import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "template", "input"]
  templateTarget!: HTMLElement
  containerTarget!: HTMLElement
  inputTarget!: HTMLElement

  addReminder() {
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      String(new Date().getTime()),
    )
    const div = document.createElement("div")
    div.innerHTML = content.trim()
    const reminderFields = div as HTMLElement
    this.containerTarget.appendChild(reminderFields)

    const reminderCount = this.containerTarget.querySelectorAll(
      ".reminder-fields",
    ).length
    const reminderNumberField = reminderFields.querySelector(".reminder-number")

    if (reminderNumberField) {
      reminderNumberField.textContent = `Reminder ${reminderCount}`
    }
  }

  removeReminder(event: Event) {
    const clickedElement = event.target as HTMLElement
    const reminderFields = clickedElement.closest(
      ".reminder-fields",
    ) as HTMLElement | null

    if (reminderFields?.dataset.newRecord === "true") {
      reminderFields.remove()
    } else if (reminderFields) {
      reminderFields.style.display = "none"
      const destroyInput = reminderFields.querySelector(
        'input[name*="_destroy"]',
      )
      if (destroyInput instanceof HTMLInputElement) {
        destroyInput.value = "1"
      }
    }
  }

  handlePresetOption(event: Event) {
    event.preventDefault()

    const clickedElement = event.target as HTMLElement
    const reminderInput = clickedElement.parentNode?.querySelector(
      ".remind-at-input",
    ) as HTMLElement | null
    // @ts-ignore
    reminderInput.value = event.target.value
  }
}
