import { TabList, TabPanel, TabPanels } from "@reach/tabs"
import clsx from "clsx"
import React, { useState } from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import {
  ProductStatus,
  useUpdateProductMutation,
  UpdateProductInput,
  UpdateProductMutation,
  ProductEditMakerFragment,
  useProductEditQuery,
  useProductProfileTestingAndComplianceQuery,
  NpaVariant,
  useCurrentUserQuery,
} from "../generated/graphql"
import { formatDate } from "../util/dates"
import {
  BorderBox,
  BorderBoxContainer,
  BorderBoxEmpty,
  BorderBoxHeading,
  BorderBoxItem,
} from "./BorderBox"
import EditLink from "./EditLink"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import { ReactComponent as PencilSVG } from "../images/pencil.svg"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import { ReactComponent as DownloadSVG } from "../images/download.svg"
import { ReactComponent as DownChevronSVG } from "../images/down-chevron.svg"
import { ReactComponent as CarSVG } from "../images/car.svg"
import { ReactComponent as AirplaneSVG } from "../images/airplane.svg"
import { ReactComponent as SmartphoneSVG } from "../images/smartphone.svg"
import { ReactComponent as PlusCircleSVG } from "../images/plus-circle.svg"
import { ReactComponent as Lightbulb } from "../images/lightbulb.svg"
import { ReactComponent as DrivingEmissons } from "../images/driving-emissions.svg"
import { ReactComponent as DrinkingWater } from "../images/drinking-water.svg"
import { ReactComponent as Pesticides } from "../images/pesticides.svg"
import { ReactComponent as Recycled } from "../images/recycled.svg"
import VisuallyHidden from "@reach/visually-hidden"
import { StyledTab } from "./StyledTab"
import {
  Field,
  FieldArray,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik"
import { Button, Label, Select } from "./FormElements"
import { deepChangedValues } from "../util/changed-values"
import CollectionSelect from "./CollectionSelect"
import {
  CheckboxWithFormikErrors,
  DateInputWithFormikErrors,
  InputWithFormikErrors,
  SelectWithFormikErrors,
  TextAreaWithFormikErrors,
  UnitInputWithFormikErrors,
} from "./WithFormikErrors"
import { deleteRequest } from "../util/request"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { EmptyValue } from "./EmptyValue"
import { ProductPricingAndLogisticsPanel } from "./ProductProfile"
import { ProductImages, ProductTechPack } from "./ProductAttachmentsEdit"
import TableRowActions from "./TableRowActions"
import * as Yup from "yup"
import Tabs from "./Tabs"
import UnitToggle from "./UnitToggle"
import UnitLabel from "./UnitLabel"
import SearchSelect from "./SearchSelect"

type ProductProps = {
  product: ProductEditMakerFragment
}

const productStatusLabels: Readonly<Record<ProductStatus, string>> = {
  DRAFT: "Draft",
  ACTIVE: "Active",
  ARCHIVED: "Archived",
}

const ProductEdit = (props: { id: string }) => {
  const result = useProductEditQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const product = result.data.product

  return <ProductEditForm product={product} />
}
const greaterThanOrEqualToZeroOrNull = Yup.number()
  .min(0, "Must be greater than or equal to 0")
  .nullable()

const validationSchema = Yup.object().shape({
  carbonOffsetAsKmOfDriving: greaterThanOrEqualToZeroOrNull,
  carbonOffsetAsPhonesCharged: greaterThanOrEqualToZeroOrNull,
  carbonOffsetAsTransatlanticFlights: greaterThanOrEqualToZeroOrNull,
  carbonOffsetKg: greaterThanOrEqualToZeroOrNull,
  fairWagePerHour: greaterThanOrEqualToZeroOrNull,
  energyConservedKwh: greaterThanOrEqualToZeroOrNull,
  waterSavedLiters: greaterThanOrEqualToZeroOrNull,
  emissionsAvoidedKg: greaterThanOrEqualToZeroOrNull,
  pesticidesAvoidedG: greaterThanOrEqualToZeroOrNull,
  wasteDivertedG: greaterThanOrEqualToZeroOrNull,
  recycledWaterBottles: greaterThanOrEqualToZeroOrNull,
  bulbEnergySavedHours: greaterThanOrEqualToZeroOrNull,
  drivingEmissionsAvoidedKm: greaterThanOrEqualToZeroOrNull,
  drinkingWaterSavedDays: greaterThanOrEqualToZeroOrNull,
  landSavedFromPesticidesM2: greaterThanOrEqualToZeroOrNull,
})

const ProductEditForm = ({ product }: ProductProps) => {
  const mutation = useUpdateProductMutation()

  const initialValues = {
    ...product,
    productionMethod: product.productionMethod ?? "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setFieldValue, setFieldError }) => {
      const params = deepChangedValues(initialValues, values)

      if (
        params.variantName ||
        (params.variants || []).filter((variant: any) => variant.id).length ||
        (params.npaVariants || []).filter((npaVariant: any) => npaVariant.id)
          .length
      ) {
        if (
          !window.confirm(
            "Changing the name of a variant will change its name in existing client projects, maker orders and client approval orders. Are you sure of this change?",
          )
        ) {
          setFieldValue("variantName", initialValues.variantName)
          setFieldValue("variants", initialValues.variants)
          setFieldValue("npaVariants", initialValues.npaVariants)
          return
        }
      }

      const input: UpdateProductInput = {
        id: product.id,
        ...params,
      }

      try {
        const {
          updateProduct: result,
        }: UpdateProductMutation = await mutation.mutateAsync({
          input,
        })

        if (result && result.errors.length) {
          for (let error of result.errors)
            if (error.path) setFieldError(error.path[1], error.message)
        } else {
          window.location.href = product.showUrl + (window.location.hash || "")
        }
      } catch (er) {
        window.alert(`An error occured: ${(er as Error).message}`)
      }
    },
  })

  const tabIds = [
    "#basic-information",
    "#main",
    "#pricing-and-logistics",
    "#compliance",
    "#impact",
  ]

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-lg-row">
            <div className="mr-auto mb-2">
              <Header product={product} />
            </div>
            <div className="d-flex flex-column text-lg-right align-self-lg-center mb-2">
              <Status product={product} />
            </div>
          </div>

          <div className="card border-pink">
            <div className="card-body">
              <div className="d-flex justify-content-end align-items-baseline">
                <a href={product.showUrl} className="text-danger mr-4">
                  Back
                </a>
                <Button variant="dark" type="submit">
                  Save
                </Button>
              </div>

              <Tabs tabIds={tabIds}>
                <div className="d-flex flex-grow-1 justify-content-center mb-4">
                  <TabList>
                    <StyledTab>Basic Information</StyledTab>
                    <StyledTab>Main</StyledTab>
                    <StyledTab>Pricing &amp; Logistics</StyledTab>
                    <StyledTab>Compliance</StyledTab>
                    <StyledTab>Impact</StyledTab>
                  </TabList>
                </div>
                <TabPanels>
                  <TabPanel>
                    <BasicInformationPanel product={product} />
                  </TabPanel>
                  <TabPanel>
                    <MainPanel product={product} />
                  </TabPanel>
                  <TabPanel>
                    <ProductPricingAndLogisticsPanel
                      product={product}
                      mode="edit"
                    />
                  </TabPanel>
                  <TabPanel>
                    <CompliancePanel id={product.id} />
                  </TabPanel>
                  <TabPanel>
                    <ImpactPanel product={product} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

const Header = ({ product }: ProductProps) => (
  <div className="d-flex flex-row mt-4 mb-4">
    <div className="mr-30">
      {product.image && product.image.imgixUrl && (
        <Imgix src={product.image.imgixUrl} sizes="100px" className="shadow" />
      )}
    </div>

    <div className="d-flex flex-column">
      <Field name="name" as={InputWithFormikErrors} />
    </div>
  </div>
)

const Status = ({ product }: ProductProps) => {
  const formik = useFormikContext()

  const deleteProduct = () => {
    if (
      product.resourceUrl &&
      window.confirm("Are you sure you want to delete this product?")
    ) {
      deleteRequest(product.resourceUrl).then(({ success }) => {
        if (success) window.location.href = "../"
      })
    }
  }

  return (
    <>
      <div>
        <span
          className="btn d-flex align-items-baseline justify-content-end text-danger mb-2"
          style={{ cursor: "pointer" }}
          onClick={deleteProduct}
        >
          Delete Product
          <TrashSVG style={{ height: 14 }} className="ml-2" />
        </span>
      </div>
      <div className="mb-2">
        <div>Last Updated: {formatDate(product.updatedAt)}</div>
      </div>
      <div
        className="btn-group btn-group-sm"
        role="group"
        aria-label="Product Status"
      >
        {Object.values(ProductStatus).map((status) => (
          <button
            type="button"
            key={status}
            onClick={() => {
              formik.setFieldValue("status", status)
            }}
            className={clsx(
              "btn btn-sm",
              // @ts-expect-error
              formik.values.status === status ? "btn-pink" : "btn-outline-pink",
            )}
          >
            {productStatusLabels[status]}
          </button>
        ))}
      </div>
    </>
  )
}

const CountryOptions = ({ countries }: any) => (
  <>
    <option value=""></option>
    {countries.map((country: any) => (
      <option value={country.id} key={country.id}>
        {country.nameWithFlag}
      </option>
    ))}
  </>
)

const BasicInformationPanel = ({ product }: ProductProps) => (
  <>
    <div className="d-flex flex-wrap mb-4 pt-4">
      <div className="d-flex align-items-baseline mb-4 mr-4">
        <div className="text-nowrap mr-2">
          <Label htmlFor="maker.name">Maker:</Label>
        </div>
        <a href={product.maker.showUrl} style={{ lineHeight: "40px" }}>
          {product.maker.name}
        </a>
      </div>

      <div className="d-flex align-items-baseline">
        <div className="text-nowrap mr-2">
          <Label htmlFor="makerName">Maker Facing Name:</Label>
        </div>
        <Field
          name="makerName"
          as={InputWithFormikErrors}
          style={{ width: "200px" }}
        />
      </div>
    </div>

    <div className="row">
      <div className="col col-sm-12 col-md-4">
        <Label htmlFor="makerCountryId">Maker Country:</Label>
        <Field
          name="makerCountryId"
          defaultValue={product.makerCountry ? product.makerCountry.id : ""}
          as={SelectWithFormikErrors}
        >
          <CountryOptions countries={product.countryOptions} />
        </Field>
      </div>

      <div className="col col-sm-12 col-md-4">
        <Label htmlFor="shipsFromCountryId">Ships From:</Label>
        <Field
          name="shipsFromCountryId"
          defaultValue={
            product.shipsFromCountry ? product.shipsFromCountry.id : ""
          }
          as={SelectWithFormikErrors}
        >
          <CountryOptions countries={product.countryOptions} />
        </Field>
      </div>

      <div className="col col-sm-12 col-md-4">
        <Label htmlFor="countryOfOriginId">Made in Country (COO):</Label>
        <Field
          name="countryOfOriginId"
          defaultValue={
            product.countryOfOrigin ? product.countryOfOrigin.id : ""
          }
          as={SelectWithFormikErrors}
        >
          <CountryOptions countries={product.countryOptions} />
        </Field>
      </div>
    </div>

    <BorderBoxContainer>
      <BorderBoxHeading>Maker Type(s)</BorderBoxHeading>
      <BorderBox>
        <Field
          name="makerTypes"
          as={CollectionSelect}
          options={product.makerTypeOptions}
        />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Attributes</BorderBoxHeading>
      <BorderBox>
        <Field
          name="selectedMakerAttributeIds"
          value={product.selectedMakerAttributes.map((option) => ({
            ...option,
            name: option.text,
          }))}
          as={CollectionSelect}
          options={product.makerAttributes.map((option) => ({
            ...option,
            name: option.text,
          }))}
        />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Maker Environments</BorderBoxHeading>
      <BorderBox>
        <Field
          name="environments"
          as={CollectionSelect}
          options={product.environmentsOptions}
        />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Capabilities</BorderBoxHeading>
      <BorderBox>
        <Field
          name="makerCapabilities"
          as={CollectionSelect}
          options={product.makerCapabilitiesOptions}
        />
      </BorderBox>
    </BorderBoxContainer>
  </>
)

const validateNumeric = (value: string) => {
  let error: string | undefined

  if (value && !/[0-9]/.test(value)) error = "Must be a number"

  return error
}

const MainPanel = ({ product }: ProductProps) => {
  const formik = useFormikContext()
  const [npaVariantTemplateId, setNpaVariantTemplateId] = useState<
    string | null
  >()

  const result = useCurrentUserQuery()
  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { currentUser } = result.data

  return (
    <>
      <BorderBoxContainer>
        <BorderBoxHeading>Product Description</BorderBoxHeading>
        <BorderBox>
          <Field name="description" as={TextAreaWithFormikErrors} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Product Information</BorderBoxHeading>
        <BorderBox>
          <table className="table table-borderless nice-table">
            <tbody>
              <tr>
                <td className="p-0">
                  <div className="form-group row">
                    <label className="col-auto col-form-label pr-2 text-uppercase text-nowrap">
                      Production Method:
                    </label>
                    <div className="col-auto">
                      <Field
                        name="productionMethod"
                        as={SelectWithFormikErrors}
                      >
                        <option value=""></option>
                        {product.productionMethodOptions.map((option) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </td>
                <td className="p-0">
                  <div className="form-group row">
                    <label className="col-auto col-form-label pr-2 text-uppercase text-nowrap">
                      HTS Code:
                    </label>
                    <div className="col-auto" style={{ marginTop: "-30px" }}>
                      <SearchSelect
                        options={product.htsCodeOptions.map((opt) => {
                          return { value: opt, label: opt }
                        })} // @ts-ignore: TODO
                        /* prettier-ignore */ values={{value: formik.values.htsCode, label: formik.values.htsCode }}
                        onChange={(option) => {
                          // @ts-ignore: TODO
                          formik.setFieldValue("htsCode", option.value)
                        }}
                        isMulti={false}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </td>
                <td className="p-0">
                  <div className="d-flex">
                    <label className="col-auto col-form-label pr-2 text-uppercase text-nowrap">
                      Fabric Type:
                    </label>
                    <div className="col-auto">
                      <Field name="fabricType" as={SelectWithFormikErrors}>
                        {product.fabricTypeOptions.map((option) => (
                          <option value={option} key={option}>
                            {option === "N/A" ? option : _.capitalize(option)}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="p-0 border-0">
                  <div className="form-group row">
                    <label className="col-auto col-form-label pr-2 text-uppercase text-nowrap">
                      Available Until:
                    </label>
                    <div className="col-auto">
                      <Field
                        name="availableUntil"
                        as={DateInputWithFormikErrors}
                      />
                    </div>
                  </div>
                </td>
                <td className="p-0 border-0">
                  <div className="form-group row">
                    <label className="col-auto col-form-label pr-2 text-uppercase text-nowrap">
                      Max Capacity:
                    </label>
                    <div className="col-auto">
                      <Field
                        name="maxCapacity"
                        as={InputWithFormikErrors}
                        validate={validateNumeric}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <BorderBoxHeading>Default Dimensions</BorderBoxHeading>

          <div className="d-flex w-100 justify-content-end">
            <UnitToggle />
          </div>

          <table className="table nice-table">
            <thead>
              <tr>
                <th>
                  <UnitLabel label="Weight" unitName="mass" />
                </th>
                <th>
                  <UnitLabel label="Height" unitName="length" />
                </th>
                <th>
                  <UnitLabel label="Width" unitName="length" />
                </th>
                <th>
                  <UnitLabel label="Length" unitName="length" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Field
                    name="dimension.weightKg"
                    as={UnitInputWithFormikErrors}
                    unitName="mass"
                    unit="kg"
                  />
                </td>
                <td>
                  <Field
                    name="dimension.heightCm"
                    as={UnitInputWithFormikErrors}
                    unitName="length"
                    unit="cm"
                  />
                </td>
                <td>
                  <Field
                    name="dimension.widthCm"
                    as={UnitInputWithFormikErrors}
                    unitName="length"
                    unit="cm"
                  />
                </td>
                <td>
                  <Field
                    name="dimension.lengthCm"
                    as={UnitInputWithFormikErrors}
                    unitName="length"
                    unit="cm"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Product Variants</BorderBoxHeading>
        <BorderBox>
          <div className="w-100">
            <FieldArray
              name="variants"
              render={(arrayHelpers) => (
                <div className="w-100">
                  <div className="d-flex w-100">
                    <div className="form-group row">
                      <label className="col-auto col-form-label pr-2 text-uppercase text-nowrap">
                        Price-Affecting Variant:
                      </label>
                      <div className="col-auto">
                        <Field
                          name="variantName"
                          disabled={product.id && currentUser?.role !== "admin"}
                          as={InputWithFormikErrors}
                        />
                      </div>
                    </div>

                    {!product.id || currentUser?.role === "admin" ? (
                      <button
                        type="button"
                        onClick={() => {
                          formik.setFieldValue("variantName", "")
                          for (const [
                            i,
                            variant,
                            // @ts-expect-error TODO(AM)
                          ] of formik.values.variants.entries()) {
                            if (variant.id) {
                              formik.setFieldValue(`variants.${i}.remove`, true)
                            } else {
                              arrayHelpers.remove(i)
                            }
                          }
                        }}
                        className="border-0 bg-transparent d-flex p-2 ml-auto"
                      >
                        <TrashSVG
                          style={{ width: 12, height: 14 }}
                          className="text-danger"
                        />
                        <VisuallyHidden>Remove Variant</VisuallyHidden>
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled
                        className="border-0 bg-transparent d-flex p-2 ml-auto"
                        data-toggle="tooltip"
                        title="Contact admin to remove variant from product"
                      >
                        <TrashSVG
                          style={{ width: 12, height: 14 }}
                          className="text-muted"
                        />
                        <VisuallyHidden>Remove Variant</VisuallyHidden>
                      </button>
                    )}
                  </div>

                  <BorderBoxContainer>
                    <div className="d-flex w-100 justify-content-start">
                      <BorderBoxHeading>Options:</BorderBoxHeading>
                      <button
                        type="button"
                        className="btn btn-sm btn-link f-action-text align-middle"
                        onClick={() =>
                          arrayHelpers.push({
                            dimension: {
                              weightKg: product.dimension?.weightKg,
                              heightCm: product.dimension?.heightCm,
                              widthCm: product.dimension?.widthCm,
                              lengthCm: product.dimension?.lengthCm,
                            },
                          })
                        }
                      >
                        <PlusCircleSVG height={13} />
                        <span className="pl-1">Add Option</span>
                      </button>
                    </div>
                    <BorderBox>
                      <div className="d-flex w-100 justify-content-end">
                        <UnitToggle />
                      </div>
                      <div className="table-responsive">
                        <table className="table nice-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>
                                <UnitLabel label="Weight" unitName="mass" />
                              </th>
                              <th>
                                <UnitLabel label="Height" unitName="length" />
                              </th>
                              <th>
                                <UnitLabel label="Width" unitName="length" />
                              </th>
                              <th>
                                <UnitLabel label="Length" unitName="length" />
                              </th>
                              <th>HTS Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              // @ts-expect-error TODO(AM)
                              formik.values.variants.map((variant, i) => {
                                if (variant.hasOwnProperty("remove"))
                                  return null

                                return (
                                  <tr key={i}>
                                    <td>
                                      {!variant.id ||
                                      currentUser?.role === "admin" ? (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (variant.id) {
                                              formik.setFieldValue(
                                                `variants.${i}.remove`,
                                                true,
                                              )
                                            } else {
                                              arrayHelpers.remove(i)
                                            }
                                          }}
                                          className="border-0 bg-transparent d-flex p-2"
                                        >
                                          <TrashSVG
                                            style={{ width: 12, height: 14 }}
                                            className="text-danger"
                                          />
                                          <VisuallyHidden>
                                            Remove Variant
                                          </VisuallyHidden>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          className="border-0 bg-transparent d-flex p-2"
                                          data-toggle="tooltip"
                                          title="Contact admin to remove variant from product"
                                        >
                                          <TrashSVG
                                            style={{ width: 12, height: 14 }}
                                            className="text-muted"
                                          />
                                          <VisuallyHidden>
                                            Remove Variant
                                          </VisuallyHidden>
                                        </button>
                                      )}
                                    </td>
                                    <td>
                                      <Field
                                        name={`variants.${i}.name`}
                                        disabled={
                                          variant.id &&
                                          currentUser?.role !== "admin"
                                        }
                                        as={InputWithFormikErrors}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`variants.${i}.dimension.weightKg`}
                                        as={UnitInputWithFormikErrors}
                                        unitName="mass"
                                        unit="kg"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`variants.${i}.dimension.heightCm`}
                                        as={UnitInputWithFormikErrors}
                                        unitName="length"
                                        unit="cm"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`variants.${i}.dimension.widthCm`}
                                        as={UnitInputWithFormikErrors}
                                        unitName="length"
                                        unit="cm"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`variants.${i}.dimension.lengthCm`}
                                        as={UnitInputWithFormikErrors}
                                        unitName="length"
                                        unit="cm"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`variants.${i}.htsCode`}
                                        as={SelectWithFormikErrors}
                                      >
                                        {product.htsCodeOptions.map(
                                          (option) => (
                                            <option value={option} key={option}>
                                              {option}
                                            </option>
                                          ),
                                        )}
                                      </Field>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </BorderBox>
                  </BorderBoxContainer>
                </div>
              )}
            />

            <FieldArray
              name="npaVariants"
              render={(arrayHelpers) => (
                <div className="w-100 mb-2">
                  <BorderBoxContainer>
                    <BorderBoxHeading>
                      Non-Price Affecting Variants
                    </BorderBoxHeading>
                    <div className="w-50 mb-4">
                      <Select
                        id="add-npa-variant-template"
                        className="mb-2"
                        onChange={(e) =>
                          setNpaVariantTemplateId(e.target.value)
                        }
                      >
                        <option>Add New</option>
                        {product.npaVariantTemplateOptions.map(
                          ({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ),
                        )}
                      </Select>
                      <Button
                        type="button"
                        onClick={() => {
                          const npaVariantTemplate = product.npaVariantTemplateOptions.find(
                            (template) => template.id === npaVariantTemplateId,
                          )

                          arrayHelpers.push({
                            npaVariantTemplateId: npaVariantTemplate?.id,
                            name: npaVariantTemplate?.name,
                            npaVariantOptions: npaVariantTemplate
                              ? npaVariantTemplate.npaVariantTemplateOptions.map(
                                  (option, i) => ({
                                    name: option.name,
                                    position: i,
                                  }),
                                )
                              : [
                                  { position: 0 },
                                  { position: 1 },
                                  { position: 3 },
                                ],
                          })

                          setNpaVariantTemplateId(null)
                        }}
                        variant="primary"
                      >
                        Add Non-Price-Affecting Variant
                      </Button>
                    </div>
                    {/* @ts-expect-error TODO(AM) */}
                    {formik.values.npaVariants.length > 0 ? (
                      <BorderBox>
                        {/* @ts-expect-error TODO(AM) */}
                        {formik.values.npaVariants.map(
                          (npaVariant: NpaVariant, i: number) => {
                            if (npaVariant.hasOwnProperty("remove")) return null

                            const namePrefix = `npaVariants.${i}`

                            return (
                              <div className="w-100" key={i}>
                                <div className="d-flex w-100">
                                  <Label htmlFor={`npaVariants${i}name`}>
                                    Name:
                                  </Label>
                                  {!npaVariant.id ||
                                  currentUser?.role === "admin" ? (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (npaVariant.id) {
                                          formik.setFieldValue(
                                            `${namePrefix}.remove`,
                                            true,
                                          )
                                        } else {
                                          arrayHelpers.remove(i)
                                        }
                                      }}
                                      className="border-0 bg-transparent d-flex p-2 ml-auto text-danger"
                                    >
                                      Delete
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="border-0 bg-transparent d-flex p-2 ml-auto text-muted"
                                      data-toggle="tooltip"
                                      title="Contact admin to remove variant from product"
                                    >
                                      Delete
                                    </button>
                                  )}
                                </div>
                                <Field
                                  name={`${namePrefix}.name`}
                                  id={`npaVariants${i}name`}
                                  disabled={
                                    npaVariant.id &&
                                    currentUser?.role !== "admin"
                                  }
                                  className="mb-4"
                                  as={InputWithFormikErrors}
                                />
                                <FieldArray
                                  name={`npaVariants.${i}.npaVariantOptions`}
                                  render={(arrayHelpers) => (
                                    <>
                                      <div className="d-flex w-100">
                                        <Label>Options</Label>
                                        <button
                                          type="button"
                                          className="ml-auto btn btn-sm btn-link f-action-text align-middle"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              position:
                                                npaVariant.npaVariantOptions
                                                  .length,
                                            })
                                          }
                                        >
                                          <PlusCircleSVG height={13} />
                                          <span className="pl-1">
                                            Add Option
                                          </span>
                                        </button>
                                      </div>
                                      <div className="table-responsive">
                                        <table className="table nice-table">
                                          <thead key={`${i}-thead`}>
                                            <tr>
                                              <th></th>
                                              <th className="text-muted text-center font-weight-normal">
                                                Client Facing Name
                                              </th>
                                              <th className="text-muted text-center font-weight-normal">
                                                Maker Facing Name
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody key={`${i}-tbody`}>
                                            {npaVariant.npaVariantOptions
                                              .sort(
                                                (a, b) =>
                                                  (a.position || 0) -
                                                  (b.position || 0),
                                              )
                                              .map((npaVariantOption, i) => {
                                                if (
                                                  npaVariantOption.hasOwnProperty(
                                                    "remove",
                                                  )
                                                )
                                                  return null
                                                return (
                                                  <tr key={i}>
                                                    <td>
                                                      {!npaVariantOption.id ||
                                                      currentUser?.role ===
                                                        "admin" ? (
                                                        <button
                                                          type="button"
                                                          onClick={() => {
                                                            if (
                                                              npaVariantOption.id
                                                            ) {
                                                              formik.setFieldValue(
                                                                `${namePrefix}.npaVariantOptions.${i}.remove`,
                                                                true,
                                                              )
                                                            } else {
                                                              arrayHelpers.remove(
                                                                i,
                                                              )
                                                            }
                                                          }}
                                                          className="border-0 bg-transparent d-flex p-2"
                                                        >
                                                          <TrashSVG
                                                            style={{
                                                              width: 12,
                                                              height: 14,
                                                            }}
                                                            className="text-danger"
                                                          />
                                                          <VisuallyHidden>
                                                            Remove Option
                                                          </VisuallyHidden>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          disabled
                                                          className="border-0 bg-transparent d-flex p-2"
                                                          data-toggle="tooltip"
                                                          title="Contact admin to remove variant from product"
                                                        >
                                                          <TrashSVG
                                                            style={{
                                                              width: 12,
                                                              height: 14,
                                                            }}
                                                            className="text-muted"
                                                          />
                                                          <VisuallyHidden>
                                                            Remove Option
                                                          </VisuallyHidden>
                                                        </button>
                                                      )}
                                                    </td>
                                                    <td>
                                                      <Field
                                                        name={`${namePrefix}.npaVariantOptions.${i}.name`}
                                                        disabled={
                                                          npaVariantOption.id &&
                                                          currentUser?.role !==
                                                            "admin"
                                                        }
                                                        as={
                                                          InputWithFormikErrors
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Field
                                                        name={`${namePrefix}.npaVariantOptions.${i}.makerName`}
                                                        disabled={
                                                          npaVariantOption.id &&
                                                          currentUser?.role !==
                                                            "admin"
                                                        }
                                                        as={
                                                          InputWithFormikErrors
                                                        }
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            )
                          },
                        )}
                      </BorderBox>
                    ) : (
                      <BorderBoxEmpty>
                        There are no Non-Price Affecting Variants.
                      </BorderBoxEmpty>
                    )}
                  </BorderBoxContainer>
                </div>
              )}
            />
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex">
          <BorderBoxHeading>Product Categories</BorderBoxHeading>
          <a className="ml-auto" href={product.newProductCategoryUrl}>
            Add
          </a>
        </div>
        {product.productCategories.length > 0 ? (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Subcategories</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {product.productProductCategories.nodes.map(
                  ({ productSubcategories, productCategory, ...ppc }) => (
                    <tr key={ppc.id}>
                      <td>{productCategory.name}</td>
                      <td>
                        {productSubcategories.nodes.length > 0 ? (
                          <div className="d-flex flex-wrap flex-grow-1">
                            {productSubcategories.nodes.map(({ name }) => (
                              <div
                                className="bg-pink rounded p-2 mr-2 mb-2"
                                key={name}
                              >
                                {name}
                              </div>
                            ))}
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <EditLink {...ppc} />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>
            No Product Categories have been added yet.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex">
          <BorderBoxHeading>Materials</BorderBoxHeading>
          <a className="ml-auto" href={product.newProductMaterialUrl}>
            Add
          </a>
        </div>

        {product.productMaterials.nodes.length > 0 ? (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Section</th>
                  <th>Sub-Section</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {product.productMaterials.nodes.map((pm) => (
                  <tr key={pm.id}>
                    <td>{pm.material.name}</td>
                    <td>
                      <div className="d-flex flex-wrap">
                        {pm.materialSubcategories.nodes.map((subcat) => (
                          <BorderBoxItem key={subcat.id}>
                            {subcat.name}
                          </BorderBoxItem>
                        ))}
                      </div>
                    </td>
                    <td>
                      <EditLink {...pm} variant="full" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>No Materials have been added yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Internal Notes</BorderBoxHeading>
        <BorderBox>
          <Field name="internalNotes" as={TextAreaWithFormikErrors} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Images</BorderBoxHeading>
        <BorderBox>
          {product.images.length > 0 && product.infoImages.length === 0 && (
            <div className="alert alert-primary w-100">
              Product Group image will be used if no product image is uploaded
            </div>
          )}
          <ProductImages images={product.infoImages} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Tech Pack</BorderBoxHeading>
        <BorderBox>
          <ProductTechPack techPack={product.techPack} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Style Number</BorderBoxHeading>
        <BorderBox>
          <div className="w-50">
            <Field name="styleNumber" as={InputWithFormikErrors} />
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Product Alias</BorderBoxHeading>
        <BorderBox>
          <div className="w-100">
            <div className="d-flex">
              <BorderBoxHeading>Name Aliases</BorderBoxHeading>
              <a className="ml-auto" href={product.newProductNameAliasUrl}>
                Add
              </a>
            </div>

            {product.nameAliases.length > 0 ? (
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {product.nameAliases.map((nameAlias) => (
                    <tr key={nameAlias.id}>
                      <td>{nameAlias.name}</td>
                      <td>{nameAlias.createdAt}</td>
                      <td>
                        <TableRowActions {...nameAlias} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-muted mb-4">
                No Name Aliases have been added yet.
              </div>
            )}

            <div className="d-flex">
              <BorderBoxHeading>Style Number Aliases</BorderBoxHeading>
              <a className="ml-auto" href={product.newProductSkuAliasUrl}>
                Add
              </a>
            </div>

            {product.skuAliases.length > 0 ? (
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {product.skuAliases.map((skuAlias) => (
                    <tr key={skuAlias.id}>
                      <td>{skuAlias.sku}</td>
                      <td>{skuAlias.createdAt}</td>
                      <td>
                        <TableRowActions {...skuAlias} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-muted mb-4">
                No Style Number Aliases have been added yet.
              </div>
            )}
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const CompliancePanel = (props: { id: string }) => {
  const result = useInfiniteGraphQLQuery(
    useProductProfileTestingAndComplianceQuery,
    ({ pageParam }) => ({
      id: props.id,
      first: 15,
      after: pageParam,
    }),
    {
      getNextPageParam: (page) =>
        page.product.productTests.pageInfo.endCursor ?? undefined,
    },
  )

  if (result.status === "loading") return <LoadingIndicator />
  if (result.status === "error") return <RequestError {...result} />

  invariant(result.data, `expected data`)

  const productTests = result.data.pages.flatMap(
    (page) => page.product.productTests.nodes,
  )

  const { newProductTestUrl } = result.data.pages[0].product

  // TODO(AM): Figure out why result.hasNextPage isn't producing the same result
  const hasNextPage =
    result.data.pages[result.data.pages.length - 1].product.productTests
      .pageInfo.hasNextPage

  return (
    <BorderBoxContainer>
      <BorderBoxHeading>Testing And Compliance</BorderBoxHeading>
      <BorderBox>
        <>
          <table className="table nice-table">
            <thead>
              <tr>
                <th>Date of Completion</th>
                <th>Name</th>
                <th>Result</th>
                <th>Notes</th>
                <th>Document</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="f-small-text">
              {productTests.map((productTest) => (
                <tr key={productTest.id}>
                  <td>{formatDate(productTest.dateOfCompletion)}</td>
                  <td>{productTest.name || <EmptyValue variant="dash" />}</td>
                  <td>{productTest.result}</td>
                  <td>
                    {productTest.description || <EmptyValue variant="dash" />}
                  </td>
                  <td>
                    {productTest.document ? (
                      <a
                        className="d-flex align-items-center text-dark-pink"
                        href={productTest.document.url}
                      >
                        <DownloadSVG style={{ height: 15 }} />
                        <VisuallyHidden>Download document</VisuallyHidden>
                      </a>
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </td>
                  <td>
                    <a
                      href={productTest.editUrl}
                      className="d-flex text-dark-pink"
                    >
                      <PencilSVG style={{ height: 14 }} />
                      <VisuallyHidden>Edit product test</VisuallyHidden>
                    </a>
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan={6} className="text-center">
                  <a href={newProductTestUrl} className="text-dark-pink">
                    {/* TODO(AM): add plus svg */}
                    <div className="small pl-1">Add</div>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          {hasNextPage && (
            <div className="pv-3 d-flex flex-grow-1 justify-content-center">
              <button
                onClick={() => result.fetchNextPage()}
                type="button"
                className="btn btn-link f-action-text"
                disabled={result.isFetchingNextPage}
              >
                {result.isLoading ? (
                  "Loading..."
                ) : (
                  <>
                    View More <DownChevronSVG className="ml-1" />
                  </>
                )}
              </button>
            </div>
          )}
        </>
      </BorderBox>
    </BorderBoxContainer>
  )
}

const ImpactPanel = ({ product }: ProductProps) => {
  const formik = useFormikContext()

  const {
    socialImpactMetricsEnabled,
    environmentalImpactMetricsEnabled,
    equivalencesEnabled,
    carbonOffsetMetricsEnabled,
  }: any = formik.values

  return (
    <>
      <BorderBoxContainer>
        <BorderBoxHeading>Product Attributes</BorderBoxHeading>
        <BorderBox>
          <Field
            name="productAttributeIds"
            value={product.productAttributes}
            as={CollectionSelect}
            options={product.productAttributeOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex justify-content-between">
          <BorderBoxHeading>Social Impact Metrics</BorderBoxHeading>
          <Field
            name="socialImpactMetricsEnabled"
            as={CheckboxWithFormikErrors}
            label="Metrics Available for this Product"
          />
        </div>
        <BorderBox>
          <div
            className={clsx(
              "d-flex w-100 align-items-top",
              !socialImpactMetricsEnabled && "text-muted",
            )}
          >
            <div className="text-uppercase pt-2" style={{ minWidth: "180px" }}>
              Fair Wage Work:
            </div>
            <div className="d-flex align-items-top">
              <div>
                <Field
                  name="fairWagePerHour"
                  as={InputWithFormikErrors}
                  validate={validateNumeric}
                  disabled={!socialImpactMetricsEnabled}
                />
              </div>
              <p className="pt-2 pl-2 text-nowrap">Hours</p>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex justify-content-between">
          <BorderBoxHeading>Environmental Impact Metrics</BorderBoxHeading>
          <Field
            name="environmentalImpactMetricsEnabled"
            as={CheckboxWithFormikErrors}
            label="Metrics Available for this Product"
          />
        </div>
        <BorderBox>
          <div
            className={clsx(!environmentalImpactMetricsEnabled && "text-muted")}
          >
            <div className="d-flex w-100 mb-3 align-items-top">
              <div
                className="text-uppercase pt-2"
                style={{ minWidth: "180px" }}
              >
                Energy:
              </div>
              <div className="d-flex align-items-top">
                <div>
                  <Field
                    name="energyConservedKwh"
                    as={InputWithFormikErrors}
                    validate={validateNumeric}
                    disabled={!environmentalImpactMetricsEnabled}
                  />
                </div>
                <p className="pt-2 pl-2 text-nowrap">kWh of Energy Conserved</p>
              </div>
            </div>
            <div className="d-flex w-100 mb-3 align-items-top">
              <div
                className="text-uppercase pt-2"
                style={{ minWidth: "180px" }}
              >
                Water:
              </div>
              <div className="d-flex align-items-top">
                <div>
                  <Field
                    name="waterSavedLiters"
                    as={InputWithFormikErrors}
                    validate={validateNumeric}
                    disabled={!environmentalImpactMetricsEnabled}
                  />
                </div>
                <p className="pt-2 pl-2 text-nowrap">L of Water Saved</p>
              </div>
            </div>
            <div className="d-flex w-100 mb-3 align-items-top">
              <div
                className="text-uppercase pt-2"
                style={{ minWidth: "180px" }}
              >
                Emissions Avoided:
              </div>
              <div className="d-flex align-items-top">
                <div>
                  <Field
                    name="emissionsAvoidedKg"
                    as={InputWithFormikErrors}
                    validate={validateNumeric}
                    disabled={!environmentalImpactMetricsEnabled}
                  />
                </div>
                <p className="pt-2 pl-2 text-nowrap">
                  kgCO<sub>2</sub>e of CO<sub>2</sub> Avoided
                </p>
              </div>
            </div>
            <div className="d-flex w-100 mb-3 align-items-top">
              <div
                className="text-uppercase pt-2"
                style={{ minWidth: "180px" }}
              >
                Pesticides Avoided:
              </div>
              <div className="d-flex align-items-top">
                <div>
                  <Field
                    name="pesticidesAvoidedG"
                    as={InputWithFormikErrors}
                    validate={validateNumeric}
                    disabled={!environmentalImpactMetricsEnabled}
                  />
                </div>
                <p className="pt-2 pl-2 text-nowrap">g of Pesticides Avoided</p>
              </div>
            </div>
            <div className="d-flex w-100 mb-3 align-items-top">
              <div
                className="text-uppercase pt-2"
                style={{ minWidth: "180px" }}
              >
                Waste Diverted:
              </div>
              <div className="d-flex align-items-top">
                <div>
                  <Field
                    name="wasteDivertedG"
                    as={InputWithFormikErrors}
                    validate={validateNumeric}
                    disabled={!environmentalImpactMetricsEnabled}
                  />
                </div>
                <p className="pt-2 pl-2 text-nowrap">g of Waste Diverted</p>
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex justify-content-between">
          <BorderBoxHeading>Impact Equivalences</BorderBoxHeading>
          <Field
            name="equivalencesEnabled"
            as={CheckboxWithFormikErrors}
            label="Equivalences Available for this Product"
          />
        </div>
        <BorderBox>
          <div className={clsx("w-100", !equivalencesEnabled && "text-muted")}>
            <div className="d-flex">
              <div className="col-6">
                <div className="d-flex align-items-center mb-2">
                  <Lightbulb />
                  <div className="ml-2">
                    <Field
                      style={{ width: 80 }}
                      name="bulbEnergySavedHours"
                      as={InputWithFormikErrors}
                      validate={validateNumeric}
                      disabled={!equivalencesEnabled}
                    />
                  </div>
                  <p className="pt-2 pl-2 text-nowrap">
                    hours of bulb energy saved
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <DrivingEmissons />
                  <div className="ml-2">
                    <Field
                      style={{ width: 80 }}
                      name="drivingEmissionsAvoidedKm"
                      as={InputWithFormikErrors}
                      validate={validateNumeric}
                      disabled={!equivalencesEnabled}
                    />
                  </div>
                  <p className="pt-2 pl-2 text-nowrap">
                    km of driving emissions avoided
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <Recycled />
                  <div className="ml-2">
                    <Field
                      style={{ width: 80 }}
                      name="recycledWaterBottles"
                      as={InputWithFormikErrors}
                      validate={validateNumeric}
                      disabled={!equivalencesEnabled}
                    />
                  </div>
                  <p className="pt-2 pl-2 text-nowrap">
                    water bottles recycled
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center mb-2">
                  <DrinkingWater />
                  <div className="ml-2">
                    <Field
                      style={{ width: 80 }}
                      name="drinkingWaterSavedDays"
                      as={InputWithFormikErrors}
                      validate={validateNumeric}
                      disabled={!equivalencesEnabled}
                    />
                  </div>
                  <p className="pt-2 pl-2 text-nowrap">
                    days of drinking water saved
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <Pesticides />
                  <div className="ml-2">
                    <Field
                      style={{ width: 80 }}
                      name="landSavedFromPesticidesM2"
                      as={InputWithFormikErrors}
                      validate={validateNumeric}
                      disabled={!equivalencesEnabled}
                    />
                  </div>
                  <p className="pt-2 pl-2 text-nowrap">
                    m² of land saved from pesticides
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex justify-content-between">
          <BorderBoxHeading>Carbon Offsetting</BorderBoxHeading>
          <Field
            name="carbonOffsetMetricsEnabled"
            as={CheckboxWithFormikErrors}
            label="Metrics Available for this Product"
          />
        </div>
        <BorderBox>
          <div className={clsx(!carbonOffsetMetricsEnabled && "text-muted")}>
            <div className="d-flex w-100 mb-3 align-items-top">
              <div
                className="text-uppercase pt-2"
                style={{ minWidth: "180px" }}
              >
                Offsetting:
              </div>
              <div className="d-flex align-items-top">
                <div>
                  <Field
                    name="carbonOffsetKg"
                    as={InputWithFormikErrors}
                    validate={validateNumeric}
                    disabled={!carbonOffsetMetricsEnabled}
                  />
                </div>
                <p className="pt-2 pl-2 text-nowrap">
                  kgCO<sub>2</sub>e
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-around w-100">
              <div className="p-4 text-center">
                <CarSVG />
                <Field
                  className="mt-2"
                  name="carbonOffsetAsKmOfDriving"
                  as={InputWithFormikErrors}
                  validate={validateNumeric}
                  disabled={!carbonOffsetMetricsEnabled}
                />
                Km of Car Driving
              </div>
              <div className="p-4 text-center">
                <AirplaneSVG />
                <Field
                  className="mt-2"
                  name="carbonOffsetAsTransatlanticFlights"
                  as={InputWithFormikErrors}
                  validate={validateNumeric}
                  disabled={!carbonOffsetMetricsEnabled}
                />
                Transatlantic Flights
              </div>
              <div className="p-4 text-center">
                <SmartphoneSVG />
                <Field
                  className="mt-2"
                  name="carbonOffsetAsPhonesCharged"
                  as={InputWithFormikErrors}
                  validate={validateNumeric}
                  disabled={!carbonOffsetMetricsEnabled}
                />
                Smart Phones Charged
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

export default withBasics(ProductEdit)
