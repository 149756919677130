import React from "react"
import { ModeOfTransit } from "../generated/graphql"

const labelMap: Record<ModeOfTransit, string> = {
  AIR: "Air",
  LAND: "Land",
  SEA: "Sea",
}

const ModeOfTransitLabel = ({ value }: { value: ModeOfTransit }) => {
  const label = labelMap[value]

  return <>{label}</>
}

export { ModeOfTransitLabel }
