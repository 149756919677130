// @ts-nocheck
import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    const hideOrShowCountries = (exportExperience) => {
      if (exportExperience === "Actively Exporting") {
        $(".maker_exporting_countries").show()
        $("#maker_exporting_country_ids").select2({
          tags: true,
        })
      } else {
        $(".maker_exporting_countries").hide()
      }
    }

    hideOrShowCountries($("#maker_export_experience").val())

    $("#maker_export_experience").on("change", (event) => {
      if (event.target instanceof HTMLSelectElement) {
        hideOrShowCountries(event.target.value)
      } else {
        throw new Error("whoops! unexpected html element")
      }
    })
  }
}
