import React, { useState, useEffect } from "react"
import find from "lodash/find"
import {
  useClientOrderSearchQuery,
  ClientOrderSort,
  ClientOrderSortField,
  SortOrder,
} from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { useDebounce } from "use-debounce"
import ClientOrderListResults from "./ClientOrderListResults"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import { FiltersDisclosureButton, SearchInput } from "./SearchElements"
import { Disclosure, DisclosurePanel } from "@reach/disclosure"
import useParams from "../hooks/useParams"
import ClientOrderSearchFilters, {
  computeDefaultFilters,
  ClientOrderFilters,
} from "./ClientOrderSearchFilters"

export const CREATED_AT_SORT_OPTION: ClientOrderSort = {
  attribute: ClientOrderSortField.CreatedAt,
  order: SortOrder.Desc,
}
export const DELIVERY_WINDOW_SORT_OPTION: ClientOrderSort = {
  attribute: ClientOrderSortField.DeliveryWindow,
  order: SortOrder.Desc,
}
const DEFAULT_SORT_OPTIONS: ClientOrderSort[] = [CREATED_AT_SORT_OPTION]

const ClientOrdersListScreen = (props: { showNewOrderButton: boolean }) => {
  const [params, setParams] = useParams()
  // @ts-expect-error todo
  const [query, setQuery] = useState(params.q || "")
  const [tracker, setTracker] = useState("all")
  const [searchQuery] = useDebounce(query, 300)
  const [filters, setFilters] = useState<ClientOrderFilters>(
    computeDefaultFilters(tracker),
  )

  const [sorts, setSorts] = useState(DEFAULT_SORT_OPTIONS)
  const toggleColumnSort = (sort: ClientOrderSort) => {
    let existingSort: ClientOrderSort | undefined = find(sorts, {
      attribute: sort.attribute,
    })
    if (existingSort) {
      if (existingSort.order === SortOrder.Asc) {
        existingSort.order = SortOrder.Desc
      } else if (existingSort.order === SortOrder.Desc) {
        existingSort.order = SortOrder.Asc
      }

      setSorts([existingSort])
    } else {
      setSorts([sort])
    }
  }

  useEffect(() => {
    setFilters(computeDefaultFilters(tracker))
  }, [tracker])

  const result = useInfiniteGraphQLQuery(
    useClientOrderSearchQuery,
    ({ pageParam }) => ({
      tracker: tracker,
      query: searchQuery,
      after: pageParam,
      sorts,
      ...filters,
    }),
    {
      getNextPageParam: (page) =>
        page.clientOrders.pageInfo.endCursor ?? undefined,
    },
  )

  return (
    <>
      <div className="d-flex align-items-center mt-6">
        <div className="d-flex mr-auto">
          <h1 className="my-0 mr-3 f-title mb-5">Client Projects</h1>
          {result.isFetching && <LoadingIndicator />}
        </div>

        {props.showNewOrderButton && (
          <div>
            <a
              className="btn btn-dark"
              href="/client-projects/new"
              data-testid="new-client-order"
            >
              New Project
            </a>
          </div>
        )}
      </div>

      <div className="tracker-tabs-wrap d-flex">
        <div
          onClick={() => setTracker("all")}
          className={`tracker-tab ${tracker === "all" ? "active" : ""}`}
        >
          All
        </div>
        <div
          onClick={() => setTracker("production")}
          className={`tracker-tab ${tracker === "production" ? "active" : ""}`}
        >
          Production Tracker
        </div>
        <div
          onClick={() => setTracker("shipping")}
          className={`tracker-tab ${tracker === "shipping" ? "active" : ""}`}
        >
          Shipping Tracker
        </div>
      </div>

      <Disclosure>
        <div className="mb-4 d-flex">
          <SearchInput
            onChange={(e) => {
              setQuery(e.currentTarget.value)
              // @ts-expect-error todo
              setParams({ q: e.currentTarget.value }, { replace: true })
            }}
            value={query}
            placeholder="Search by Order Number or Description"
          />
          <FiltersDisclosureButton />
        </div>

        <DisclosurePanel>
          <div className="border rounded border-pink position-relative">
            <div className="p-3">
              <ClientOrderSearchFilters
                tracker={tracker}
                setFilters={setFilters}
                filters={filters}
              />
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <ClientOrderListResults
        tracker={tracker}
        result={result}
        sorts={sorts}
        onColumnSortClick={toggleColumnSort}
      />

      {result.hasNextPage && (
        <button
          onClick={() => result.fetchNextPage()}
          type="button"
          className="btn btn-primary mb-3 mt-3"
          disabled={!!result.isFetchingNextPage}
        >
          {result.isLoading ? "Loading..." : "Load more"}
        </button>
      )}
    </>
  )
}

export default withBasics(ClientOrdersListScreen)
