import React, { useState, useEffect, ChangeEvent } from "react"
import { withBasics } from "./withBasics"
import {
  TimeAndActionCalendarTaskInput,
  UpdateTimeAndActionCalendarTemplateInput,
  UpdateTimeAndActionCalendarTemplateMutation,
  useTimeAndActionCalendarTemplateEditQuery,
  useUpdateTimeAndActionCalendarTemplateMutation,
} from "../generated/graphql"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import invariant from "tiny-invariant"
import { BorderBox, BorderBoxContainer, BorderBoxHeading } from "./BorderBox"
import TimeAndActionCalendarTasks from "./TimeAndActionCalendarTasksEdit"

const TimeAndActionTemplateEdit = (props: { id: string }) => {
  const result = useTimeAndActionCalendarTemplateEditQuery({ id: props.id })
  const mutation = useUpdateTimeAndActionCalendarTemplateMutation()

  const initialName = result?.data?.timeAndActionCalendarTemplate?.name || ""
  const [name, setName] = useState<string>(initialName)

  useEffect(() => {
    if (result.data) {
      setName(result.data.timeAndActionCalendarTemplate.name)
    }
  }, [result.data])

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const template = result.data.timeAndActionCalendarTemplate

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const submit = async (tasks: TimeAndActionCalendarTaskInput[]) => {
    const input: UpdateTimeAndActionCalendarTemplateInput = {
      id: template.id,
      name: name,
      tasks: tasks.map(
        (task): TimeAndActionCalendarTaskInput => {
          return {
            ...task,
          }
        },
      ),
    }

    try {
      const {
        updateTimeAndActionCalendarTemplate: result,
      }: UpdateTimeAndActionCalendarTemplateMutation = await mutation.mutateAsync(
        {
          input,
        },
      )

      if (result) {
        if (result.errors.length) {
          // @ts-ignore: TODO
          // for (let error of result.errors)
          //   if (error.path) setFieldError(error.path[1], error.message)
        } else {
          window.location.replace(window.location.pathname)
        }
      }
    } catch (err) {
      const message = (() => {
        const { message } = err as Error
        return `An error occured: ${message}`
      })()
      window.alert(message)
    }
  }

  return (
    <div className="d-flex flex-column">
      <BorderBoxContainer>
        <div className="d-flex align-items-baseline w-100">
          <BorderBoxHeading>Calendar</BorderBoxHeading>
          <RequestError {...mutation} />
        </div>

        <BorderBox>
          <label className={"form-label font-weight-bold"}>Template Name</label>
          <input
            type="text"
            value={name}
            className={"form-control"}
            onChange={handleNameChange}
          />

          <TimeAndActionCalendarTasks
            tasks={template.tasks.nodes}
            template={true}
            submit={submit}
          />
        </BorderBox>
      </BorderBoxContainer>
    </div>
  )
}

export default withBasics(TimeAndActionTemplateEdit)
