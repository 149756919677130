import React from "react"
import GenderEqualitySVG from "../images/impact-report-gender-equality.png"
import DecentWorkSVG from "../images/impact-report-decent-work.png"
import ResponsibleConsumptionSVG from "../images/impact-report-responsible-consumption.png"
import { withBasics } from "./withBasics"

const ImpactReportOverviewImages = () => (
  <div
    className="d-flex w-100 justify-content-around pb-3"
    style={{ maxWidth: "500px", margin: "0 auto" }}
  >
    <img src={GenderEqualitySVG} width={90} height={90} alt="" />
    <img src={DecentWorkSVG} width={90} height={90} alt="" />
    <img src={ResponsibleConsumptionSVG} width={90} height={90} alt="" />
  </div>
)

export default withBasics(ImpactReportOverviewImages)
