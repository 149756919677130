import React, { useState } from "react"
import Toggle from "react-toggle"
import "react-toggle/style.css"
import { ModeOfTransit, ShippingPortLocation } from "../generated/graphql"
import CogsTableShippingRateDeprecated from "./CogsTableShippingRateDeprecated"
import ModeOfTransitSelect from "./ModeOfTransitSelect"
import ShippingPortSelect from "./ShippingPortSelect"
import { withBasics } from "./withBasics"

/**
 * this file is intendeded to be deleted as soon as the legacy
 * product view is no longer available
 */
const CogsTableSettingsDeprecated = ({
  productId,
  productGroupId,
  makerId,
  defaultAirShippingPortId,
  defaultSeaShippingPortId,
  otherAirShippingPortId,
  otherSeaShippingPortId,
}) => {
  const defaultExportPortId =
    defaultAirShippingPortId ||
    defaultSeaShippingPortId ||
    otherAirShippingPortId ||
    otherSeaShippingPortId
  const [exportPortId, setExportPortId] = useState(defaultExportPortId)
  const defaultModeOfTransit = [
    defaultAirShippingPortId,
    otherAirShippingPortId,
  ].includes(exportPortId)
    ? ModeOfTransit.Air
    : ModeOfTransit.Sea
  const [modeOfTransit, setModeOfTransit] = useState(defaultModeOfTransit)
  const [importPortId, setImportPortId] = useState(null)
  const onExportShippingPortChange = (option) => {
    if (option) {
      const { value } = option
      setExportPortId(value)
    } else {
      setExportPortId(null)
    }
  }
  const onImportShippingPortChange = (option) => {
    if (option) {
      const { value } = option
      setImportPortId(value)
    } else {
      setImportPortId(null)
    }
  }
  const [includeShippingCost, setIncludeShippingCost] = useState(false)
  const [includeDutyRate, setIncludeDutyRate] = useState(false)
  const onIncludeShippingCostChange = () =>
    setIncludeShippingCost(!includeShippingCost)
  const onIncludeDutyRateChange = () => setIncludeDutyRate(!includeDutyRate)
  const onModeOfTansitChange = ({ value }) => {
    setModeOfTransit(value)
    setImportPortId(null)

    if (value === ModeOfTransit.Air) {
      setExportPortId(defaultAirShippingPortId || otherAirShippingPortId)
    } else {
      setExportPortId(defaultSeaShippingPortId || otherSeaShippingPortId)
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <label htmlFor="exportShippingPort">Mode of Transit</label>
          <ModeOfTransitSelect
            value={modeOfTransit}
            onChange={onModeOfTansitChange}
          />
        </div>
        <div className="col">
          <label htmlFor="exportShippingPort">Export Shipping Port</label>
          <ShippingPortSelect
            makerId={makerId}
            locationType={ShippingPortLocation.Export}
            modeOfTransit={modeOfTransit}
            onChange={onExportShippingPortChange}
            value={exportPortId}
          />
        </div>
        <div className="col">
          <label htmlFor="importShippingPort">Import Shipping Port</label>
          <ShippingPortSelect
            value={importPortId}
            locationType={ShippingPortLocation.Import}
            modeOfTransit={modeOfTransit}
            onChange={onImportShippingPortChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="includeShippingCost">Include Shipping Cost</label>
          <Toggle
            id="shipping-rate-toggle"
            className="ml-2"
            checked={includeShippingCost}
            onChange={onIncludeShippingCostChange}
            disabled={importPortId === null || exportPortId === null}
          />
        </div>
        <div className="col">
          <label htmlFor="includeDutyRate">Include Duties</label>
          <Toggle
            id="duty-rate-toggle"
            className="ml-2"
            checked={includeDutyRate}
            onChange={onIncludeDutyRateChange}
            disabled={importPortId === null || exportPortId === null}
          />
        </div>
      </div>

      <CogsTableShippingRateDeprecated
        exportPortId={exportPortId}
        importPortId={importPortId}
        includeShippingCost={includeShippingCost}
        includeDutyRate={includeDutyRate}
        productId={productId}
        productGroupId={productGroupId}
      />
    </div>
  )
}

export default withBasics(CogsTableSettingsDeprecated)
