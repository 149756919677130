import React from "react"
import Checkbox from "./Checkbox"
import { withBasics } from "./withBasics"

const ImpactReportFilters = () => {
  const urlParams = new URLSearchParams(window.location.search)
  // @ts-expect-error
  const types = urlParams.get("types") ? urlParams.get("types").split(",") : []

  const handleChange = (type: string, checked: boolean) => {
    const newTypes = checked
      ? [...types, type]
      : types.filter((t) => t !== type)

    if (!newTypes.length) {
      urlParams.delete("types")
    } else {
      urlParams.set("types", newTypes.join(","))
    }

    window.location.search = urlParams.toString()
  }

  return (
    <div className="mb-2">
      <Checkbox
        label="Single"
        id="single"
        checked={types.includes("single")}
        // @ts-expect-error
        onClick={(e) => handleChange("single", e.target.checked)}
      />
      <Checkbox
        label="Yearly"
        id="yearly"
        checked={types.includes("yearly")}
        // @ts-expect-error
        onClick={(e) => handleChange("yearly", e.target.checked)}
      />
      <Checkbox
        label="All-Time Basis"
        id="all_time_basis"
        checked={types.includes("all_time_basis")}
        // @ts-expect-error
        onClick={(e) => handleChange("all_time_basis", e.target.checked)}
      />
    </div>
  )
}

export default withBasics(ImpactReportFilters)
