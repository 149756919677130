import React from "react"
import * as Schema from "../generated/graphql"

type Region = Pick<Schema.Region, "id" | "name" | "__typename">
type Country = Pick<Schema.Country, "id" | "name" | "__typename">

export interface CountriesTableProps {
  countries: Array<Pick<Schema.Country, "id" | "name"> & { region: Region }>
}

const CountriesTable = ({ countries }: CountriesTableProps) => {
  const list: Array<{
    region: Pick<Region, "id" | "name" | "__typename">
    countries: Array<Pick<Country, "id" | "name" | "__typename">>
  }> = []

  for (let country of countries) {
    const regionIndex = list.findIndex((x) => x.region.id === country.region.id)
    if (regionIndex === -1) {
      list.push({ region: country.region, countries: [country] })
    } else {
      list[regionIndex].countries.push(country)
    }
  }
  list.sort((a, b) => a.region.name.localeCompare(b.region.name))

  return (
    <table>
      <tbody>
        {list.map(({ region, countries }) => (
          <tr key={region.id}>
            <td className="font-weight-bold pr-4">{region.name}</td>
            {countries.map((country) => (
              <td key={country.id} className="pr-4">
                {country.name}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CountriesTable
