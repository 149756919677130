import compact from "lodash/compact"
import union from "lodash/union"
import without from "lodash/without"
import React, { useEffect } from "react"
import {
  Certification,
  Country,
  ProductCategory,
  useMakerSearchFilterOptionsQuery,
  MakerAttribute,
} from "../generated/graphql"
import { isNotNull } from "../util/filters"
import Checkbox from "./Checkbox"
import { RequestError } from "./Errors"
import SearchSelect from "./SearchSelect"
import SelectTree from "./SelectTree"
import { withBasics } from "./withBasics"

// Status
const ACTIVE_STATUS: string = "active"
const DRAFT_STATUS: string = "draft"
const ARCHIVED_STATUS: string = "archived"

export type SlimProductCategory = Pick<ProductCategory, "id" | "name">

export interface MakerSearchResultsTableProps {
  setStatuses: (statuses: string[]) => void
  statuses: string[]
  setProductionCapacities: (productionCapacities: string[]) => void
  productionCapacities: string[]
  setCertifications: (certifications: Array<Certification["id"]>) => void
  certifications: Array<Certification["id"]>
  setMakerAttributes: (attribute: MakerAttribute[]) => void
  makerAttributes: MakerAttribute[]
  setShippingCountries: (countries: Country[]) => void //TODO: Change to option
  shippingCountries: Country[]
  environments: string[]
  setEnvironments: (environments: string[]) => void
  capabilities: string[]
  setCapabilities: (capabilities: string[]) => void
  costRangeEstimates: string[]
  setCostRangeEstimates: (costRangeEstime: string[]) => void
  productCategories: SlimProductCategory[]
  setProductCategories: (categories: SlimProductCategory[]) => void
  productSubcategoryIds: string[]
  setProductSubcategoryIds: (subcategoryIds: string[]) => void
  materialIds: string[]
  setMaterialIds: (materialIds: string[]) => void
  materialSubcategoryIds: string[]
  setMaterialSubcategoryIds: (materialSubcategoryIds: string[]) => void
}

const MakerSearchFilters = (props: MakerSearchResultsTableProps) => {
  const {
    statuses,
    setStatuses,
    productionCapacities,
    setProductionCapacities,
    makerAttributes,
    setMakerAttributes,
    certifications,
    setCertifications,
    shippingCountries,
    setShippingCountries,
    environments,
    setEnvironments,
    capabilities,
    setCapabilities,
    costRangeEstimates,
    setCostRangeEstimates,
    productCategories,
    setProductCategories,
    productSubcategoryIds,
    setProductSubcategoryIds,
    materialIds,
    setMaterialIds,
    materialSubcategoryIds,
    setMaterialSubcategoryIds,
  } = props
  const result = useMakerSearchFilterOptionsQuery()

  useEffect(() => {
    setStatuses([ACTIVE_STATUS])
    // todo...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (result.status === "loading") {
    return null
  }

  if (result.status === "error") {
    return <RequestError error={result.error} />
  }

  const { data } = result
  if (data == null) return null

  const { makerSearchFilterOptions } = data

  return (
    <div className="border rounded border-pink position-relative">
      <div className="p-3">
        <div className="row mb-2 ">
          <div className="position-absolute right-0 top-0">
            <button
              className="float-right btn btn-link"
              style={{ color: "#dc3545" }}
              onClick={() => {
                setStatuses([ACTIVE_STATUS])
                setProductionCapacities([])
                setCertifications([])
                setShippingCountries([])
                setEnvironments([])
                setCapabilities([])
                setCostRangeEstimates([])
                setProductCategories([])
                setProductSubcategoryIds([])
                setMaterialIds([])
                setMaterialSubcategoryIds([])
                setMakerAttributes([])
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <Checkbox
              checked={statuses.includes(ACTIVE_STATUS)}
              onChange={(e) => {
                if (e.target.checked) {
                  setStatuses(union(statuses, [ACTIVE_STATUS]))
                } else {
                  setStatuses(without(statuses, ACTIVE_STATUS))
                }
              }}
              id={"ActiveMakerCheckbox"}
              label={"Active"}
            />

            <Checkbox
              checked={statuses.includes(DRAFT_STATUS)}
              onChange={(e) => {
                if (e.target.checked) {
                  setStatuses(union(statuses, [DRAFT_STATUS]))
                } else {
                  setStatuses(without(statuses, DRAFT_STATUS))
                }
              }}
              id={"DraftMakerCheckbox"}
              label={"Draft"}
            />

            <Checkbox
              checked={statuses.includes(ARCHIVED_STATUS)}
              onChange={(e) => {
                if (e.target.checked) {
                  setStatuses(union(statuses, [ARCHIVED_STATUS]))
                } else {
                  setStatuses(without(statuses, ARCHIVED_STATUS))
                }
              }}
              id={"ArchivedMakerCheckbox"}
              label={"Archived"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.productionCapacities.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={productionCapacities.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setProductionCapacities(options.map((o) => o.value))
              }
              label={"Production Capacity"}
            />
          </div>
          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.certifications.nodes.map(
                (certification) => ({
                  value: certification.id,
                  label: certification.name,
                }),
              )}
              values={certifications.map((id) => ({
                value: id,
                label:
                  makerSearchFilterOptions.certifications.nodes.find(
                    (c) => c.id === id,
                  )?.name ?? "–",
              }))}
              onChange={(options) =>
                setCertifications(options.map((o) => o.value))
              }
              label="Certifications"
            />
          </div>
          <div className="col-md-4">
            <SelectTree
              label="Materials"
              options={makerSearchFilterOptions.materials.map((material) => {
                const children = material.materialSubcategories.nodes.map(
                  (subcategory) => {
                    return {
                      label: subcategory.name,
                      value: subcategory.id,
                      type: "subcategory",
                      checked: materialSubcategoryIds.includes(subcategory.id),
                      children: [],
                    }
                  },
                )

                return {
                  label: material.name,
                  value: material.id,
                  type: "material",
                  subcategories: material.materialSubcategories.nodes.map(
                    (x) => x.id,
                  ),
                  checked: materialIds
                    .map((m: any) => m.id)
                    .includes(material.id),
                  children,
                }
              })}
              onChange={(_currentNode, selectedNodes) => {
                const materialIds = compact(
                  selectedNodes.map((node) => {
                    if (node.type === "material") {
                      return node.value
                    }
                    return undefined
                  }),
                )
                const materialSubcategoryIds = selectedNodes
                  .flatMap((node) => {
                    switch (node.type) {
                      case "material":
                        return node.subcategories
                      case "subcategory":
                        return node.value
                      default:
                        return undefined
                    }
                  })
                  .filter(isNotNull)

                setMaterialSubcategoryIds(materialSubcategoryIds)
                setMaterialIds(materialIds)
              }}
              onReset={() => {
                setMaterialSubcategoryIds([])
                setMaterialIds([])
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.makerAttributes.map(
                (attribute) => ({
                  id: attribute.id,
                  label: attribute.text,
                  attribute: attribute,
                }),
              )}
              values={makerAttributes.map((attribute) => ({
                value: attribute.id,
                label: attribute.text,
                attribute: attribute,
              }))}
              onChange={(options) =>
                setMakerAttributes(options.map((o) => o.attribute))
              }
              label="Attributes"
            />
          </div>
          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.shippingCountries.map(
                (country) => ({
                  value: country.id,
                  label: country.name,
                  country: country,
                }),
              )}
              values={shippingCountries.map((value: Country) => ({
                value: value.id,
                label: value.name,
                country: value,
              }))}
              onChange={(options) =>
                setShippingCountries(options.map((o) => o.country))
              }
              label={"Shipping Countries"}
            />
          </div>
          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.costRangeEstimates.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={costRangeEstimates.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setCostRangeEstimates(options.map((o) => o.value))
              }
              label="Cost Range"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.environments.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={environments.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setEnvironments(options.map((o) => o.value))
              }
              label="Environments"
            />
          </div>

          <div className="col-md-4">
            <SearchSelect
              options={makerSearchFilterOptions.capabilities.map(
                (value: string) => ({
                  value,
                  label: value,
                }),
              )}
              values={capabilities.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={(options) =>
                setCapabilities(options.map((o) => o.value))
              }
              label={"Capabilities"}
            />
          </div>

          <div className="col-md-4">
            <div className="mb-2 mt-2">
              <SelectTree
                label="Product Categories"
                options={makerSearchFilterOptions.productCategories.map(
                  (category) => {
                    const children = category.productSubcategories.nodes.map(
                      (subcategory) => {
                        return {
                          label: subcategory.name,
                          value: subcategory.id,
                          type: "subcategory",
                          checked: productSubcategoryIds.includes(
                            subcategory.id,
                          ),
                          children: [],
                        }
                      },
                    )

                    return {
                      label: category.name,
                      value: category.id,
                      type: "category",
                      subcategories: category.productSubcategories.nodes.map(
                        (x) => x.id,
                      ),
                      checked: productCategories
                        .map((c) => c.id)
                        .includes(category.id),
                      children,
                    }
                  },
                )}
                onChange={(_currentNode, selectedNodes) => {
                  const productCategoryIds = compact(
                    selectedNodes.map((node) => {
                      if (node.type === "category") {
                        return node.value
                      }
                      return undefined
                    }),
                  )
                  const subcategories = selectedNodes
                    .flatMap((node) => {
                      switch (node.type) {
                        case "category":
                          return node.subcategories
                        case "subcategory":
                          return node.value
                        default:
                          return undefined
                      }
                    })
                    .filter(isNotNull)

                  setProductSubcategoryIds(subcategories)

                  setProductCategories(
                    makerSearchFilterOptions.productCategories.filter((c) =>
                      productCategoryIds.includes(c.id),
                    ),
                  )
                }}
                onReset={() => {
                  setProductSubcategoryIds([])
                  setProductCategories([])
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withBasics(MakerSearchFilters)
