import React, { Component } from "react"
import * as Sentry from "@sentry/browser"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <>
          <div className="alert alert-danger mt-3" role="alert">
            <strong>Uh oh!</strong> An unexpected error occured
          </div>
          <button
            onClick={() =>
              Sentry.showReportDialog({
                eventId: this.state.eventId || undefined,
              })
            }
            className="btn btn-primary"
          >
            Report feedback
          </button>
        </>
      )
    }

    //when there's not an error, render children untouched
    return this.props.children
  }
}

export default ErrorBoundary

export const withBoundary = (Comp) => {
  const WithBoundary = (props) => (
    <ErrorBoundary>
      <Comp {...props} />
    </ErrorBoundary>
  )

  WithBoundary.displayName = `withBoundary(${Comp.displayName || Comp.name})`
  return WithBoundary
}
