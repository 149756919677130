import React, { useState } from "react"
import { withBasics } from "./withBasics"
import {
  TimeAndActionCalendarTask,
  TimeAndActionCalendarTaskInput,
  TimeAndActionCalendarTaskOwner,
  TimeAndActionCalendarTaskStatusOption,
  UpdateTimeAndActionCalendarTaskInput,
  UpdateTimeAndActionCalendarTaskMutation,
  useCurrentUserQuery,
  useUpdateTimeAndActionCalendarTaskMutation,
} from "../generated/graphql"
import LoadingIndicator from "./LoadingIndicator"
import invariant from "tiny-invariant"
import TimeAndActionHistoryModal from "./TimeAndActionHistoryModal"
import TimeAndActionTaskStatusChangeModal from "./TimeAndActionTaskStatusChangeModal"
import TimeAndActionTaskChangeRequests from "./TimeAndActionTaskChangeRequests"
import { formatDate } from "../util/dates"

type TaskViewerProps = {
  tasks: any
  showUrl: string
  statusOptions: TimeAndActionCalendarTaskStatusOption[]
  submit: (tasks: TimeAndActionCalendarTaskInput[]) => void
  locked: boolean
}

type SingleTaskViewerProps = {
  task: any
  statusOptions: TimeAndActionCalendarTaskStatusOption[]
  currentUser: any
  updateTask: (task: TimeAndActionCalendarTask, changes: any) => void
  openHistoryModal: (task: TimeAndActionCalendarTask) => void
  locked: boolean
}

const taskRowClass = (task: TimeAndActionCalendarTask) => {
  const classes: String[] = []
  if (task.status === "completed") classes.push("completed")
  if (task.pastDue) classes.push("past-due")
  return classes.join(" ")
}

const TimeAndActionCalendarTasks = ({
  tasks,
  showUrl,
  statusOptions,
  submit,
  locked,
}: TaskViewerProps) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false)

  const [
    historyModalTask,
    setHistoryModalTask,
  ] = useState<TimeAndActionCalendarTask | null>(null)

  const mutation = useUpdateTimeAndActionCalendarTaskMutation()
  const currentUserQuery = useCurrentUserQuery()
  if (currentUserQuery.status === "loading") return <LoadingIndicator />
  invariant(currentUserQuery.data, `expected data`)
  const currentUser = currentUserQuery.data.currentUser

  const openHistoryModal = (task: TimeAndActionCalendarTask) => {
    setHistoryModalTask(task)
    setHistoryModalOpen(true)
  }

  const closeHistoryModal = () => {
    setHistoryModalTask(null)
    setHistoryModalOpen(false)
  }

  const updateTask = async (task: TimeAndActionCalendarTask, changes: any) => {
    const input: UpdateTimeAndActionCalendarTaskInput = {
      id: task.id,
      ...changes,
    }
    try {
      const {
        updateTimeAndActionCalendarTask: result,
      }: UpdateTimeAndActionCalendarTaskMutation = await mutation.mutateAsync({
        input,
      })

      if (result && result.errors.length) {
        console.log(result)
      } else {
        window.location.href = showUrl + (window.location.hash || "")
      }
    } catch (er) {
      window.alert(`An error occured: ${(er as Error).message}`)
    }
  }

  return (
    <div className={`table-responsive`}>
      <TimeAndActionHistoryModal
        isOpen={historyModalOpen}
        onClose={closeHistoryModal}
        calendarChanges={historyModalTask?.taskChanges || []}
      />

      <table className="table nice-table content-centered-table">
        <thead>
          <tr>
            <th colSpan={3}></th>
            <th colSpan={2} style={{ paddingBottom: "0" }}>
              Projected
            </th>
            <th colSpan={2} style={{ paddingBottom: "0" }}>
              Actual
            </th>
            <th colSpan={5}></th>
          </tr>
          <tr>
            <th>Stage</th>
            <th>Task</th>
            <th>Duration</th>
            <th>Start Date</th>
            <th>Due Date</th>
            <th>Start Date</th>
            <th>Date Completed</th>
            <th>Actions</th>
            <th>Change Request</th>
            <th>Notify Party</th>
            {currentUser && (
              <>
                <th>Reminders</th>
                <th>Maker Visible</th>
              </>
            )}
            <th>History</th>
          </tr>
        </thead>
        <tbody>
          {tasks
            .sort((a: any, b: any) => a.position - b.position)
            .map((task: TimeAndActionCalendarTask) => (
              <TaskRow
                key={task.id}
                task={task}
                statusOptions={statusOptions}
                currentUser={currentUser}
                updateTask={updateTask}
                openHistoryModal={openHistoryModal}
                locked={locked}
              />
            ))}
        </tbody>
      </table>
    </div>
  )
}

const TaskRow = ({
  task,
  statusOptions,
  currentUser,
  updateTask,
  openHistoryModal,
  locked,
}: SingleTaskViewerProps) => {
  const [taskStatus, setTaskStatus] = useState(task.status)
  const [statusChangeModalOpen, setStatusChangeModalOpen] = useState(false)

  const requestStatusChange = (status: String) => {
    setTaskStatus(status)

    if (status === "not_started") {
      updateTask(task, { status: status })
    } else {
      setStatusChangeModalOpen(true)
    }
  }

  return (
    <>
      <TimeAndActionTaskStatusChangeModal
        isOpen={statusChangeModalOpen}
        onClose={() => setStatusChangeModalOpen(false)}
        task={task}
        status={taskStatus}
        currentUser={currentUser}
        updateTask={updateTask}
      />

      <tr key={task.id} className={taskRowClass(task)}>
        <td>{task.stageHumanized}</td>
        <td>{task.name}</td>
        <td>{task.durationDays}</td>
        <td>{task.startDate ? formatDate(task.startDate) : ""}</td>
        <td>{task.dueDate ? formatDate(task.dueDate) : ""}</td>
        <td>{task.actualStartDate ? formatDate(task.actualStartDate) : ""}</td>
        <td>{task.dateCompleted ? formatDate(task.dateCompleted) : ""}</td>

        <td className={`d-flex`} style={{ minWidth: "150px" }}>
          <select
            className="form-control"
            name="TimeAndActionCalendarTaskStatusSelect"
            value={taskStatus}
            disabled={locked}
            onChange={(e) => {
              requestStatusChange(e.target.value)
            }}
          >
            {statusOptions.map(
              (option: TimeAndActionCalendarTaskStatusOption) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ),
            )}
          </select>
        </td>

        <td>
          <TimeAndActionTaskChangeRequests
            task={task}
            activeChangeRequest={task.activeChangeRequest}
            currentUser={currentUser}
            updateTask={updateTask}
          />
        </td>

        <td>
          {task.owners.nodes.map((owner: TimeAndActionCalendarTaskOwner) => (
            <div key={owner.id} style={{ whiteSpace: "nowrap" }}>
              {owner.maker ? "Maker" : owner.user?.fullName}
            </div>
          ))}
        </td>
        {currentUser && (
          <>
            <td>
              {task.remindersOn ? (
                <div>
                  Yes
                  <div>
                    <a href={task.remindersUrl}>Edit</a>
                  </div>
                </div>
              ) : (
                "No"
              )}
            </td>
            <td>{task.displayToMaker ? "Yes" : "No"}</td>
          </>
        )}
        <td>
          <div
            className="btn btn-sm btn-link"
            onClick={() => openHistoryModal(task)}
          >
            View History
          </div>
        </td>
      </tr>
    </>
  )
}

export default withBasics(TimeAndActionCalendarTasks)
