import { Controller } from "stimulus"
import invariant from "tiny-invariant"

export default class PollWhileLoadingController extends Controller {
  elementId: string | undefined

  connect() {
    invariant(this.element instanceof HTMLElement)
    this.elementId = this.element.id
    invariant(this.element.id, `element needs id`)
    const data = this.element.dataset
    invariant(data.status, `expected needs data-status`)
    if (data.status === "loading") {
      setTimeout(this.refetch, 500)
    }
  }

  refetch = async () => {
    try {
      const resp = await window.fetch(window.location.href)
      const html = await resp.text()
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")
      invariant(this.elementId)
      const target = doc.getElementById(this.elementId)
      invariant(target instanceof HTMLElement, `target not found in response`)

      this.element.replaceWith(target)
      this.disconnect()
    } catch (err) {
      window.alert(`An error occured: ${err}`)
    }
  }
}
