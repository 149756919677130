import React from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

export const ANIMATION_TIMEOUT = 150
export const BODY_OPEN_CLASS_NAME = "ReactModal__Body--open"

ReactModal.setAppElement("#modal-root")
ReactModal.defaultStyles = {}

const ReactModalAdapter = ({
  className,
  isOpen,
  ...props
}: ReactModal.Props) => {
  const contentClassName = `${className}__Content`
  const overlayClassName = `${className}__Overlay`

  return (
    <ReactModal
      closeTimeoutMS={ANIMATION_TIMEOUT}
      className={contentClassName}
      // @ts-expect-error not sure
      portalClassName={className}
      overlayClassName={overlayClassName}
      bodyOpenClassName={BODY_OPEN_CLASS_NAME}
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      {...props}
    />
  )
}

const Modal = styled(ReactModalAdapter)`
  &__Overlay {
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    text-align: center;
    z-index: 1;

    &.ReactModal__Overlay--after-open {
      animation: fadeIn ${ANIMATION_TIMEOUT}ms forwards;
      animation-timing-function: ease-in;
    }

    &.ReactModal__Overlay--before-close {
      animation: fadeOut ${ANIMATION_TIMEOUT}ms forwards;
      animation-timing-function: ease-out;
    }
  }

  &__Content {
    @keyframes zoomIn {
      0% {
        transform: translateY(24px) scale(0.98);
      }

      100% {
        transform: translateY(0) scale(1);
      }
    }

    @keyframes zoomOut {
      0% {
        transform: translateY(0) scale(1);
      }

      100% {
        transform: translateY(24px) scale(0.98);
      }
    }

    margin: 90px auto;
    outline: none;
    text-align: left;

    /* let clicks pass through to overlay, possibly a hack */
    pointer-events: none;

    &.ReactModal__Content--after-open {
      animation: zoomIn ${ANIMATION_TIMEOUT}ms forwards;
      animation-timing-function: ease-in;
    }

    &.ReactModal__Content--before-close {
      animation: zoomOut ${ANIMATION_TIMEOUT}ms forwards;
      animation-timing-function: ease-out;
    }
  }
`

export { ReactModalAdapter }
export default Modal
