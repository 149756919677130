import React from "react"
import { Country } from "../generated/graphql"
import countryCodeEmoji from "../util/cc-emoji"

export const CountryFlag = ({
  country,
}: {
  country: Pick<Country, "iso3166">
}) => {
  return <>{countryCodeEmoji(country.iso3166)}</>
}
