// @ts-nocheck
import $ from "jquery"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select"]

  connect() {}

  onChange(e) {
    e.preventDefault()
    const newStatus = e.target.value
    const modalElement = $(e.target.dataset.modal)
    const modalContent = modalElement.find(".modal-body")
    const modalTitle = modalElement.find(".modal-title")

    const taskId = e.target.dataset.id
    const url = `/time_and_action_calendar_tasks/${taskId}/status_modal?status=${newStatus}`

    if (newStatus !== "not_started") {
      const modalTitleContent =
        newStatus === "completed"
          ? "Task - Mark Completed"
          : "Task - Mark In Progress"

      // Fill modal with html
      fetch(url)
        .then((res) => res.text())
        .then((htmlResponse) => {
          modalTitle.html(modalTitleContent)
          modalContent.html(htmlResponse)
          modalElement.modal("show")
        })
    } else {
      this.submit()
    }
  }

  submit() {
    this.dispatch("submit", { detail: { content: "test" } })
  }
}
