import React from "react"
import { useBetween } from "use-between"
import { convert, getSystemUnits, useUnitState } from "../util/units"
import { withBasics } from "./withBasics"

export interface Props {
  value: number
  unit: string
  unitName: string
}

function UnitValue(props: Props) {
  const { unitSystem } = useBetween(useUnitState)
  const units: any = getSystemUnits(unitSystem)
  const currentUnitName = units[props.unitName]
  const displayValue = parseFloat(
    convert(props.value)
      .from(props.unit as any)
      .to(currentUnitName)
      .toFixed(3),
  )

  return <>{displayValue}</>
}

export default withBasics(UnitValue)
