import { DirectUpload } from "@rails/activestorage"
import React, { useRef, useState } from "react"
import Imgix, { ImgixProvider } from "react-imgix"
import { Blobby } from "../util/active-storage"
import { withBasics } from "./withBasics"

export interface Props {
  name: string
  directUploadUrl: string
  attachments: Attachment[]
  imgixSource: string
  fileInputId: string
  confirmDeleteMessage?: string
}

export interface Attachment {
  id: string
  signedId: string
  imgixUrl: string
}

/**
 * intended to be UI element that replaces <input type=file multiple />
 * within the context of active record
 */
function FancyFileInput(props: Props) {
  const fileRef = useRef<HTMLInputElement>(null)

  const [blobs, setBlobs] = useState<Array<Blobby>>([])
  const [inputKey, setInputKey] = useState(0)
  const [attachments, setAttachments] = useState(props.attachments)
  const [loadingCount, setLoadingCount] = useState<number>(0)

  const handleChange = () => {
    if (!fileRef.current) throw new Error(`expected file ref`)
    const files = fileRef.current.files
    if (!files) return

    for (const file of Array.from(files)) {
      const upload = new DirectUpload(file, props.directUploadUrl)

      setLoadingCount((prev) => prev + 1)
      upload.create((error, blob) => {
        setLoadingCount((prev) => prev - 1)
        if (error) {
          alert(`Error uploading file: ${error}`)
          return
        }

        setBlobs((prev) => [...prev, (blob as unknown) as Blobby])
        setInputKey((prev) => prev + 1)
      })
    }
  }

  return (
    <ImgixProvider domain={props.imgixSource}>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 10,
          }}
        >
          {attachments.map((a) => (
            <ImageBlock
              key={a.id}
              signedId={a.signedId}
              src={a.imgixUrl}
              onRemoveClick={() => {
                if (props.confirmDeleteMessage) {
                  const isConfirmed = window.confirm(props.confirmDeleteMessage)
                  if (!isConfirmed) return
                }
                setAttachments((prev) => prev.filter((p) => p.id !== a.id))
              }}
              name={props.name}
            />
          ))}

          {blobs.map((b) => (
            <ImageBlock
              key={b.id}
              signedId={b.signed_id}
              src={b.key}
              onRemoveClick={() => {
                setBlobs((prev) => prev.filter((p) => p.id !== b.id))
              }}
              name={props.name}
            />
          ))}
        </div>

        <input
          type="file"
          ref={fileRef}
          name="ignore"
          key={inputKey}
          onChange={(e) => {
            handleChange()
          }}
          multiple={true}
          id={props.fileInputId}
        />

        {loadingCount !== 0 ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : null}
      </div>
    </ImgixProvider>
  )
}

export default withBasics(FancyFileInput)

const ImageBlock = (props: {
  name: string
  signedId: string
  src: string
  onRemoveClick: () => void
}) => {
  return (
    <div>
      <input type="hidden" name={props.name} value={props.signedId} />
      <div className="border">
        <Imgix
          src={props.src}
          sizes="100px"
          width={100}
          imgixParams={{
            ar: "1:1",
            fit: "crop",
          }}
        />
      </div>
      <button
        type="button"
        onClick={props.onRemoveClick}
        className="btn btn-sm"
      >
        &times; &nbsp;
        <span className="small">Remove</span>
      </button>
    </div>
  )
}
