import { isValid, parseISO } from "date-fns"

function formatDate(
  timestamp: string | Date | null | undefined,
  format: "date" | "year" = "date",
): string | null {
  if (timestamp == null) return null

  const date = timestamp instanceof Date ? timestamp : parseISO(timestamp)

  if (!isValid(date)) {
    throw new Error(`Invalid date: ${JSON.stringify(timestamp)}`)
  }

  switch (format) {
    case "date":
      return new Intl.DateTimeFormat("default").format(date)
    case "year":
      return new Intl.DateTimeFormat("default", { year: "numeric" }).format(
        date,
      )
  }
}

function formatDateLong(dateString: string | null | undefined) {
  if (dateString == null) return null

  return new Date(dateString).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  })
}

function formatDateTimeLong(dateString: string | null | undefined) {
  if (dateString == null) return null

  return new Date(dateString).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  })
}

function getDaysBetweenDates(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day

  // Calculate the difference in milliseconds
  const diff = Math.abs(endDate.getTime() - startDate.getTime())

  // Calculate the number of days
  const days = Math.round(diff / oneDay)

  return days
}

function createBasicDate(dateString: string) {
  const [year, month, day] = dateString.split("-").map(Number)
  return new Date(year, month - 1, day) // Month is 0-based
}

export {
  formatDate,
  formatDateLong,
  formatDateTimeLong,
  getDaysBetweenDates,
  createBasicDate,
}
