import React from "react"
import { convert } from "../util/units"
import { Dimension } from "../generated/graphql"

type ProductDimensionsProps = {
  dimension: Pick<Dimension, "heightCm" | "lengthCm" | "widthCm">
}

const ProductDimensions = ({ dimension }: ProductDimensionsProps) => {
  const sizeDimensions = [
    { label: "Length", value: dimension.lengthCm },
    { label: "Width", value: dimension.widthCm },
    { label: "Height", value: dimension.heightCm },
  ].filter((dimension) => dimension.value)

  if (sizeDimensions.length === 0) return null

  return (
    <>
      {"Product Dimensions: "}
      {sizeDimensions.map((dimension, i) => (
        <span key={i}>
          {(dimension.value || 0).toFixed(1)} cm /{" "}
          {convert(dimension.value || 0)
            .from("cm")
            .to("in")
            .toFixed(1)}
          "{sizeDimensions.length > 1 ? ` (${dimension.label}) ` : ""}
        </span>
      ))}
    </>
  )
}

export default ProductDimensions
