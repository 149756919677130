import React from "react"
import Imgix from "react-imgix"
import { ProductAttributeListFragment } from "../generated/graphql"
import { EmptyValue } from "./EmptyValue"

export const ProductAttributes = ({
  product,
}: {
  product: ProductAttributeListFragment
}) => {
  return (
    <>
      {product.productAttributes.length > 0 ? (
        <div className="d-flex flex-wrap flex-grow-1 justify-content-around">
          {product.productAttributes.map((attribute) => (
            <div
              className="p-2 mr-2 mb-2 d-flex flex-column"
              key={attribute.name}
            >
              <Imgix
                src={attribute.icon.imgixUrl}
                width={50}
                height={50}
                className="mb-2 mx-auto"
              />
              <div className="text-center text-dark f-header-2">
                {attribute.name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyValue variant="message" />
      )}
    </>
  )
}
