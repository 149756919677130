// based on
// https://github.com/molefrog/wouter/blob/master/use-location.js
import { useEffect, useMemo, useState } from "react"

/**
 * @typedef {object} Location
 * @property {string} pathname
 * @property {string} [search]
 *
 * @typedef {object} LocationSet
 * @property {string} pathname
 * @property {string} [search]
 * @property {boolean|undefined} [replace]
 */

/**
 * @returns {[Location, (args: LocationSet) => void]}
 */
const useLocation = () => {
  const [path, update] = useState(
    /** @type {Location} */ ({
      pathname: window.location.pathname,
      search: window.location.search,
    }),
  )

  useEffect(() => {
    patchHistoryEvents()

    const events = ["popstate", "pushState", "replaceState"]
    const handler = () =>
      update({
        pathname: window.location.pathname,
        search: window.location.search,
      })

    events.map((e) => window.addEventListener(e, handler))
    return () => {
      events.map((e) => window.removeEventListener(e, handler))
    }
  }, [])

  /**
   *
   * @param {LocationSet} args
   */
  const set = ({ pathname, search, replace = false }) => {
    const url = `${pathname}${search || ""}`
    if (replace) {
      window.history.replaceState(0, "", url)
    } else {
      window.history.pushState(0, "", url)
    }
  }

  const value = useMemo(() => [path, set], [path])
  return value
}
export default useLocation

// While History API does have `popstate` event, the only
// proper way to listen to changes via `push/replaceState`
// is to monkey-patch these methods.
//
// See https://stackoverflow.com/a/4585031

let patched = 0

const patchHistoryEvents = () => {
  if (patched) return
  ;["pushState", "replaceState"].forEach((type) => {
    const original = window.history[type]

    window.history[type] = function () {
      const result = original.apply(this, arguments)
      const event = new Event(type)
      // @ts-ignore
      event.arguments = arguments

      dispatchEvent(event)
      return result
    }
  })

  return (patched = 1)
}
