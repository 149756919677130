// @ts-nocheck
import $ from "jquery"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "lock"]

  connect() {}

  onChange(e) {
    var x = $(e.target)
    if (x.data("target") !== "client-order-form.lock") {
      var mid = x.closest("[data-maker-id]").data("maker-id")
      this.lockTargets.forEach((el) => {
        if (el.getAttribute("data-maker-id") === mid) {
          var formGroup = $(el).closest(".form-group")
          formGroup.removeClass("d-none")
          el.checked = true
        }
      })
    }
  }

  confirm(e) {
    if (this.lockTargets.some((el) => el.checked)) {
      $(this.modalTarget).modal("show")
    } else {
      this.element.submit()
    }
  }

  cancel(e) {
    $(this.modalTarget).modal("hide")
  }
}
