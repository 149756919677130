import React, { ReactNode } from "react"

const MaybeTooltip = ({
  show,
  text,
  children,
}: {
  show: boolean
  text: string
  children: ReactNode
}) => {
  if (show)
    return (
      <div title={text} data-toggle="tooltip">
        {children}
      </div>
    )
  else return <>{children}</>
}

export default MaybeTooltip
