import React from "react"

export const RequestError = ({ error }: { error: unknown }) => {
  if (error == null) {
    return null
  }

  return (
    <div className="alert alert-danger" role="alert">
      <strong>Uh oh! you've encountered a request error </strong>
      <pre className="text-danger">
        {error instanceof Error ? error.message : `unknown`}
      </pre>
    </div>
  )
}
