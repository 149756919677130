import useLocation from "./useLocation"
import { parse, stringify } from "qs"
import { useMemo } from "react"

const useParams = () => {
  const [location, setLocation] = useLocation()

  const value = useMemo(
    () => [
      parse(location.search, { ignoreQueryPrefix: true }),
      /**
       * @param {any} params
       * @param {object} [options]
       * @param {boolean|undefined} [options.replace]
       */
      (params, options) => {
        setLocation({
          pathname: location.pathname,
          search: stringify(params, { addQueryPrefix: true }),
          replace: options && options.replace,
        })
      },
    ],
    [location.pathname, location.search, setLocation],
  )
  return value
}
export default useParams
