// @ts-nocheck
import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    if (window.location.hash === "#feedback")
      $(this.element).closest(".modal").modal("hide")

    $(this.element).on("click", () => {
      $("#feedback-tab").trigger("click")
      $(this.element).closest(".modal").modal("hide")
    })
  }
}
