import React from "react"
import { withBoundary } from "./ErrorBoundary"
import "react-toggle/style.css"
import Modal from "./Modal"
import * as S from "./MakerActivateButton.styled"
import { withBasics } from "./withBasics"
import { useMakerOrderHistoryQuery } from "../generated/graphql"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import invariant from "tiny-invariant"
import { formatDate } from "../util/dates"
import ReactMarkdown from "react-markdown"

interface MakerOrderHistoryModalProps {
  isOpen: boolean
  onClose: () => void
  makerOrderId: string
}

const MakerOrderHistoryModal = ({
  isOpen,
  onClose,
  makerOrderId,
}: MakerOrderHistoryModalProps) => {
  const result = useMakerOrderHistoryQuery({ makerOrderId: makerOrderId })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const makerOrderHistory = result.data.makerOrderHistory

  return (
    <S.Container className="mb-2 mt-2">
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Maker Order History</h5>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              {makerOrderHistory.nodes.length > 0 ? (
                <ul className="list-group list-group-flush">
                  {makerOrderHistory.nodes.map((change) => (
                    <li key={change.id} className="list-group-item">
                      <ReactMarkdown>{`${
                        change.user?.displayName || change.responsible
                      } ${change.description}`}</ReactMarkdown>
                      {change.note && (
                        <div>
                          {"with note:"} <em>{change.note}</em>
                        </div>
                      )}
                      <div>
                        <small>{formatDate(change.createdAt)}</small>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="pt-3 pb-1 text-center">
                  {"The status hasn't been changed yet."}
                </p>
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </Modal>
    </S.Container>
  )
}

export default withBoundary(withBasics(MakerOrderHistoryModal))
