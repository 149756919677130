import React, { useState } from "react"
import { withBasics } from "./withBasics"
import {
  TimeAndActionCalendarTask,
  TimeAndActionTaskChangeRequest,
} from "../generated/graphql"
import Modal from "./Modal"
import { formatDateLong } from "../util/dates"

interface TimeAndActionTaskChangeRequestProps {
  task: TimeAndActionCalendarTask
  activeChangeRequest: TimeAndActionTaskChangeRequest | null
  currentUser: any
  updateTask: (task: TimeAndActionCalendarTask, changes: any) => void
}

const TimeAndActionTaskChangeRequests = ({
  task,
  activeChangeRequest,
  currentUser,
  updateTask,
}: TimeAndActionTaskChangeRequestProps) => {
  const [editChangeRequestModalOpen, setEditChangeRequestModalOpen] = useState(
    false,
  )

  if (currentUser) {
    if (activeChangeRequest) {
      return (
        <>
          {editChangeRequestModalOpen && (
            <EditChangeRequestModal
              updateTask={updateTask}
              task={task}
              changeRequest={activeChangeRequest}
              isOpen={true}
              onClose={() => setEditChangeRequestModalOpen(false)}
            />
          )}
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
              onClick={() => setEditChangeRequestModalOpen(true)}
            >
              Review Change Request
            </button>
          </div>
        </>
      )
    } else {
      return <div>No Change Request</div>
    }
  } else {
    return null
  }
}

interface EditChangeRequestModalProps {
  isOpen: boolean
  task: TimeAndActionCalendarTask
  onClose: () => void
  changeRequest: TimeAndActionTaskChangeRequest
  updateTask: (task: TimeAndActionCalendarTask, changes: any) => void
}

const EditChangeRequestModal = ({
  isOpen,
  onClose,
  task,
  changeRequest,
  updateTask,
}: EditChangeRequestModalProps) => {
  const acceptOrRejectClick = (e: any, resultStatus: string) => {
    e.preventDefault()
    const form = document.getElementById(
      "changeRequestReviewForm",
    ) as HTMLFormElement
    const formData = new FormData(form)
    formData.append("status", resultStatus)
    const values: Record<string, string> = {}
    formData.forEach((value, key) => {
      values[key] = value as string
    })

    updateTask(task, {
      changeRequests: [
        {
          id: changeRequest.id,
          ...values,
        },
      ],
    })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Task - Change Request Review</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-0">
            <div className="mb-4 px-3">
              <p>
                Please review the change request with the following information.
              </p>

              {changeRequest.startDate && (
                <p>
                  Existing start date:
                  <b className={"ml-2"}>
                    {changeRequest.existingStartDate
                      ? formatDateLong(changeRequest.existingStartDate)
                      : "n/a"}
                  </b>
                </p>
              )}

              <p>
                Proposed new start date:
                <b className={"ml-2"}>
                  {formatDateLong(changeRequest.startDate)}
                </b>
              </p>

              {changeRequest.dueDate && (
                <p>
                  Existing due date:
                  <b className={"ml-2"}>
                    {changeRequest.existingDueDate
                      ? formatDateLong(changeRequest.existingDueDate)
                      : "n/a"}
                  </b>
                </p>
              )}

              <p>
                Proposed new due date:
                <b className={"ml-2"}>
                  {formatDateLong(changeRequest.dueDate)}
                </b>
              </p>

              <p>
                <b>Maker Notes:</b>
                <br />
                {changeRequest.notes}
              </p>

              <form id="changeRequestReviewForm">
                <label htmlFor="rejectionNotes">Notes:</label>
                <textarea
                  className="form-control text"
                  id="rejectionNotes"
                  name="rejectionNotes"
                />

                <div className="d-inline-flex align-items-center mt-2">
                  <div className="col-auto-w5 mr-2">
                    <button
                      onClick={(e) => acceptOrRejectClick(e, "accepted")}
                      className="btn btn-md btn-primary mr-3"
                      name="status"
                      value="accepted"
                    >
                      Accept
                    </button>
                    <button
                      onClick={(e) => acceptOrRejectClick(e, "rejected")}
                      className="btn btn-md btn-primary mr-3"
                      name="status"
                      value="rejected"
                    >
                      Reject
                    </button>

                    <button
                      type="button"
                      className="btn btn-md btn-secondary"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </Modal>
  )
}

export default withBasics(TimeAndActionTaskChangeRequests)
