import React from "react"
import { useBetween } from "use-between"
import { getSystemUnits, useUnitState } from "../util/units"
import { withBasics } from "./withBasics"

export interface Props {
  label: string
  unitName: string
}

function UnitLabel(props: Props) {
  const { unitSystem } = useBetween(useUnitState)
  const units: any = getSystemUnits(unitSystem)
  const currentUnitName = units[props.unitName]

  const unitLabel = (() => {
    switch (currentUnitName) {
      case "lb":
        return "lb/oz"
      default:
        return currentUnitName
    }
  })()

  return <>{`${props.label} (${unitLabel})`}</>
}

export default withBasics(UnitLabel)
