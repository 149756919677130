import { Controller } from "stimulus"
import invariant from "tiny-invariant"

export default class ConfirmCheckbox extends Controller {
  connect() {
    invariant(
      this.element instanceof HTMLInputElement,
      `expected input element`,
    )
    this.element.addEventListener("click", (ev) => {
      const message = this.element.getAttribute("data-message")
      invariant(message != null, `expected data-message`)
      if (!window.confirm(message)) {
        ev.preventDefault()
      }
    })
  }
}
