import * as Sentry from "@sentry/browser"
import "../styles/global.scss"
import "bootstrap"
import { start as startActiveStorage } from "@rails/activestorage"
import { start as startUjs } from "@rails/ujs"
import { getEnv } from "../util/env"
import "../util/cocoon"
import "../util/development"
import "../dom/link-to-tab"
import "../dom/popover"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "unpoly"

import "select2"
import "select2/dist/css/select2.css"

import "react-datepicker/dist/react-datepicker.css"

const sentryDsn = getEnv("SENTRY_DSN_PUBLIC")
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: getEnv("SENTRY_CURRENT_ENV"),
    release: getEnv("SENTRY_RELEASE"),
  })
}

startActiveStorage()
startUjs()

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext)

// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)
require.context("../images", true)

const application = Application.start()
const context = require.context("../controllers", true, /\.(js|ts)$/)
application.load(definitionsFromContext(context))
