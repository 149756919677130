import React, { useState } from "react"
import { useImpactReportSearchQuery } from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { useDebounce } from "use-debounce"
import ImpactReportListResults from "./ImpactReportListResults"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import { FiltersDisclosureButton, SearchInput } from "./SearchElements"
import { Disclosure, DisclosurePanel } from "@reach/disclosure"
import useParams from "../hooks/useParams"
import ImpactReportSearchFilters, {
  DEFAULT_IMPACT_REPORT_FILTERS,
  ImpactReportFilters,
} from "./ImpactReportSearchFilters"

const ImpactReportsListScreen = (props: {
  showNewOrderButton: boolean
  newImpactReportPath: string
}) => {
  const [params, setParams] = useParams()
  // @ts-expect-error todo
  const [query, setQuery] = useState(params.q || "")
  const [searchQuery] = useDebounce(query, 300)
  const [filters, setFilters] = useState<ImpactReportFilters>(
    DEFAULT_IMPACT_REPORT_FILTERS,
  )

  const result = useInfiniteGraphQLQuery(
    useImpactReportSearchQuery,
    ({ pageParam }) => ({
      query: searchQuery,
      after: pageParam,
      ...filters,
    }),
    {
      getNextPageParam: (page) =>
        page.impactReports.pageInfo.endCursor ?? undefined,
    },
  )

  return (
    <>
      <div className="d-flex align-items-center mt-6">
        <div className="d-flex mr-auto justify-content-between w-100 mr-3 mb-5">
          <h1 className="my-0 f-title">Impact Reports</h1>
          {result.isFetching && <LoadingIndicator />}
          <a
            href={props.newImpactReportPath}
            className="btn btn-dark h-fit-content"
          >
            Create Impact Report
          </a>
        </div>
      </div>

      <Disclosure>
        <div className="mb-4 d-flex">
          <SearchInput
            onChange={(e) => {
              setQuery(e.currentTarget.value)
              // @ts-expect-error todo
              setParams({ q: e.currentTarget.value }, { replace: true })
            }}
            value={query}
            placeholder="Search by Report Number or Description"
          />
          <FiltersDisclosureButton />
        </div>

        <DisclosurePanel>
          <div className="border rounded border-pink position-relative">
            <div className="p-3">
              <ImpactReportSearchFilters
                setFilters={setFilters}
                filters={filters}
              />
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <ImpactReportListResults result={result} />

      {result.hasNextPage && (
        <button
          onClick={() => result.fetchNextPage()}
          type="button"
          className="btn btn-primary mb-3 mt-3"
          disabled={!!result.isFetchingNextPage}
        >
          {result.isLoading ? "Loading..." : "Load more"}
        </button>
      )}
    </>
  )
}

export default withBasics(ImpactReportsListScreen)
