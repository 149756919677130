import clsx from "clsx"
import React from "react"
import {
  ActiveClientOrderMakerFragment,
  ProductShowMakerFragment,
} from "../generated/graphql"

type AddProductToClientOrderButtonProps = {
  clientOrder: Pick<
    ActiveClientOrderMakerFragment,
    "id" | "newClientOrderProductUrl" | "makerOrders"
  >
  product: Pick<ProductShowMakerFragment, "id">
  className: string
}

const AddProductToClientOrderButton = ({
  clientOrder,
  product,
  className,
}: AddProductToClientOrderButtonProps) => {
  return (
    <div>
      <a
        href={`${clientOrder.newClientOrderProductUrl}?product_id=${product.id}`}
        className={clsx(className, "w-auto")}
      >
        Add to order
      </a>
    </div>
  )
}

export default AddProductToClientOrderButton
