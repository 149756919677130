import React, { ReactNode } from "react"
import { Tabs as ReachTabs } from "@reach/tabs"
import { withBasics } from "./withBasics"

type TabsProps = {
  children: ReactNode
  tabIds: string[]
}

const Tabs = (props: TabsProps) => (
  <ReachTabs
    defaultIndex={
      props.tabIds.includes(window.location.hash)
        ? props.tabIds.indexOf(window.location.hash)
        : 0
    }
    onChange={(index) => (window.location.hash = props.tabIds[index])}
  >
    {props.children}
  </ReachTabs>
)

export default withBasics(Tabs)
