import { useFormikContext } from "formik"
import debounce from "just-debounce-it"
import React, { useCallback } from "react"

export const AutoSave = ({ debounceMs }: { debounceMs: number }) => {
  const formik = useFormikContext()
  const [, setLastSaved] = React.useState<string | null>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => {
      if (!formik.dirty) return
      formik.submitForm().then(() => setLastSaved(new Date().toISOString()))
    }, debounceMs),
    [debounceMs, formik.submitForm, formik.dirty],
  )

  React.useEffect(() => {
    debouncedSubmit()
  }, [debouncedSubmit, formik.values])

  return null
}
