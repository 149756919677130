import React from "react"
import {
  UpdateClientOrderMutation,
  ClientOrderShowMakerFragment,
  useUpdateClientOrderMutation,
} from "../generated/graphql"
import CreatableSelect from "react-select/creatable"

const LeadSelect = (props: {
  clientOrder: ClientOrderShowMakerFragment
  label: string
  attribute: string
  defaultValue: any
  isDisabled?: boolean
}) => {
  const { clientOrder, label, attribute, defaultValue } = props
  const mutation = useUpdateClientOrderMutation()

  const handleChange = async (contactIds: any[]) => {
    const {
      updateClientOrder: result,
    }: UpdateClientOrderMutation = await mutation.mutateAsync({
      input: {
        id: clientOrder.id,
        [attribute]: contactIds,
      },
    })

    if (result && result.errors.length) {
      alert("Error updating lead")
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-between mb-2 w-100">
      <div style={{ width: "200px" }} className="mr-4">
        <label
          htmlFor={`client-order-${attribute}`}
          className="text-nowrap font-weight-bold mb-0 mr-3"
          style={{ fontSize: "20px" }}
        >
          {label}:
        </label>
      </div>

      <CreatableSelect
        id={`client-order-${attribute}`}
        menuPosition="fixed"
        isMulti
        isDisabled={props.isDisabled}
        onChange={(options) => {
          handleChange(options.map((option: any) => option.value))
        }}
        options={clientOrder.leadOptions.map((item: any) => ({
          label: item.fullName,
          value: item.id,
        }))}
        defaultValue={defaultValue.map((item: any) => ({
          label: item.fullName,
          value: item.id,
        }))}
        classNamePrefix="collection-select"
        className="w-300px"
        isValidNewOption={() => false}
      />
    </div>
  )
}

export default LeadSelect
