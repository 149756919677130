import React from "react"
import { withBoundary } from "./ErrorBoundary"
import "react-toggle/style.css"
import Modal from "./Modal"
import * as S from "./MakerActivateButton.styled"
import { withBasics } from "./withBasics"

interface MakerOrderShipmentDeliveredOnModalProps {
  isOpen: boolean
  onClose: () => void
  updateMakerOrder: (changes: any) => void
}

const MakerOrderShipmentDeliveredOnModal = ({
  isOpen,
  onClose,
  updateMakerOrder,
}: MakerOrderShipmentDeliveredOnModalProps) => {
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const values: Record<string, string> = {}
    formData.forEach((value, key) => {
      values[key] = value as string
    })

    updateMakerOrder({
      shipmentStatus: values.shipmentStatus,
      shipment: {
        deliveredOn: values.deliveredOn,
      },
    })
  }

  return (
    <S.Container className="mb-2 mt-2">
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Maker Order Delivered On Date</h5>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="mb-4 px-3">
                <p>Please enter the date of delivery for this maker order.</p>

                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="shipmentStatus"
                    value={"delivered"}
                  />
                  <label
                    htmlFor={"deliveredOn"}
                    className="form-control-label font-weight-bold"
                  >
                    {"Delivered On"}
                  </label>
                  <input
                    type="date"
                    name={"deliveredOn"}
                    max={new Date().toISOString().split("T")[0]}
                    className="form-control mb-2"
                    required
                  />

                  <div className="d-inline-flex align-items-center">
                    <div className="col-auto-w5 mr-2">
                      <button
                        type="submit"
                        className="btn btn-md btn-primary mr-2"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-md btn-secondary"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </Modal>
    </S.Container>
  )
}

export default withBoundary(withBasics(MakerOrderShipmentDeliveredOnModal))
