import VisuallyHidden from "@reach/visually-hidden"
import React from "react"
import Imgix, { SharedImigixAndSourceProps } from "react-imgix"
import styled from "styled-components"
import { ReactComponent as PencilSVG } from "../images/pencil.svg"
import { ReactComponent as StarSVG } from "../images/star.svg"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import { ReactComponent as DownloadSVG } from "../images/download.svg"
import { ReactComponent as EyeSVG } from "../images/eye.svg"
import { deleteRequest } from "../util/request"

function downloadUrl({
  imgixUrl,
  filename,
}: {
  imgixUrl: string
  filename: string
}) {
  const url = new URL(imgixUrl)
  url.searchParams.set("dl", filename)
  return url.toString()
}

export const ImageBackdrop = styled.div({
  "display": "grid",
  "position": "absolute",
  "flexDirection": "column",
  "alignItems": "flex-end",
  "justifyContent": "end",

  "top": 0,
  "bottom": 0,
  "left": 0,
  "right": 0,

  "backgroundColor": "rgba(0,0,0,0)",
  "transition": "background-color 150ms",

  ":hover": {
    backgroundColor: "rgba(0,0,0,0.85)",
  },
})

export const ImageOnHover = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 150ms;

  ${ImageBackdrop}:hover & {
    opacity: 1;
  }
`

export const ImageWithControls = (props: {
  imgixUrl: string
  filename: string
  includeInImpactReport?: boolean // only maker marketing images
  editUrl?: string
  resourceUrl?: string
  imgixParams?: SharedImigixAndSourceProps["imgixParams"]
}) => {
  const deleteImage = () => {
    if (
      props.resourceUrl &&
      window.confirm("Are you sure you want to delete this image?")
    ) {
      deleteRequest(props.resourceUrl).then(({ success }) => {
        if (success) window.location.reload()
      })
    }
  }

  return (
    <div className="d-flex flex-column text-decoration-none position-relative">
      <Imgix
        src={props.imgixUrl}
        className="w-100 shadow"
        htmlAttributes={{
          alt: "",
        }}
        sizes="200px"
        imgixParams={{
          "ar": `1:1`,
          "fit": `fill`,
          "fill-color": `white`,
          ...props.imgixParams,
        }}
      />
      <ImageBackdrop>
        {props.includeInImpactReport && (
          <a href={props.editUrl} className="text-white d-flex p-1">
            <StarSVG
              fill={props.includeInImpactReport ? "currentColor" : "none"}
              style={{ width: 12 }}
            />
            <VisuallyHidden>
              Include in impact report (
              {props.includeInImpactReport ? `Yes` : `No`})
            </VisuallyHidden>
          </a>
        )}
        <ImageOnHover>
          <a href={downloadUrl(props)} className="d-flex text-white p-1">
            <DownloadSVG />
            <VisuallyHidden>Download image</VisuallyHidden>
          </a>
        </ImageOnHover>
        <ImageOnHover>
          <a
            href={props.imgixUrl}
            className="d-flex text-white p-1"
            target="_blank"
            rel="noreferrer"
          >
            <EyeSVG style={{ width: 10 }} />
            <VisuallyHidden>View image</VisuallyHidden>
          </a>
        </ImageOnHover>
        {props.editUrl && (
          <ImageOnHover>
            <a href={props.editUrl} className="d-flex text-white p-1">
              <PencilSVG style={{ width: 10 }} />
              <VisuallyHidden>Edit</VisuallyHidden>
            </a>
          </ImageOnHover>
        )}
        {props.resourceUrl && (
          <ImageOnHover>
            <button
              onClick={deleteImage}
              className="border-0 p-0 bg-transparent d-flex p-1"
            >
              <TrashSVG
                style={{ width: 8, height: 10 }}
                className="text-danger"
              />
            </button>
          </ImageOnHover>
        )}
      </ImageBackdrop>
    </div>
  )
}
