import React from "react"
import { formatPercentage } from "../util/percentage"
import { EmptyValue } from "./EmptyValue"

const FormatPercentageMaybe = ({
  percentage,
}: {
  percentage: number | null | undefined
}) => {
  if (percentage == null) return <EmptyValue />
  return <>{formatPercentage(percentage)}</>
}

export { FormatPercentageMaybe }
