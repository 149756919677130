// @ts-nocheck
import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    $(this.element).on("show.bs.modal", (e) => this.loadContent(e))
  }

  loadContent(e) {
    let url

    if (this.data.get("source")) {
      url = this.data.get("source")
    } else if (this.data.get("path")) {
      url = this.data.get("path")
      const idString = e.relatedTarget.dataset.id
      url = url?.replace(/:id/g, idString)
    } else {
      url = e.relatedTarget.dataset.fullpath
    }

    fetch(url)
      .then((res) => res.text())
      .then((htmlResponse) => {
        this.contentTarget.innerHTML = htmlResponse
      })
  }
}
