import React from "react"
import { Field, FormikProvider, useFormik } from "formik"
import invariant from "tiny-invariant"
import { withBasics } from "./withBasics"
import LoadingIndicator from "./LoadingIndicator"
import { RequestError } from "./Errors"
import {
  useActiveMakerLegalAgreementsQuery,
  useMakerShowQuery,
  useCreateMakerOrderMutation,
  CreateMakerOrderMutation,
  CreateMakerOrderInput,
} from "../generated/graphql"
import { SelectWithFormikErrors } from "./WithFormikErrors"
import { Button, Label } from "./FormElements"
import ContactsFormSection from "./ContactsFormSection"

type MakerOrderCreateProps = {
  clientOrderId: string
  makerOptions: any
}

type CreateMakerOrderProps = {
  clientOrderId: string
  makerOptions: MakerOption[]
  legalAgreementOptions: LegalAgreementOption[]
}

type MakerOrderContactsWrapperProps = {
  makerSlug: string
  formik: any
}

type MakerOption = {
  id: string
  slug: string
  name: string
}

type LegalAgreementOption = {
  id: string
  name: string
}

const MakerOrderCreate = ({
  clientOrderId,
  makerOptions,
}: MakerOrderCreateProps) => {
  const result = useActiveMakerLegalAgreementsQuery()

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const legalAgreementOptions = result.data.activeMakerLegalAgreements

  return (
    <MakerOrderCreateForm
      clientOrderId={clientOrderId}
      makerOptions={makerOptions}
      legalAgreementOptions={legalAgreementOptions}
    />
  )
}

const MakerOrderCreateForm = ({
  clientOrderId,
  makerOptions,
  legalAgreementOptions,
}: CreateMakerOrderProps) => {
  const mutation = useCreateMakerOrderMutation()

  const formik = useFormik({
    initialValues: {
      contacts: [],
    },
    onSubmit: async (values: any, { setFieldValue, setFieldError }) => {
      const maker = makerOptions.find(
        (mo: MakerOption) => mo.slug === values.makerSlug,
      )
      const input: CreateMakerOrderInput = {
        clientOrderId: clientOrderId,
        makerId: maker?.id,
        ...values,
      }

      try {
        const {
          createMakerOrder: result,
        }: CreateMakerOrderMutation = await mutation.mutateAsync({
          input,
        })

        if (result && result.errors.length) {
          for (let error of result.errors)
            if (error.path) setFieldError(error.path[1], error.message)
        } else {
          window.location.href = `/maker_orders/${result.makerOrder.humanId}`
        }
      } catch (er) {
        window.alert(`An error occured: ${(er as Error).message}`)
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Label>Maker</Label>
          <Field name="makerSlug" id="maker_slug" as={SelectWithFormikErrors}>
            <option></option>
            {makerOptions.map(({ slug, name }) => (
              <option value={slug} key={slug}>
                {name}
              </option>
            ))}
          </Field>
        </div>

        <div className="form-group">
          <Label>Maker Legal Agreement</Label>
          <Field
            name="makerLegalAgreementId"
            id="maker_legal_agreement_id"
            as={SelectWithFormikErrors}
          >
            <option></option>
            {legalAgreementOptions.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </Field>

          <a
            href="/maker_legal_agreements"
            rel="noreferrer"
            target="_blank"
            className="form-text f-small-text"
          >
            Manage Legal Agreements
          </a>
        </div>

        {formik.values.makerSlug && (
          <MakerOrderContactsWrapper
            formik={formik}
            makerSlug={formik.values.makerSlug}
          />
        )}

        <div className="ml-auto">
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </FormikProvider>
  )
}

const MakerOrderContactsWrapper = ({
  formik,
  makerSlug,
}: MakerOrderContactsWrapperProps) => {
  const result = useMakerShowQuery({ slug: makerSlug })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)

  const makerContacts = result.data.maker.makerContacts.nodes.filter(
    (c) => c.archived === false,
  )
  return (
    <ContactsFormSection
      formik={formik}
      contactOptions={makerContacts.map((mc) => mc.contact)}
      initialContacts={[]}
    />
  )
}

export default withBasics(MakerOrderCreate)
