/**
 * checks if it's a valid-ish looking url pointing at a website
 */
function isLinkableUrl(value: string): boolean {
  try {
    const url = new URL(value)
    return url.protocol === "http:" || url.protocol === "https:"
  } catch (er) {
    return false
  }
}

export { isLinkableUrl }
