import React from "react"
import clsx from "clsx"
import VisuallyHidden from "@reach/visually-hidden"
import { ReactComponent as CopySVG } from "../images/copy.svg"

const DuplicateButton = ({
  duplicateUrl,
  className,
  anchor,
  variant = "full",
  width = 10,
}: {
  duplicateUrl: string
  className?: string
  anchor?: string
  variant?: "icon" | "full"
  width?: number
}) => {
  const url = new URL(duplicateUrl)

  if (anchor != null) url.hash = anchor
  const csrf = document
    .querySelector("meta[name=csrf-token]")
    ?.getAttribute("content")
  return (
    <form action={url.toString()} method="POST">
      <input
        type="hidden"
        value={csrf || undefined}
        name="authenticity_token"
      />
      <button
        className={clsx(
          "d-flex align-self-center align-items-center text-dark-pink btn btn-link",
          className,
        )}
      >
        <CopySVG style={{ width }} />
        {variant === "full" ? (
          <div className="small pl-1">Duplicate</div>
        ) : variant === "icon" ? (
          <VisuallyHidden>Duplicate</VisuallyHidden>
        ) : null}
      </button>
    </form>
  )
}

export default DuplicateButton
