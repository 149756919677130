import { Disclosure, DisclosurePanel } from "@reach/disclosure"
import find from "lodash/find"
import React, { useState } from "react"
import { useDebounce } from "use-debounce/lib"
import {
  Certification,
  MakerSort,
  MakerSortField,
  SortOrder,
  useMakerSearchQuery,
  MakerAttribute,
} from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { pluralize } from "../util/pluralize"
import LoadingIndicator from "./LoadingIndicator"
import MakerSearchFilters, { SlimProductCategory } from "./MakerSearchFilters"
import MakerSearchResultsTable from "./MakerSearchResultsTable"
import { FiltersDisclosureButton, SearchInput } from "./SearchElements"
import { withBasics } from "./withBasics"

export const NAME_SORT_OPTION: MakerSort = {
  attribute: MakerSortField.Name,
  order: SortOrder.Asc,
}
export const MOST_RECENT_ORDER_OPTION: MakerSort = {
  attribute: MakerSortField.MostRecentOrder,
  order: SortOrder.Asc,
}
const DEFAULT_SORT_OPTIONS: MakerSort[] = [NAME_SORT_OPTION]

const MakerSearchScreen = () => {
  const [searchInput, setSearchInput] = useState("")
  const [searchInputDebounced] = useDebounce(searchInput, 400)

  // Filters
  const [statuses, setStatuses] = useState<Array<string>>([])
  const [productionCapacities, setProductionCapacities] = useState([])
  const [certifications, setCertifications] = useState<
    Array<Certification["id"]>
  >([])
  const [makerAttributes, setMakerAttributes] = useState<Array<MakerAttribute>>(
    [],
  )
  const [productCategories, setProductCategories] = useState<
    Array<SlimProductCategory>
  >([])
  const [productSubcategoryIds, setProductSubcategoryIds] = useState<
    Array<string>
  >([])
  const [costRangeEstimates, setCostRangeEstimates] = useState([])
  const [shippingCountries, setShippingCountries] = useState([])
  const [environments, setEnvironments] = useState([])
  const [capabilities, setCapabilities] = useState([])
  const [materialIds, setMaterialIds] = useState<Array<string>>([])
  const [materialSubcategoryIds, setMaterialSubcategoryIds] = useState<
    Array<string>
  >([])

  // Sorting
  const [sorts, setSorts] = useState(DEFAULT_SORT_OPTIONS)
  const toggleColumnSort = (sort: MakerSort) => {
    let existingSort: MakerSort | undefined = find(sorts, {
      attribute: sort.attribute,
    })
    if (existingSort) {
      if (existingSort.order === SortOrder.Asc) {
        existingSort.order = SortOrder.Desc
      } else if (existingSort.order === SortOrder.Desc) {
        existingSort.order = SortOrder.Asc
      }

      setSorts([existingSort])
    } else {
      setSorts([sort])
    }
  }

  const result = useInfiniteGraphQLQuery(
    useMakerSearchQuery,
    ({ pageParam }) => ({
      query: searchInputDebounced,
      statuses,
      costRangeEstimates,
      productionCapacities,
      certifications,
      makerAttributeIds: makerAttributes.map((attribute) => attribute.id),
      environments,
      capabilities,
      sorts,
      productCategoryIds: productCategories.map((category) => category.id),
      productSubcategoryIds,
      materialIds,
      materialSubcategoryIds,
      // @ts-expect-error todo
      shippingCountryIds: shippingCountries.map((country) => country.id),
      after: pageParam,
    }),
    {
      getNextPageParam: (page) => page.makers.pageInfo.endCursor ?? undefined,
    },
  )

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center mt-6">
        <div className="d-flex mr-auto">
          <h1 className="my-0 mr-3 f-title mb-5">Makers</h1>
          {result.isFetching && <LoadingIndicator />}
        </div>
        <div>
          <a className="btn btn-dark" href="/makers/new">
            New Maker
          </a>
        </div>
      </div>

      <Disclosure>
        <div className="mb-4 d-flex">
          <SearchInput
            placeholder="Search by Maker Name or Maker ID..."
            aria-label="Search"
            autoFocus={true}
            onChange={(e) => setSearchInput(e.currentTarget.value)}
            value={searchInput}
          />
          <FiltersDisclosureButton />
        </div>
        <DisclosurePanel>
          <MakerSearchFilters
            setStatuses={setStatuses}
            statuses={statuses}
            productionCapacities={productionCapacities}
            // @ts-expect-error todo
            setProductionCapacities={setProductionCapacities}
            certifications={certifications}
            setCertifications={setCertifications}
            makerAttributes={makerAttributes}
            setMakerAttributes={setMakerAttributes}
            shippingCountries={shippingCountries}
            // @ts-expect-error todo
            setShippingCountries={setShippingCountries}
            environments={environments}
            // @ts-expect-error todo
            setEnvironments={setEnvironments}
            capabilities={capabilities}
            // @ts-expect-error todo
            setCapabilities={setCapabilities}
            costRangeEstimates={costRangeEstimates}
            // @ts-expect-error todo
            setCostRangeEstimates={setCostRangeEstimates}
            productCategories={productCategories}
            setProductCategories={setProductCategories}
            productSubcategoryIds={productSubcategoryIds}
            setProductSubcategoryIds={setProductSubcategoryIds}
            materialIds={materialIds}
            setMaterialIds={setMaterialIds}
            materialSubcategoryIds={materialSubcategoryIds}
            setMaterialSubcategoryIds={setMaterialSubcategoryIds}
          />
        </DisclosurePanel>
      </Disclosure>
      <>
        Results:{" "}
        {result.status === "success"
          ? pluralize({
              zero: "No results",
              singular: "1 Maker",
              plural: "{{count}} Makers",
              count: result.data.pages[0].makers.totalCount,
            })
          : "…"}
      </>

      <MakerSearchResultsTable
        result={result}
        sorts={sorts}
        onColumnSortClick={toggleColumnSort}
      />

      {result.hasNextPage && (
        <button
          onClick={() => result.fetchNextPage()}
          type="button"
          className="btn btn-primary mb-3"
          disabled={result.isFetchingNextPage}
        >
          {result.isLoading ? "Loading..." : "Load more"}
        </button>
      )}
    </div>
  )
}

export default withBasics(MakerSearchScreen)
