import React, { useState } from "react"
import Toggle from "react-toggle"
import "react-toggle/style.css"
import invariant from "tiny-invariant"
import {
  ModeOfTransit,
  Scalars,
  ShippingPortLocation,
} from "../generated/graphql"
import CogsTableShippingRate from "./CogsTableShippingRate"
import ModeOfTransitSelect from "./ModeOfTransitSelect"
import ShippingPortSelect from "./ShippingPortSelect"
import { withBasics } from "./withBasics"

const CogsTableSettings = ({
  mode,
  productId,
  productGroupId,
  makerId,
  defaultAirShippingPortId,
  defaultSeaShippingPortId,
  otherAirShippingPortId,
  otherSeaShippingPortId,
}: {
  mode: "edit" | "view"
  productId: Scalars["ID"] | null
  productGroupId: Scalars["ID"]
  makerId: Scalars["ID"]
  defaultAirShippingPortId?: Scalars["ID"]
  defaultSeaShippingPortId?: Scalars["ID"]
  otherAirShippingPortId?: Scalars["ID"]
  otherSeaShippingPortId?: Scalars["ID"]
}) => {
  const defaultExportPortId =
    defaultAirShippingPortId ||
    defaultSeaShippingPortId ||
    otherAirShippingPortId ||
    otherSeaShippingPortId
  const [exportPortId, setExportPortId] = useState<Scalars["ID"] | null>(
    defaultExportPortId ?? null,
  )
  const defaultModeOfTransit = ([
    defaultAirShippingPortId,
    otherAirShippingPortId,
  ] as Array<Scalars["ID"] | null>).includes(exportPortId)
    ? ModeOfTransit.Air
    : ModeOfTransit.Sea

  const [modeOfTransit, setModeOfTransit] = useState<ModeOfTransit>(
    defaultModeOfTransit,
  )
  const [importPortId, setImportPortId] = useState<string | null>(null)
  const onExportShippingPortChange = (option: { value: string } | null) => {
    if (option) {
      const { value } = option
      setExportPortId(value)
    } else {
      setExportPortId(null)
    }
  }
  const onImportShippingPortChange = (option: { value: string } | null) => {
    if (option) {
      const { value } = option
      setImportPortId(value)
    } else {
      setImportPortId(null)
    }
  }
  const [includeShippingCost, setIncludeShippingCost] = useState(false)
  const [includeDutyRate, setIncludeDutyRate] = useState(false)
  const onIncludeShippingCostChange = () =>
    setIncludeShippingCost(!includeShippingCost)
  const onIncludeDutyRateChange = () => setIncludeDutyRate(!includeDutyRate)
  const onModeOfTansitChange = (newValue: { value: ModeOfTransit } | null) => {
    invariant(newValue, `expected option`)
    setModeOfTransit(newValue.value)
    setImportPortId(null)

    if (newValue.value === ModeOfTransit.Air) {
      setExportPortId(
        defaultAirShippingPortId ?? otherAirShippingPortId ?? null,
      )
    } else {
      setExportPortId(
        defaultSeaShippingPortId ?? otherSeaShippingPortId ?? null,
      )
    }
  }

  return (
    <div>
      <div className="rounded bg-pink mt-4 mb-4 px-4 py-3">
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <label htmlFor="exportShippingPort" className="text-nowrap">
              Mode of Transit
            </label>
            <ModeOfTransitSelect
              value={modeOfTransit}
              onChange={onModeOfTansitChange}
            />
          </div>
          <div className="col">
            <label htmlFor="exportShippingPort" className="text-nowrap">
              Export Shipping Port
            </label>
            <ShippingPortSelect
              makerId={makerId}
              locationType={ShippingPortLocation.Export}
              modeOfTransit={modeOfTransit}
              onChange={onExportShippingPortChange}
              value={exportPortId}
            />
          </div>
          <div className="col">
            <label htmlFor="importShippingPort" className="text-nowrap">
              Import Shipping Port
            </label>
            <ShippingPortSelect
              value={importPortId}
              locationType={ShippingPortLocation.Import}
              modeOfTransit={modeOfTransit}
              onChange={onImportShippingPortChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label htmlFor="includeShippingCost">Include Shipping Cost</label>
            <Toggle
              id="shipping-rate-toggle"
              className="ml-2"
              checked={includeShippingCost}
              onChange={onIncludeShippingCostChange}
              disabled={importPortId === null || exportPortId === null}
            />
          </div>
          <div className="col">
            <label htmlFor="includeDutyRate">Include Duties</label>
            <Toggle
              id="duty-rate-toggle"
              className="ml-2"
              checked={includeDutyRate}
              onChange={onIncludeDutyRateChange}
              disabled={importPortId === null || exportPortId === null}
            />
          </div>
        </div>
      </div>

      <CogsTableShippingRate
        exportPortId={exportPortId}
        importPortId={importPortId}
        includeShippingCost={includeShippingCost}
        includeDutyRate={includeDutyRate}
        productId={productId}
        productGroupId={productGroupId}
        mode={mode}
      />
    </div>
  )
}

export default withBasics(CogsTableSettings)
