import { getYear } from "date-fns"
import { Field, FormikProvider, useFormik, useFormikContext } from "formik"
import React, { useState } from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import * as Yup from "yup"
import {
  Certification,
  ClientOrder,
  ClientOrderShowMakerFragment,
  ImpactReportShowMakerFragment,
  MakerMarketingImage,
  useClientOrderShowQuery,
  useClientOrdersQuery,
  useImpactReportMutation,
  useImpactReportShowQuery,
} from "../generated/graphql"
import { changedValues } from "../util/changed-values"
import {
  BorderBox,
  BorderBoxContainer,
  BorderBoxEmpty,
  BorderBoxHeading,
} from "./BorderBox"
import Checkbox from "./Checkbox"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import { Button } from "./FormElements"
import { ImpactReportMakersForm } from "./ImpactReportMakers"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import {
  SelectWithFormikErrors,
  TextAreaWithFormikErrors,
} from "./WithFormikErrors"

type ImpactReportProps = {
  impactReport: ImpactReportShowMakerFragment
  clientOrder?: ClientOrderShowMakerFragment
  variant: "edit" | "new"
}

const ImpactReportFormWrapper = (props: {
  id?: string
  clientOrderId?: string
  variant: "edit" | "new"
}) => {
  const impactReportResult = useImpactReportShowQuery({ id: props.id || "" })

  if (impactReportResult.status === "error")
    return <RequestError {...impactReportResult} />

  if (impactReportResult.status === "loading") return <LoadingIndicator />

  invariant(impactReportResult.data, `expected data`)

  const { impactReport } = impactReportResult.data

  if (props.clientOrderId) {
    return (
      <ClientOrderWrapper
        impactReport={impactReport}
        clientOrderId={props.clientOrderId}
        variant={props.variant}
      />
    )
  } else {
    return (
      <ImpactReportForm impactReport={impactReport} variant={props.variant} />
    )
  }
}

const ClientOrderWrapper = (props: {
  impactReport: ImpactReportShowMakerFragment
  clientOrderId: string
  variant: "edit" | "new"
}) => {
  const clientOrderResult = useClientOrderShowQuery({ id: props.clientOrderId })

  if (clientOrderResult.status === "error")
    return <RequestError {...clientOrderResult} />
  if (clientOrderResult.status === "loading") return <LoadingIndicator />

  invariant(clientOrderResult.data, `expected data`)
  const { clientOrder } = clientOrderResult.data

  return (
    <ImpactReportForm
      impactReport={props.impactReport}
      clientOrder={clientOrder}
      variant={props.variant}
    />
  )
}

const ImpactReportCopySelect = ({
  impactReport,
  type,
  name,
  label,
}: {
  impactReport: ImpactReportShowMakerFragment
  type: string
  name: string
  label: string
}) => (
  <>
    <div className="w-100 text-right mb-1 f-small-text">
      <a href={impactReport.impactReportCopiesUrl}>Manage</a>
    </div>
    <div className="form-group d-flex">
      <label className="w5 col-form-label pr-2 text-uppercase">{label}</label>
      <div className="w-100">
        <Field name={name} as={SelectWithFormikErrors}>
          <option value=""></option>
          {impactReport.impactReportCopyOptions
            .filter((irc) => irc.type === type)
            .map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
        </Field>
      </div>
    </div>
  </>
)

const validationSchema = Yup.object().shape({
  clientId: Yup.string().required("Type is required").nullable(),
  type: Yup.string().required("Client is required").nullable(),
  description: Yup.string()
    .nullable()
    .when("type", {
      is: "single",
      otherwise: Yup.string().required("Descripton is required").nullable(),
    }),
  year: Yup.string()
    .nullable()
    .when("type", {
      is: "yearly",
      then: Yup.string().required("Year is required").nullable(),
    }),
})

type FieldValues = ImpactReportShowMakerFragment & {
  clientOrderIds?: Array<ClientOrder["id"]>
  makerMarketingImageId?: MakerMarketingImage["id"]
  certificationIds: Array<Certification["id"]>
}

const ImpactReportForm = ({
  impactReport,
  clientOrder,
  variant,
}: ImpactReportProps) => {
  const [step, setStep] = useState(1)
  const mutation = useImpactReportMutation()

  const initialValues: FieldValues = {
    ...impactReport,
    makerMarketingImageId: impactReport.makerMarketingImage?.id,
    certificationIds: impactReport.certifications.nodes.map((cert) => cert.id),
  }

  if (clientOrder) {
    initialValues.type = "single"
    initialValues.description = clientOrder.description
    initialValues.clientOrderIds = [clientOrder.id]
    initialValues.clientId = clientOrder.client.id
  }

  const formik = useFormik<FieldValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: clientOrder ? null : validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      // Skip step 2 if creating an impact report from a client order,
      // or if editing an existing record
      if (!clientOrder && !impactReport.id && step === 1) {
        setStep(2)
        return
      }

      const params = changedValues(impactReport, values)

      const result = await mutation.mutateAsync(
        {
          input: {
            id: impactReport.id,
            ...params,
          },
        },
        {
          onError: (er) => {
            window.alert(`An error occured: ${er}`)
          },
        },
      )

      if (result && result.impactReport.errors.length) {
        for (let error of result.impactReport.errors)
          if (error.path) {
            setFieldError(error.path[1], error.message)
          }
      } else {
        window.location.href = result.impactReport.impactReport.showUrl
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {mutation.error ? <RequestError error={mutation.error} /> : null}

        <div className="container pt-4">
          <div className="d-flex mb-4">
            <h1 className="f-title mb-4 mr-auto">
              {impactReport.id ? "Update" : "Create"} Impact Report
            </h1>
            <div className="d-flex justify-content-end align-items-baseline mt-4">
              {step === 1 ? (
                <a
                  href={
                    impactReport.id
                      ? impactReport.showUrl
                      : impactReport.indexUrl
                  }
                  className="text-danger mr-4"
                >
                  Cancel
                </a>
              ) : (
                <span
                  onClick={() => {
                    setStep(step - 1)
                    formik.setFieldValue("clientOrderIds", undefined)
                  }}
                  className="text-danger mr-4"
                  style={{ cursor: "pointer" }}
                >
                  Back
                </span>
              )}
              <Button variant="dark" type="submit">
                Continue
              </Button>
            </div>
          </div>
          {step === 1 && (
            <ImpactReportFormStep1
              impactReport={impactReport}
              clientOrder={clientOrder}
              variant={variant}
            />
          )}
          {step === 2 && (
            <ImpactReportFormStep2
              impactReport={impactReport}
              variant={variant}
            />
          )}
        </div>
      </form>
    </FormikProvider>
  )
}

const humanReadableImpactReportType = (type: string) => {
  switch (type) {
    case "single":
      return "Single"
    case "yearly":
      return "Yearly"
    case "all_time_basis":
      return "All-Time Basis"
  }
}

const MaybeImpactReportMakersForm = ({ id }: { id: string }) => {
  const result = useImpactReportShowQuery({ id: id })
  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const impactReport = result.data.impactReport

  return <ImpactReportMakersForm impactReport={impactReport} readOnly={false} />
}

const ImpactReportFormStep1 = ({
  impactReport,
  clientOrder,
  variant,
}: ImpactReportProps) => {
  const formik = useFormikContext<FieldValues>()
  const values = formik.values

  return (
    <>
      {clientOrder || impactReport.id ? (
        <div className="d-flex mb-4">
          <div style={{ marginRight: "100px" }}>
            <span className="font-weight-bold pr-4">Client</span>
            {clientOrder ? clientOrder.client.name : impactReport.client?.name}
          </div>
          <div style={{ marginRight: "100px" }}>
            <span className="font-weight-bold pr-4">Type</span>
            {humanReadableImpactReportType(impactReport.type || "")}
          </div>
          {clientOrder && (
            <div>
              <span className="font-weight-bold pr-4">Client Project</span>#
              {clientOrder.humanId}
            </div>
          )}
        </div>
      ) : (
        <div className="row mb-4">
          <div className="col">
            <div className="form-group d-flex">
              <label className="w5 col-form-label pr-2 text-uppercase text-nowrap">
                Client:
              </label>
              <div className="w-100">
                <Field name="clientId" as={SelectWithFormikErrors}>
                  <option></option>
                  {impactReport.clientOptions.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group d-flex">
              <label className="w5 col-form-label pr-2 text-uppercase text-nowrap">
                Type:
              </label>
              <div className="w-100">
                <Field name="type" as={SelectWithFormikErrors}>
                  <option></option>
                  {impactReport.typeOptions.map((type) => (
                    <option value={type} key={type}>
                      {humanReadableImpactReportType(type)}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </div>
          {values.type === "yearly" ? (
            <div className="col">
              <div className="form-group d-flex">
                <label className="w5 col-form-label pr-2 text-uppercase text-nowrap">
                  Year:
                </label>
                <div className="w-100">
                  <Field name="year" as={SelectWithFormikErrors}>
                    <option></option>
                    {Array.from(
                      { length: getYear(new Date()) - 2020 },
                      (v, i) => 2021 + i,
                    ).map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
          ) : (
            <div className="col"></div>
          )}
        </div>
      )}
      {(values.type === "yearly" || values.type === "all_time_basis") && (
        <div className="row mb-4">
          <div className="col">
            <label className="col-form-label pr-2 text-uppercase text-nowrap">
              Description:
            </label>
            <div className="w-100">
              <Field name="description" as={TextAreaWithFormikErrors} />
            </div>
          </div>
        </div>
      )}
      <div className="row mb-4 pt-4">
        <div className="col">
          <ImpactReportCopySelect
            impactReport={impactReport}
            type="overview"
            name="overviewCopyId"
            label="Overview Description:"
          />
        </div>
        <div className="col">
          <ImpactReportCopySelect
            impactReport={impactReport}
            type="impact_description"
            name="impactDescriptionCopyId"
            label="Impact Description:"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <ImpactReportCopySelect
            impactReport={impactReport}
            type="carbon_offsetting_description"
            name="carbonOffsettingDescriptionCopyId"
            label="Carbon Offsetting Calculation Description:"
          />
        </div>
        <div className="col">
          <ImpactReportCopySelect
            impactReport={impactReport}
            type="disclaimer_notes"
            name="disclaimerNotesCopyId"
            label="Disclaimer Notes:"
          />
        </div>
      </div>
      {(values.type === "yearly" || values.type === "all_time_basis") && (
        <div className="row mb-4">
          <div className="col">
            <ImpactReportCopySelect
              impactReport={impactReport}
              type="conclusion"
              name="conclusionCopyId"
              label="Conclusion:"
            />
          </div>
          <div className="col"></div>
        </div>
      )}

      {impactReport.id &&
        impactReport.type === "single" &&
        variant === "edit" && (
          <MaybeImpactReportMakersForm id={impactReport.id} />
        )}

      {impactReport.id &&
      (impactReport.type === "yearly" ||
        impactReport.type === "all_time_basis") ? (
        <>
          <BorderBoxContainer>
            <BorderBoxHeading>Marketing Photos</BorderBoxHeading>
            <BorderBox>
              <div className="d-grid grid-cols-6 gap-4 mb-2">
                {impactReport.makerMarketingImages.nodes.map((mmi) => (
                  <div className="d-flex flex-column text-decoration-none position-relative">
                    <Imgix
                      src={mmi.imgixUrl}
                      className="w-100 shadow"
                      htmlAttributes={{
                        alt: "",
                        style: {
                          aspectRatio: `1 / 1`,
                        },
                      }}
                      sizes="200px"
                      imgixParams={{
                        "ar": `2:1`,
                        "fit": `fill`,
                        "fill-color": `white`,
                      }}
                    />
                    <input
                      type="radio"
                      value={mmi.id}
                      name="maker_marketing_image_id"
                      className="accent-primary position-absolute top-0 right-0 mr-2 mt-2"
                      checked={formik.values.makerMarketingImageId === mmi.id}
                      onChange={(ev) => {
                        formik.setFieldValue(
                          "makerMarketingImageId",
                          ev.currentTarget.value,
                        )
                      }}
                    />
                  </div>
                ))}
              </div>
            </BorderBox>
          </BorderBoxContainer>

          <BorderBoxContainer>
            <BorderBoxHeading>Certifications</BorderBoxHeading>
            <BorderBox>
              <ul>
                {impactReport.certificationOptions.map((cert) => (
                  <li key={cert.id}>
                    <input
                      id={`checkbox-${cert.id}`}
                      type="checkbox"
                      className="form-check-input"
                      checked={formik.values.certificationIds.includes(cert.id)}
                      onChange={(ev) => {
                        let prev = formik.values.certificationIds
                        let next = ev.target.checked
                          ? [...prev, cert.id]
                          : prev.filter((x) => x !== cert.id)
                        formik.setFieldValue("certificationIds", next)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${cert.id}`}
                    >
                      {cert.name}
                    </label>
                  </li>
                ))}
              </ul>
            </BorderBox>
          </BorderBoxContainer>
        </>
      ) : null}
    </>
  )
}

const ImpactReportFormStep2 = ({ impactReport }: ImpactReportProps) => {
  const formik = useFormikContext()
  const values: any = formik.values

  const result = useClientOrdersQuery({
    clientId: values.clientId,
    year: values.year,
  })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const clientOrders = result.data.clientOrders

  if (values.type !== "single" && !values.clientOrderIds)
    setTimeout(() =>
      formik.setFieldValue(
        "clientOrderIds",
        clientOrders.nodes.map((clientOrder) => clientOrder.id),
      ),
    )

  const overviewCopy = impactReport.impactReportCopyOptions.find(
    (irc) => irc.id === values.overviewCopyId,
  )
  const impactDescriptionCopy = impactReport.impactReportCopyOptions.find(
    (irc) => irc.id === values.impactDescriptionCopyId,
  )
  const carbonOffsettingDescriptionCopy = impactReport.impactReportCopyOptions.find(
    (irc) => irc.id === values.carbonOffsettingDescriptionCopyId,
  )
  const conclusionCopy = impactReport.impactReportCopyOptions.find(
    (irc) => irc.id === values.conclusionCopyId,
  )
  const disclaimerNotesCopy = impactReport.impactReportCopyOptions.find(
    (irc) => irc.id === values.disclaimerNotesCopyId,
  )
  const client = impactReport.clientOptions.find(
    (client) => client.id === values.clientId,
  )

  const validClientOrders = clientOrders.nodes.filter(
    (clientOrder) => values.type !== "single" || !clientOrder.impactReport,
  )

  return (
    <>
      <div className="d-flex">
        <div style={{ marginRight: "100px" }}>
          <span className="font-weight-bold pr-4">Client Name</span>
          {client ? client.name : <EmptyValue variant="dash" />}
        </div>
        <div style={{ marginRight: "100px" }}>
          <span className="font-weight-bold pr-4">Type</span>
          {humanReadableImpactReportType(values.type)}
        </div>
        {values.type === "yearly" && (
          <div>
            <span className="font-weight-bold pr-4">Year</span>
            {values.year}
          </div>
        )}
      </div>

      {values.description && (
        <BorderBoxContainer>
          <BorderBoxHeading>Description</BorderBoxHeading>
          <BorderBox>{values.description}</BorderBox>
        </BorderBoxContainer>
      )}

      {overviewCopy && (
        <BorderBoxContainer>
          <BorderBoxHeading>Overview Description</BorderBoxHeading>
          <BorderBox>{overviewCopy.content}</BorderBox>
        </BorderBoxContainer>
      )}
      {impactDescriptionCopy && (
        <BorderBoxContainer>
          <BorderBoxHeading>Impact Description</BorderBoxHeading>
          <BorderBox>{impactDescriptionCopy.content}</BorderBox>
        </BorderBoxContainer>
      )}
      {carbonOffsettingDescriptionCopy && (
        <BorderBoxContainer>
          <BorderBoxHeading>Carbon Offsetting Description</BorderBoxHeading>
          <BorderBox>{carbonOffsettingDescriptionCopy.content}</BorderBox>
        </BorderBoxContainer>
      )}
      {conclusionCopy && (
        <BorderBoxContainer>
          <BorderBoxHeading>Conclusion</BorderBoxHeading>
          <BorderBox>{conclusionCopy.content}</BorderBox>
        </BorderBoxContainer>
      )}
      {disclaimerNotesCopy && (
        <BorderBoxContainer>
          <BorderBoxHeading>Disclaimer Notes</BorderBoxHeading>
          <BorderBox>{disclaimerNotesCopy.content}</BorderBox>
        </BorderBoxContainer>
      )}

      <BorderBoxContainer>
        <BorderBoxHeading>Client Projects</BorderBoxHeading>
        {validClientOrders.length > 0 ? (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Description</th>
                  <th>Products</th>
                  <th>Makers</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {validClientOrders.map((clientOrder) => (
                  <tr key={clientOrder.id}>
                    <td>
                      <a href={clientOrder.showUrl}>#{clientOrder.humanId}</a>
                    </td>
                    <td>{clientOrder.description}</td>
                    <td>{clientOrder.productCount}</td>
                    <td>
                      {clientOrder.makers.nodes.length > 0 ? (
                        clientOrder.makers.nodes.map((maker) => (
                          <div key={maker.id}>
                            <a href={maker.showUrl}>{maker.name}</a>
                          </div>
                        ))
                      ) : (
                        <EmptyValue variant="message" />
                      )}
                    </td>
                    <td>
                      <Checkbox
                        onChange={() => {
                          if (values.type === "single") {
                            formik.setFieldValue("clientOrderIds", [
                              clientOrder.id,
                            ])
                          } else {
                            if (!values.clientOrderIds) {
                              formik.setFieldValue("clientOrderIds", [
                                clientOrder.id,
                              ])
                            } else if (
                              values.clientOrderIds.includes(clientOrder.id)
                            ) {
                              formik.setFieldValue(
                                "clientOrderIds",
                                values.clientOrderIds.filter(
                                  (id: string) => id !== clientOrder.id,
                                ),
                              )
                            } else {
                              formik.setFieldValue("clientOrderIds", [
                                ...values.clientOrderIds,
                                clientOrder.id,
                              ])
                            }
                          }
                        }}
                        checked={
                          values.clientOrderIds &&
                          values.clientOrderIds.includes(clientOrder.id)
                        }
                        label=""
                        id={`clientOrder${clientOrder.id}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>
            No Client Projects are availale for selection.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>
    </>
  )
}

export default withBasics(ImpactReportFormWrapper)
