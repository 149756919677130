import assertNever from "assert-never"
import configureMeasurements, {
  AllMeasures,
  AllMeasuresSystems,
  AllMeasuresUnits,
  Converter,
  length,
  mass,
} from "convert-units"
import { useState } from "react"

export type MeasurementStandard = "imperial" | "metric"

/** @see https://github.com/convert-units/convert-units/issues/221 */
type HackyButBetterTypeForConvert = (
  value?: number | undefined,
) => Converter<AllMeasures, AllMeasuresSystems, AllMeasuresUnits>

export const convert = (configureMeasurements({
  mass,
  // @ts-expect-error https://github.com/convert-units/convert-units/issues/221
  length,
}) as unknown) as HackyButBetterTypeForConvert

export const useUnitState = () => {
  const [unitSystem, setUnitSystem] = useState<MeasurementStandard>("metric")

  return {
    unitSystem,
    setUnitSystem,
  }
}

export const unitOptions = {
  mass: ["kg", "lb"],
  length: ["cm", "in"],
} as const

export const getSystemUnits = (system: MeasurementStandard) => {
  switch (system) {
    case "imperial":
      return {
        mass: "lb",
        length: "in",
      } as const
    case "metric":
      return {
        mass: "kg",
        length: "cm",
      } as const
    default:
      assertNever(system)
  }
}
